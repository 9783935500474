import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useRef, useState } from 'react'
import firebase from 'firebase/app'
import { reactLocalStorage } from 'reactjs-localstorage'
import { NotificationManager } from 'react-notifications'
import 'react-notifications/lib/notifications.css'
import { useAuth } from '../services/AuthContext'

export default function AddUser({ users, setUsers }) {
  const [open, setOpen] = useState(false)
  const cancelButtonRef = useRef()
  const [infoClick, setinfoClick] = useState(true)
  const [userClick, setUserClick] = useState(false)
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [gender, setGender] = useState('select')
  const [people, setPeople] = useState(false)
  const [attendance, setAttendance] = useState(false)
  const [payment, setPayment] = useState(false)
  const [calendar, setCalendar] = useState(false)
  const [assessment, setAssessment] = useState(false)
  const [message, setMessage] = useState(false)
  const [school, setSchool] = useState(false)
  const [subscription, setSubscription] = useState(false)
  const [roleField, setRoleField] = useState([])
  const { signUp } = useAuth()

  const tabDefaultStyle =
    'text-primary md:text-white text-xs md:text-sm   uppercase py-4 px-4 md:px-6 block focus:outline-none'
  const tabClickStyle =
    'uppercase text-sm md:text-sm  py-4 px-4 md:px-6 block  focus:outline-none text-primary md:text-white  border-b-2 font-medium border-primary md:border-white'
  const saveButtonDefault =
    'px-3 py-1.5  rounded-sm outline-none focus:outline-none text-gray-300  bg-gray-100'
  const saveButtonClick =
    'px-3 py-1.5  rounded-sm outline-none focus:outline-none text-white  bg-primary'
  const [errorMessage, setError] = useState('')
  const [loading, setLoading] = useState(false)

  const handleTabClick = (tab) => {
    switch (tab) {
      case 'info': {
        setinfoClick(true)
        setUserClick(false)
        break
      }
      case 'role': {
        setUserClick(true)
        setinfoClick(false)
        break
      }
    }
  }

  function closeModal() {
    setOpen(false)
    setName('')
    setPhone('')
    setEmail('')
    setGender('')
    setinfoClick(false)
    setUserClick(false)
  }

  function openModal() {
    setinfoClick(true)
    setUserClick(false)
    setOpen(true)
  }

  const handleSave = () => {
    if (name !== '' && phone !== '' && email !== '' && gender !== '') {
      checkPhone()
    } else {
      NotificationManager.info('Enter the require fields')
    }
  }

  const checkPhone = () => {
    setError('')
    setLoading(true)
    var db = firebase.firestore()
    db.collection('user')
      .where('phone', '==', phone)
      .get()
      .then((querySnapshot) => {
        if (querySnapshot.size === 0) {
          checkEmail()
        } else {
          setLoading(false)
          NotificationManager.info('Phone number already been used')
        }
      })
      .catch((error) => {
        setError(error.message)
      })
  }

  const checkEmail = () => {
    setError('')
    setLoading(true)
    var db = firebase.firestore()
    db.collection('user')
      .where('email', '==', email)
      .get()
      .then((querySnapshot) => {
        if (querySnapshot.size === 0) {
          // save()
          handleSignUp(email, phone)
        } else {
          setLoading(false)
          NotificationManager.info('Email has already been used')
        }
      })
      .catch((error) => {
        setError(error.message)
      })
  }

  const save = () => {
    if (people) {
      roleField.push('people')
    }
    if (assessment) {
      roleField.push('assessment')
    }
    if (attendance) {
      roleField.push('attendance')
    }
    if (payment) {
      roleField.push('payment')
    }
    if (calendar) {
      roleField.push('calendar')
    }
    if (message) {
      roleField.push('message')
    }
    if (school) {
      roleField.push('school')
    }
    if (subscription) {
      roleField.push('subscription')
    }

    if (roleField.length === 0) {
      setLoading(false)
      return NotificationManager.error('Select pages')
    }

    var db = firebase.firestore()
    db.collection('user')
      .add({
        school: reactLocalStorage.get('current-school'),
        schoolname: reactLocalStorage.get('school-name'),
        name: name,
        phone: phone,
        email: email,
        gender: gender,
        active: true,
        roles: roleField,
        role: 'school-user',
        state: reactLocalStorage.get('state'),
        // photo: reactLocalStorage.get('photo'),
        heading: reactLocalStorage.get('heading'),
        date: firebase.firestore.Timestamp.now(),
      })
      .then((docRef) => {
        var u = [...users]
        u.push({
          doc_id: docRef.id,
          school: reactLocalStorage.get('current-school'),
          schoolname: reactLocalStorage.get('school-name'),
          name: name,
          phone: phone,
          email: email,
          gender: gender,
          active: true,
          state: reactLocalStorage.get('state'),
          roles: roleField,
          date: firebase.firestore.Timestamp.now(),
        })
        setUsers(u)
        setLoading(false)
        closeModal()
        NotificationManager.info('Added Successful')
        // console.log("Document written with ID: ", docRef.id);
      })
      .catch((error) => {
        setError(error.message)
        setLoading(false)
      })
  }

  async function handleSignUp(email, phone) {
    try {
      setError('')
      setLoading(true)
      await signUp(email, phone)
      //setLoading(false)
      save()
    } catch (error) {
      setError(error.message)
      setLoading(false)
      NotificationManager.error(error.message)
    }
  }

  const handleNext = () => {
    setinfoClick(false)
    setUserClick(true)
  }

  return (
    <>
      <div className="">
        <button
          type="button"
          onClick={openModal}
          className="space-x-1 flex justify-center outline-none focus:outline-none border py-2 px-4 rounded-sm"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-5 w-5"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
              clipRule="evenodd"
            />
          </svg>
          Add New
        </button>
      </div>
      <Transition show={open} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-y-auto"
          initialFocus={cancelButtonRef}
          static
          open={open}
          onClose={closeModal}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-full max-w-md border border-gray-100  md:border-none text-sm  overflow-hidden text-left align-middle transition-all transform bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 py-3  md:text-white bg-white md:bg-primary"
                >
                  <nav class="flex flex-row justify-center">
                    <button
                      onClick={() => {
                        handleTabClick('info')
                      }}
                      className={infoClick ? tabClickStyle : tabDefaultStyle}
                    >
                      basic Information
                    </button>
                    <button
                      onClick={() => {
                        handleTabClick('role')
                      }}
                      className={userClick ? tabClickStyle : tabDefaultStyle}
                    >
                      Permission pages
                    </button>
                  </nav>
                </Dialog.Title>
                {infoClick ? (
                  <form className="flex flex-col px-5">
                    <label htmlFor="name" className="py-1.5">
                      Name
                    </label>
                    <input
                      className="rounded-sm p-2 focus:outline-none focus:ring-1 bg-account_background "
                      type="text"
                      placeholder="full name"
                      required
                      name="name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />

                    <label htmlFor="phone" className="py-1.5">
                      Phone
                    </label>
                    <input
                      className="rounded-sm p-2 focus:outline-none focus:ring-1 bg-account_background "
                      type="phone"
                      placeholder="phone number"
                      required
                      name="phone"
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                    />
                    <label htmlFor="email" className="py-1.5">
                      Email Address
                    </label>
                    <input
                      className="rounded-sm p-2 focus:outline-none focus:ring-1 bg-account_background "
                      type="email"
                      placeholder="Email address"
                      required
                      name="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <label htmlFor="gender" className="py-1.5">
                      Gender
                    </label>
                    <select
                      className="rounded-sm p-2 focus:outline-none focus:ring-1 bg-account_background"
                      placeholder="gender"
                      required
                      name="gender"
                      value={gender}
                      onChange={(e) => setGender(e.target.value)}
                    >
                      <option value="select" selected>
                        select
                      </option>
                      <option value="male">Male</option>
                      <option value="female">Female</option>
                    </select>
                  </form>
                ) : (
                  <Role />
                )}
                <div className="px-5 py-5 flex items-end justify-end space-x-5">
                  <button
                    onClick={closeModal}
                    className="px-3 py-1.5 rounded-sm outline-none focus:outline-none text-gray-500 hover:bg-gray-100"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={() => {
                      // handleSave();
                      infoClick ? handleNext() : handleSave()
                    }}
                    className={
                      name && phone && gender !== 'select' && email
                        ? saveButtonClick
                        : saveButtonDefault
                    }
                  >
                    {loading ? (
                      <i
                        className={'fas fa-circle-notch fa-spin text-base'}
                      ></i>
                    ) : (
                      <p className="2xl:text-lg">
                        {infoClick ? 'Next' : 'Save'}
                      </p>
                    )}
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  )

  function Role() {
    return (
      <div className="max-h-96 overflow-y-auto mt-5">
        <div className="px-5">
          <div className="grid  grid-cols-3 justify-between py-1 ">
            <div className="flex space-x-2">
              <input
                type="checkbox"
                name="people"
                id="people"
                key="people"
                checked={people}
                value={people}
                onChange={() => setPeople(!people)}
              />
              <label htmlFor="people" className="py-1.5">
                People
              </label>
            </div>

            <div className="flex space-x-2">
              <input
                type="checkbox"
                name="attendance"
                id="attendance"
                key="attendance"
                checked={attendance}
                value={attendance}
                onChange={() => setAttendance(!attendance)}
              />
              <label htmlFor="attendance" className="py-1.5">
                Attendance
              </label>
            </div>
            <div className="flex space-x-2">
              <input
                type="checkbox"
                name="assessment"
                id="assessment"
                key="assessment"
                value={assessment}
                checked={assessment}
                onChange={() => setAssessment(!assessment)}
              />
              <label htmlFor="assessment" className="py-1.5">
                Assessment
              </label>
            </div>
          </div>

          <div className="grid  grid-cols-3 justify-between py-1 ">
            <div className="flex space-x-2">
              <input
                type="checkbox"
                name="payment"
                id="payment"
                key="payment"
                checked={payment}
                value={payment}
                onChange={() => setPayment(!payment)}
              />
              <label htmlFor="payment" className="py-1.5">
                Payment
              </label>
            </div>

            <div className="flex space-x-2">
              <input
                type="checkbox"
                name="calendar"
                id="calendar"
                key="calendar"
                checked={calendar}
                value={calendar}
                onChange={() => setCalendar(!calendar)}
              />
              <label htmlFor="calendar" className="py-1.5">
                Timetable
              </label>
            </div>
            <div className="flex space-x-2">
              <input
                type="checkbox"
                name="message"
                id="message"
                key="message"
                value={message}
                checked={message}
                onChange={() => setMessage(!message)}
              />
              <label htmlFor="message" className="py-1.5">
                Message
              </label>
            </div>
          </div>

          <div className="grid  grid-cols-3 justify-between py-1 ">
            <div className="flex space-x-2">
              <input
                type="checkbox"
                name="school"
                id="school"
                key="school"
                value={school}
                checked={school}
                onChange={() => setSchool(!school)}
              />
              <label htmlFor="school" className="py-1.5">
                School
              </label>
            </div>
            <div className="flex space-x-2">
              <input
                type="checkbox"
                name="subscription"
                id="subscription"
                key="subscription"
                value={subscription}
                checked={subscription}
                onChange={() => setSubscription(!subscription)}
              />
              <label htmlFor="subscription" className="py-1.5">
                Subscription
              </label>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
