import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useRef, useState } from 'react'
import firebase from 'firebase/app'
import { reactLocalStorage } from 'reactjs-localstorage'
import { NotificationManager } from 'react-notifications'
import 'react-notifications/lib/notifications.css'
import axios from 'axios'
import { useAuth } from '../services/AuthContext'
export default function AddTeacher({
  document,
  classDocument,
  teacherCount,
  setTeacherCount,
  teachers,
  setTeachers,
}) {
  const [open, setOpen] = useState(false)
  const cancelButtonRef = useRef()
  const [infoClick, setinfoClick] = useState(true)
  const [roleClick, setroleClick] = useState(false)
  const [roleField, setRoleField] = useState([
    { subject: 'select', classvalue: 'select' },
  ])
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [address, setAddress] = useState('')
  const [gender, setGender] = useState('select')
  const [role, setRole] = useState('accountant')
  const [specialization, setSpecialization] = useState('')
  const [qualification, setQualification] = useState('')
  const { signUp } = useAuth()

  const tabDefaultStyle =
    'text-primary md:text-white text-xs md:text-sm   uppercase py-4 px-4 md:px-6 block focus:outline-none'
  const tabClickStyle =
    'uppercase text-sm md:text-sm  py-4 px-4 md:px-6 block  focus:outline-none text-primary md:text-white  border-b-2 font-medium border-primary md:border-white'
  const saveButtonDefault =
    'px-3 py-1.5  rounded-sm outline-none focus:outline-none text-gray-300  bg-gray-100'
  const saveButtonClick =
    'px-3 py-1.5  rounded-sm outline-none focus:outline-none text-white  bg-primary'
  const [errorMessage, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const [formValue, setFormValue] = useState('')
  const [formValue2, setFormValue2] = useState('')
  const [post, setPost] = useState(null)

  const handleTabClick = (tab) => {
    switch (tab) {
      case 'info': {
        setinfoClick(true)
        setroleClick(false)
        break
      }
      case 'role': {
        setroleClick(true)
        setinfoClick(false)
        break
      }
    }
  }

  function closeModal() {
    setOpen(false)
    setName('')
    setPhone('')
    setEmail('')
    setAddress('')
    setGender('')
    setinfoClick(false)
    setroleClick(false)
    setRoleField([{ subject: 'select', classvalue: 'select' }])
  }

  function openModal() {
    setinfoClick(true)
    setroleClick(false)
    setOpen(true)
  }

  const handleSave = () => {
    if (name !== '' && phone !== '' && gender !== '') {
      checkPhone()
    } else {
      NotificationManager.info('Enter the require fields')
    }
  }

  const checkPhone = () => {
    setError('')
    setLoading(true)
    var db = firebase.firestore()
    db.collection('teachers')
      .where('phone', '==', phone)
      .get()
      .then((querySnapshot) => {
        if (querySnapshot.size === 0) {
          handleSignUp(email, phone)
        } else {
          setLoading(false)
          NotificationManager.info('Phone number already been used')
        }
      })
      .catch((error) => {
        setError(error.message)
      })
  }

  //STOPPED
  const checkEmail = () => {
    setError('')
    setLoading(true)
    var db = firebase.firestore()
    db.collection('teachers')
      .where('email', '==', email)
      .get()
      .then((querySnapshot) => {
        if (querySnapshot.size === 0) {
          checkEmailInPerson()
        } else {
          setLoading(false)
          NotificationManager.info('Email has already been used')
        }
      })
      .catch((error) => {
        setError(error.message)
      })
  }
  //STOPPED
  const checkEmailInPerson = () => {
    setError('')
    setLoading(true)
    var db = firebase.firestore()
    db.collection('persons')
      .where('email', '==', email)
      .get()
      .then((querySnapshot) => {
        if (querySnapshot.size === 0) {
          handleSignUp(email, phone)
        } else {
          setLoading(false)
          NotificationManager.info('Email has already been used')
        }
      })
      .catch((error) => {
        setError(error.message)
      })
  }

  function generateEmail() {
    return phone.concat('@edusaf.com')
  }

  async function sendSMS() {
    const schl = reactLocalStorage.get('school-name')
    const assignMail = email ? email : generateEmail()
    var mobile = ''
    var first = phone[0]
    if (first === '0') {
      mobile = phone.replace(first, '234')
    } else {
      mobile = phone
    }
    const message = `Dear ${name}, Welcome to Edusaf! You have been added as a teacher by ${schl}. Your school-assigned email is ${assignMail} and your mobile phone number is your default password. Download the app from the below link. https://shorturl.at/zGZ49`
    const baseURL = `https://www.bulksmsnigeria.com/api/v1/sms/create?api_token=XBm5xvGn3hwDgY4oQJ8jZMEaWco6aUZIXX6j2HDKNXgh7XBsMwkOMnJnFAZS &from=EDUSAF&to=${mobile}&body=${message}&dnd=2`
    //const baseURL = `https://account.kudisms.net/api/?username=hashim.ismaila@gmail.com&password=himaAS1506&message=${message}&sender=EDUSAF&mobiles=${mobile}`

    await axios.get(baseURL).then((response) => {
      setPost(response.data)
    })

    if (!post) return null
    return post
  }

  async function handleSignUp(email, phone) {
    try {
      setError('')
      setLoading(true)
      await signUp(email ? email : generateEmail(), phone)
      //setLoading(false)
      save()
    } catch (error) {
      setError(error.message)
      setLoading(false)
      NotificationManager.error(error.message)
    }
  }
  const save = () => {
    filterRoles()

    var db = firebase.firestore()
    db.collection('teachers')
      .add({
        school: reactLocalStorage.get('current-school'),
        schoolname: reactLocalStorage.get('school-name'),
        name: name,
        phone: phone,
        email: email ? email : generateEmail(),
        qualification: qualification ? qualification : '',
        specialization: specialization ? specialization : '',
        gender: gender,
        role: role,
        active: true,
        roles: roleField,
        photo: '',
        state: reactLocalStorage.get('state'),
        form_master: formValue,
        form_master2: formValue2,
        date: firebase.firestore.Timestamp.now(),
      })
      .then((docRef) => {
        // updateCount()
        // setLoading(false);
        // console.log("Document written with ID: ", docRef.id);
        var data = [...teachers]
        data.push({
          id: docRef.id,
          school: reactLocalStorage.get('current-school'),
          schoolname: reactLocalStorage.get('school-name'),
          name: name,
          phone: phone,
          email: email ? email : generateEmail(),
          form_master: formValue,
          // address: address,
          gender: gender,
          role: role,
          active: true,
          roles: roleField,
          photo: '',
          state: 'Gombe',
          lga: 'Gombe',
        })
        setTeachers(data)
        sendSMS()
      })
      .catch((error) => {
        setError(error.message)
        setLoading(false)
      })
    setLoading(false)
    closeModal()
    setTeacherCount(teacherCount + 1)
    NotificationManager.info('Added Successful')
  }

  const handleNext = () => {
    setinfoClick(false)
    setroleClick(true)
  }

  const filterRoles = () => {
    roleField.map((role, index) => {
      if (role.subject === 'select' || role.classvalue === 'select') {
        roleField.pop(index)
      }
    })
  }

  return (
    <>
      <div className="">
        <button
          type="button"
          onClick={openModal}
          className="space-x-1 flex justify-center outline-none focus:outline-none border py-2 px-4 rounded-sm"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-5 w-5"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
              clipRule="evenodd"
            />
          </svg>
          Add New
        </button>
      </div>
      <Transition show={open} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-y-auto"
          initialFocus={cancelButtonRef}
          static
          open={open}
          onClose={closeModal}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-full max-w-md border border-gray-100  md:border-none text-sm  overflow-hidden text-left align-middle transition-all transform bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 py-3  md:text-white bg-white md:bg-primary"
                >
                  <nav class="flex flex-row justify-center">
                    <button
                      onClick={() => {
                        handleTabClick('info')
                      }}
                      className={infoClick ? tabClickStyle : tabDefaultStyle}
                    >
                      basic Information
                    </button>
                    {role !== 'non-teaching' ? (
                      <button
                        onClick={() => {
                          handleTabClick('role')
                        }}
                        className={roleClick ? tabClickStyle : tabDefaultStyle}
                      >
                        Subject & Class
                      </button>
                    ) : (
                      ''
                    )}
                  </nav>
                </Dialog.Title>
                {infoClick ? (
                  <form className="flex flex-col px-5">
                    <label htmlFor="name" className="py-1.5">
                      Name
                    </label>
                    <input
                      className="rounded-sm p-2 focus:outline-none focus:ring-1 bg-account_background "
                      type="text"
                      placeholder="full name"
                      required
                      name="name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />

                    <div className="grid grid-flow-col grid-cols-2 justify-between py-1 space-x-5  overflow-y-auto overflow-x-hidden">
                      <div className="flex flex-col">
                        <label htmlFor="phone" className="py-1.5">
                          Phone
                        </label>
                        <input
                          className="rounded-sm p-2 focus:outline-none focus:ring-1 bg-account_background "
                          type="phone"
                          placeholder="phone number"
                          required
                          name="phone"
                          value={phone}
                          onChange={(e) => setPhone(e.target.value)}
                        />
                      </div>
                      <div className="flex flex-col">
                        <label htmlFor="email" className="py-1.5">
                          Email Address
                        </label>
                        <input
                          className="rounded-sm p-2 focus:outline-none focus:ring-1 bg-account_background "
                          type="email"
                          placeholder="Optional"
                          required
                          name="email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>
                    </div>

                    <div className="grid grid-flow-col grid-cols-2 justify-between py-1 space-x-5  overflow-y-auto overflow-x-hidden">
                      <div className="flex flex-col">
                        <label htmlFor="phone" className="py-1.5">
                          Qualification
                        </label>
                        <select
                          className="rounded-sm p-2 focus:outline-none focus:ring-1 bg-account_background"
                          placeholder="qualification"
                          required
                          name="qualification"
                          value={qualification}
                          onChange={(e) => setQualification(e.target.value)}
                        >
                          <option value="select" selected>
                            select
                          </option>
                          <option value="HAFIZ">HAFIZ</option>
                          <option value="PhD">PhD</option>
                          <option value="MSc">MSc</option>
                          <option value="MA">MA</option>
                          <option value="B.eng">B.Eng</option>
                          <option value="B.Tech">B.Tech</option>
                          <option value="B.Sc">B.Sc</option>
                          <option value="B.Ed">B.Ed</option>
                          <option value="B.A">B.A</option>
                          <option value="B.Sc(Ed)">B.Sc(Ed)</option>
                          <option value="HND">HND</option>
                          <option value="OND">OND</option>
                          <option value="NCE">NCE</option>
                          <option value="SSCE">SSCE</option>
                        </select>
                      </div>
                      <div className="flex flex-col">
                        <label htmlFor="email" className="py-1.5">
                          Specialization
                        </label>
                        <input
                          className="rounded-sm p-2 focus:outline-none focus:ring-1 bg-account_background "
                          type="text"
                          placeholder="Specialization"
                          required
                          name="specialization"
                          value={specialization}
                          onChange={(e) => setSpecialization(e.target.value)}
                        />
                      </div>
                    </div>

                    <div className="grid grid-flow-col grid-cols-2 justify-between py-1 space-x-5  overflow-y-auto overflow-x-hidden">
                      <div className="flex flex-col">
                        <label htmlFor="gender" className="py-1.5">
                          Gender
                        </label>
                        <select
                          className="rounded-sm p-2 focus:outline-none focus:ring-1 bg-account_background"
                          placeholder="gender"
                          required
                          name="gender"
                          value={gender}
                          onChange={(e) => setGender(e.target.value)}
                        >
                          <option value="select" selected>
                            select
                          </option>
                          <option value="male">Male</option>
                          <option value="female">Female</option>
                        </select>
                      </div>
                      <div className="flex flex-col">
                        <label htmlFor="role" className="py-1.5">
                          Role
                        </label>
                        <select
                          value={role}
                          onChange={(e) => setRole(e.target.value)}
                          className="rounded-sm px-2 py-2 focus:outline-none focus:ring-1 bg-account_background"
                          name="role"
                          id="role"
                          placeholder="role in school"
                          required
                        >
                          {reactLocalStorage.get('school-name') ===
                            'IMAMUL BUKHARI ACADEMY, GOMBE' ||
                          reactLocalStorage.get('school-name') ===
                            'AL-IMAN SECONDARY SCHOO' ? (
                            <option value="admission-officer">
                              Admission Officer
                            </option>
                          ) : (
                            ''
                          )}

                          {reactLocalStorage.get('school-name') ===
                          'Maitakobi Model School ' ? (
                            <option value="accountant-form-master">
                              Accountant & Form Master
                            </option>
                          ) : (
                            ''
                          )}

                          {reactLocalStorage.get('school-name') ===
                          'MAITAKOBI ISLAMIC SCHOOL' ? (
                            <option value="accountant-form-master">
                              Accountant & Form Master
                            </option>
                          ) : (
                            ''
                          )}

                          <option value="form-master">Form Master</option>
                          <option value="accountant">Accountant</option>
                          <option value="teaching">Teaching</option>
                          <option value="non-teaching">Non Teaching</option>
                        </select>
                      </div>
                    </div>
                    <div className="">
                      {role === 'form-master' ||
                      role === 'accountant-form-master' ? (
                        <div className="grid grid-flow-col  grid-cols-2 justify-between py-1 space-x-5  overflow-y-auto overflow-x-hidden ">
                          <div className="flex flex-col">
                            <label
                              htmlFor="class"
                              className="py-1.5 flex justify-end"
                            >
                              Class
                            </label>
                            <select
                              className="rounded-sm p-2  focus:outline-none focus:ring-1 bg-account_background"
                              placeholder="class"
                              required
                              name="formValue"
                              value={formValue}
                              onChange={(e) => setFormValue(e.target.value)}
                            >
                              <option value="select class">select class</option>
                              {classDocument.map((classes, index) =>
                                classes.click ? (
                                  <option
                                    className="uppercase"
                                    key={classes.id}
                                    value={classes.name}
                                  >
                                    {classes.name}
                                  </option>
                                ) : (
                                  ''
                                )
                              )}
                            </select>
                          </div>
                          <div className="flex flex-col">
                            <label
                              htmlFor="class"
                              className="py-1.5 flex justify-end"
                            >
                              Class
                            </label>
                            <select
                              className="rounded-sm p-2  focus:outline-none focus:ring-1 bg-account_background"
                              placeholder="class"
                              required
                              name="formValue2"
                              value={formValue2}
                              onChange={(e) => setFormValue2(e.target.value)}
                            >
                              <option value="select class">select class</option>
                              {classDocument.map((classes, index) =>
                                classes.click ? (
                                  <option
                                    className="uppercase"
                                    key={classes.id}
                                    value={classes.name}
                                  >
                                    {classes.name}
                                  </option>
                                ) : (
                                  ''
                                )
                              )}
                            </select>
                          </div>
                        </div>
                      ) : (
                        ''
                      )}
                    </div>
                  </form>
                ) : (
                  <Role />
                )}
                <div className="px-5 py-5 flex items-end justify-end space-x-5">
                  <button
                    onClick={closeModal}
                    className="px-3 py-1.5 rounded-sm outline-none focus:outline-none text-gray-500 hover:bg-gray-100"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={() => {
                      // handleSave();
                      infoClick && role !== 'non-teaching'
                        ? handleNext()
                        : handleSave()
                    }}
                    className={
                      name && phone && gender !== 'select'
                        ? saveButtonClick
                        : saveButtonDefault
                    }
                  >
                    {loading ? (
                      <i
                        className={'fas fa-circle-notch fa-spin text-base'}
                      ></i>
                    ) : (
                      <p className="2xl:text-lg">
                        {infoClick && role !== 'non-teaching' ? 'Next' : 'Save'}
                      </p>
                    )}
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  )

  function Role() {
    const handleAddField = () => {
      setRoleField([...roleField, { subject: 'select', classvalue: 'select' }])
    }
    return (
      <div className="max-h-96 overflow-y-auto">
        <div className="flex justify-end p-5">
          <button
            type="button"
            onClick={() => handleAddField()}
            className="space-x-1 flex justify-center outline-none focus:outline-none border py-2 px-4 rounded-sm"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-5 w-5"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
                clipRule="evenodd"
              />
            </svg>
            Add Record
          </button>
        </div>
        {roleField.map((role, index) => (
          <AddRole
            key={index}
            subject={role.subject}
            classvalue={role.classvalue}
            index={index}
          />
        ))}
      </div>
    )
  }
  function AddRole({ subject, classvalue, index }) {
    const handleRoleSelection = (index, e) => {
      const values = [...roleField]
      values[index][e.target.name] = e.target.value
      setRoleField(values)
    }
    return (
      <div className="px-5">
        <div className="grid grid-flow-col grid-cols-2 justify-between py-1 space-x-5  overflow-y-auto overflow-x-hidden">
          <div className=" flex flex-col">
            <label htmlFor="subject" className="py-1.5">
              Subject
            </label>
            <select
              className="rounded-sm p-2 focus:outline-none focus:ring-1 bg-account_background"
              placeholder="subject"
              required
              name="subject"
              value={subject}
              onChange={(e) => handleRoleSelection(index, e)}
            >
              <option value="select" disabled selected>
                select
              </option>
              {document.map((subject, index) =>
                subject.click ? (
                  <option
                    className="uppercase"
                    key={subject.id}
                    value={subject.name}
                  >
                    {subject.name}
                  </option>
                ) : (
                  ''
                )
              )}
            </select>
          </div>
          <div className="flex flex-col">
            <label htmlFor="class" className="py-1.5 flex justify-end">
              Class
            </label>
            <select
              className="rounded-sm p-2  focus:outline-none focus:ring-1 bg-account_background"
              placeholder="class"
              required
              name="classvalue"
              value={classvalue}
              onChange={(e) => handleRoleSelection(index, e)}
            >
              <option value="select" disabled selected>
                select
              </option>
              {classDocument.map((classes, index) =>
                classes.click ? (
                  <option
                    className="uppercase"
                    key={classes.id}
                    value={classes.name}
                  >
                    {classes.name}
                  </option>
                ) : (
                  ''
                )
              )}
            </select>
          </div>
        </div>
      </div>
    )
  }
}
