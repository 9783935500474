import firebase from 'firebase/app'
import { reactLocalStorage } from 'reactjs-localstorage'
import { useState, useEffect } from 'react'
import Subject from './Subject'
import Classes from './Classes'
import TimeTableTeachers from './TimeTableTeachers'
import TimeTableConstraints from './TimeTableConstraints'
import ExpensesView from './ExpensesView'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { NotificationManager } from 'react-notifications'
import 'react-notifications/lib/notifications.css'

const ManageTimeTable = () => {
  const [classClick, setClassClick] = useState()
  const [subjectClick, setSubjectClick] = useState()
  const [teacherClick, setTeacherClick] = useState()
  const [document, setDocument] = useState([])
  const [teachers, setTeachers] = useState([])
  const [constraints, setConstraints] = useState([])
  const [classDocument, setClassDocument] = useState([])
  const [loading, setLoading] = useState(false)
  const [emptyRecord, setEmptyRecord] = useState()
  const [classEmptyRecord, setClassEmptyRecord] = useState()
  const [load, setLoad] = useState()
  const [error, setError] = useState()
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [transaction, setTransaction] = useState([])

  const tabDefaultStyle =
    'text-white text-xs md:text-sm  md:text-gray-600 uppercase py-4 px-4 md:px-6 block hover:text-blue-500 focus:outline-none'
  const tabClickStyle =
    'uppercase text-sm md:text-sm  py-4 px-4 md:px-6 block md:hover:text-blue-500 focus:outline-none text-white md:text-blue-500 border-b-2 font-medium md:border-blue-500'

  const handleTabClick = (tab) => {
    switch (tab) {
      case 'class': {
        setClassClick(true)
        setSubjectClick(false)
        setTeacherClick(false)
        setConstraints(false)

        break
      }
      case 'subject': {
        setSubjectClick(true)
        setClassClick(false)
        setTeacherClick(false)
        setConstraints(false)
        break
      }
      case 'teacher': {
        setTeacherClick(true)
        setSubjectClick(false)
        setClassClick(false)
        setConstraints(false)
        break
      }

      default: {
        setConstraints(true)
        setTeacherClick(false)
        setSubjectClick(false)
        setClassClick(false)
      }
    }
  }

  useEffect(() => {
    // getSchoolSubjects()
    // getSchoolsClasses()
    getTeachers()
    //SET DEFAULT CLICK
    setTeacherClick(true)
    setSubjectClick(false)
    setClassClick(false)
    setConstraints(false)
  }, [])

  const getSchoolSubjects = () => {
    setLoading(true)
    var db = firebase.firestore()
    var query = db.collection('school-subjects')
    query
      .doc(reactLocalStorage.get('current-school'))
      .get()
      .then((doc) => {
        if (doc.exists) {
          setDocument(doc.data().subject)
          setLoading(false)
        } else {
          // doc.data() will be undefined in this case
          getSubjects()
          setEmptyRecord(true)
        }
      })
      .catch((error) => {
        console.log('Error getting document:', error)
      })
  }

  const getSubjects = () => {
    var db = firebase.firestore()
    var query = db.collection('subjects').orderBy('name')
    var data = []
    query.get().then(function (querySnapshot) {
      querySnapshot.forEach(function (doc) {
        data.push({ name: doc.data().name, id: doc.id, click: false })
      })
      setDocument(data)
      setLoading(false)
    })
  }

  const getSchoolsClasses = () => {
    setLoading(true)
    var db = firebase.firestore()
    var query = db.collection('school-classes')
    query
      .doc(reactLocalStorage.get('current-school'))
      .get()
      .then((doc) => {
        if (doc.exists) {
          setClassDocument(doc.data().classes)
          setLoading(false)
        } else {
          // doc.data() will be undefined in this case
          getClasses()
          setClassEmptyRecord(true)
        }
      })
      .catch((error) => {
        console.log('Error getting document:', error)
      })
  }

  const getClasses = () => {
    var db = firebase.firestore()
    var query = db.collection('classes').orderBy('position')
    var data = []
    query.get().then(function (querySnapshot) {
      querySnapshot.forEach(function (doc) {
        data.push({
          name: doc.data().name,
          id: doc.id,
          // section: doc.data().section,
          click: false,
        })
      })
      setClassDocument(data)
      setLoading(false)
    })
  }

  const getTeachers = () => {
    var db = firebase.firestore()
    var record = []
    db.collection('teachers')
      .where('school', '==', reactLocalStorage.get('current-school'))
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          var t = doc.data()
          t.doc_id = doc.id
          record.push(t)
        })
        setTeachers(record)
      })
      .catch((error) => {
        console.log('Error getting documents: ', error)
      })
  }

  const getStudents = (start, end) => {
    console.log('start date:: ', firebase.firestore.Timestamp.fromDate(start))
    console.log('start end:: ', firebase.firestore.Timestamp.fromDate(end))
    setLoading(true)
    var db = firebase.firestore()
    var record = []

    db.collection('expense')
      .where('school', '==', reactLocalStorage.get('current-school'))
      .orderBy('created_time')
      .startAt(firebase.firestore.Timestamp.fromDate(start))
      .endAt(firebase.firestore.Timestamp.fromDate(end))
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          record.push(doc.data())
        })
        setTransaction(record)
        if (record.length === 0) {
          NotificationManager.info('No record within the selected date')
        }
        setLoading(false)
      })
      .catch((error) => {
        setLoading(false)
        console.log('Error getting documents: ', error)
      })
  }

  const handleStartDateChange = (date) => {
    setStartDate(date)
    if (date && endDate) {
      getStudents(date, endDate)
    }
    //
  }

  const handleEndDateChange = (date) => {
    setEndDate(date)
    if (date && startDate) {
      getStudents(startDate, date)
    }
  }

  return (
    <div className="">
      <div className="">
        <div className="m:0 md:mx-12 md:my-5 ">
          <div className="flex">
            <div>
              <label>From: </label>
              <DatePicker
                selected={startDate}
                onChange={handleStartDateChange}
                selectsStart
                startDate={startDate}
                // endDate={endDate}
                placeholderText="select start date"
              />
            </div>
            <div>
              <label>To: </label>
              <DatePicker
                selected={endDate}
                onChange={handleEndDateChange}
                selectsEnd
                // startDate={startDate}
                endDate={endDate}
                minDate={startDate}
                placeholderText="select end date"
              />
            </div>
          </div>
          {/* <div class="sticky top-0 z-30 bg-primary md:bg-gray-50">
            <nav class="flex flex-row">
              <button
                onClick={() => {
                  handleTabClick('teacher')
                }}
                className={teacherClick ? tabClickStyle : tabDefaultStyle}
              >
                Expenses
              </button>
            </nav>
          </div> */}

          <div className="my-5 max-h-115  md:max-h-115 xl_1:max-h-130 2xl:max-h-130 overflow-y-auto ">
            {
              <ExpensesView
                transaction={transaction}
                setTransaction={setTransaction}
                loading={loading}
                setLoading={setLoading}
                classDocument={classDocument}
                setClassDocument={setClassDocument}
                classEmptyRecord={classEmptyRecord}
                hideAdd={true}
              />
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default ManageTimeTable
