import { Menu, Dialog, Transition } from '@headlessui/react'
import { Fragment, useState, useEffect, useRef } from 'react'
import { ExclamationIcon } from '@heroicons/react/outline'
import firebase from 'firebase/app'
import { NotificationManager } from 'react-notifications'
import 'react-notifications/lib/notifications.css'
import { reactLocalStorage } from 'reactjs-localstorage'

import toast, { Toaster } from 'react-hot-toast'

const notify = () => toast('Deleted Successful')

export default function DropMenu({
  teacher,
  teachers,
  setTeachers,
  teacherCount,
  setTeacherCount,
  document,
  classDocument,
  index,
  filter,
  setFilter,
  notEmptyFilter,
  setNotEmptyFilter,
}) {
  const [showDeleteDialog, setshowDeleteDialog] = useState(false)
  const [showEditDialog, setshowEditDialog] = useState(false)
  const cancelButtonRef = useRef()
  const [loading, setLoading] = useState(false)
  const [errorMessage, setError] = useState('')

  const [infoClick, setinfoClick] = useState(true)
  const [roleClick, setroleClick] = useState(false)
  const [roleField, setRoleField] = useState()

  const [name, setName] = useState()
  const [email, setEmail] = useState()
  const [phone, setPhone] = useState()
  const [address, setAddress] = useState()
  const [gender, setGender] = useState()
  const [role, setRole] = useState()
  const [specialization, setSpecialization] = useState()
  const [qualification, setQualification] = useState()
  const [formValue, setFormValue] = useState()
  const [formValue2, setFormValue2] = useState()

  const tabDefaultStyle =
    'text-primary md:text-white text-xs md:text-sm uppercase py-4 px-4 md:px-6 block focus:outline-none'
  const tabClickStyle =
    'uppercase text-sm md:text-sm  py-4 px-4 md:px-6 block focus:outline-none text-primary md:text-white  border-b-2 font-medium border-primary md:border-white'
  const saveButtonDefault =
    'px-3 py-1.5  rounded-sm outline-none focus:outline-none text-gray-300  bg-gray-100'
  const saveButtonClick =
    'px-3 py-1.5  rounded-sm outline-none focus:outline-none text-white  bg-primary'

  function Role() {
    const handleAddField = () => {
      setRoleField([...roleField, { subject: 'select', classvalue: 'select' }])
    }
    return (
      <div className="max-h-96 overflow-y-auto">
        <div className="flex justify-end p-5">
          <button
            type="button"
            onClick={() => handleAddField()}
            className="space-x-1 flex justify-center outline-none focus:outline-none border py-2 px-4 rounded-sm"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-5 w-5"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
                clipRule="evenodd"
              />
            </svg>
            Add Record
          </button>
        </div>
        {roleField.map((role, index) => (
          <AddRole
            key={index}
            subject={role.subject}
            classvalue={role.classvalue}
            index={index}
          />
        ))}
      </div>
    )
  }

  const handleSave = () => {
    if (name !== '' && phone !== '' && email !== '' && gender !== '') {
      save()
    } else {
      NotificationManager.info('Enter the require fields')
    }
  }

  const save = () => {
    setLoading(true)
    setError('')
    filterRoles()

    var db = firebase.firestore()
    db.collection('teachers')
      .doc(teacher.doc_id)
      .update({
        school: reactLocalStorage.get('current-school'),
        schoolname: reactLocalStorage.get('school-name'),
        qualification: qualification ? qualification : '',
        specialization: specialization ? specialization : '',
        name: name,
        phone: phone,
        email: email,
        form_master: formValue,
        form_master2: formValue2,
        gender: gender,
        role: role,
        active: true,
        roles: roleField,
        photo: '',
        state: reactLocalStorage.get('state')
          ? reactLocalStorage.get('state')
          : 'Plateau state',
        lga: reactLocalStorage.get('lga')
          ? reactLocalStorage.get('lga')
          : 'Jos-North',
        date: firebase.firestore.Timestamp.now(),
      })
      .then((docRef) => {
        let ters = [...teachers]
        var updateTeacher = ters[index]

        updateTeacher.name = name
        updateTeacher.phone = phone
        updateTeacher.email = email
        updateTeacher.form_master = formValue
        updateTeacher.gender = gender
        updateTeacher.qualification = qualification
        updateTeacher.specialization = specialization
        updateTeacher.active = true
        updateTeacher.roles = roleField

        ters[index] = updateTeacher
        setTeachers(ters)
        NotificationManager.info('Updated Successful')
      })
      .catch((error) => {
        console.log('errr:: ', error)
        setError(error.message)
      })
    setLoading(false)
    closeEditModal()
  }

  function AddRole({ subject, classvalue, index }) {
    const handleRoleSelection = (index, e) => {
      const values = [...roleField]
      values[index][e.target.name] = e.target.value
      setRoleField(values)
    }
    return (
      <div className="grid grid-flow-col justify-between px-5">
        <div className="flex flex-col">
          <label htmlFor="subject" className="py-1.5">
            Subject
          </label>
          <select
            className="rounded-sm p-2 focus:outline-none focus:ring-1 bg-account_background"
            placeholder="subject"
            required
            name="subject"
            value={subject}
            onChange={(e) => handleRoleSelection(index, e)}
          >
            <option value="select" selected>
              select
            </option>
            {document.map((subject, index) =>
              subject.click ? (
                <option
                  className="uppercase"
                  key={subject.id}
                  value={subject.name}
                >
                  {subject.name}
                </option>
              ) : (
                ''
              )
            )}
          </select>
        </div>
        <div className="flex flex-col">
          <label htmlFor="class" className="py-1.5">
            Class
          </label>
          <select
            className="rounded-sm p-2  focus:outline-none focus:ring-1 bg-account_background"
            placeholder="class"
            required
            name="classvalue"
            value={classvalue}
            onChange={(e) => handleRoleSelection(index, e)}
          >
            <option value="select" selected>
              select
            </option>
            {classDocument.map((classes, index) =>
              classes.click ? (
                <option
                  className="uppercase"
                  key={classes.id}
                  value={classes.name}
                >
                  {classes.name}
                </option>
              ) : (
                ''
              )
            )}
          </select>
        </div>
      </div>
    )
  }
  const handleTabClick = (tab) => {
    switch (tab) {
      case 'info': {
        setinfoClick(true)
        setroleClick(false)
        break
      }
      case 'role': {
        setroleClick(true)
        setinfoClick(false)
        break
      }
    }
  }

  function closeEditModal() {
    setshowEditDialog(false)
    //  setName("");
    //  setPhone("");
    //  setEmail("");
    //  setAddress("");
    //  setGender("");
    //  setinfoClick(false);
    //  setroleClick(false);
    //  setRoleField([{ subject: "select", classvalue: "select" }]);
  }

  function getData() {
    setName(teacher.name)
    setEmail(teacher.email)
    setPhone(teacher.phone)
    setGender(teacher.gender)
    setRole(teacher.role)
    setRoleField(teacher.roles)
    setSpecialization(teacher.specialization)
    setQualification(teacher.qualification)
    setFormValue(teacher.form_master)
    setFormValue2(teacher.form_master2 ? teacher.form_master2 : '')
  }
  function openEditModal() {
    getData()

    setinfoClick(true)
    setroleClick(false)
    setshowEditDialog(true)
  }

  const handleNext = () => {
    setinfoClick(false)
    setroleClick(true)
  }

  const filterRoles = () => {
    roleField.map((role, index) => {
      if (role.subject === 'select' || role.classvalue === 'select') {
        roleField.splice(index, 1)
      }
      setRoleField(roleField)
    })
  }

  const deleteTeacher = () => {
    var db = firebase.firestore()
    setError('')
    setLoading(true)
    db.collection('teachers')
      .doc(teacher.doc_id)
      .delete()
      .then((querySnapshot) => {
        //notify();
        var data = [...teachers]
        data.map((t) => {
          if (t.doc_id === teacher.doc_id) {
            data.pop()
          }
        })

        setTeachers(data)
        setTeacherCount(teacherCount - 1)
        NotificationManager.info('Deleted Successful')
        setLoading(false)
        closeModal()
      })
      .catch((error) => {
        setError(error.message)
      })
  }
  function closeModal() {
    setshowDeleteDialog(false)
  }

  function showDialogModal() {
    getData()
    setshowDeleteDialog(true)
  }

  function EditInactiveIcon(props) {
    return (
      <svg
        {...props}
        width="20"
        height="20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2.5 14.375V17.5h3.125l9.217-9.217-3.125-3.125L2.5 14.375zm14.758-8.508a.83.83 0 000-1.175l-1.95-1.95a.83.83 0 00-1.175 0l-1.525 1.525 3.125 3.125 1.525-1.525z"
          fill="#000"
          fill-opacity=".7"
        />
      </svg>
    )
  }

  function EditActiveIcon(props) {
    return (
      <svg
        {...props}
        width="20"
        height="20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2.5 14.375V17.5h3.125l9.217-9.217-3.125-3.125L2.5 14.375zm14.758-8.508a.83.83 0 000-1.175l-1.95-1.95a.83.83 0 00-1.175 0l-1.525 1.525 3.125 3.125 1.525-1.525z"
          fill="#0064AF"
        />
      </svg>
    )
  }

  function ViewInactiveIcon(props) {
    return (
      <svg
        {...props}
        width="20"
        height="20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15.833 15.833H4.167V4.167H10V2.5H4.167c-.925 0-1.667.75-1.667 1.667v11.666c0 .917.742 1.667 1.667 1.667h11.666c.917 0 1.667-.75 1.667-1.667V10h-1.667v5.833zM11.667 2.5v1.667h2.991l-8.191 8.191 1.175 1.175 8.191-8.191v2.991H17.5V2.5h-5.833z"
          fill="#000"
          fill-opacity=".7"
        />
      </svg>
    )
  }

  function ViewActiveIcon(props) {
    return (
      <svg
        {...props}
        width="20"
        height="20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15.833 15.833H4.167V4.167H10V2.5H4.167c-.925 0-1.667.75-1.667 1.667v11.666c0 .917.742 1.667 1.667 1.667h11.666c.917 0 1.667-.75 1.667-1.667V10h-1.667v5.833zM11.667 2.5v1.667h2.991l-8.191 8.191 1.175 1.175 8.191-8.191v2.991H17.5V2.5h-5.833z"
          fill="#0064AF"
        />
      </svg>
    )
  }
  function DeleteInactiveIcon(props) {
    return (
      <svg
        {...props}
        width="20"
        height="20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5 15.833c0 .917.75 1.667 1.667 1.667h6.666c.917 0 1.667-.75 1.667-1.667v-10H5v10zM6.667 7.5h6.666v8.333H6.667V7.5zm6.25-4.167l-.834-.833H7.917l-.834.833H4.167V5h11.666V3.333h-2.916z"
          fill="#000"
          fill-opacity=".7"
        />
      </svg>
    )
  }

  function DeleteActiveIcon(props) {
    return (
      <svg
        {...props}
        width="20"
        height="20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5 15.833c0 .917.75 1.667 1.667 1.667h6.666c.917 0 1.667-.75 1.667-1.667v-10H5v10zM6.667 7.5h6.666v8.333H6.667V7.5zm6.25-4.167l-.834-.833H7.917l-.834.833H4.167V5h11.666V3.333h-2.916z"
          fill="#0064AF"
        />
      </svg>
    )
  }

  return (
    <div className="text-right ">
      <Menu as="div" className="relative inline-block text-left ">
        {({ open }) => (
          <>
            <div>
              <Menu.Button className="text-gray-500 hover:text-primary outline-none focus:outline-none">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z" />
                </svg>
              </Menu.Button>
            </div>
            <Transition
              show={open}
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items
                static
                className="absolute  right-0 bottom-0  w-48  mt-2 border-account_background border  bg-white divide-y divide-gray-100 rounded-md shadow-lg  focus:outline-none z-50"
              >
                {/* <div className="px-1 py-1">
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        className={`${
                          active
                            ? " text-secondary outline-none focus:outline-none"
                            : "text-gray-900 outline-none "
                        } group flex rounded-md items-center w-full px-2 py-2 text-sm outline-none focus:outline-none`}
                      >
                        {active ? (
                          <ViewActiveIcon
                            className="w-5 h-5 mr-2"
                            aria-hidden="true"
                          />
                        ) : (
                          <ViewInactiveIcon
                            className="w-5 h-5 mr-2"
                            aria-hidden="true"
                          />
                        )}
                        View
                      </button>
                    )}
                  </Menu.Item>
                </div> */}

                <div
                  className="p-1 cursor-pointer "
                  onClick={() => openEditModal()}
                >
                  <Menu.Item as="button">
                    {({ active }) => (
                      <button
                        className={`${
                          active
                            ? ' text-secondary outline-none focus:outline-none'
                            : 'text-gray-900'
                        } group flex rounded-md items-center w-full px-2 py-2 text-sm outline-none focus:outline-none`}
                      >
                        {active ? (
                          <EditActiveIcon
                            className="w-5 h-5 mr-2"
                            aria-hidden="true"
                          />
                        ) : (
                          <EditInactiveIcon
                            className="w-5 h-5 mr-2"
                            aria-hidden="true"
                          />
                        )}
                        Edit
                      </button>
                    )}
                  </Menu.Item>
                </div>

                <div
                  className="p-1 cursor-pointer"
                  onClick={() => showDialogModal()}
                >
                  <Menu.Item as="button">
                    {({ active }) => (
                      <button
                        className={`${
                          active
                            ? ' text-secondary outline-none focus:outline-none'
                            : 'text-gray-900'
                        } group flex rounded-md items-center w-full px-2 py-2 text-sm outline-none focus:outline-none`}
                      >
                        {active ? (
                          <DeleteActiveIcon
                            className="w-5 h-5 mr-2"
                            aria-hidden="true"
                          />
                        ) : (
                          <DeleteInactiveIcon
                            className="w-5 h-5 mr-2"
                            aria-hidden="true"
                          />
                        )}
                        Delete
                      </button>
                    )}
                  </Menu.Item>
                </div>
              </Menu.Items>
            </Transition>
          </>
        )}
      </Menu>

      <Transition show={showDeleteDialog} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-y-auto"
          initialFocus={cancelButtonRef}
          static
          showDialog={showDeleteDialog}
          open={showDeleteDialog}
          onClose={closeModal}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0  ">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block align-bottom bg-white text-left  transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full rounded-md shadow-lg ring-1 ring-black ring-opacity-5 ">
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4 ">
                  <div className="sm:flex sm:items-start ">
                    <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                      <ExclamationIcon
                        className="h-6 w-6 text-red-600"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left select-none">
                      <Dialog.Title
                        as="h3"
                        className="text-lg leading-6 font-medium text-gray-900"
                      >
                        Delete {teacher.name}
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500 select-none">
                          Are you sure you want to delete {teacher.name}{' '}
                          account? All of his data will be permanently removed.
                          This action cannot be undone.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse ">
                  <button
                    className="w-full  inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={() => deleteTeacher()}
                  >
                    {loading ? (
                      <i
                        className={'fas fa-circle-notch fa-spin text-base'}
                      ></i>
                    ) : (
                      <p className="2xl:text-lg">Delete</p>
                    )}
                  </button>
                  <button
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none  sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={() => closeModal()}
                    ref={cancelButtonRef}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>

      <Transition show={showEditDialog} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-y-auto"
          initialFocus={cancelButtonRef}
          static
          open={showEditDialog}
          onClose={closeEditModal}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-full max-w-md border border-gray-100  md:border-none text-sm  overflow-hidden text-left align-middle transition-all transform bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 py-3  md:text-white bg-white md:bg-primary"
                >
                  <nav class="flex flex-row justify-center">
                    <button
                      onClick={() => {
                        handleTabClick('info')
                      }}
                      className={infoClick ? tabClickStyle : tabDefaultStyle}
                    >
                      basic Information
                    </button>
                    {role !== 'non-teaching' ? (
                      <button
                        onClick={() => {
                          handleTabClick('role')
                        }}
                        className={roleClick ? tabClickStyle : tabDefaultStyle}
                      >
                        Subject & Class
                      </button>
                    ) : (
                      ''
                    )}
                  </nav>
                </Dialog.Title>
                {infoClick ? (
                  <form className="flex flex-col px-5">
                    <label htmlFor="name" className="py-1.5">
                      Name
                    </label>
                    <input
                      className="rounded-sm p-2 focus:outline-none focus:ring-1 bg-account_background "
                      type="text"
                      placeholder="full name"
                      required
                      name="name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />

                    <div className="grid grid-flow-col grid-cols-2 justify-between py-1 space-x-5  overflow-y-auto overflow-x-hidden">
                      <div className="flex flex-col">
                        <label htmlFor="phone" className="py-1.5">
                          Phone
                        </label>
                        <input
                          className="rounded-sm p-2 focus:outline-none focus:ring-1 bg-account_background "
                          type="phone"
                          placeholder="phone number"
                          required
                          name="phone"
                          value={phone}
                          onChange={(e) => setPhone(e.target.value)}
                        />
                      </div>
                      <div className="flex flex-col">
                        <label htmlFor="email" className="py-1.5">
                          Email Address
                        </label>
                        <input
                          className="rounded-sm p-2 focus:outline-none focus:ring-1 bg-account_background "
                          type="email"
                          placeholder="Optional"
                          required
                          name="email"
                          value={email}
                          disabled
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>
                    </div>

                    <div className="grid grid-flow-col grid-cols-2 justify-between py-1 space-x-5  overflow-y-auto overflow-x-hidden">
                      <div className="flex flex-col">
                        <label htmlFor="phone" className="py-1.5">
                          Qualification
                        </label>
                        <select
                          className="rounded-sm p-2 focus:outline-none focus:ring-1 bg-account_background"
                          placeholder="qualification"
                          required
                          name="qualification"
                          value={qualification}
                          onChange={(e) => setQualification(e.target.value)}
                        >
                          <option value="select" selected>
                            select
                          </option>
                          <option value="HAFIZ">HAFIZ</option>
                          <option value="PhD">PhD</option>
                          <option value="MSc">MSc</option>
                          <option value="MA">MA</option>
                          <option value="B.eng">B.Eng</option>
                          <option value="B.Tech">B.Tech</option>
                          <option value="B.Sc">B.Sc</option>
                          <option value="B.Ed">B.Ed</option>
                          <option value="B.A">B.A</option>
                          <option value="B.Sc(Ed)">B.Sc(Ed)</option>
                          <option value="HND">HND</option>
                          <option value="OND">OND</option>
                          <option value="NCE">NCE</option>
                          <option value="SSCE">SSCE</option>
                        </select>
                      </div>
                      <div className="flex flex-col">
                        <label htmlFor="email" className="py-1.5">
                          Specialization
                        </label>
                        <input
                          className="rounded-sm p-2 focus:outline-none focus:ring-1 bg-account_background "
                          type="text"
                          placeholder="Specialization"
                          required
                          name="specialization"
                          value={specialization}
                          onChange={(e) => setSpecialization(e.target.value)}
                        />
                      </div>
                    </div>

                    <div className="grid grid-flow-col grid-cols-2 justify-between py-1 space-x-5  overflow-y-auto overflow-x-hidden">
                      <div className="flex flex-col">
                        <label htmlFor="gender" className="py-1.5">
                          Gender
                        </label>
                        <select
                          className="rounded-sm p-2 focus:outline-none focus:ring-1 bg-account_background"
                          placeholder="gender"
                          required
                          name="gender"
                          value={gender}
                          onChange={(e) => setGender(e.target.value)}
                        >
                          <option value="select" selected>
                            select
                          </option>
                          <option value="male">Male</option>
                          <option value="female">Female</option>
                        </select>
                      </div>
                      <div className="flex flex-col">
                        <label htmlFor="role" className="py-1.5">
                          Role
                        </label>
                        <select
                          value={role}
                          onChange={(e) => setRole(e.target.value)}
                          className="rounded-sm px-2 py-2 focus:outline-none focus:ring-1 bg-account_background"
                          name="role"
                          id="role"
                          placeholder="role in school"
                          required
                        >
                          {reactLocalStorage.get('school-name') ===
                            'IMAMUL BUKHARI ACADEMY, GOMBE' ||
                          reactLocalStorage.get('school-name') ===
                            'AL-IMAN SECONDARY SCHOOL' ? (
                            <option value="admission-officer">
                              Admission Officer
                            </option>
                          ) : (
                            ''
                          )}
                          {reactLocalStorage.get('school-name') ===
                          'Maitakobi Model School ' ? (
                            <option value="accountant-form-master">
                              Accountant & Form Master
                            </option>
                          ) : (
                            ''
                          )}
                          {reactLocalStorage.get('school-name') ===
                          'MAITAKOBI ISLAMIC SCHOOL' ? (
                            <option value="accountant-form-master">
                              Accountant & Form Master
                            </option>
                          ) : (
                            ''
                          )}

                          <option value="form-master">Form Master</option>
                          <option value="accountant">Accountant</option>
                          <option value="teaching">Teaching</option>
                          <option value="non-teaching">Non Teaching</option>
                        </select>
                      </div>
                    </div>

                    <div className="">
                      {role === 'form-master' ||
                      role === 'accountant-form-master' ? (
                        // reactLocalStorage.get('school-name') ===
                        // 'IMAMUL BUKHARI ACADEMY, GOMBE' ? (
                        <div className="grid grid-flow-col  grid-cols-2 justify-between py-1 space-x-5  overflow-y-auto overflow-x-hidden ">
                          <div className="flex flex-col">
                            <label
                              htmlFor="class"
                              className="py-1.5 flex justify-end"
                            >
                              Class
                            </label>
                            <select
                              className="rounded-sm p-2  focus:outline-none focus:ring-1 bg-account_background"
                              placeholder="class"
                              required
                              name="formValue"
                              value={formValue}
                              onChange={(e) => setFormValue(e.target.value)}
                            >
                              <option value="select class">select class</option>
                              {classDocument.map((classes, index) =>
                                classes.click ? (
                                  <option
                                    className="uppercase"
                                    key={classes.id}
                                    value={classes.name}
                                  >
                                    {classes.name}
                                  </option>
                                ) : (
                                  ''
                                )
                              )}
                            </select>
                          </div>
                          <div className="flex flex-col">
                            <label
                              htmlFor="class"
                              className="py-1.5 flex justify-end"
                            >
                              Class
                            </label>
                            <select
                              className="rounded-sm p-2  focus:outline-none focus:ring-1 bg-account_background"
                              placeholder="class"
                              required
                              name="formValue2"
                              value={formValue2}
                              onChange={(e) => setFormValue2(e.target.value)}
                            >
                              <option value="select class">select class</option>
                              {classDocument.map((classes, index) =>
                                classes.click ? (
                                  <option
                                    className="uppercase"
                                    key={classes.id}
                                    value={classes.name}
                                  >
                                    {classes.name}
                                  </option>
                                ) : (
                                  ''
                                )
                              )}
                            </select>
                          </div>
                        </div>
                      ) : (
                        ''
                      )}
                    </div>
                  </form>
                ) : (
                  <Role />
                )}
                <div className="px-5 py-5 flex items-end justify-end space-x-5">
                  <button
                    onClick={closeEditModal}
                    className="px-3 py-1.5 rounded-sm outline-none focus:outline-none text-gray-500 hover:bg-gray-100"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={() => {
                      // handleSave();
                      infoClick && role !== 'non-teaching'
                        ? handleNext()
                        : handleSave()
                    }}
                    className={
                      name && phone && gender !== 'select'
                        ? saveButtonClick
                        : saveButtonDefault
                    }
                  >
                    {loading ? (
                      <i
                        className={'fas fa-circle-notch fa-spin text-base'}
                      ></i>
                    ) : (
                      <p className="2xl:text-lg">
                        {infoClick && role !== 'non-teaching' ? 'Next' : 'Save'}
                      </p>
                    )}
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </div>
  )
}
