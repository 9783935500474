import { Menu, Dialog, Transition } from '@headlessui/react'
import { Fragment, useState, useRef } from 'react'
import { ExclamationIcon } from '@heroicons/react/outline'
import firebase from 'firebase/app'
import { storage } from '../firebase'
import { NotificationManager } from 'react-notifications'
import 'react-notifications/lib/notifications.css'
import { reactLocalStorage } from 'reactjs-localstorage'
import regular from '../assets/font/regular.ttf'
import bold from '../assets/font/bold.ttf'

import toast, { Toaster } from 'react-hot-toast'

const notify = () => toast('Deleted Successful')

export default function AssessmentDropMenu({
  student,
  classValue,
  sessionValue,
  termValue,
  subjectValue,
  isNewRecord,
  setIsNewRecord,
  assessment,
  setAssessment,
  allStudent,
  setAllStudent,
  index,
  setSubjectValue,
}) {
  const [showDeleteDialog, setshowDeleteDialog] = useState(false)
  const [showEditDialog, setshowEditDialog] = useState(false)
  const cancelButtonRef = useRef()
  const [loading, setLoading] = useState(false)
  const [errorMessage, setError] = useState('')
  const [assessment1, setAssessment1] = useState(student.ass1)
  const [assessment2, setAssessment2] = useState(student.ass2)
  const [test, setTest] = useState(student.test)
  const [test2, setTest2] = useState(student.test2 ? student.test2 : 0)
  const [exam, setExam] = useState(student.exam)
  const [total, setTotal] = useState(student.total)
  const [grade, setGrade] = useState(student.grade)
  const [remark, setRemark] = useState(student.remark)
  const [name, setName] = useState(student.name)
  const [id, setId] = useState(student.studentid)
  // const [subject, setSubject] = useState(student.subject)

  const tabDefaultStyle =
    'text-primary md:text-white text-xs md:text-sm select-none  uppercase py-4 px-4 md:px-6 block focus:outline-none'
  const tabClickStyle =
    'uppercase text-sm md:text-sm  py-4 px-4 md:px-6 block select-none  focus:outline-none text-primary md:text-white  border-b-2 font-medium border-primary md:border-white'
  const saveButtonDefault =
    'px-3 py-2  rounded-sm outline-none focus:outline-none text-gray-300  bg-gray-100'
  const saveButtonClick =
    'px-3 py-2  rounded-sm outline-none focus:outline-none text-white  bg-primary'

  var schoolName = reactLocalStorage.get('school-name')
  var zenithPrimarySchool = 'zenith international integrated school gombe'

  const currentSchool = reactLocalStorage.get('current-school')
  const currentSchoolName = reactLocalStorage.get('school-name')
  const modibbo = 'MODIBBO IDRIS ACADEMY, GOMBE'
  const annibras = 'An-Nibras Model School'
  const smartGombe = 'Smart Academy Gombe'
  const flyingColorsGombe = 'knNn0QpqbUaVBcOBUBFU'
  const zenithPrimary = 'FBzIYMgZ1nJlyMX2Btk1'
  const markazu = 'qSfZ9ljUp8bmYNJo8InQ'
  const blissGombe = 'BLISS SUCCESS MODEL SCHOOL'
  const sunshineGombe = 'SUNSHINE MODEL SCHOOL, AKKO GOMBE'
  const alImanCollegeJos = 'Al-iman College Jos'
  const alImanSchoolGirls = 'AL-IMAN GIRLS SECONDARY SCHOOL'
  const alImanSchoolBoys = 'AL-IMAN SECONDARY SCHOOL'
  const esteemJos = 'fhqa4oQYx5j5KotPOLWz'
  const presteenJos = 'voZ4XR9kBq6txi85vzAB'
  const albayan = 'ATi6GodoPjPJkLfzU5OQ'
  const sunnahJos = '8B2gLb0TzZBOzkLc5byX'
  const sunnahJosDogonDutse = 'dF1Ve6tDZfykDTtl8Xs7'
  const iqraaJos = 'H8ER5nzwQIHmWH1dz3I3'
  var alimanSec =
    (currentSchoolName === alImanSchoolBoys && classValue.includes('J.S.S')) ||
    (currentSchoolName === alImanSchoolBoys && classValue.includes('S.S.S'))

  const caType =
    currentSchool === zenithPrimary ||
    currentSchool === markazu ||
    currentSchoolName === alImanSchoolGirls ||
    alimanSec ||
    currentSchool === esteemJos ||
    currentSchool === presteenJos ||
    currentSchool === albayan ||
    currentSchool === iqraaJos || currentSchool === sunnahJosDogonDutse
      ? '4'
      : '3'

  function closeEditModal() {
    setshowEditDialog(false)
    setName('')
    setId('')
    setAssessment1(0)
    setAssessment2(0)
    setTest(0)
    setTest2(0)
    setExam(0)
    setTotal(0)
    setGrade('')
    // setSubjectValue('')
  }
  function openEditModal() {
    if (subjectValue != 'select') {
      setName(student.name)
      setId(student.studentid)
      setAssessment1(student.ass1)
      setAssessment2(student.ass2)
      setTest(student.test)
      setTest2(student.test2 ? student.test2 : 0)
      setExam(student.exam)
      setTotal(student.total)
      setGrade(student.grade)
      // setSubjectValue(student.subject)
      setshowEditDialog(true)
    } else {
      NotificationManager.info('Select subject')
    }
  }
  function closeModal() {
    setshowDeleteDialog(false)
  }

  function showDialogModal() {
    if (subjectValue != 'select') {
      if (Number(student.total) > 0) {
        setshowDeleteDialog(true)
      } else {
        NotificationManager.info('Student has no any assemment record')
      }
    } else {
      NotificationManager.info('Select subject')
    }
  }

  function EditInactiveIcon(props) {
    return (
      <svg
        {...props}
        width="20"
        height="20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2.5 14.375V17.5h3.125l9.217-9.217-3.125-3.125L2.5 14.375zm14.758-8.508a.83.83 0 000-1.175l-1.95-1.95a.83.83 0 00-1.175 0l-1.525 1.525 3.125 3.125 1.525-1.525z"
          fill="#000"
          fill-opacity=".7"
        />
      </svg>
    )
  }

  function EditActiveIcon(props) {
    return (
      <svg
        {...props}
        width="20"
        height="20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2.5 14.375V17.5h3.125l9.217-9.217-3.125-3.125L2.5 14.375zm14.758-8.508a.83.83 0 000-1.175l-1.95-1.95a.83.83 0 00-1.175 0l-1.525 1.525 3.125 3.125 1.525-1.525z"
          fill="#0064AF"
        />
      </svg>
    )
  }

  function DeleteInactiveIcon(props) {
    return (
      <svg
        {...props}
        width="20"
        height="20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5 15.833c0 .917.75 1.667 1.667 1.667h6.666c.917 0 1.667-.75 1.667-1.667v-10H5v10zM6.667 7.5h6.666v8.333H6.667V7.5zm6.25-4.167l-.834-.833H7.917l-.834.833H4.167V5h11.666V3.333h-2.916z"
          fill="#000"
          fill-opacity=".7"
        />
      </svg>
    )
  }

  function DeleteActiveIcon(props) {
    return (
      <svg
        {...props}
        width="20"
        height="20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5 15.833c0 .917.75 1.667 1.667 1.667h6.666c.917 0 1.667-.75 1.667-1.667v-10H5v10zM6.667 7.5h6.666v8.333H6.667V7.5zm6.25-4.167l-.834-.833H7.917l-.834.833H4.167V5h11.666V3.333h-2.916z"
          fill="#0064AF"
        />
      </svg>
    )
  }

  const deleteStudent = () => {
    var isFound = checkAssessment(student)
    deleteStudentAssessment(isFound)

    // var db = firebase.firestore()
    // setError('')
    // setLoading(true)
    // db.collection('students')
    //   .doc(student.id)
    //   .delete()
    //   .then((querySnapshot) => {
    //     //notify();
    //     // setStudentCount(studentCount - 1)
    //     NotificationManager.info('Deleted Successful')
    //   })
    //   .catch((error) => {
    //     setError(error.message)
    //   })
  }

  const handleSave = () => {
    if (isNewRecord) {
      if (Number(total) > 0) {
        save()
      } else {
        NotificationManager.info('Enter the require fields')
      }
    } else {
      var isFound = checkAssessment(student)
      if (isFound > -1) {
        assessment.splice(isFound, 1)
      }
      addStudentAssessment()
    }
  }

  function checkAssessment(doc) {
    var isFound = -1
    for (let index = 0; index < assessment.length; index++) {
      const element = assessment[index]
      if (element.doc_id === doc.doc_id) {
        isFound = index
        break
      }
    }
    return isFound
  }

  function calTotal(name, e) {
    switch (name) {
      case 'ass1':
        {
          if (e.target.value < 0) e.target.value = 0
          if (e.target.value > 10) e.target.value = 10

          setAssessment1(e.target.value)
          var value =
            Number(e.target.value) +
            Number(isNaN(assessment2) ? 0 : assessment2) +
            Number(isNaN(test) ? 0 : test) +
            Number(isNaN(test2) ? 0 : test2) +
            Number(isNaN(exam) ? 0 : exam)
          getGrade(value)
          getRemark(value)
        }
        break
      case 'ass2':
        {
          if (e.target.value < 0) e.target.value = 0
          if (e.target.value > 10) e.target.value = 10
          setAssessment2(e.target.value)
          var value =
            Number(isNaN(assessment1) ? 0 : assessment1) +
            Number(e.target.value) +
            Number(isNaN(test) ? 0 : test) +
            Number(isNaN(test2) ? 0 : test2) +
            Number(isNaN(exam) ? 0 : exam)
          getGrade(value)
          getRemark(value)
        }
        break
      case 'test':
        {
          if (e.target.value < 0) e.target.value = 0
          if (e.target.value > 20) e.target.value = 20
          if (currentSchool === modibbo && e.target.value > 10) {
            e.target.value = 10
          }

          setTest(e.target.value)
          var value =
            Number(isNaN(assessment1) ? 0 : assessment1) +
            Number(isNaN(assessment2) ? 0 : assessment2) +
            Number(e.target.value) +
            Number(isNaN(test2) ? 0 : test2) +
            Number(isNaN(exam) ? 0 : exam)
          getGrade(value)
          getRemark(value)
        }
        break
      case 'test2':
        {
          if (e.target.value < 0) e.target.value = 0
          if (e.target.value > 20) e.target.value = 20
          if (currentSchool === modibbo && e.target.value > 10) {
            e.target.value = 10
          }

          setTest2(e.target.value)
          var value =
            Number(isNaN(assessment1) ? 0 : assessment1) +
            Number(isNaN(assessment2) ? 0 : assessment2) +
            Number(isNaN(test) ? 0 : test) +
            Number(e.target.value) +
            Number(isNaN(exam) ? 0 : exam)
          getGrade(value)
          getRemark(value)
        }
        break

      case 'exam':
        {
          if (e.target.value < 0) e.target.value = 0

          if (currentSchool === modibbo) {
            if (e.target.value > 70) e.target.value = 70
          } else {
            if (e.target.value > 60) e.target.value = 60
          }

          setExam(e.target.value)
          var value =
            Number(isNaN(assessment1) ? 0 : assessment1) +
            Number(isNaN(assessment2) ? 0 : assessment2) +
            Number(isNaN(test) ? 0 : test) +
            Number(isNaN(test2) ? 0 : test2) +
            Number(e.target.value)
          getGrade(value)
          getRemark(value)
        }
        break
    }
  }

  const getGrade = (totalValue) => {
    setTotal(totalValue)
    if (totalValue >= 70) {
      setGrade('A')
    } else if (totalValue >= 60) {
      setGrade('B')
    } else if (totalValue >= 50) {
      setGrade('C')
    } else if (totalValue >= 45) {
      setGrade('D')
    } else if (totalValue >= 40) {
      setGrade('E')
    } else {
      setGrade('F')
    }
  }

  const getRemark = (totalValue) => {
    setTotal(totalValue)
    if (totalValue >= 80) {
      setRemark('Excellent')
    } else if (totalValue >= 70) {
      setRemark('Very Good')
    } else if (totalValue >= 65) {
      setRemark('Good')
    } else if (totalValue >= 60) {
      setRemark('Credit')
    } else if (totalValue >= 40) {
      setRemark('Pass')
    } else {
      setRemark('Fail')
    }
  }

  // const handleChange = (event) => {
  //   const value = Math.max(min, Math.min(max, Number(event.target.value)))
  //   setValue(value)
  // }

  const save = () => {
    setLoading(true)
    setError('')
    var db = firebase.firestore()
    db.collection('assessment')
      .doc(
        reactLocalStorage
          .get('current-school')
          .concat('_')
          .concat(sessionValue.replace('/', '_'))
          .concat('_')
          .concat(termValue.replace(' ', '_'))
          .concat('_')
          .concat(classValue)
          .concat('_')
          .concat(subjectValue.replace(' ', '_'))
      )
      .set({
        subject: subjectValue,
        class: classValue,
        session: sessionValue,
        term: termValue,
        sstc: reactLocalStorage
          .get('current-school')
          .concat('_')
          .concat(sessionValue.replace('/', '_'))
          .concat('_')
          .concat(termValue.replace(' ', '_'))
          .concat('_')
          .concat(classValue),
        students: [
          {
            subject: subjectValue,
            doc_id: student.doc_id,
            studentid: student.studentid,
            name: student.name,
            ass1: !assessment1 ? '-' : parseFloat(assessment1),
            ass2: !assessment2 ? '-' : parseFloat(assessment2),
            test: !test ? '-' : parseFloat(test),
            test2: !test2 ? '-' : parseFloat(test2),
            exam: !exam ? '-' : parseFloat(exam),
            total: total,
            grade: grade,
            remark: remark,
            class: classValue,
            gender: student.gender,
            status: student.status,
            state: student.state,
            school: student.school,
            school_class: student.school_class,
            position: 1,
            class_average: total,
          },
        ],
        date: firebase.firestore.Timestamp.now(),
      })
      .then((docRef) => {
        var stu = [...allStudent]
        stu[index].ass1 = !assessment1 ? '-' : parseFloat(assessment1)
        stu[index].ass2 = !assessment2 ? '-' : parseFloat(assessment2)
        stu[index].test = !test ? '-' : parseFloat(test)
        stu[index].test2 = !test2 ? '-' : parseFloat(test2)
        stu[index].exam = !exam ? '-' : parseFloat(exam)
        stu[index].total = total
        stu[index].grade = grade
        stu[index].remark = remark
        stu[index].position = 1
        stu[index].subject = subjectValue
        setAllStudent(stu)
        setIsNewRecord(!isNewRecord)
        setAssessment([...assessment, stu[index]])

        //setAssessmentPosition()
        NotificationManager.info('Added Successful')
        setLoading(false)
        closeEditModal()
      })
      .catch((error) => {
        setError(error.message)
        setLoading(false)
      })
  }
  const addStudentAssessment = () => {
    setLoading(true)
    setError('')
    var db = firebase.firestore()

    // POST DATA
    var stu = [...allStudent]
    stu[index].ass1 = !assessment1 ? '-' : parseFloat(assessment1)
    stu[index].ass2 = !assessment2 ? '-' : parseFloat(assessment2)
    stu[index].test = !test ? '-' : parseFloat(test)
    stu[index].test2 = !test2 ? '-' : parseFloat(test2)
    stu[index].exam = !exam ? '-' : parseFloat(exam)
    stu[index].total = total
    stu[index].grade = grade
    stu[index].remark = remark
    stu[index].subject = subjectValue

    var assess = [...assessment, stu[index]]
    var score = []
    assess.map((ass) => {
      score.push(ass.total)
    })
    var avg = classAverage(score)
    var ranks = findRanks(score)
    assess.map((assessment, index) => {
      assessment.position = ranks[index]
      assessment.class_average = avg
    })

    // Remove current Item
    // Remove current Item
    var lastItem = assess.pop()
    var lastItemPosition = ranks.pop()

    db.collection('assessment')
      .doc(
        reactLocalStorage
          .get('current-school')
          .concat('_')
          .concat(sessionValue.replace('/', '_'))
          .concat('_')
          .concat(termValue.replace(' ', '_'))
          .concat('_')
          .concat(classValue)
          .concat('_')
          .concat(subjectValue.replace(' ', '_'))
      )
      .update({
        students: [
          ...assess,
          {
            subject: subjectValue,
            doc_id: student.doc_id,
            studentid: student.studentid,
            name: student.name,
            ass1: !assessment1 ? '-' : parseFloat(assessment1),
            ass2: !assessment2 ? '-' : parseFloat(assessment2),
            test: !test ? '-' : parseFloat(test),
            test2: !test2 ? '-' : parseFloat(test2),
            exam: !exam ? '-' : parseFloat(exam),
            total: total,
            grade: grade,
            remark: remark,
            class: classValue,
            gender: student.gender,
            status: student.status,
            state: student.state,
            school: student.school,
            school_class: student.school_class,
            position: lastItemPosition,
            class_average: avg,
          },
        ],
        date: firebase.firestore.Timestamp.now(),
      })
      .then((docRef) => {
        setAllStudent(stu)
        setAssessment([...assess, stu[index]])

        //setAssessmentPosition()
        NotificationManager.info('Added Successful')
        setLoading(false)
        closeEditModal()
      })
      .catch((error) => {
        setError(error.message)
        setLoading(false)
      })
  }

  const deleteStudentAssessment = (indexAss) => {
    setLoading(true)
    setError('')
    var db = firebase.firestore()

    // POST DATA
    var stu = [...allStudent]
    delete stu[index].ass1
    delete stu[index].ass2
    delete stu[index].test
    delete stu[index].test2
    delete stu[index].exam
    delete stu[index].total
    delete stu[index].grade
    delete stu[index].remark
    delete stu[index].position
    var assess = [...assessment]
    assess.splice(indexAss, 1)

    var score = []
    assess.map((ass) => {
      score.push(ass.total)
    })
    var avg = classAverage(score)
    var ranks = findRanks(score)
    assess.map((assessment, index) => {
      assessment.position = ranks[index]
      assessment.class_average = avg
    })

    // Remove current Item
    // Remove current Item
    //var lastItem = assess.pop()
    // var lastItemPosition = ranks.pop()

    db.collection('assessment')
      .doc(
        reactLocalStorage
          .get('current-school')
          .concat('_')
          .concat(sessionValue.replace('/', '_'))
          .concat('_')
          .concat(termValue.replace(' ', '_'))
          .concat('_')
          .concat(classValue)
          .concat('_')
          .concat(subjectValue.replace(' ', '_'))
      )
      .update({
        students: [...assess],
        date: firebase.firestore.Timestamp.now(),
      })
      .then((docRef) => {
        setAllStudent(stu)
        setAssessment([...assess])

        //setAssessmentPosition()
        NotificationManager.info('Delete Successful')
        setshowDeleteDialog(false)
        closeModal()
      })
      .catch((error) => {
        setError(error.message)
        setLoading(false)
      })
  }

  const findRanks = (arr = []) => {
    const { length } = arr
    let sortArray = arr.slice()
    sortArray.sort((a, b) => b - a)
    const result = []
    for (let i = 0; i < length; i++) {
      const j = sortArray.indexOf(arr[i])
      result.push(j + 1)
    }
    return result
  }
  const classAverage = (arr = []) => {
    var total = 0
    arr.map((score) => {
      total = total + Number(score)
    })
    var avg = total / arr.length
    var fixed = avg.toFixed(1)
    return fixed
  }

  return (
    <div className="text-right ">
      <Menu as="div" className="relative inline-block text-left ">
        {({ open }) => (
          <>
            <div>
              <Menu.Button className="text-gray-500 hover:text-primary outline-none focus:outline-none">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z" />
                </svg>
              </Menu.Button>
            </div>
            <Transition
              show={open}
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items
                static
                className="absolute  right-0 bottom-0  w-48  mt-2 border-account_background border  bg-white divide-y divide-gray-100 rounded-md shadow-lg  focus:outline-none z-50"
              >
                <div
                  className="p-1 cursor-pointer "
                  onClick={() => openEditModal()}
                >
                  <Menu.Item as="button">
                    {({ active }) => (
                      <button
                        className={`${
                          active
                            ? ' text-secondary outline-none focus:outline-none'
                            : 'text-gray-900'
                        } group flex rounded-md items-center w-full px-2 py-2 text-sm outline-none focus:outline-none`}
                      >
                        {active ? (
                          <EditActiveIcon
                            className="w-5 h-5 mr-2"
                            aria-hidden="true"
                          />
                        ) : (
                          <EditInactiveIcon
                            className="w-5 h-5 mr-2"
                            aria-hidden="true"
                          />
                        )}
                        Edit
                      </button>
                    )}
                  </Menu.Item>
                </div>

                <div
                  className="p-1 cursor-pointer"
                  onClick={() => showDialogModal()}
                >
                  <Menu.Item as="button">
                    {({ active }) => (
                      <button
                        className={`${
                          active
                            ? ' text-secondary outline-none focus:outline-none'
                            : 'text-gray-900'
                        } group flex rounded-md items-center w-full px-2 py-2 text-sm outline-none focus:outline-none`}
                      >
                        {active ? (
                          <DeleteActiveIcon
                            className="w-5 h-5 mr-2"
                            aria-hidden="true"
                          />
                        ) : (
                          <DeleteInactiveIcon
                            className="w-5 h-5 mr-2"
                            aria-hidden="true"
                          />
                        )}
                        Delete
                      </button>
                    )}
                  </Menu.Item>
                </div>
              </Menu.Items>
            </Transition>
          </>
        )}
      </Menu>

      <Transition show={showDeleteDialog} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-y-auto"
          initialFocus={cancelButtonRef}
          static
          showDialog={showDeleteDialog}
          open={showDeleteDialog}
          onClose={closeModal}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0  ">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block align-bottom bg-white text-left  transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full rounded-md shadow-lg ring-1 ring-black ring-opacity-5 ">
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4 ">
                  <div className="sm:flex sm:items-start ">
                    <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                      <ExclamationIcon
                        className="h-6 w-6 text-red-600"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left select-none">
                      <Dialog.Title
                        as="h3"
                        className="text-lg leading-6 font-medium text-gray-900"
                      >
                        Delete {student.name}
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500 select-none">
                          Are you sure you want to delete {student.name} this
                          term assessment?.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse ">
                  <button
                    className="w-full  inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={() => deleteStudent()}
                  >
                    {loading ? (
                      <i
                        className={'fas fa-circle-notch fa-spin text-base'}
                      ></i>
                    ) : (
                      <p className="2xl:text-lg">Delete</p>
                    )}
                  </button>
                  <button
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none  sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={() => closeModal()}
                    ref={cancelButtonRef}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>

      <Transition show={showEditDialog} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-y-auto"
          initialFocus={cancelButtonRef}
          static
          open={showEditDialog}
          onClose={closeEditModal}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-full max-w-md border border-gray-100  md:border-none text-sm  overflow-hidden text-left align-middle transition-all transform bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 py-3  md:text-white bg-white md:bg-primary"
                >
                  <nav class="flex flex-row justify-center">
                    <button className={tabClickStyle}>{name}</button>
                  </nav>
                </Dialog.Title>

                <form className="flex flex-col px-5 max-h-100 overflow-y-auto">
                  <div className="grid grid-flow-col grid-cols-2 py-1 space-x-3">
                    <div className="flex flex-col w-full">
                      <label htmlFor="ass1" className="py-2">
                        1<sup>st</sup> ASSIGMENT
                      </label>
                      <input
                        className="rounded-sm p-2  focus:outline-none focus:ring-1 bg-account_background "
                        type="number"
                        placeholder="10"
                        required
                        name="ass1"
                        value={assessment1}
                        min={1}
                        max={10}
                        onChange={(e) => calTotal('ass1', e)}
                      />
                    </div>
                    <div className="flex flex-col w-full">
                      <label htmlFor="ass2" className="py-2 flex justify-end">
                        2<sub>nd</sub> ASSIGMENT
                      </label>
                      <input
                        className="rounded-sm p-2 focus:outline-none focus:ring-1 bg-account_background "
                        type="number"
                        placeholder="10"
                        required
                        name="ass2"
                        value={assessment2}
                        min={1}
                        max={10}
                        onChange={(e) => calTotal('ass2', e)}
                      />
                    </div>
                  </div>
                  <div className="grid grid-flow-col grid-cols-2 py-1 space-x-3">
                    <div className="flex flex-col w-full">
                      <label htmlFor="test" className="py-2">
                        1st TEST
                      </label>
                      <input
                        className="rounded-sm p-2  focus:outline-none focus:ring-1 bg-account_background "
                        type="number"
                        placeholder={currentSchool === modibbo ? '10' : '20'}
                        required
                        name="test"
                        value={test}
                        onChange={(e) => calTotal('test', e)}
                      />
                    </div>
                    <div className="flex flex-col w-full">
                      <label htmlFor="exam" className="py-2 flex justify-end">
                        {caType === '4' ? '2nd TEST' : 'EXAM'}
                      </label>
                      <input
                        className="rounded-sm p-2 focus:outline-none focus:ring-1 bg-account_background "
                        type="number"
                        placeholder={
                          caType === '4'
                            ? '20'
                            : currentSchool === modibbo
                            ? '70'
                            : '60'
                        }
                        required
                        name={caType === '4' ? 'test2' : 'exam'}
                        value={caType === '4' ? test2 : exam}
                        min={1}
                        // max={60}
                        onChange={(e) =>
                          calTotal(caType === '4' ? 'test2' : 'exam', e)
                        }
                      />
                    </div>
                  </div>
                  <div className="grid grid-flow-col grid-cols-2 py-1 space-x-3">
                    <div className="flex flex-col w-full">
                      <label htmlFor="total" className="py-2">
                        {caType === '4' ? 'EXAM' : 'TOTAL'}
                      </label>
                      <input
                        className="rounded-sm p-2  focus:outline-none focus:ring-1 bg-account_background "
                        type="number"
                        placeholder={caType === '4' ? 'exam' : 'total'}
                        required
                        name={caType === '4' ? 'exam' : 'total'}
                        value={caType === '4' ? exam : total}
                        onChange={(e) =>
                          calTotal(caType === '4' ? 'exam' : '', e)
                        }

                        // onChange={(e) => getGrade(e.target.value)}
                      />
                    </div>
                    <div className="flex flex-col w-full">
                      <label htmlFor="grade" className="py-2 flex justify-end">
                        {caType === '4' ? 'TOTAL' : 'GRADE'}
                      </label>
                      <input
                        className="rounded-sm p-2 focus:outline-none focus:ring-1 bg-account_background "
                        placeholder=""
                        required
                        name={caType === '4' ? 'total' : 'grade'}
                        disabled
                        value={caType === '4' ? total : grade}
                        //onChange={(e) => setId(e.target.value)}
                      />
                    </div>
                  </div>
                </form>

                <div className="px-5 py-5 flex items-end justify-end space-x-5">
                  <button
                    onClick={closeEditModal}
                    className="px-3 py-2 rounded-sm outline-none focus:outline-none text-gray-500 hover:bg-gray-100"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={() => {
                      handleSave()
                    }}
                    className={name && id ? saveButtonClick : saveButtonDefault}
                  >
                    {loading ? (
                      <i
                        className={'fas fa-circle-notch fa-spin text-base'}
                      ></i>
                    ) : (
                      <p className="2xl:text-lg">Save</p>
                    )}
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </div>
  )
}
