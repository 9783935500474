import React from 'react'
import { Text, View, StyleSheet } from '@react-pdf/renderer'

const styles = StyleSheet.create({
  titleContainer: {
    marginTop: 10,
  },
  reportTitle: {
    fontSize: 12,
    textAlign: 'right',
    // textTransform: 'uppercase',
  },
})

const EdusafMessage = () => (
  <View style={styles.titleContainer}>
    <Text style={styles.reportTitle}>Powered by edutech</Text>
  </View>
)

export default EdusafMessage
