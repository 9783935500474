import AuthenticationSidenav from '../components/AuthenticationSidenav'
import { useState } from 'react'
import ShowDrawer from '../components/ShowDrawer'
import ManageManual from '../components/ManageManual'

const SigninManual = () => {
  

  const [isOpen, setIsOpen] = useState(false)
  const closeClasses =
    'w-64 lg:w-72 2xl:w-96 absolute md:relative md:translate-x-0 inset-y-0 left-0 transform -translate-x-full transition duration-200 ease-in-out'
  const openClasses =
    'w-64 lg:w-72 2xl:w-96 absolute  md:relative md:translate-x-0 inset-y-0 left-0 transform transition duration-200 ease-in-out'

 
const [subTitle,setSubTitle] = useState()
   const handleClick = () => {
     !isOpen ? setIsOpen(true) : setIsOpen(false)
   }

  return (
    <div className="md:flex bg-white  md:bg-account_background   ">
      <ShowDrawer handleClick={handleClick} isOpen={isOpen} />

      <div className={!isOpen ? closeClasses : openClasses}>
        <AuthenticationSidenav type="authentication" />
      </div>

      <div className=" md:flex-1  ">
        <div className="grid md:px-5 ">
          <ManageManual web={true} mobile={false} setSubTitle={setSubTitle} />
        </div>
      </div>
    </div>
  )
}

export default SigninManual
