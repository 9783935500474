import StudentDropMenu from './StudentDropMenu'
import firebase from 'firebase/app'
import { reactLocalStorage } from 'reactjs-localstorage'
import { usePagination } from 'use-pagination-firestore'
import { useEffect, useState } from 'react'
import { NotificationManager } from 'react-notifications'
import Avatar from 'react-avatar'

const StudentTable = ({
  classDocument,
  students,
  setStudents,
  classValue,
  setClassValue,
  filter,
  setFilter,
  notEmptyFilter,
  setNotEmptyFilter,
  load,
}) => {
  const [pageSize, setPageSize] = useState(5)
  const [currentPage, setCurrentPage] = useState(0)
  const [loading, setLoading] = useState(false)
  const [items, setItems] = useState([])

  // const [studentCount, setTotalRecord] = useState();
  // const { items, isLoading, isStart, isEnd, getPrev, getNext } = usePagination(
  //   firebase
  //     .firestore()
  //     .collection('students')
  //     .where('school_class', '==', reactLocalStorage.get('current-school').concat(classValue))
  //     .orderBy('name'),
  //   {
  //     limit: pageSize,
  //   }
  // )

  // const gotoNext = () => {
  //   getNext()
  //   setCurrentPage(currentPage + 1)
  // }

  // const gotoPrevious = () => {
  //   getPrev()
  //   setCurrentPage(currentPage - 1)
  // }

  if (load) {
    return (
      <div className="grid justify-center items-center">
        <i className={' text-primary fas fa-circle-notch fa-spin text-5xl'}></i>
      </div>
    )
  }

  return (
    <div className="">
      <div className="flex flex-col max-h-96  md:max-h-82 xl_1:max-h-120 2xl:max-h-130 overflow-y-auto   shadow-md border-b border-gray-200 rounded-sm  ">
        <div className="flex-grow  ">
          <table className="relative w-full min-w-full   divide-y divide-gray-200 ">
            <thead className="">
              <tr className=" ">
                <th className="sticky bg-gray-50 z-30 md:z-20 left-0  top-0  px-4  py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Name
                </th>
                <th className="sticky bg-gray-50 z-20 top-0 px-4   py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Class
                </th>
                <th className="sticky bg-gray-50 z-20 top-0 px-4   py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Gender/Date of Birth
                </th>
                <th className="sticky bg-gray-50 z-20  top-0 px-4  py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  State
                </th>
                <th className="sticky bg-gray-50 z-20 top-0 px-4 py-3">
                  <span className="sr-only">Edit</span>
                </th>
              </tr>
            </thead>

            <tbody className="bg-white divide-y divide-gray-200 ">
              {/* {sectionValue == 'select'
                ? ''
                : filterSection()} */}

              {notEmptyFilter
                ? filter.map((student, index) => (
                    <tr key={student.id}>
                      <td
                        key={1}
                        className="sticky left-0  bg-white px-4 py-2 whitespace-nowrap "
                      >
                        <div className="flex items-center">
                          <div className="flex-shrink-0 h-10 w-10">
                            {student.photo ? (
                              <img
                                key={student.studentid}
                                className="h-10 w-10 rounded-full"
                                src={student.photo}
                                alt=""
                              />
                            ) : (
                              <Avatar
                                name={student.name}
                                size="32"
                                textSizeRatio={1}
                                round={true}
                              />
                            )}
                          </div>
                          <div className="ml-4">
                            <div className="text-sm font-medium capitalize text-gray-900">
                              {student.name}
                            </div>
                            <div className="text-sm capitalize text-gray-500">
                              {student.studentid}
                            </div>
                          </div>
                        </div>
                      </td>
                      <td
                        key={2}
                        className="px-4 py-2 whitespace-nowrap capitalize"
                      >
                        <div className="text-sm text-gray-900">
                          {student.section}
                        </div>
                        <div className="text-sm text-gray-500">
                          {student.class}
                        </div>
                      </td>

                      <td key={3} className="px-4 py-2 whitespace-nowrap">
                        <div className="text-sm uppercase text-gray-900">
                          {student.gender}
                        </div>
                        <div className="text-sm capitalize text-gray-500">
                          {student.dob}
                        </div>
                      </td>
                      <td key={4} className="px-4 py-2 whitespace-nowrap">
                        <div className="text-sm capitalize text-gray-900">
                          {student.state}
                        </div>
                        <div className="text-sm capitalize text-gray-500">
                          {student.lga}
                        </div>
                      </td>

                      <td
                        key={5}
                        className="px-2  whitespace-nowrap text-right text-sm font-medium z-30"
                      >
                        <StudentDropMenu
                          student={student}
                          classDocument={classDocument}
                          students={students}
                          setStudents={setStudents}
                          classValue={classValue}
                          setClassValue={setClassValue}
                        />
                      </td>
                    </tr>
                  ))
                : students.map((student, index) => (
                    <tr key={student.id}>
                      <td
                        key={1}
                        className="sticky left-0  bg-white px-4 py-2 whitespace-nowrap "
                      >
                        <div className="flex items-center">
                          <div className="flex-shrink-0 h-10 w-10">
                            {student.photo ? (
                              <img
                                key={student.studentid}
                                className="h-10 w-10 rounded-full"
                                src={student.photo}
                                alt=""
                              />
                            ) : (
                              <Avatar
                                name={student.name}
                                size="32"
                                textSizeRatio={1}
                                round={true}
                              />
                            )}
                          </div>
                          <div className="ml-4">
                            <div className="text-sm font-medium capitalize text-gray-900">
                              {student.name}
                            </div>
                            <div className="text-sm capitalize text-gray-500">
                              {student.studentid}
                            </div>
                          </div>
                        </div>
                      </td>
                      <td
                        key={2}
                        className="px-4 py-2 whitespace-nowrap capitalize"
                      >
                        <div className="text-sm text-gray-900">
                          {student.section}
                        </div>
                        <div className="text-sm text-gray-500">
                          {student.class}
                        </div>
                      </td>

                      <td key={3} className="px-4 py-2 whitespace-nowrap">
                        <div className="text-sm uppercase text-gray-900">
                          {student.gender}
                        </div>
                        <div className="text-sm capitalize text-gray-500">
                          {student.dob}
                        </div>
                      </td>
                      <td key={4} className="px-4 py-2 whitespace-nowrap">
                        <div className="text-sm capitalize text-gray-900">
                          {student.state}
                        </div>
                        <div className="text-sm capitalize text-gray-500">
                          {student.lga}
                        </div>
                      </td>

                      <td
                        key={5}
                        className="px-2  whitespace-nowrap text-right text-sm font-medium z-30"
                      >
                        <StudentDropMenu
                          student={student}
                          index={index}
                          classDocument={classDocument}
                          students={students}
                          setStudents={setStudents}
                          classValue={classValue}
                          setClassValue={setClassValue}
                        />
                      </td>
                    </tr>
                  ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* PAGINATION */}
      {/* <div className="">
        <div className="grid justify-items-end  ">
          <div className="flex text-gray-400 py-5 px-4 md:px-0  space-x-4 md:space-x-8">
            <div className="flex">
              <p className="text-sm text-gray-600">Rows per page:</p>
              {items.length > 0 && studentCount > pageSize ? (
                <select
                  onChange={(e) => setPageSize(e.target.value)}
                  value={pageSize}
                  className="text-gray-600 bg-white text-sm text-center items-center justify-center px-4 rounded-sm f outline-none focus:outline-none"
                >
                  <option value="5">5</option>
                  <option value="10">10</option>
                  <option value="15">15</option>
                  <option value="20">20</option>
                </select>
              ) : (
                <select
                  onChange={(e) => setPageSize(e.target.value)}
                  value={pageSize}
                  disabled
                  className="text-gray-600 bg-white text-sm text-center items-center justify-center px-4 rounded-sm f outline-none focus:outline-none"
                >
                  <option value="5">5</option>
                  <option value="10">10</option>
                  <option value="15">15</option>
                  <option value="20">20</option>
                </select>
              )}
            </div>
            <div className="text-sm text-gray-600">
              <span>{parseInt(currentPage) * parseInt(pageSize) + 1} </span>
              <span> - </span>
              <span>
                {studentCount > pageSize &&
                  parseInt(currentPage) * parseInt(pageSize) +
                    parseInt(pageSize)}
                {studentCount <= pageSize && studentCount}
              </span>
              <span> of </span>
              <span>{studentCount}</span>
            </div>

            {items.length > 0 && currentPage > 0 && (
              <button
                onClick={gotoPrevious}
                className="outline-none focus:outline-none"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-5 w-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="#000000"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M15 19l-7-7 7-7"
                  />
                </svg>
              </button>
            )}
            {currentPage <= 0 && (
              <button className="outline-none focus:outline-none">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-5 w-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M15 19l-7-7 7-7"
                  />
                </svg>
              </button>
            )}

            {!isEnd && (
              <button
                onClick={gotoNext}
                className="outline-none focus:outline-none"
              >
                {' '}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-5 w-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="#000000"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M9 5l7 7-7 7"
                  />
                </svg>
              </button>
            )}
            {isEnd && (
              <button className="outline-none focus:outline-none">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-5 w-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M9 5l7 7-7 7"
                  />
                </svg>
              </button>
            )}
          </div>
        </div>
      </div> */}
    </div>
  )
}
export default StudentTable
