import { useState, useEffect } from 'react'
import UnPaidTable from './UnPaidTable'
import firebase from 'firebase/app'
import { reactLocalStorage } from 'reactjs-localstorage'
import {
  PDFDownloadLink,
  PDFViewer,
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
  Font,
} from '@react-pdf/renderer'
import {
  Table,
  TableHeader,
  TableBody,
  DataTableCell,
  TableCell,
} from '@david.kucsai/react-pdf-table'

import regular from '../assets/font/regular.ttf'
import bold from '../assets/font/bold.ttf'
Font.register({
  family: 'Ubuntu',
  fonts: [
    {
      src: regular,
    },
    {
      src: bold,
      fontWeight: 'bold',
    },
    {
      src: 'https://fonts.gstatic.com/s/questrial/v13/QdVUSTchPBm7nuUeVf7EuStkm20oJA.ttf',
      fontWeight: 'normal',
      fontStyle: 'italic',
    },
  ],
})
const StudentsUnPaid = ({
  // unPaidCount,
  // setUnPaidCount,
  sessionValue,
  termValue,
  classValue,
  amount,
  allStudent,
  //paidStudent,
  unpaid,
  setUnPaidClick,
  fees,
  selectedFeesIndex,
  loading,
  setLoading,
  activated,
}) => {
  const [classDocument, setClassDocument] = useState([])

  useEffect(() => {}, [])

  const styles = StyleSheet.create({
    page: {
      flexDirection: 'column',
      paddingTop: 30,
      // paddingLeft: 50,
      // paddingRight: 50,
      paddingHorizontal: 30,
      fontFamily: 'Helvetica',
    },

    section: {
      // marginVertical: 5,
      color: '#fff',
    },
    section2: {
      marginHorizontal: 20,
      color: '#fff',
    },
    logo: {
      // width: 100,
      // height: 100,
      marginLeft: 'auto',
      marginRight: 'auto',
    },

    school: {
      // marginVertical: 10,
      color: '#07173B',
      // letterSpacing: 1,
      fontSize: 40,
      fontWeight: 'heavy',
      textAlign: 'center',
      textTransform: 'uppercase',
      fontFamily: 'Times-Bold',
    },
    school2: {
      // marginVertical: 10,
      color: '#07173B',
      // letterSpacing: 1,
      fontSize: 20,
      fontWeight: 'heavy',
      textAlign: 'center',
      textTransform: 'uppercase',
      fontFamily: 'Times-Bold',
    },

    title: {
      marginTop: 5,
      color: '#3E8EDE',
      // letterSpacing: 1,
      fontSize: 15,
      textAlign: 'center',
      textTransform: 'uppercase',
      fontFamily: 'Helvetica',
    },
    subTitle: {
      // marginVertical: 5,
      marginTop: 15,
      color: '#3E8EDE',
      // letterSpacing: 1,
      fontSize: 12,
      // textAlign: 'center',
      textTransform: 'uppercase',
      fontFamily: 'Helvetica',
    },
    subSubjetClass: {
      marginVertical: 5,
      // color: '#3E8EDE',
      // letterSpacing: 1,
      fontSize: 12,
      // textAlign: 'center',
      textTransform: 'uppercase',
      fontFamily: 'Ubuntu',
    },
    remarkText: {
      // marginVertical: 5,
      marginTop: 20,
      // letterSpacing: 1,
      fontSize: 12,
      // textDecoration: 'underline',
      // textAlign: 'center',
      // textTransform: 'uppercase',
      fontFamily: 'Helvetica',
    },
    edusaf: {
      fontSize: 8,
      fontFamily: 'Helvetica',
      position: 'absolute',
      bottom: 5,
      right: 5,
    },

    table: {
      fontSize: 12,
      textAlign: 'center',
      fontFamily: 'Helvetica',
    },
    tableBehaviour: {
      fontSize: 12,
      textAlign: 'center',
      fontFamily: 'Helvetica',
      width: 250,
      flex: 1,
      // flexDirection: 'row',
    },
    tableRatingParent: {
      fontSize: 12,
      textAlign: 'center',
      fontFamily: 'Helvetica',
      width: 200,
      flex: 1,
      // flexDirection: 'row',
    },
    tableBehaviourParent: {
      fontSize: 12,
      textAlign: 'center',
      fontFamily: 'Helvetica',
      flexDirection: 'row',
      display: 'flex',
    },

    tableRating: {
      fontSize: 12,
      textAlign: 'left',
      paddingLeft: 5,
      fontFamily: 'Helvetica',
    },
    tableLeft: {
      fontSize: 10,
      textAlign: 'left',
      fontFamily: 'Helvetica',
      paddingLeft: 5,
      // backgroundColor: '#D3ECE6',
    },
    student: {
      fontSize: 12,
      textAlign: 'left',
      fontFamily: 'Helvetica',
      paddingLeft: 5,
      paddingVertical: 2,
    },
    studentTable: {
      // marginVertical: 10,
    },
    total: {
      fontSize: 12,
      textAlign: 'center',
      fontFamily: 'Helvetica',
      // backgroundColor: '#D3ECE6',
    },
    tablesubject: {
      fontSize: 12,
      textAlign: 'left',
      paddingLeft: 5,
      // backgroundColor: '#D3ECE6',
      fontFamily: 'Helvetica',
    },
    tableNamesubject: {
      fontSize: 12,
      textAlign: 'left',
      paddingLeft: 5,
      // backgroundColor: '#D3ECE6',
      fontFamily: 'Helvetica',
      paddingVertical: 2,
    },
    studentDetails: {
      fontSize: 12,
      textAlign: 'left',
      fontFamily: 'Helvetica',
      paddingLeft: 5,
      paddingVertical: 2,
    },
  })
  function getCAData() {
    // setLoading(false)
    var dat = []
    unpaid.map((stud, index) => {
      dat.push({
        index: (
          <View style={styles.tableLeft}>
            <Text>{index + 1}</Text>
          </View>
        ),
        studentid: (
          <View style={styles.tableLeft}>
            <Text>{stud.studentid}</Text>
          </View>
        ),
        name: (
          <View style={styles.tableLeft}>
            <Text>{stud.name.toUpperCase()}</Text>
          </View>
        ),
        fee: (
          <View style={styles.tableLeft}>
            <Text>{amount}</Text>
          </View>
        ),

        discount: (
          <View style={styles.tableLeft}>
            <Text>{stud.discount ? (stud.discount / 100) * amount : 0}</Text>
          </View>
        ),
        total: (
          <View style={styles.tableLeft}>
            <Text>
              {stud.discount ? amount - (stud.discount / 100) * amount : amount}
            </Text>
          </View>
        ),
        paid: (
          <View style={styles.tableLeft2}>
            <Text>{stud.paid_amount ? stud.paid_amount : stud.amount}</Text>
          </View>
        ),
        balance: (
          <View style={styles.tableLeft2}>
            <Text>{stud.balance}</Text>
          </View>
        ),
      })
    })

    return dat
  }

  const MyTable = () => {
    var dt = getCAData()

    return (
      <Table style={styles.table} data={[...dt]}>
        <TableHeader>
          <TableCell weighting={0.05}>
            <View style={styles.tableLeft}>
              <Text>SN</Text>
            </View>
          </TableCell>

          <TableCell weighting={0.5}>
            <View style={styles.tableLeft}>
              <Text>NAME</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.15}>
            <View style={styles.tableLeft}>
              <Text>FEE</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.15}>
            <View style={styles.tableLeft}>
              <Text>DISCOUNT</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.15}>
            <View style={styles.tableLeft}>
              <Text>TOTAL</Text>
            </View>
          </TableCell>

          {/* <TableCell weighting={0.15}>
            <View style={styles.tableLeft}>
              <Text>PAID</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.15}>
            <View style={styles.tableLeft}>
              <Text>BALANCE</Text>
            </View>
          </TableCell> */}
        </TableHeader>
        <TableBody>
          <DataTableCell weighting={0.05} getContent={(r) => r.index} />
          <DataTableCell weighting={0.5} getContent={(r) => r.name} />
          <DataTableCell weighting={0.15} getContent={(r) => r.fee} />
          <DataTableCell weighting={0.15} getContent={(r) => r.discount} />
          <DataTableCell weighting={0.15} getContent={(r) => r.total} />
          {/* <DataTableCell weighting={0.15} getContent={(r) => r.paid} />
          <DataTableCell weighting={0.15} getContent={(r) => r.balance} /> */}
        </TableBody>
      </Table>
    )
  }

  const MyDocument = () => {
    var logo = reactLocalStorage.get('heading')
    const sch = reactLocalStorage.get('school-name')
    if (sch === 'IMAMUL BUKHARI ACADEMY, GOMBE') {
      switch (classValue) {
        case 'IBN MAJAH':
        case "NASA'I":
        case 'TIRMIZI':
        case 'ABU DAWOOD':
        case 'AHMAD BN HAMBAL':
        case "IMAMUSH SHAFI'A":
        case 'MALIK BN ANAS':
        case 'ANAS BN MALIK':
        case 'MUSLIM BN HAJJAJ':
        case 'ABDULLAHI BN ABBAS':
        case 'ABDULLAHI BN AMRU BN ASS':
        case 'ABDULLAHI BN MAS’UD':
        case 'SA’AD BN ABI WAQQAS':
        case 'ABDULLAHI BN UMAR':
        case 'ABDULLAHI BN QAISIN':
          {
            console.log('hhh:: ', classValue)
            logo =
              'https://firebasestorage.googleapis.com/v0/b/edusaf-629d9.appspot.com/o/heading%2FJsTijJ2meHpVfoAGeWC7_islamic.jpg?alt=media&token=d3b07cc4-a89c-4a26-888b-bf2e789ac8a5'
          }
          break
      }
    }
    return (
      <Document>
        <Page size="A4" style={styles.page}>
          <Image style={styles.logo} src={logo} />

          <View style={styles.school2}>
            <Text>{termValue} UNPAID STUDENTS</Text>
          </View>

          <View style={styles.subSubjetClass}>
            <Text>Class: {classValue}</Text>
          </View>

          <MyTable />

          <View style={styles.edusaf}>
            <Text>Powered by edutech</Text>
          </View>
        </Page>
      </Document>
    )
  }

  return (
    <div className="">
      <div className="pt-6">
        <div className="">
          <UnPaidTable
            classDocument={classDocument}
            // unPaidCount={unPaidCount}
            // setUnPaidCount={setUnPaidCount}
            sessionValue={sessionValue}
            termValue={termValue}
            amount={amount}
            classValue={classValue}
            allStudent={allStudent}
            //paidStudent={paidStudent}
            unpaid={unpaid}
            setUnPaidClick={setUnPaidClick}
            fees={fees}
            selectedFeesIndex={selectedFeesIndex}
            loading={loading}
            setLoading={setLoading}
          />
        </div>
        <div className="grid py-5 px-4 md:px-0  justify-end">
          {activated && unpaid.length !== 0 ? (
            <button className="px-3 py-1.5  rounded-sm outline-none focus:outline-none text-white  bg-primary">
              <PDFDownloadLink
                document={<MyDocument />}
                fileName={classValue
                  .replace(' ', '_')
                  .concat('_unpaid_students.pdf')}
              >
                {({ blob, url, loading, error }) => 'Download Unpaid'}
              </PDFDownloadLink>
            </button>
          ) : (
            ''
          )}
        </div>
      </div>
    </div>
  )
}

export default StudentsUnPaid
