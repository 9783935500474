const ButtonPrimary = ({ title, loading }) => {
  return (
    <button className="bg-primary text-white flex justify-center py-2 px-5 mt-6 rounded-md focus:outline-none 2xl:py-3 2xl:mt-10">
      {loading ? (
        <i className={'fas fa-circle-notch fa-spin text-base'}></i>
      ) : (
        <div className="flex">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-6 w-6 mr-2 2xl:h-7"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
            />
          </svg>
          <p className="2xl:text-lg">{title}</p>
        </div>
      )}
    </button>
  )
}

export default ButtonPrimary
