import firebase from 'firebase/app'
import { reactLocalStorage } from 'reactjs-localstorage'
import { usePagination } from 'use-pagination-firestore'
import { useEffect, useState } from 'react'
import { NotificationManager } from 'react-notifications'
import Avatar from 'react-avatar'
import DropMenuUser from './DropMenuUser'

const UserTable = ({ users,setUsers, loading, setUserCount }) => {
  const showNotification = (type, title, message) => {
    switch (type) {
      case 'info':
        NotificationManager.info(message)
        break
      case 'success':
        NotificationManager.success(message, title)
        break
      case 'warning':
        NotificationManager.warning(message, title, 3000)
        break
      case 'error':
        NotificationManager.error(message, title, 5000, () => {
          alert('callback')
        })
        break
    }
  }

  if (loading) {
    return (
      <div className="grid justify-center items-center">
        <i className={' text-primary fas fa-circle-notch fa-spin text-5xl'}></i>
      </div>
    )
  }

  return (
    <div key="div1" className="">
      <div
        key="div2"
        className="flex flex-col max-h-96  md:max-h-82 xl_1:max-h-120 2xl:max-h-130 overflow-y-auto   shadow-md border-b border-gray-200 rounded-sm  "
      >
        <div key="div3" className="flex-grow  ">
          <table
            key="tableteacher"
            className="relative w-full min-w-full   divide-y divide-gray-200 "
          >
            <thead key="theadteacher" className="">
              <tr className=" ">
                <th className="sticky bg-gray-50 z-30 md:z-20 left-0  top-0  px-4  py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Name
                </th>
                <th className="sticky bg-gray-50 z-20 top-0 px-4   py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  contact
                </th>
                <th className="sticky bg-gray-50 z-20 top-0 px-4   py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  pages
                </th>
                <th className="sticky bg-gray-50 z-20  top-0 px-4  py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  status
                </th>
                <th className="sticky bg-gray-50 z-20 top-0 px-4 py-3">
                  <span className="sr-only">Edit</span>
                </th>
              </tr>
            </thead>

            <tbody className="bg-white divide-y divide-gray-200 ">
              {users.map((user, index) => (
                <tr key={user.email}>
                  <td
                    key={1}
                    className="sticky left-0  bg-white px-4 py-2 whitespace-nowrap"
                  >
                    <div className="flex items-center">
                      <div className="flex-shrink-0 h-10 w-10">
                        {user.image ? (
                          <img
                            className="h-10 w-10 rounded-full"
                            src={user.image}
                            alt=""
                          />
                        ) : (
                          <Avatar
                            name={user.name}
                            size="32"
                            textSizeRatio={1}
                            round={true}
                          />
                        )}
                      </div>
                      <div className="ml-4">
                        <div className="text-sm font-medium capitalize text-gray-900">
                          {user.name}
                        </div>
                        <div className="text-sm capitalize text-gray-500">
                          {user.gender}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td key={2} className="px-4 py-2 whitespace-nowrap">
                    <div className="text-sm text-gray-900">{user.email}</div>
                    <div className="text-sm text-gray-500">{user.phone}</div>
                  </td>

                  <td key={3} className="px-4 py-2 ">
                    <div className="text-sm uppercase text-gray-900">
                      {/* {person.subject} */}
                      {user.roles.map((role, index) => role + ', ')}
                    </div>
                    {/* <div className="text-sm capitalize text-gray-500">
                      {user.roles.map((role, index) => role.classvalue + ', ')}
                    </div> */}
                  </td>
                  <td key={4} className="px-4 py-2 whitespace-nowrap">
                    {user.active ? (
                      <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                        Active
                      </span>
                    ) : (
                      <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">
                        Disable
                      </span>
                    )}
                  </td>

                  <td
                    key={5}
                    className="px-2  whitespace-nowrap text-right text-sm font-medium z-30"
                  >
                    <DropMenuUser
                      user={user}
                      users={users}
                      setUsers={setUsers}
                      setUserCount={setUserCount}
                      index={index}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}
export default UserTable
