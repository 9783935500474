import TeacherTable from './TeacherTable'
import AddTeacher from './AddTeacher'
import { useState, useEffect } from 'react'
import firebase from 'firebase/app'
import { reactLocalStorage } from 'reactjs-localstorage'

import {
  PDFDownloadLink,
  PDFViewer,
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
} from '@react-pdf/renderer'
import {
  Table,
  TableHeader,
  TableBody,
  DataTableCell,
  TableCell,
} from '@david.kucsai/react-pdf-table'

const Teachers = ({ teacherCount, setTeacherCount }) => {
  const [document, setDocument] = useState([])
  const [classDocument, setClassDocument] = useState([])
  const [loading, setLoading] = useState(false)
  const [teachers, setTeachers] = useState([])
  const [filter, setFilter] = useState([])
  const [notEmptyFilter, setNotEmptyFilter] = useState(false)
  const [activated, setActivated] = useState(reactLocalStorage.get('activated'))

  useEffect(() => {
    getClasses()
    getSubjects()
    getTeachers()
  }, [])

  const getSubjects = () => {
    setLoading(true)
    var db = firebase.firestore()
    var query = db.collection('school-subjects')
    query
      .doc(reactLocalStorage.get('current-school'))
      .get()
      .then((doc) => {
        if (doc.exists) {
          setDocument(doc.data().subject)
          setLoading(false)
        }
      })
      .catch((error) => {
        console.log('Error getting document:', error)
      })
  }

  const getClasses = () => {
    setLoading(true)
    var db = firebase.firestore()
    var query = db.collection('school-classes')
    query
      .doc(reactLocalStorage.get('current-school'))
      .get()
      .then((doc) => {
        if (doc.exists) {
          setClassDocument(doc.data().classes)
          setLoading(false)
        }
      })
      .catch((error) => {
        console.log('Error getting document:', error)
      })
  }

  const getTeachers = () => {
    var db = firebase.firestore()
    var record = []
    db.collection('teachers')
      .where('school', '==', reactLocalStorage.get('current-school'))
      .orderBy('name')
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          var t = doc.data()
          t.doc_id = doc.id
          if (t.form_master === 'select class') {
            t.form_master = ''
          }
          if (t.form_master2 === 'select class') {
            t.form_master2 = ''
          }
          record.push(t)
        })
        setTeachers(record)
        setTeacherCount(record.length)
      })
      .catch((error) => {
        console.log('Error getting documents: ', error)
      })
  }

  function filterTeacher(value) {
    const filtered = teachers.filter((teacher) => {
      if (value !== '') {
        setNotEmptyFilter(true)
        return teacher.name.toLowerCase().includes(value.toLowerCase())
      } else {
        setNotEmptyFilter(false)
        return false
      }
    })
    setFilter(filtered)
  }

  const styles = StyleSheet.create({
    page: {
      flexDirection: 'column',
      paddingTop: 30,
      // paddingLeft: 50,
      // paddingRight: 50,
      paddingHorizontal: 30,
      fontFamily: 'Helvetica',
    },

    section: {
      // marginVertical: 5,
      color: '#fff',
    },
    section2: {
      marginHorizontal: 20,
      color: '#fff',
    },
    logo: {
      // width: 100,
      // height: 100,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    logo2: {
      marginLeft: 'auto',
      marginRight: 'auto',
      height: '90px',
    },

    school: {
      // marginVertical: 10,
      color: '#07173B',
      // letterSpacing: 1,
      fontSize: 40,
      fontWeight: 'heavy',
      textAlign: 'center',
      textTransform: 'uppercase',
      fontFamily: 'Times-Bold',
    },
    school2: {
      // marginVertical: 10,
      color: '#07173B',
      // letterSpacing: 1,
      fontSize: 20,
      fontWeight: 'heavy',
      textAlign: 'center',
      textTransform: 'uppercase',
      fontFamily: 'Times-Bold',
    },

    title: {
      marginTop: 5,
      color: '#3E8EDE',
      // letterSpacing: 1,
      fontSize: 15,
      textAlign: 'center',
      textTransform: 'uppercase',
      fontFamily: 'Helvetica',
    },
    subTitle: {
      // marginVertical: 5,
      marginTop: 15,
      color: '#3E8EDE',
      // letterSpacing: 1,
      fontSize: 12,
      // textAlign: 'center',
      textTransform: 'uppercase',
      fontFamily: 'Helvetica',
    },
    subSubjetClass: {
      marginVertical: 5,
      // color: '#3E8EDE',
      // letterSpacing: 1,
      fontSize: 12,
      // textAlign: 'center',
      textTransform: 'uppercase',
      fontFamily: 'Helvetica',
    },
    remarkText: {
      // marginVertical: 5,
      marginTop: 20,
      // letterSpacing: 1,
      fontSize: 12,
      // textDecoration: 'underline',
      // textAlign: 'center',
      // textTransform: 'uppercase',
      fontFamily: 'Helvetica',
    },
    edusaf: {
      fontSize: 8,
      fontFamily: 'Helvetica',
      position: 'absolute',
      bottom: 5,
      right: 5,
    },

    table: {
      fontSize: 12,
      textAlign: 'center',
      fontFamily: 'Helvetica',
    },
    tableBehaviour: {
      fontSize: 12,
      textAlign: 'center',
      fontFamily: 'Helvetica',
      width: 250,
      flex: 1,
      // flexDirection: 'row',
    },
    tableRatingParent: {
      fontSize: 12,
      textAlign: 'center',
      fontFamily: 'Helvetica',
      width: 200,
      flex: 1,
      // flexDirection: 'row',
    },
    tableBehaviourParent: {
      fontSize: 12,
      textAlign: 'center',
      fontFamily: 'Helvetica',
      flexDirection: 'row',
      display: 'flex',
    },

    tableRating: {
      fontSize: 12,
      textAlign: 'left',
      paddingLeft: 5,
      fontFamily: 'Helvetica',
    },
    tableLeft: {
      fontSize: 10,
      textAlign: 'left',
      fontFamily: 'Helvetica',
      paddingLeft: 5,
      // backgroundColor: '#D3ECE6',
    },
    student: {
      fontSize: 12,
      textAlign: 'left',
      fontFamily: 'Helvetica',
      paddingLeft: 5,
      paddingVertical: 2,
    },
    studentTable: {
      // marginVertical: 10,
    },
    total: {
      fontSize: 12,
      textAlign: 'center',
      fontFamily: 'Helvetica',
      // backgroundColor: '#D3ECE6',
    },
    tablesubject: {
      fontSize: 12,
      textAlign: 'left',
      paddingLeft: 5,
      // backgroundColor: '#D3ECE6',
      fontFamily: 'Helvetica',
    },
    tableNamesubject: {
      fontSize: 12,
      textAlign: 'left',
      paddingLeft: 5,
      // backgroundColor: '#D3ECE6',
      fontFamily: 'Helvetica',
      paddingVertical: 2,
    },
    studentDetails: {
      fontSize: 12,
      textAlign: 'left',
      fontFamily: 'Helvetica',
      paddingLeft: 5,
      paddingVertical: 2,
    },
  })

  function getCAData() {
    // setLoading(false)
    var dat = []
    teachers.map((stud, index) => {
      dat.push({
        index: (
          <View style={styles.tableLeft}>
            <Text>{index + 1}</Text>
          </View>
        ),
        name: (
          <View style={styles.tableLeft}>
            <Text>{stud.name}</Text>
          </View>
        ),
        phone: (
          <View style={styles.tableLeft}>
            <Text>{stud.phone}</Text>
          </View>
        ),
        email: (
          <View style={styles.tableLeft}>
            <Text>{stud.email}</Text>
          </View>
        ),
        gender: (
          <View style={styles.tableLeft}>
            <Text>
              {stud.gender.charAt(0).toUpperCase() + stud.gender.slice(1)}
            </Text>
          </View>
        ),
        qualification: (
          <View style={styles.tableLeft}>
            <Text>
              <Text>{stud.qualification}</Text>
            </Text>
          </View>
        ),
        specialization: (
          <View style={styles.tableLeft}>
            <Text>
              <Text>{stud.specialization}</Text>
            </Text>
          </View>
        ),
        role: (
          <View style={styles.tableLeft}>
            <Text>
              {stud.role === 'form-master'
                ? 'Form: ' + stud.form_master + ', ' + stud.form_master2
                : stud.role.charAt(0).toUpperCase() + stud.role.slice(1)}
            </Text>
          </View>
        ),
        form_master: (
          <View style={styles.tableLeft}>
            <Text>{stud.form_master}</Text>
          </View>
        ),
      })
    })

    return dat
  }

  const MyTable = () => {
    var dt = getCAData()

    return (
      <Table style={styles.table} data={[...dt]}>
        <TableHeader>
          <TableCell weighting={0.04}>
            <View style={styles.tableLeft}>
              <Text>SN</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.22}>
            <View style={styles.tableLeft}>
              <Text>NAME</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.12}>
            <View style={styles.tableLeft}>
              <Text>QUALIFICATION</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.12}>
            <View style={styles.tableLeft}>
              <Text>SPECIALIZATION</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.1}>
            <View style={styles.tableLeft}>
              <Text>PHONE</Text>
            </View>
          </TableCell>

          <TableCell weighting={0.2}>
            <View style={styles.tableLeft}>
              <Text>EMAIL</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.07}>
            <View style={styles.tableLeft}>
              <Text>GENDER</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.13}>
            <View style={styles.tableLeft}>
              <Text>ROLE</Text>
            </View>
          </TableCell>
        </TableHeader>
        <TableBody>
          <DataTableCell weighting={0.04} getContent={(r) => r.index} />
          <DataTableCell weighting={0.22} getContent={(r) => r.name} />
          <DataTableCell weighting={0.12} getContent={(r) => r.qualification} />
          <DataTableCell
            weighting={0.12}
            getContent={(r) => r.specialization}
          />
          <DataTableCell weighting={0.1} getContent={(r) => r.phone} />
          <DataTableCell weighting={0.2} getContent={(r) => r.email} />
          <DataTableCell weighting={0.07} getContent={(r) => r.gender} />
          <DataTableCell weighting={0.13} getContent={(r) => r.role} />
        </TableBody>
      </Table>
    )
  }

  const MyDocument = () => {
      const currentSchool = reactLocalStorage.get('current-school')
    const albayan = 'ATi6GodoPjPJkLfzU5OQ'
    const logo = reactLocalStorage.get('heading')
    return (
      <Document>
        <Page size="A4" orientation="landscape" style={styles.page}>
          <Image
            style={currentSchool !== albayan ? styles.logo : styles.logo2}
            src={logo}
          />
          {/* <View style={styles.school}>
            <Text>MY SCHOOL NAME</Text>
          </View> */}

          <View style={styles.school2}>
            <Text>TEACHERS</Text>
          </View>

          <MyTable />

          <View style={styles.edusaf}>
            <Text>Powered by edutech</Text>
          </View>
        </Page>
      </Document>
    )
  }

  if (loading) {
    return (
      <div className="grid justify-center items-center">
        <i className={' text-primary fas fa-circle-notch fa-spin text-5xl'}></i>
      </div>
    )
  }

  return (
    <div className="">
      <div className="grid grid-flow-col justify-between items-center">
        <div className="w-40 md:w-56 border bg-white text-primary py-2 mx-4 md:mx-0 px-2 md:px-4 flex text-center items-center rounded-sm">
          <svg
            className="text-primary"
            width="16"
            height="14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.917 8.444h-.659l-.233-.202c.817-.852 1.308-1.959 1.308-3.163 0-2.685-2.425-4.861-5.416-4.861C2.925.218.5 2.394.5 5.079.5 7.764 2.925 9.94 5.917 9.94a5.767 5.767 0 003.525-1.174l.225.21v.59l4.166 3.732 1.242-1.114-4.158-3.74zm-5 0c-2.075 0-3.75-1.503-3.75-3.365 0-1.862 1.675-3.365 3.75-3.365s3.75 1.503 3.75 3.365c0 1.862-1.675 3.365-3.75 3.365z"
              fill="#B7B9BE"
            />
          </svg>
          <input
            className="bg-white text-primary outline-none text-sm pl-2 w-32 md:w-52"
            type="text"
            placeholder="Search"
            onChange={(e) => filterTeacher(e.target.value)}
          />
        </div>

        <div className="mx-4 md:mx-0 text-primary bg-white text-sm">
          <AddTeacher
            document={document}
            classDocument={classDocument}
            teacherCount={teacherCount}
            setTeacherCount={setTeacherCount}
            teachers={notEmptyFilter ? filter : teachers}
            setTeachers={notEmptyFilter ? setFilter : setTeachers}
          />
        </div>
      </div>

      <div className="pt-6">
        <div className="">
          <TeacherTable
            teachers={notEmptyFilter ? filter : teachers}
            setTeachers={notEmptyFilter ? setFilter : setTeachers}
            teacherCount={teacherCount}
            setTeacherCount={setTeacherCount}
            document={document}
            classDocument={classDocument}
            // filter={filter}
            // setFilter={setFilter}
            // notEmptyFilter={notEmptyFilter}
            // setNotEmptyFilter={setNotEmptyFilter}
            loading={loading}
          />
        </div>
        <div className="grid py-5 px-4 md:px-0  justify-end">
          {activated === 'true' && teachers.length !== 0 ? (
            <button className="px-3 py-1.5  rounded-sm outline-none focus:outline-none text-white  bg-primary">
              <PDFDownloadLink
                document={<MyDocument />}
                fileName="teachers.pdf"
              >
                {({ blob, url, loading, error }) => 'Download Teachers'}
              </PDFDownloadLink>
            </button>
          ) : (
            ''
          )}
        </div>
      </div>
    </div>
  )
}

export default Teachers
