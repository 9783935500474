import firebase from 'firebase/app'
import { reactLocalStorage } from 'reactjs-localstorage'
import { useState, useEffect } from 'react'
import Subject from './Subject'
import Classes from './Classes'
import School from './School'
import Fees from './fees'
import Term from './term'
import Promotion from './promotion'
import IDGeneration from './IDGeneration'

const ManageSchool = ({
  school,
  subject,
  classvalue,
  payment,
  term,
  setSubTitle,
}) => {
  const [schoolClick, setSchoolClick] = useState(school)
  const [subjectClick, setSubjectClick] = useState(subject)
  const [classClick, setClassClick] = useState(classvalue)
  const [paymentClick, setPaymentClick] = useState(payment)
  const [termClick, setTermClick] = useState(term)
  const [promotionClick, setPromotionClick] = useState()
  const [idFormat, setIdFormat] = useState()
  const [activated, setActivated] = useState()

  const tabDefaultStyle =
    'text-white text-xs md:text-sm  md:text-gray-600 uppercase py-4 px-4 md:px-6 block hover:text-blue-500 focus:outline-none'
  const tabClickStyle =
    'uppercase text-sm md:text-sm  py-4 px-4 md:px-6 block md:hover:text-blue-500 focus:outline-none text-white md:text-blue-500 border-b-2 font-medium md:border-blue-500'

  const [document, setDocument] = useState([])
  const [classDocument, setClassDocument] = useState([])
  const [loading, setLoading] = useState(false)
  const [emptyRecord, setEmptyRecord] = useState()
  const [classEmptyRecord, setClassEmptyRecord] = useState()

  const handleTabClick = (tab) => {
    switch (tab) {
      // case 'school': {
      //   setSchoolClick(true)
      //   setSubjectClick(false)
      //   setClassClick(false)
      //   setPaymentClick(false)
      //   setTermClick(false)
      //   setSubTitle('School')
      //   break
      // }
      case 'subject': {
        setSubjectClick(true)
        setSchoolClick(false)
        setClassClick(false)
        setPaymentClick(false)
        setTermClick(false)
        setPromotionClick(false)
        setIdFormat(false)
        setSubTitle('Subjects')
        break
      }
      case 'class': {
        setClassClick(true)
        setSchoolClick(false)
        setSubjectClick(false)
        setPaymentClick(false)
        setTermClick(false)
        setPromotionClick(false)
        setSubTitle('Classess')
        setIdFormat(false)
        break
      }
      case 'payment': {
        setPaymentClick(true)
        setSubjectClick(false)
        setSchoolClick(false)
        setClassClick(false)
        setTermClick(false)
        setPromotionClick(false)
        setSubTitle('Fees')
        setIdFormat(false)
        break
      }
      case 'promotion': {
        setPromotionClick(true)
        setPaymentClick(false)
        setSubjectClick(false)
        setSchoolClick(false)
        setClassClick(false)
        setTermClick(false)
        setIdFormat(false)
        setSubTitle('Class Promotion')
        break
      }
      case 'id': {
        setIdFormat(true)
        setPromotionClick(false)
        setPaymentClick(false)
        setSubjectClick(false)
        setSchoolClick(false)
        setClassClick(false)
        setTermClick(false)
        setSubTitle('Admission number format')
        break
      }
      default: {
        setTermClick(true)
        setClassClick(false)
        setSchoolClick(false)
        setSubjectClick(false)
        setPaymentClick(false)
        setPromotionClick(false)
        setIdFormat(false)
        setSubTitle('Term')
      }
    }
  }

  useEffect(() => {
    getSchoolSubjects()
    getSchoolsClasses()

    //SET DEFAULT CLICK
    setTermClick(true)
    setClassClick(false)
    setSchoolClick(false)
    setSubjectClick(false)
    setPaymentClick(false)
    setPromotionClick(false)
    setIdFormat(false)
    setSubTitle('Term')

    getStudents()
  }, [])

  const getSchoolSubjects = () => {
    setLoading(true)
    var db = firebase.firestore()
    var query = db.collection('school-subjects')
    query
      .doc(reactLocalStorage.get('current-school'))
      .get()
      .then((doc) => {
        if (doc.exists) {
          setDocument(doc.data().subject)
          // setLoading(false)
        } else {
          // doc.data() will be undefined in this case
          getSubjects()
          setEmptyRecord(true)
        }
      })
      .catch((error) => {
        console.log('Error getting document:', error)
      })
  }

  const getSubjects = () => {
    var db = firebase.firestore()
    var query = db.collection('subjects').orderBy('name')
    var data = []
    query.get().then(function (querySnapshot) {
      querySnapshot.forEach(function (doc) {
        data.push({ name: doc.data().name, id: doc.id, click: false })
      })
      setDocument(data)
      // setLoading(false)
    })
  }

  const getSchoolsClasses = () => {
   // setLoading(true)
    var db = firebase.firestore()
    var query = db.collection('school-classes')
    query
      .doc(reactLocalStorage.get('current-school'))
      .get()
      .then((doc) => {
        if (doc.exists) {
          setClassDocument(doc.data().classes)
          //setLoading(false)
        } else {
          // doc.data() will be undefined in this case
          getClasses()
          setClassEmptyRecord(true)
        }
      })
      .catch((error) => {
        console.log('Error getting document:', error)
      })
  }

  const getClasses = () => {
    var db = firebase.firestore()
    var query = db.collection('classes').orderBy('position')
    var data = []
    query.get().then(function (querySnapshot) {
      querySnapshot.forEach(function (doc) {
        data.push({
          name: doc.data().name,
          id: doc.id,
          // section: doc.data().section,
          click: false,
        })
      })
      setClassDocument(data)
     // setLoading(false)
    })
  }

  const getStudents = () => {
  //  setLoading(true)
    var db = firebase.firestore()
    var record = []
    db.collection('students')
      .where('school', '==', reactLocalStorage.get('current-school'))
      .get()
      .then((querySnapshot) => {
        getSubscription(querySnapshot.size)
      })
      .catch((error) => {
        setLoading(false)
        console.log('Error getting documents: ', error)
      })
  }

  const getSubscription = (totalStudent) => {
    var db = firebase.firestore()
    var path = reactLocalStorage
      .get('current-school')
      .concat('_')
      .concat(reactLocalStorage.get('session').replace('/', '_'))
      .concat('_')
      .concat(reactLocalStorage.get('term').replace(' ', '_'))

    var query = db.collection('subscription')
    query
      .doc(path)
      .get()
      .then((doc) => {
        if (doc.exists) {
          var plan = doc.data().plan
          var slotPaid = doc.data().slot
          if (plan === 'Trial') {
            setActivated(true)
          } else if (plan === 'Paid' && slotPaid <= totalStudent) {
            setActivated(true)
          } else {
            setActivated(false)
          }
        }
        setLoading(false)
      })
      .catch((error) => {
        console.log('Error getting document:', error)
        setLoading(false)
      })
  }

  if (loading) {
      return (
          <div className="grid min-h-screen justify-center items-center">
              <i className={' text-primary fas fa-circle-notch fa-spin text-5xl'}></i>
           </div>
      )
  }

  // if (!loading && !activated) {
  //   return (
  //     <div className="grid min-h-screen justify-center items-center">
  //       <i className={' text-primary  text-5xl'}>
  //         Pay your subscription to access to page
  //       </i>
  //     </div>
  //   )
  // }

  return (
    <div className="">
      <div className="sticky top-0 z-30 mx-0 md:mx-12 md:my-3 select-none bg-primary md:bg-white  ">
        <p className="md:hidden py-3 md:py-0 ml-4 md:ml-0 text-white md:text-gray-800 font-medium text-lg">
          Manage School
        </p>
      </div>
      <div className="">
        <div className="m:0 md:mx-12 md:my-5 ">
          <div class="sticky top-0 z-30 bg-primary md:bg-gray-50">
            <nav class="flex flex-row">
              {/* <button
                onClick={() => {
                  handleTabClick('school')
                }}
                className={schoolClick ? tabClickStyle : tabDefaultStyle}
              >
                School
              </button> */}

              <button
                onClick={() => {
                  handleTabClick('term')
                }}
                className={termClick ? tabClickStyle : tabDefaultStyle}
              >
                term
              </button>

              <button
                onClick={() => {
                  handleTabClick('subject')
                }}
                className={subjectClick ? tabClickStyle : tabDefaultStyle}
              >
                Subjects
              </button>

              <button
                onClick={() => {
                  handleTabClick('class')
                }}
                className={classClick ? tabClickStyle : tabDefaultStyle}
              >
                Classes
              </button>

              <button
                onClick={() => {
                  handleTabClick('id')
                }}
                className={idFormat ? tabClickStyle : tabDefaultStyle}
              >
                ID format
              </button>

              <button
                onClick={() => {
                  handleTabClick('payment')
                }}
                className={paymentClick ? tabClickStyle : tabDefaultStyle}
              >
                fees
              </button>

              <button
                onClick={() => {
                  handleTabClick('promotion')
                }}
                className={promotionClick ? tabClickStyle : tabDefaultStyle}
              >
                promotion
              </button>
            </nav>
          </div>

          <div className="my-5">
            {subjectClick ? (
              <Subject
                loading={loading}
                document={document}
                setDocument={setDocument}
                emptyRecord={emptyRecord}
              />
            ) : classClick ? (
              <Classes
                loading={loading}
                classDocument={classDocument}
                setClassDocument={setClassDocument}
                classEmptyRecord={classEmptyRecord}
              />
            ) : paymentClick ? (
              <Fees
                loading={loading}
                classDocument={classDocument}
                setClassDocument={setClassDocument}
                classEmptyRecord={classEmptyRecord}
              />
            ) : promotionClick ? (
              <Promotion
                loading={loading}
                classDocument={classDocument}
                setClassDocument={setClassDocument}
                classEmptyRecord={classEmptyRecord}
              />
            ) : idFormat ? (
              <IDGeneration
                loading={loading}
                classDocument={classDocument}
                setClassDocument={setClassDocument}
                classEmptyRecord={classEmptyRecord}
              />
            ) : (
              <Term
                loading={loading}
                classDocument={classDocument}
                setClassDocument={setClassDocument}
                classEmptyRecord={classEmptyRecord}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ManageSchool
