import React, { Fragment } from 'react'
import { Text, View, StyleSheet } from '@react-pdf/renderer'

// const styles = StyleSheet.create({
//   headerContainer: {
//     marginTop: 36,
//     justifyContent: 'flex-start',
//     width: '50%',
//   },
//   billTo: {
//     marginTop: 20,
//     paddingBottom: 3,
//     fontFamily: 'Helvetica-Oblique',
//   },
// })

// const BillTo = ({ invoice }) => (
//   <View style={styles.headerContainer}>
//     <Text style={styles.billTo}>Bill To:</Text>
//     <Text>{invoice.fullname}</Text>
//     <Text>{invoice.fullname}</Text>
//     <Text>{invoice.address}</Text>
//     <Text>{invoice.phone}</Text>
//     <Text>{invoice.email}</Text>
//   </View>

// )

const styles = StyleSheet.create({
  invoiceNoContainer: {
    flexDirection: 'row',
    // marginTop: 36,
    justifyContent: 'flex-start',
  },
  invoiceDateContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  invoiceDate: {
    fontSize: 12,
    fontStyle: 'bold',
  },
  label: {
    width: 60,
  },
})

const BillTo = ({ invoice }) => (
  <Fragment>
    <View style={styles.invoiceNoContainer}>
      <Text style={styles.label}>ID:</Text>
      <Text style={styles.invoiceDate}>{invoice.id}</Text>
    </View>
    <View style={styles.invoiceNoContainer}>
      <Text style={styles.label}>Name:</Text>
      <Text style={styles.invoiceDate}>{invoice.fullname}</Text>
    </View>
    <View style={styles.invoiceNoContainer}>
      <Text style={styles.label}>Class:</Text>
      <Text style={styles.invoiceDate}>{invoice.class}</Text>
    </View>
    <View style={styles.invoiceNoContainer}>
      <Text style={styles.label}>Session:</Text>
      <Text style={styles.invoiceDate}>{invoice.session}</Text>
    </View>
    <View style={styles.invoiceDateContainer}>
      <Text style={styles.label}>Term: </Text>
      <Text>{invoice.term}</Text>
    </View>
  </Fragment>
)

export default BillTo
