import { useState } from 'react'
import StudentsPaid from './StudentsPaid'
import StudentsUnPaid from './StudentsUnPaid'
import firebase from 'firebase/app'
import { reactLocalStorage } from 'reactjs-localstorage'
import { useEffect } from 'react'

const ManagePayment = ({ paid, unpaid, setSubTitle, setClickPeople }) => {
  const [paidClick, setPaidClick] = useState(paid)
  const [unPaidClick, setUnPaidClick] = useState(unpaid)
  const [paidCount, setPaidCount] = useState()
  const [unPaidCount, setUnPaidCount] = useState()
  const [classValue, setClassValue] = useState('select')
  const [sessionValue, setSessionValue] = useState(
    reactLocalStorage.get('session')
  )
  const [termValue, setTermValue] = useState(reactLocalStorage.get('term'))
  const [classDocument, setClassDocument] = useState([])
  const [allStudent, setAllStudent] = useState([])
  const [paidStudent, setPaidStudent] = useState([])
  const [unPaidStudent, setUnPaidStudent] = useState([])
  const [loading, setLoading] = useState(false)
  const [amount, setAmount] = useState()
  const [fees, setFees] = useState()
  const [selectedFeesIndex, setSelectedFeesIndex] = useState()

  const tabDefaultStyle =
    'text-white text-xs md:text-sm  md:text-gray-600 uppercase py-4 px-4 md:px-6 block hover:text-blue-500 focus:outline-none'
  const tabClickStyle =
    'uppercase text-sm md:text-sm  py-4 px-4 md:px-6 block md:hover:text-blue-500 focus:outline-none text-white md:text-blue-500 border-b-2 font-medium md:border-blue-500'

  const handleTabClick = (tab) => {
    switch (tab) {
      case 'paid': {
        setPaidClick(true)
        setUnPaidClick(false)
        //setPaidCount(paidCount)
        break
      }

      default: {
        setUnPaidClick(true)
        setPaidClick(false)
        //setUnPaidCount(unPaidCount)
      }
    }
  }

  useEffect(() => {
    getTerm()
    setPaidClick(true)
    setUnPaidClick(false)
  }, [])

  const activated = reactLocalStorage.get('activated')

  const getClasses = () => {
    setLoading(true)
    var db = firebase.firestore()
    var items = []
    var query = db
      .collection('school-classes')
      .doc(reactLocalStorage.get('current-school'))
    query
      .get()
      .then((doc) => {
        if (doc.exists) {
          doc.data().classes.forEach((dot) => {
            if (dot.click) {
              items.push({
                name: dot.name,
                id: dot.id,
                section: dot.section,
                click: true,
              })
            }
          })

          setClassDocument(items)
        } else {
          // doc.data() will be undefined in this case
          // setLoading(false)
        }
        setLoading(false)
      })
      .catch((error) => {
        setLoading(false)
        console.log('Error getting document:', error)
      })
  }

  const getStudents = (e) => {
    setLoading(true)
    var db = firebase.firestore()
    db.collection('students')
      .where(
        'school_class',
        '==',
        reactLocalStorage.get('current-school').concat(e.target.value)
      )
      .orderBy('name')
      .get()
      .then((querySnapshot) => {
        var values = []
        querySnapshot.forEach((doc) => {
          //doc.data() is never undefined for query doc snapshots

          var s = doc.data()
          s.doc_id = doc.id
          values.push(s)
        })
        setAllStudent(values)
        setLoading(false)
        getFees(values.length, e.target.value)
      })
      .catch((error) => {
        console.log('Error getting documents: ', error)
        setLoading(false)
      })
  }
  async function getTerm() {
    setLoading(true)
    var db = firebase.firestore()
    var query = db.collection('term')
    query
      .doc(reactLocalStorage.get('current-school'))
      .get()
      .then((doc) => {
        if (doc.exists) {
          reactLocalStorage.set('session', doc.data().session)
          reactLocalStorage.set('term', doc.data().term)
          reactLocalStorage.set('start', doc.data().start)
          reactLocalStorage.set('end', doc.data().end)
          reactLocalStorage.set('next', doc.data().next)
          reactLocalStorage.set('position', doc.data().position)
          setTermValue(doc.data().term)
        } else {
          //  NotificationManager.info('Session and term not set')
        }
        setLoading(false)
        getClasses()
      })

      .catch((error) => {
        console.log('Error getting document:', error)
      })
    setLoading(false)
  }

  const getFees = (studentSize, classValue) => {
    setLoading(true)
    var db = firebase.firestore()
    var query = db.collection('fees')
    query
      .doc(
        reactLocalStorage
          .get('current-school')
          .concat('_')
          .concat(reactLocalStorage.get('session').replace('/', '_'))
          .concat('_')
          .concat(reactLocalStorage.get('term').replace(' ', '_'))
      )
      .get()
      .then((doc) => {
        if (doc.exists) {
          // setClassDocument(doc.data().classes)
          setFees(doc.data())
          doc.data().classes.map((item, index) => {
            if (item.name == classValue) {
              setPaidStudent(item.paid)
              setAmount(item.amount)
              setSelectedFeesIndex(index)
              setPaidCount(item.paid.length)
              setUnPaidCount(studentSize - item.paid.length)
            }
          })
          //setPaidCount(paidCount)
          //setUnPaidCount(unPaidCount)
          setLoading(false)
        } else {
          setPaidStudent([])
          //setUnPaidStudent([])
          setPaidCount(0)
          setUnPaidCount(studentSize)
          // NotificationManager.info('No record found')
          // doc.data() will be undefined in this case
          //  getClasses()
          //  setClassEmptyRecord(true)
          setLoading(false)
        }
      })
      .catch((error) => {
        setLoading(false)
        console.log('Error getting document:', error)
      })
  }

  function getDifference(array1, array2) {
    return array1.filter((object1) => {
      return !array2.some((object2) => {
        return object1.doc_id === object2.doc_id
      })
    })
  }

  const handleClassSelection = (e) => {
    if (e.target.value !== 'select') {
      setClassValue(e.target.value)
      getStudents(e)
    }
  }

  return (
    <div className="">
      <div className="mx-0 md:mx-12 md:my-3 bg-primary md:bg-white  ">
        <p className="md:hidden py-3 md:py-0 ml-4 md:ml-0 text-white md:text-gray-800 font-medium text-lg">
          People
        </p>
      </div>

      <div className="">
        <div className="m:0 md:mx-12 md:my-5  ">
          <div className="grid grid-flow-col justify-between items-center mb-5 ">
            <div className="text-primary  flex text-center items-center rounded-sm space-x-4">
              <select
                className="rounded-sm p-2 w-40 md:w-56 focus:outline-none focus:ring-1 bg-gray-50 w-full"
                placeholder="class"
                required
                name="classValue"
                value={classValue}
                onChange={(e) => handleClassSelection(e)}
              >
                <option value="select" selected>
                  Class
                </option>
                {classDocument.map((classes, index) =>
                  classes.click ? (
                    <option
                      className="uppercase"
                      key={classes.id}
                      value={classes.name}
                    >
                      {classes.name}
                    </option>
                  ) : (
                    ''
                  )
                )}
              </select>
            </div>
          </div>

          <div class="bg-primary md:bg-gray-50">
            <nav class="flex flex-row">
              <button
                onClick={() => {
                  handleTabClick('paid')
                  // setClickPeople(true);
                  // setSubTitle("Teachers");
                }}
                className={paidClick ? tabClickStyle : tabDefaultStyle}
              >
                paid ({paidCount})
              </button>

              <button
                onClick={() => {
                  handleTabClick('unpaid')
                  // setClickPeople(true);
                  // setSubTitle("Students");
                }}
                className={unPaidClick ? tabClickStyle : tabDefaultStyle}
              >
                unpaid ({getDifference(allStudent, paidStudent).length})
              </button>
            </nav>
          </div>

          <div className="my-5 ">
            {paidClick ? (
              <StudentsPaid
                key="paid"
                setPaidCount={setPaidCount}
                paidStudent={paidStudent}
                paidCount={paidCount}
                loading={loading}
                setLoading={setLoading}
                termValue={termValue}
                sessionValue={sessionValue}
                classValue={classValue}
                activated={activated}
                fees={fees}
                setFees={setFees}
                setPaidStudent={setPaidStudent}
                selectedFeesIndex={selectedFeesIndex}
              />
            ) : (
              <StudentsUnPaid
                key="unpaid"
                //setUnPaidCount={setUnPaidCount}
                sessionValue={sessionValue}
                termValue={termValue}
                classValue={classValue}
                amount={amount}
                allStudent={allStudent}
                //paidStudent={paidStudent}
                unpaid={getDifference(allStudent, paidStudent)}
                // unPaidCount={unPaidCount}
                setUnPaidClick={setUnPaidClick}
                fees={fees}
                selectedFeesIndex={selectedFeesIndex}
                loading={loading}
                setLoading={setLoading}
                activated={activated}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ManagePayment
