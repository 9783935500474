import { useState } from "react";
import AddClasses from "./AddClasses";
import firebase from "firebase/app";
import { reactLocalStorage } from "reactjs-localstorage";
import { NotificationManager } from "react-notifications";
import "react-notifications/lib/notifications.css";

const Classes = ({
  classDocument,
  classEmptyRecord,
  loading,
  setClassDocument,
  hideAdd,
}) => {
  const defaultStyle = "px-3 py-1.5 bg-gray-50 select-none cursor-default";
  const clickStyle = "px-3 py-1.5 bg-account_click select-none cursor-default";

  const [load, setLoad] = useState();
  const [error, setError] = useState();
  const handleClick = (subject, id, click, index) => {
    const values = [...classDocument];
    values[index] = {
      name: subject,
      id: id,
      click: !click,
    };

    setClassDocument(values);
  };

  const handleSave = () => {
    setLoad(true);
    setError("");
    var db = firebase.firestore();
    db.collection("school-classes")
      .doc(reactLocalStorage.get("current-school"))
      .set({
        classes: classDocument,
        date: firebase.firestore.Timestamp.now(),
      })
      .then((docRef) => {
        NotificationManager.info("Added Successful");
        setLoad(false);
      })
      .catch((error) => {
        setError(error.message);
        setLoad(false);
      });
  };

  const handleUpdate = () => {
    setLoad(true);
    setError("");
    var db = firebase.firestore();
    db.collection("school-classes")
      .doc(reactLocalStorage.get("current-school"))
      .update({
        classes: classDocument,
        date: firebase.firestore.Timestamp.now(),
      })
      .then((docRef) => {
        NotificationManager.info("Saved Successful");
        setLoad(false);
        // console.log("Document written with ID: ", docRef.id);
      })
      .catch((error) => {
        setError(error.message);
        setLoad(false);
      });
  };

  if (loading) {
    return (
      <div className="grid  justify-center items-center ">
        <i className={" text-primary fas fa-circle-notch fa-spin text-5xl"}></i>
      </div>
    );
  }

  return (
    <div className=" ">
      {!hideAdd ? (
        <div className=" grid grid-flow-col  justify-end items-center mb-5">
          <div className="mx-4 md:mx-0 text-primary bg-white text-sm">
            <AddClasses
              classDocument={classDocument}
              setClassDocument={setClassDocument}
            />
          </div>
        </div>
      ) : (
        ''
      )}

      <div className="grid px-4 md:px-0 gap-2 md:gap-4  grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4  xl_1:grid-cols-5 ">
        {classDocument.map((subject, index) => (
          <div
            key={subject.id}
            className={subject.click ? clickStyle : defaultStyle}
            onClick={() =>
              handleClick(subject.name, subject.id, subject.click, index)
            }
          >
            <div className="">
              <input
                checked={subject.click}
                type="checkbox"
                id={subject.id}
                value={subject.name}
              />{' '}
              <label className="capitalize">{subject.name}</label>
            </div>

            {/* {subject.deletable && (
                <DropMenuClasses
                  name={subject.name}
                  id={subject.id}
                  classDocument={classDocument}
                  setClassDocument={setClassDocument}
                  index={index}
                />
              )} */}
          </div>
        ))}
      </div>

      {!hideAdd ? (
        <div className="grid py-5 px-4 md:px-0  justify-end">
          <button
            onClick={() => {
              classEmptyRecord ? handleSave() : handleUpdate()
            }}
            className="px-5 py-2  rounded-sm outline-none focus:outline-none text-white  bg-primary"
          >
            {load ? (
              <i className={'fas fa-circle-notch fa-spin text-base'}></i>
            ) : (
              <p className="2xl:text-lg">Save</p>
            )}
          </button>
        </div>
      ) : (
        ''
      )}
    </div>
  )
};

export default Classes;
