import './assets/css/styles.css'
import { AuthProvider } from './services/AuthContext'
import SignupAccount from './pages/SignupAccount'
import Signup from './pages/Signup'
import Signin from './pages/Signin'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import PrivateRoute from './components/PrivateRoute'
import ForgotPassword from './pages/ForgotPassword'
import EmailVerification from './pages/EmailVerification'
import EmailConfirmation from './pages/EmailConfirmation'
import UnVerifiedAccount from './pages/UnverifiedAccount'
import SignUpCompletion from './pages/SignUpCompletion'
import SigninManual from './pages/SigninManual'
import Landing from './pages/Landing'
import { NotificationContainer } from 'react-notifications'
import toast, { Toaster } from 'react-hot-toast'
import Dashboard from './pages/Dashboard'
import firebase from 'firebase/app'
import VerifyReport from './components/VerifyReport'
import Privacy from './pages/PrivacyPolicy'

function App() {

  return (
    <div className="">
      <Router>
        <AuthProvider>
          <Switch>
            {/* <Route exact path="/" component={SignupAccount} /> */}
            <Route exact path="/" component={Signin} />
            <Route path="/login" component={Signin} />
            {/* <Route path="/signup" component={Signup} /> */}
            {/* <Route path="/emailsent" component={EmailVerification} /> */}
            {/* <Route path="/reverify" component={UnVerifiedAccount} /> */}
            <Route path="/emailconfirmation" component={EmailConfirmation} />
            <Route path="/forgotpassword" component={ForgotPassword} />
            {/* <Route path="/dashboard" component={Dashboard} /> */}
            <Route path="/manual" component={SigninManual} />
            <Route path="/privacy" component={Privacy} />
            <Route path="/v/:pstu" component={VerifyReport} />
            <Route path="/v" component={VerifyReport} />
            {/* <PrivateRoute
              path="/signupcompletion"
              component={SignUpCompletion}
            /> */}
            <PrivateRoute path="/landing" component={Landing} />
          </Switch>
        </AuthProvider>
      </Router>
      <Toaster />
      <NotificationContainer />
    </div>
  )
}

export default App
