import React from 'react'
import { View, StyleSheet } from '@react-pdf/renderer'
import DetailsTableHeader from './DetailsTableHeader'

const styles = StyleSheet.create({
  tableContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 10,
    // borderWidth: 1,
    // borderColor: '#29166F',
  },
})

const DetailsTable = ({ invoice }) => (
  <View style={styles.tableContainer}>
    <DetailsTableHeader />
  </View>
)

export default DetailsTable
