import Teachers from './Teachers'
import { useState } from 'react'
import Students from './Students'
import firebase from 'firebase/app'
import { reactLocalStorage } from 'reactjs-localstorage'
import { useEffect } from 'react'

const ManagePeople = ({ teacher, student, setSubTitle, setClickPeople }) => {
  const [teacherClick, setTeacherClick] = useState(teacher)
  const [studentClick, setStudentClick] = useState(student)
  const [teacherCount, setTeacherCount] = useState(0)
  const [studentCount, setStudentCount] = useState(0)
  const tabDefaultStyle =
    'text-white text-xs md:text-sm  md:text-gray-600 uppercase py-4 px-4 md:px-6 block hover:text-blue-500 focus:outline-none'
  const tabClickStyle =
    'uppercase text-sm md:text-sm  py-4 px-4 md:px-6 block md:hover:text-blue-500 focus:outline-none text-white md:text-blue-500 border-b-2 font-medium md:border-blue-500'

  const handleTabClick = (tab) => {
    switch (tab) {
      case 'teacher': {
        setTeacherClick(true)
        setStudentClick(false)
        break
      }

      default: {
        setStudentClick(true)
        setTeacherClick(false)
      }
    }
  }

  useEffect(() => {
    // getCount();
    //getCount2();
  }, [])

  // const getCount = () => {
  //   var db = firebase.firestore();
  //   var query = db
  //     .collection("counter")
  //     .where("school", "==", reactLocalStorage.get("current-school"));
  //   query.get().then(function (querySnapshot) {
  //     querySnapshot.forEach(function (doc) {
  //       var data = doc.data();
  //       setTeacherCount(data.teacher);
  //       setStudentCount(data.student);
  //     });
  //   });
  // };

  
  //TEMPORAY
  return (
    <div className="">
      <div className="mx-0 md:mx-12 md:my-3 bg-primary md:bg-white  ">
        <p className="md:hidden py-3 md:py-0 ml-4 md:ml-0 text-white md:text-gray-800 font-medium text-lg">
          People
        </p>
      </div>
      <div className="">
        <div className="m:0 md:mx-12 md:my-5  ">
          <div class="bg-primary md:bg-gray-50">
            <nav class="flex flex-row">
              <button
                onClick={() => {
                  handleTabClick('teacher')
                  // setClickPeople(true);
                  // setSubTitle("Teachers");
                }}
                className={teacherClick ? tabClickStyle : tabDefaultStyle}
              >
                Teachers ({teacherCount})
              </button>

              <button
                onClick={() => {
                  handleTabClick('student')
                  // setClickPeople(true);
                  // setSubTitle("Students");
                }}
                className={studentClick ? tabClickStyle : tabDefaultStyle}
              >
                Students ({studentCount})
              </button>
            </nav>
          </div>

          <div className="my-5 ">
            {teacherClick ? (
              <Teachers
                teacherCount={teacherCount}
                setTeacherCount={setTeacherCount}
              />
            ) : (
              <Students
                studentCount={studentCount}
                setStudentCount={setStudentCount}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ManagePeople
