import { Menu, Dialog, Transition } from '@headlessui/react'
import { Fragment, useState, useRef } from 'react'
import { ExclamationIcon } from '@heroicons/react/outline'
import firebase from 'firebase/app'
import { NotificationManager } from 'react-notifications'
import 'react-notifications/lib/notifications.css'
import CurrencyInput from 'react-currency-input-field'

import { reactLocalStorage } from 'reactjs-localstorage'

export default function DropMenuPaidStudent({
  paidStudent,
  student,
  index,
  fees,
  setFees,
  setPaidStudent,
  selectedFeesIndex,
  paidCount,
  setPaidCount,
}) {
  const [showDeleteDialog, setshowDeleteDialog] = useState(false)
  const [showEditDialog, setshowEditDialog] = useState(false)
  const cancelButtonRef = useRef()
  const [loading, setLoading] = useState(false)

  const [infoClick, setinfoClick] = useState(true)

  const [name, setName] = useState(student.name)
  const [id, setId] = useState(student.studentid)
  const [fee, setFee] = useState(student.fees)
  const [paid, setPaid] = useState(
    student.paid_amount ? student.paid_amount : student.amount
  )
  const [balance, setBalance] = useState(student.balance)

  const tabDefaultStyle =
    'text-primary md:text-white text-xs md:text-sm uppercase py-4 px-4 md:px-6 block focus:outline-none'
  const tabClickStyle =
    'uppercase text-sm md:text-sm  py-4 px-4 md:px-6 block focus:outline-none text-primary md:text-white  border-b-2 font-medium border-primary md:border-white'
  const saveButtonDefault =
    'px-3 py-1.5  rounded-sm outline-none focus:outline-none text-gray-300  bg-gray-100'
  const saveButtonClick =
    'px-3 py-1.5  rounded-sm outline-none focus:outline-none text-white  bg-primary'

  const handleSave = () => {
    if (paid !== '') {
      save()
    } else {
      NotificationManager.info('Enter the require fields')
    }
  }

  const save = () => {
    setLoading(true)
    var feesList = fees
    var paidList = paidStudent
    var selectedStudent = student
    selectedStudent.balance = balance
    student.paid_amount
      ? (selectedStudent.paid_amount = paid)
      : (selectedStudent.amount = paid)

    paidList.map((stu, inde) => {
      if (stu.studentid === selectedStudent.studentid) {
        paidList[inde] = selectedStudent
      }
    })

    feesList.classes[selectedFeesIndex].paid = paidList
    var update = [...paidList]

    saveRecord(feesList, update, 1)
  }
  const deletePayment = () => {
    setLoading(true)
    var feesList = fees
    var paidList = paidStudent
    var selectedStudent = student
    paidList.map((stu, inde) => {
      if (stu.studentid === selectedStudent.studentid) {
        paidList.splice(inde, 1)
      }
    })

    feesList.classes[selectedFeesIndex].paid = paidList
    var update = [...paidList]

    saveRecord(feesList, update, 2)
  }

  const saveRecord = (feesList, update, status) => {
    var db = firebase.firestore()
    var sch = reactLocalStorage.get('current-school')
    var session = reactLocalStorage.get('session').replace('/', '_')
    var term = reactLocalStorage.get('term').replace(' ', '_')
    var doc = sch.concat('_').concat(session).concat('_').concat(term)
    db.collection('fees')
      .doc(doc)
      .update({
        classes: feesList.classes,
        date: firebase.firestore.Timestamp.now(),
      })
      .then((docRef) => {
        setPaidStudent(update)
        setLoading(false)
        if (status !== 2) closeEditModal()
        NotificationManager.info('Updated Successful')
        if (status === 2) {
          closeModal()
          setPaidCount(paidCount - 1)
        }
      })
      .catch((error) => {
        setLoading(false)
        closeEditModal()
        NotificationManager.info(error.message)
      })
  }

  function closeEditModal() {
    setshowEditDialog(false)
  }

  function openEditModal() {
    setinfoClick(true)
    setshowEditDialog(true)
  }

  function closeModal() {
    setshowDeleteDialog(false)
  }

  function showDialogModal() {
    setshowDeleteDialog(true)
  }

  function EditInactiveIcon(props) {
    return (
      <svg
        {...props}
        width="20"
        height="20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2.5 14.375V17.5h3.125l9.217-9.217-3.125-3.125L2.5 14.375zm14.758-8.508a.83.83 0 000-1.175l-1.95-1.95a.83.83 0 00-1.175 0l-1.525 1.525 3.125 3.125 1.525-1.525z"
          fill="#000"
          fill-opacity=".7"
        />
      </svg>
    )
  }

  function EditActiveIcon(props) {
    return (
      <svg
        {...props}
        width="20"
        height="20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2.5 14.375V17.5h3.125l9.217-9.217-3.125-3.125L2.5 14.375zm14.758-8.508a.83.83 0 000-1.175l-1.95-1.95a.83.83 0 00-1.175 0l-1.525 1.525 3.125 3.125 1.525-1.525z"
          fill="#0064AF"
        />
      </svg>
    )
  }

  function ViewInactiveIcon(props) {
    return (
      <svg
        {...props}
        width="20"
        height="20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15.833 15.833H4.167V4.167H10V2.5H4.167c-.925 0-1.667.75-1.667 1.667v11.666c0 .917.742 1.667 1.667 1.667h11.666c.917 0 1.667-.75 1.667-1.667V10h-1.667v5.833zM11.667 2.5v1.667h2.991l-8.191 8.191 1.175 1.175 8.191-8.191v2.991H17.5V2.5h-5.833z"
          fill="#000"
          fill-opacity=".7"
        />
      </svg>
    )
  }

  function ViewActiveIcon(props) {
    return (
      <svg
        {...props}
        width="20"
        height="20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15.833 15.833H4.167V4.167H10V2.5H4.167c-.925 0-1.667.75-1.667 1.667v11.666c0 .917.742 1.667 1.667 1.667h11.666c.917 0 1.667-.75 1.667-1.667V10h-1.667v5.833zM11.667 2.5v1.667h2.991l-8.191 8.191 1.175 1.175 8.191-8.191v2.991H17.5V2.5h-5.833z"
          fill="#0064AF"
        />
      </svg>
    )
  }

  function DeleteInactiveIcon(props) {
    return (
      <svg
        {...props}
        width="20"
        height="20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5 15.833c0 .917.75 1.667 1.667 1.667h6.666c.917 0 1.667-.75 1.667-1.667v-10H5v10zM6.667 7.5h6.666v8.333H6.667V7.5zm6.25-4.167l-.834-.833H7.917l-.834.833H4.167V5h11.666V3.333h-2.916z"
          fill="#000"
          fill-opacity=".7"
        />
      </svg>
    )
  }

  function DeleteActiveIcon(props) {
    return (
      <svg
        {...props}
        width="20"
        height="20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5 15.833c0 .917.75 1.667 1.667 1.667h6.666c.917 0 1.667-.75 1.667-1.667v-10H5v10zM6.667 7.5h6.666v8.333H6.667V7.5zm6.25-4.167l-.834-.833H7.917l-.834.833H4.167V5h11.666V3.333h-2.916z"
          fill="#0064AF"
        />
      </svg>
    )
  }

  const updatePaid = (value)=>{
    setPaid(value)
    setBalance(parseFloat(fee) - parseFloat(value))
  }
  const updateBalance = (value)=>{
    setBalance(value)
    
  }

  return (
    <div className="text-right ">
      <Menu as="div" className="relative inline-block text-left ">
        {({ open }) => (
          <>
            <div>
              <Menu.Button className="text-gray-500 hover:text-primary outline-none focus:outline-none">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z" />
                </svg>
              </Menu.Button>
            </div>
            <Transition
              show={open}
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items
                static
                className="absolute  right-0 bottom-0  w-48  mt-2 border-account_background border  bg-white divide-y divide-gray-100 rounded-md shadow-lg  focus:outline-none z-50"
              >
                {/* <div className="px-1 py-1">
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        className={`${
                          active
                            ? " text-secondary outline-none focus:outline-none"
                            : "text-gray-900 outline-none "
                        } group flex rounded-md items-center w-full px-2 py-2 text-sm outline-none focus:outline-none`}
                      >
                        {active ? (
                          <ViewActiveIcon
                            className="w-5 h-5 mr-2"
                            aria-hidden="true"
                          />
                        ) : (
                          <ViewInactiveIcon
                            className="w-5 h-5 mr-2"
                            aria-hidden="true"
                          />
                        )}
                        View
                      </button>
                    )}
                  </Menu.Item>
                </div> */}

                <div
                  className="p-1 cursor-pointer "
                  onClick={() => openEditModal()}
                >
                  <Menu.Item as="button">
                    {({ active }) => (
                      <button
                        className={`${
                          active
                            ? ' text-secondary outline-none focus:outline-none'
                            : 'text-gray-900'
                        } group flex rounded-md items-center w-full px-2 py-2 text-sm outline-none focus:outline-none`}
                      >
                        {active ? (
                          <EditActiveIcon
                            className="w-5 h-5 mr-2"
                            aria-hidden="true"
                          />
                        ) : (
                          <EditInactiveIcon
                            className="w-5 h-5 mr-2"
                            aria-hidden="true"
                          />
                        )}
                        Edit
                      </button>
                    )}
                  </Menu.Item>
                </div>

                <div
                  className="p-1 cursor-pointer"
                  onClick={() => showDialogModal()}
                >
                  <Menu.Item as="button">
                    {({ active }) => (
                      <button
                        className={`${
                          active
                            ? ' text-secondary outline-none focus:outline-none'
                            : 'text-gray-900'
                        } group flex rounded-md items-center w-full px-2 py-2 text-sm outline-none focus:outline-none`}
                      >
                        {active ? (
                          <DeleteActiveIcon
                            className="w-5 h-5 mr-2"
                            aria-hidden="true"
                          />
                        ) : (
                          <DeleteInactiveIcon
                            className="w-5 h-5 mr-2"
                            aria-hidden="true"
                          />
                        )}
                        Delete
                      </button>
                    )}
                  </Menu.Item>
                </div>
              </Menu.Items>
            </Transition>
          </>
        )}
      </Menu>

      <Transition show={showDeleteDialog} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-y-auto"
          initialFocus={cancelButtonRef}
          static
          showDialog={showDeleteDialog}
          open={showDeleteDialog}
          onClose={closeModal}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0  ">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block align-bottom bg-white text-left  transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full rounded-md shadow-lg ring-1 ring-black ring-opacity-5 ">
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4 ">
                  <div className="sm:flex sm:items-start ">
                    <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                      <ExclamationIcon
                        className="h-6 w-6 text-red-600"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left select-none">
                      <Dialog.Title
                        as="h3"
                        className="text-lg leading-6 font-medium text-gray-900"
                      >
                        Delete {student.name} payment
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500 select-none">
                          Are you sure you want to delete {student.name}{' '}
                          payment? This action cannot be undone.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse ">
                  <button
                    className="w-full  inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={() => deletePayment()}
                  >
                    {loading ? (
                      <i
                        className={'fas fa-circle-notch fa-spin text-base'}
                      ></i>
                    ) : (
                      <p className="2xl:text-lg">Delete</p>
                    )}
                  </button>
                  <button
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none  sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={() => closeModal()}
                    ref={cancelButtonRef}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>

      <Transition show={showEditDialog} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-y-auto"
          initialFocus={cancelButtonRef}
          static
          open={showEditDialog}
          onClose={closeEditModal}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-full max-w-md border border-gray-100  md:border-none text-sm  overflow-hidden text-left align-middle transition-all transform bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 py-3  md:text-white bg-white md:bg-primary"
                >
                  <nav class="flex flex-row justify-center">
                    <button className={tabClickStyle}>Edit payment</button>
                  </nav>
                </Dialog.Title>
                <form className="flex flex-col px-5">
                  <label htmlFor="paid" className="py-1.5">
                    Student name
                  </label>
                  <input
                    className="rounded-sm p-2 focus:outline-none focus:ring-1 bg-account_background "
                    type="text"
                    placeholder="name"
                    disabled={true}
                    required
                    name="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />

                  <div className="grid grid-flow-col grid-cols-2 justify-between py-1 space-x-5  overflow-y-auto overflow-x-hidden">
                    <div className="flex flex-col">
                      <label htmlFor="paid" className="py-1.5">
                        Amount Paid
                      </label>
                      <CurrencyInput
                        className="rounded-sm p-2 focus:outline-none focus:ring-1 bg-account_background "
                        type="text"
                        prefix="₦"
                        placeholder="paid"
                        required
                        name={paid}
                        value={paid !== '0' ? paid : null}
                        onValueChange={(value, name) => {
                          updatePaid(value)
                        }}
                      />
                    </div>
                    <div className="flex flex-col">
                      <label htmlFor="balance" className="py-1.5">
                        Balance
                      </label>
                      <CurrencyInput
                        className="rounded-sm p-2 focus:outline-none focus:ring-1 bg-account_background "
                        type="text"
                        placeholder="balance"
                        required
                        prefix="₦"
                        name={balance}
                        value={balance !== '0' ? balance : null}
                        onValueChange={(value, name) => {
                          updateBalance(value)
                        }}
                      />
                    </div>
                  </div>
                </form>

                <div className="px-5 py-5 flex items-end justify-end space-x-5">
                  <button
                    onClick={closeEditModal}
                    className="px-3 py-1.5 rounded-sm outline-none focus:outline-none text-gray-500 hover:bg-gray-100"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={() => {
                      handleSave()
                    }}
                    className={saveButtonClick}
                  >
                    {loading ? (
                      <i
                        className={'fas fa-circle-notch fa-spin text-base'}
                      ></i>
                    ) : (
                      <p className="2xl:text-lg">Save</p>
                    )}
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </div>
  )
}
