import React from 'react'
import {
  Page,
  Document,
  Svg,
  View,
  Path,
  StyleSheet,
  Image,
} from '@react-pdf/renderer'
import logo from '../../assets/icon/logo.JPG'
import InvoiceTitle from './InvoiceTitle'
import InvoiceData from '../../jsonData/InvoiceData'
import InvoiceNo from './InvoiceNo'
import BillTo from './BillTo'
import InvoiceItemsTable from './InvoiceItemsTable'
import DetailsTable from './DetailsTable'
import EdusafMessage from './EdusafMessage'
import { renderToStaticMarkup } from 'react-dom/server'
import ReactHtmlParser from 'react-html-parser'
import QRCode from 'qrcode.react'

const styles = StyleSheet.create({
  page: {
    backgroundColor: '#fff',
    fontFamily: 'Helvetica',
    fontSize: 11,
    paddingTop: 30,
    paddingLeft: 50,
    paddingRight: 50,
    lineHeight: 1.5,
    flexDirection: 'column',
    
  },
  logo: {
    // width: 100,
    // height: 100,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  qrcode: {
    width: 100,
    height: 100,
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: 10,
  },
  title: {
    backgroundColor: '#00C497',
    color: '#00C497',
  },
})

const PdfDocument = ({ invoicedata }) => {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <Image style={styles.logo} src={logo} />
        <InvoiceTitle style={styles.title} title={'FIRST TERM RESULT'} />
        {/* <InvoiceNo invoice={invoicedata} /> */}
        <DetailsTable invoice={invoicedata} />
        <BillTo invoice={invoicedata} />
        <InvoiceItemsTable invoice={invoicedata} />
        <EdusafMessage />
        <PdfWithQrCode />

        {/* <InvoiceThankYouMsg /> */}
      </Page>
    </Document>
  )
}

const PdfWithQrCode = () => {
  const qrCodeComponent = (
    <QRCode value={InvoiceData.invoice_no} renderAs="svg" size={50} />
  )

  const qrCodeComponentStaticMarkup = renderToStaticMarkup(qrCodeComponent)

  const parsedQrCodeSvg = parseQrCodeMarkup(qrCodeComponentStaticMarkup)
  if (!parsedQrCodeSvg) {
    return null
  }

  return (
    <View>
      <Svg style={styles.qrcode} viewBox="0 0 29 29">
        {parsedQrCodeSvg.props.children
          .filter((c) => c.type === 'path')
          .map((child, index) => (
            <Path key={index} d={child.props.d} fill={child.props.fill} />
          ))}
      </Svg>
    </View>
  )
}

const parseQrCodeMarkup = (markup) => {
  let parsedQrCodeSvg = null

  ReactHtmlParser(markup).forEach((el) => {
    const { type } = el
    if (type === 'svg') {
      parsedQrCodeSvg = el
    }
  })

  return parsedQrCodeSvg
}

export default PdfDocument
