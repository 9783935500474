import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useRef, useState } from 'react'
import firebase from 'firebase/app'
import { reactLocalStorage } from 'reactjs-localstorage'
import { NotificationManager } from 'react-notifications'
import 'react-notifications/lib/notifications.css'
import { ExclamationIcon } from '@heroicons/react/outline'

export default function ActivateTeacher({
  teachers,
  teacher,
  setTeachers,
  index,
}) {
  const [open, setOpen] = useState(false)
  const cancelButtonRef = useRef()
  const [roleField, setRoleField] = useState(teacher.roles)

  const [loading, setLoading] = useState(false)

  function closeModal() {
    setOpen(false)
  }

  function openModal() {
    setOpen(true)
  }

  const filterRoles = () => {
    roleField.map((role, index) => {
      if (role.subject === 'select' || role.classvalue === 'select') {
        roleField.splice(index, 1)
      }
      setRoleField(roleField)
    })
  }

  const deactivateTeacher = () => {
    setLoading(true)
    filterRoles()

    var db = firebase.firestore()
    db.collection('teachers')
      .doc(teacher.doc_id)
      .update({
        school: reactLocalStorage.get('current-school'),
        schoolname: reactLocalStorage.get('school-name'),
        name: teacher.name,
        phone: teacher.phone,
        email: teacher.email,
        // address: address,
        form_master: teacher.formValue ? teacher.formValue : '',
        gender: teacher.gender,
        role: teacher.role,
        active: true,
        roles: roleField,
        photo: '',
        state: reactLocalStorage.get('state')
          ? reactLocalStorage.get('state')
          : 'Plateau state',
        lga: reactLocalStorage.get('lga')
          ? reactLocalStorage.get('lga')
          : 'Jos-North',
        date: firebase.firestore.Timestamp.now(),
      })
      .then((docRef) => {
        var t = [...teachers]
        teacher.status = false
        var updateTeacher = {
          school: reactLocalStorage.get('current-school'),
          schoolname: reactLocalStorage.get('school-name'),
          name: teacher.name,
          phone: teacher.phone,
          email: teacher.email,
          // address: address,
          form_master: teacher.formValue ? teacher.formValue : '',
          gender: teacher.gender,
          role: teacher.role,
          active: true,
          roles: roleField,
          photo: '',
          state: reactLocalStorage.get('state')
            ? reactLocalStorage.get('state')
            : 'Plateau state',
          lga: reactLocalStorage.get('lga')
            ? reactLocalStorage.get('lga')
            : 'Jos-North',
          date: firebase.firestore.Timestamp.now(),
        }
        t[index] = updateTeacher
        setTeachers(t)
        closeModal()
        NotificationManager.info('Account Deactivated Successful')
      })
      .catch((error) => {
        NotificationManager.error(error.message)
      })
    setLoading(false)
    closeModal()
  }

  return (
    <>
      <div className="">
        <button
          onClick={openModal}
          className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100"
        >
          Disable
        </button>
      </div>

      <Transition show={open} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-y-auto"
          initialFocus={cancelButtonRef}
          static
          showDialog={open}
          open={open}
          onClose={closeModal}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0  ">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block align-bottom bg-white text-left  transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full rounded-md shadow-lg ring-1 ring-black ring-opacity-5 ">
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4 ">
                  <div className="sm:flex sm:items-start ">
                    <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                      <ExclamationIcon
                        className="h-6 w-6 "
                        aria-hidden="true"
                      />
                    </div>
                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left select-none">
                      <Dialog.Title
                        as="h3"
                        className="text-lg leading-6 font-medium text-gray-900"
                      >
                        Activate {teacher.name}
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500 select-none">
                          Are you sure you want to activate {teacher.name}{' '}
                          account? This action restore his access to the app
                          and your school record
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse ">
                  <button
                    className="w-full  inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={() => deactivateTeacher()}
                  >
                    {loading ? (
                      <i
                        className={'fas fa-circle-notch fa-spin text-base'}
                      ></i>
                    ) : (
                      <p className="2xl:text-lg">Activate</p>
                    )}
                  </button>
                  <button
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none  sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={() => closeModal()}
                    ref={cancelButtonRef}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}
