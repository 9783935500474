import { useState, useEffect } from 'react'
import AddClasses from './AddClasses'
import firebase from 'firebase/app'
import { reactLocalStorage } from 'reactjs-localstorage'
import { NotificationManager } from 'react-notifications'
import 'react-notifications/lib/notifications.css'
import CurrencyInput from 'react-currency-input-field'
const Fees = ({ classEmptyRecord, loading }) => {
  const defaultStyle = 'px-3 py-1.5 bg-gray-50 select-none cursor-default'
  const clickStyle = 'px-3 py-1.5 bg-account_click select-none cursor-default'

  const [load, setLoad] = useState()
  const [error, setError] = useState()
  const [classValue, setClassValue] = useState('select')
  const [sessionValue, setSessionValue] = useState('2023/2024')
  const [termValue, setTermValue] = useState('First Term')
  const [selectedClass, setSelectedClass] = useState([])
  const [isNewRecord, setIsNewRecord] = useState(false)
  const [loading2, setLoading2] = useState(false)
  const [classDocument, setClassDocument] = useState([])

  //const [paid, setPaid]= useState([])

  useEffect(() => {
    getClasses()
  }, [])

  const getClasses = () => {
    setLoading2(true)
    var db = firebase.firestore()
    var items = []
    var query = db
      .collection('school-classes')
      .doc(reactLocalStorage.get('current-school'))
    query
      .get()
      .then((doc) => {
        if (doc.exists) {
          doc.data().classes.forEach((dot) => {
            if (dot.click) {
              items.push({
                name: dot.name,
                id: dot.id,
                click: true,
              })
            }
          })
          setClassDocument(items)
          //setLoading(false)
        } else {
          // doc.data() will be undefined in this case
          // setLoading(false)
        }
        setLoading2(false)
        //getFees()
      })
      .catch((error) => {
        setLoading2(false)
        console.log('Error getting document:', error)
      })
  }

  const setClass = () => {
    setSelectedClass([])
    classDocument.map((classes, index) => {
      if (classes.click) {
        setSelectedClass((selectedClass) => [
          ...selectedClass,
          {
            id: classes.id,
            name: classes.name,
            amount: classes.amount ? classes.amount : '0',
            paid: classes.paid ? classes.paid : [],
          },
        ])
      }
    })
  }

  const getFees = () => {
    setLoading2(true)
    var db = firebase.firestore()
    var query = db.collection('fees')
    query
      .doc(
        reactLocalStorage
          .get('current-school')
          .concat('_')
          .concat(sessionValue.replace('/', '_'))
          .concat('_')
          .concat(termValue.replace(' ', '_'))
      )
      .get()
      .then((doc) => {
        if (doc.exists) {
          var newlyAdded =
            parseInt(classDocument.length) - parseInt(doc.data().classes.length)
          // setClassDocument(doc.data().classes)
          let values = []
          doc.data().classes.map((item, index) => {
            values.push(item)
          })
          if (newlyAdded > 0) {
            for (let index = 0; index < newlyAdded; index++) {
              const element =
                classDocument[parseInt(doc.data().classes.length) + index]
              element.paid = []
              values.push(element)
            }
          }

          //setPaid(doc.data().paid)
          setSelectedClass(values)
          setIsNewRecord(false)
        } else {
          setClass()
          NotificationManager.info('No record found')
          setIsNewRecord(true)
          // doc.data() will be undefined in this case
          //  getClasses()
          //  setClassEmptyRecord(true)
        }
        setLoading2(false)
      })
      .catch((error) => {
        console.log('Error getting document:', error)
      })
  }

  const save = () => {
    setLoad(true)
    setError('')
    var db = firebase.firestore()
    db.collection('fees')
      .doc(
        reactLocalStorage
          .get('current-school')
          .concat('_')
          .concat(sessionValue.replace('/', '_'))
          .concat('_')
          .concat(termValue.replace(' ', '_'))
      )
      .set({
        classes: selectedClass,
        date: firebase.firestore.Timestamp.now(),
      })
      .then((docRef) => {
        NotificationManager.info('Added Successful')
        setLoad(false)
      })
      .catch((error) => {
        setError(error.message)
        setLoad(false)
      })
  }

  // const update = () => {
  //   setLoad(true)
  //   setError('')
  //   var db = firebase.firestore()
  //   db.collection('fees')
  //     .doc(
  //       reactLocalStorage
  //         .get('current-school')
  //         .concat('_')
  //         .concat(sessionValue.replace('/', '_'))
  //         .concat('_')
  //         .concat(termValue.replace(' ', '_'))
  //     )
  //     .update({
  //       classes: selectedClass,
  //       date: firebase.firestore.Timestamp.now(),
  //     })
  //     .then((docRef) => {
  //       NotificationManager.info('Saved Successful')
  //       setLoad(false)
  //     })
  //     .catch((error) => {
  //       setError(error.message)
  //       setLoad(false)
  //     })
  // }

  if (loading2) {
    return (
      <div className="grid  justify-center items-center ">
        <i className={' text-primary fas fa-circle-notch fa-spin text-5xl'}></i>
      </div>
    )
  }

  return (
    <div className=" ">
      <div className=" grid grid-flow-col  justify-start items-center mb-5">
        <div className="text-primary  flex text-center items-center rounded-sm space-x-4">
          <select
            className="rounded-sm p-2 w-40 md:w-56 focus:outline-none focus:ring-1 bg-gray-50 w-full"
            placeholder="session"
            required
            name="sessionValue"
            value={sessionValue}
            onChange={(e) => {
              setSessionValue(e.target.value)
            }}
          >
            {/* <option value="select" selected>
                  Session
                </option> */}
            {/* <option value="2022/2023">2022/2023</option> */}
            <option value="2023/2024">2023/2024</option>
            <option value="2024/2025">2024/2025</option>
          </select>
          <select
            className="rounded-sm p-2 w-40 md:w-56 focus:outline-none focus:ring-1 bg-gray-50 w-full"
            placeholder="term"
            required
            name="termValue"
            value={termValue}
            onChange={(e) => setTermValue(e.target.value)}
          >
            {/* <option value="select" selected>
                  term
                </option> */}
            <option value="First Term">First Term</option>
            <option value="Second Term">Second Term</option>
            <option value="Third Term">Third Term</option>
            {/* <option value="Fourth Term">Fourth Term</option> */}
          </select>

          <button
            type="button"
            onClick={() => getFees()}
            className="space-x-1 flex  justify-center outline-none focus:outline-none border py-2 px-4 rounded-sm"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              class="h-5 w-5"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
              />
            </svg>
            Search
          </button>
        </div>
      </div>
      <div className="grid px-4 md:px-0 gap-2 md:gap-4  grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4  xl_1:grid-cols-5 ">
        {selectedClass.map((subject, index) => (
          <div key={subject.id} className={clickStyle}>
            <div className="">
              <label className="capitalize">{subject.name}</label>
              <div
                key={subject.id}
                className="w-40 md:w-56 border bg-white text-primary py-2 mx-4 md:mx-0 px-2 md:px-4 flex text-center items-center rounded-sm"
              >
                <CurrencyInput
                  className="bg-white text-primary outline-none text-base pl-2 w-32 md:w-52"
                  key={subject.index}
                  id={subject.id}
                  name={subject.name}
                  placeholder="amount"
                  prefix="₦"
                  value={subject.amount !== '0' ? subject.amount : null}
                  // defaultValue={1000}
                  decimalsLimit={2}
                  onValueChange={(value, name) => {
                    subject.amount = value
                    setSelectedClass([...selectedClass])
                  }}
                />
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className="grid  px-4 md:px-0  justify-end">
        {selectedClass.length > 0 ? (
          <button
            onClick={() => {
              save()
            }}
            className="px-5 py-2 my-2  rounded-sm outline-none focus:outline-none text-white  bg-primary"
          >
            {load ? (
              <i className={'fas fa-circle-notch fa-spin text-base'}></i>
            ) : (
              <p className="2xl:text-lg">Save</p>
            )}
          </button>
        ) : (
          ''
        )}
      </div>
    </div>
  )
}

export default Fees
