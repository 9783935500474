import { useState } from 'react'
import firebase from 'firebase/app'
import { reactLocalStorage } from 'reactjs-localstorage'
import { NotificationManager } from 'react-notifications'
import 'react-notifications/lib/notifications.css'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

import {
  PDFDownloadLink,
  PDFViewer,
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
} from '@react-pdf/renderer'
import {
  Table,
  TableHeader,
  TableBody,
  DataTableCell,
  TableCell,
} from '@david.kucsai/react-pdf-table'
import ExpenseTable from './ExpenseTable'

const ExpensesView = ({
  loading,
  setLoading,
  transaction,
  setTransaction,
  classEmptyRecord,
  setClassDocument,
}) => {
  const defaultStyle = 'px-3 py-1.5 bg-gray-50 select-none cursor-default'
  const clickStyle = 'px-3 py-1.5 bg-account_click select-none cursor-default'

  const [error, setError] = useState()
  const activated = reactLocalStorage.get('activated')

  function currencyFormat(num) {
    return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  }

  function getCAData() {
    // setLoading(false)
    var dat = []
    var balance = 0
    var income = 0
    var expense = 0

    transaction.map((stud, index) => {
      
        balance = balance + parseFloat(stud.amount)
        expense = expense + parseFloat(stud.amount)
      

      dat.push({
        index: (
          <View style={styles.tableLeft}>
            <Text>{index + 1}</Text>
          </View>
        ),
        date: (
          <View style={styles.tableLeft}>
            <Text>{stud.date}</Text>
          </View>
        ),
        name: (
          <View style={styles.tableLeft}>
            <Text>{stud.name}</Text>
          </View>
        ),
        income: (
          <View style={styles.tableLeft}>
            <Text>
              {stud.payment_type === 'income'
                ? currencyFormat(stud.amount)
                : '-------------'}
            </Text>
          </View>
        ),
        expense: (
          <View style={styles.tableLeft}>
            <Text>{currencyFormat(stud.amount)}</Text>
          </View>
        ),
        balance: (
          <View style={styles.tableLeft}>
            <Text>{currencyFormat(balance)}</Text>
          </View>
        ),
      })
    })

    return dat
  }

  const MyTable = () => {
    var dt = getCAData()

    return (
      <Table style={styles.table} data={[...dt]}>
        <TableHeader>
          <TableCell weighting={0.04}>
            <View style={styles.tableLeft}>
              <Text>SN</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.15}>
            <View style={styles.tableLeft}>
              <Text>DATE</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.35}>
            <View style={styles.tableLeft}>
              <Text>DESCRIPTION</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.306}>
            <View style={styles.tableLeft}>
              <Text>AMOUNT</Text>
            </View>
          </TableCell>
          {/* <TableCell weighting={0.153}>
            <View style={styles.tableLeft}>
              <Text>RECEIVED</Text>
            </View>
          </TableCell> */}

          <TableCell weighting={0.153}>
            <View style={styles.tableLeft}>
              <Text>BALANCE</Text>
            </View>
          </TableCell>
        </TableHeader>
        <TableBody>
          <DataTableCell weighting={0.04} getContent={(r) => r.index} />
          <DataTableCell weighting={0.15} getContent={(r) => r.date} />
          <DataTableCell weighting={0.35} getContent={(r) => r.name} />
          <DataTableCell weighting={0.306} getContent={(r) => r.expense} />
          {/* <DataTableCell weighting={0.153} getContent={(r) => r.income} /> */}
          <DataTableCell weighting={0.153} getContent={(r) => r.balance} />
        </TableBody>
      </Table>
    )
  }

  const MyDocument = () => {
    var balance = 0
    var income = 0
    var expense = 0
    transaction.map((stud, index) => {
      if (stud.payment_type === 'income') {
        balance = balance + parseFloat(stud.amount)
        income = income + parseFloat(stud.amount)
      } else {
        balance = balance - parseFloat(stud.amount)
        expense = expense + parseFloat(stud.amount)
      }
    })
    const logo = reactLocalStorage.get('heading')

    return (
      <Document>
        <Page size="A4" style={styles.page}>
          <Image style={styles.logo} src={logo} />
          {/* <View style={styles.school}>
            <Text>MY SCHOOL NAME</Text>
          </View> */}

          <View style={styles.tableRatingParent}>
            {/* <Text>Received: {currencyFormat(income)}</Text> */}
            <Text>TOTAL EXPENSES: {currencyFormat(expense)}</Text>
            {/* <Text>Balance: {currencyFormat(balance)}</Text> */}
          </View>

          <MyTable />

          <View style={styles.edusaf}>
            <Text>Powered by edutech</Text>
          </View>
        </Page>
      </Document>
    )
  }

  const styles = StyleSheet.create({
    page: {
      flexDirection: 'column',
      paddingTop: 30,
      // paddingLeft: 50,
      // paddingRight: 50,
      paddingHorizontal: 30,
      fontFamily: 'Helvetica',
    },

    section: {
      // marginVertical: 5,
      color: '#fff',
    },
    section2: {
      marginHorizontal: 20,
      color: '#fff',
    },
    logo: {
      // width: 100,
      // height: 100,
      marginLeft: 'auto',
      marginRight: 'auto',
    },

    school: {
      // marginVertical: 10,
      color: '#07173B',
      // letterSpacing: 1,
      fontSize: 40,
      fontWeight: 'heavy',
      textAlign: 'center',
      textTransform: 'uppercase',
      fontFamily: 'Times-Bold',
    },

    title: {
      marginTop: 5,
      color: '#3E8EDE',
      // letterSpacing: 1,
      fontSize: 15,
      textAlign: 'center',
      textTransform: 'uppercase',
      fontFamily: 'Helvetica',
    },
    subTitle: {
      // marginVertical: 5,
      marginTop: 15,
      color: '#3E8EDE',
      // letterSpacing: 1,
      fontSize: 12,
      // textAlign: 'center',
      textTransform: 'uppercase',
      fontFamily: 'Helvetica',
    },
    subSubjetClass: {
      marginVertical: 5,
      // color: '#3E8EDE',
      // letterSpacing: 1,
      fontSize: 12,
      // textAlign: 'center',
      textTransform: 'uppercase',
      fontFamily: 'Helvetica',
    },
    remarkText: {
      // marginVertical: 5,
      marginTop: 20,
      // letterSpacing: 1,
      fontSize: 12,
      // textDecoration: 'underline',
      // textAlign: 'center',
      // textTransform: 'uppercase',
      fontFamily: 'Helvetica',
    },
    edusaf: {
      fontSize: 8,
      fontFamily: 'Helvetica',
      position: 'absolute',
      bottom: 5,
      right: 5,
    },

    table: {
      fontSize: 12,
      textAlign: 'center',
      fontFamily: 'Helvetica',
    },
    tableBehaviour: {
      fontSize: 12,
      textAlign: 'center',
      fontFamily: 'Helvetica',
      width: 250,
      flex: 1,
      // flexDirection: 'row',
    },
    tableRatingParent: {
      fontSize: 12,
      textAlign: 'center',
      fontFamily: 'Helvetica',
      width: 200,
      textAlign: 'left',
      paddingVertical: 5,
      marginTop: 10,

      // flexDirection: 'row',
    },
    tableBehaviourParent: {
      fontSize: 12,
      textAlign: 'center',
      fontFamily: 'Helvetica',
      flexDirection: 'row',
      display: 'flex',
    },

    tableRating: {
      fontSize: 12,
      textAlign: 'left',
      paddingLeft: 5,
      fontFamily: 'Helvetica',
    },
    tableLeft: {
      fontSize: 10,
      textAlign: 'left',
      fontFamily: 'Helvetica',
      paddingLeft: 5,
      // backgroundColor: '#D3ECE6',
    },
    student: {
      fontSize: 12,
      textAlign: 'left',
      fontFamily: 'Helvetica',
      paddingLeft: 5,
      paddingVertical: 2,
    },
    studentTable: {
      // marginVertical: 10,
    },
    total: {
      fontSize: 12,
      textAlign: 'center',
      fontFamily: 'Helvetica',
      // backgroundColor: '#D3ECE6',
    },
    tablesubject: {
      fontSize: 12,
      textAlign: 'left',
      paddingLeft: 5,
      // backgroundColor: '#D3ECE6',
      fontFamily: 'Helvetica',
    },
    tableNamesubject: {
      fontSize: 12,
      textAlign: 'left',
      paddingLeft: 5,
      // backgroundColor: '#D3ECE6',
      fontFamily: 'Helvetica',
      paddingVertical: 2,
    },
    studentDetails: {
      fontSize: 12,
      textAlign: 'left',
      fontFamily: 'Helvetica',
      paddingLeft: 5,
      paddingVertical: 2,
    },
  })

  if (loading) {
    return (
      <div className="grid  justify-center items-center ">
        <i className={' text-primary fas fa-circle-notch fa-spin text-5xl'}></i>
      </div>
    )
  }

  return (
    <div className=" ">
      {/* <div>
        <h2>Date Range Picker Example</h2>
        <div>
          <label>Start Date:</label>
          <DatePicker
            selected={startDate}
            onChange={handleStartDateChange}
            selectsStart
            startDate={startDate}
            endDate={endDate}
            placeholderText="Select start date"
          />
        </div>
        <div>
          <label>End Date:</label>
          <DatePicker
            selected={endDate}
            onChange={handleEndDateChange}
            selectsEnd
            startDate={startDate}
            endDate={endDate}
            minDate={startDate}
            placeholderText="Select end date"
          />
        </div>
      </div> */}
      <div className="">
        <ExpenseTable
          transaction={transaction}
          setTransaction={setTransaction}
          loading={loading}
          setLoading={setLoading}
        />
      </div>

      <div className="grid py-5 px-4 md:px-0  justify-end">
        {activated === 'true' && transaction.length !== 0 ? (
          <button className="px-3 py-1.5  rounded-sm outline-none focus:outline-none text-white  bg-primary">
            <PDFDownloadLink
              document={<MyDocument />}
              fileName="attendance.pdf"
            >
              {({ blob, url, loading, error }) => 'Download Attendance'}
            </PDFDownloadLink>
          </button>
        ) : (
          ''
        )}
      </div>
    </div>
  )
}

export default ExpensesView
