import firebase from 'firebase/app'
import { reactLocalStorage } from 'reactjs-localstorage'
import { usePagination } from 'use-pagination-firestore'
import { useEffect, useState } from 'react'
import { NotificationManager } from 'react-notifications'
import AssessmentDropMenu from './AssessmentDropMenu'

const AssessmentTable = ({
  allStudent,
  setAllStudent,
  classValue,
  sessionValue,
  termValue,
  subjectValue,
  setSubjectValue,
  loading,
  isNewRecord,
  setIsNewRecord,
  assessment,
  setAssessment,
}) => {
  const [studentCount, setStudentCount] = useState()
  var schoolName = reactLocalStorage.get('school-name')
  var zenithPrimarySchool = 'zenith international integrated school gombe'

  const currentSchool = reactLocalStorage.get('current-school')
  const currentSchoolName = reactLocalStorage.get('school-name')
  const modibbo = 'MODIBBO IDRIS ACADEMY, GOMBE'
  const annibras = 'An-Nibras Model School'
  const smartGombe = 'Smart Academy Gombe'
  const flyingColorsGombe = 'knNn0QpqbUaVBcOBUBFU'
  const zenithPrimary = 'FBzIYMgZ1nJlyMX2Btk1'
  const markazu = 'qSfZ9ljUp8bmYNJo8InQ'
  const blissGombe = 'BLISS SUCCESS MODEL SCHOOL'
  const sunshineGombe = 'SUNSHINE MODEL SCHOOL, AKKO GOMBE'
  const alImanCollegeJos = 'Al-iman College Jos'
  const alImanSchoolGirls = 'AL-IMAN GIRLS SECONDARY SCHOOL'
  const alImanSchoolBoys = 'AL-IMAN SECONDARY SCHOOL'
  const esteemJos = 'fhqa4oQYx5j5KotPOLWz'
  const presteenJos = 'voZ4XR9kBq6txi85vzAB'
  const albayan = 'ATi6GodoPjPJkLfzU5OQ'
   const iqraaJos = 'H8ER5nzwQIHmWH1dz3I3'
  var alimanSec =
    (currentSchoolName === alImanSchoolBoys && classValue.includes('J.S.S')) ||
    (currentSchoolName === alImanSchoolBoys && classValue.includes('S.S.S'))

  const caType =
    currentSchool === zenithPrimary ||
    currentSchool === markazu ||
    currentSchoolName === alImanSchoolGirls ||
    alimanSec ||
    currentSchool === esteemJos ||
    currentSchool === presteenJos ||
    currentSchool === albayan || currentSchool === iqraaJos
      ? '4'
      : '3'

  if (loading) {
    return (
      <div className="grid  justify-center items-center ">
        <i className={' text-primary fas fa-circle-notch fa-spin text-5xl'}></i>
      </div>
    )
  }
  return (
    <div className="">
      <div className="flex flex-col max-h-96  md:max-h-82 xl_1:max-h-120 2xl:max-h-130 overflow-y-auto   shadow-md border-b border-gray-200 rounded-sm  ">
        <div className="flex-grow  ">
          <table className="relative w-full min-w-full   divide-y divide-gray-200 ">
            <thead className="">
              <tr className=" ">
                <th className="sticky bg-gray-50 z-30 md:z-20 left-0  top-0  px-2  py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  SN
                </th>
                <th className="sticky bg-gray-50 z-30 md:z-20 left-0  top-0  px-2  py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Name
                </th>
                <th className="sticky bg-gray-50 z-20 top-0 px-4   py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  {currentSchool === albayan ? '1st ASS' : 'ASS 1'}
                </th>
                <th className="sticky bg-gray-50 z-20 top-0 px-4   py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  {currentSchool === albayan ? '1st TEST' : 'ASS 2'}
                </th>
                <th className="sticky bg-gray-50 z-20  top-0 px-4  py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  {currentSchool === albayan ? '2nd ASS' : 'TEST 1'}
                </th>
                {caType === '4' ? (
                  <th className="sticky bg-gray-50 z-20  top-0 px-4  py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    {currentSchool === albayan ? '2nd TEST' : 'TEST 2'}
                  </th>
                ) : (
                  ''
                )}
                <th className="sticky bg-gray-50 z-20  top-0 px-4  py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  EXAM
                </th>
                <th className="sticky bg-gray-50 z-20  top-0 px-4  py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  TOTAL
                </th>
                <th className="sticky bg-gray-50 z-20  top-0 px-4  py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  GRADE
                </th>
                <th className="sticky bg-gray-50 z-20  top-0 px-4  py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  POS
                </th>
                <th className="sticky bg-gray-50 z-20 top-0 px-4 py-3">
                  <span className="sr-only">Edit</span>
                </th>
              </tr>
            </thead>

            <tbody className="bg-white divide-y divide-gray-200 ">
              {/* {sectionValue == 'select'
                ? ''
                : filterSection()} */}
              {allStudent.map((student, index) => (
                <tr key={student.id}>
                  <td>
                    <div key={2} className="px-2 text-sm text-gray-900">
                      {index + 1}
                    </div>
                  </td>
                  <td
                    key={1}
                    className="sticky left-0  bg-white px-2 py-2 whitespace-nowrap "
                  >
                    <div className="flex items-center">
                      <div className="">
                        <div className="text-sm font-medium capitalize text-gray-900">
                          {student.name.toUpperCase()}
                        </div>
                        <div className="text-sm capitalize text-gray-500">
                          {student.studentid}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td
                    key={2}
                    className="px-4 py-2 whitespace-nowrap capitalize"
                  >
                    <div className="text-sm text-gray-900">{student.ass1}</div>
                  </td>

                  <td key={3} className="px-4 py-2 whitespace-nowrap">
                    <div className="text-sm uppercase text-gray-900">
                      {student.ass2}
                    </div>
                  </td>

                  <td key={4} className="px-4 py-2 whitespace-nowrap">
                    <div className="text-sm capitalize text-gray-900">
                      {student.test}
                    </div>
                  </td>
                  {caType === '4' ? (
                    <td key={41} className="px-4 py-2 whitespace-nowrap">
                      <div className="text-sm capitalize text-gray-900">
                        {student.test2}
                      </div>
                    </td>
                  ) : (
                    ''
                  )}
                  <td key={5} className="px-4 py-2 whitespace-nowrap">
                    <div className="text-sm capitalize text-gray-900">
                      {student.exam}
                    </div>
                  </td>
                  <td key={6} className="px-4 py-2 whitespace-nowrap">
                    <div className="text-sm capitalize text-gray-900">
                      {student.total}
                    </div>
                  </td>
                  <td key={7} className="px-4 py-2 whitespace-nowrap">
                    <div className="text-sm capitalize text-gray-900">
                      {student.grade}
                    </div>
                  </td>
                  <td key={8} className="px-4 py-2 whitespace-nowrap">
                    <div className="text-sm capitalize text-gray-900">
                      {student.position}
                    </div>
                  </td>
                  <td
                    key={8}
                    className="px-2  whitespace-nowrap text-right text-sm font-medium z-30"
                  >
                    <AssessmentDropMenu
                      student={student}
                      allStudent={allStudent}
                      setAllStudent={setAllStudent}
                      // studentCount={studentCount}
                      // setStudentCount={setStudentCount}
                      classValue={classValue}
                      sessionValue={sessionValue}
                      termValue={termValue}
                      subjectValue={subjectValue}
                      setSubjectValue={setSubjectValue}
                      isNewRecord={isNewRecord}
                      setIsNewRecord={setIsNewRecord}
                      assessment={assessment}
                      setAssessment={setAssessment}
                      index={index}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}
export default AssessmentTable
