const InvoiceData = {
  id: 'ALS/22/0001',
  invoice_no: '201906-28',
  amount: '45,000',
  total: '4,000',
  balance: '0.00',
  fullname: 'Hashim Ismail Muhammad',
  class:  'S.S.S THREE',
  session:  '2021/2022',
  term: 'First Term',
  trans_date: '26-11-2021',
  due_date: '26-11-2021',
  items: [
    {
      sno: 1,
      desc: 'SCHOOL FEES',
      amount: '45,000.00',
    },
    {
      sno: 2,
      desc: 'TOTAL PAID',
      amount: '45,000.00',
    },
    {
      sno: 3,
      desc: 'BALANCE',
      amount: '0.00',
    },
  ],
}

export default InvoiceData
