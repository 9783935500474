import firebase from 'firebase/app'
import { reactLocalStorage } from 'reactjs-localstorage'
import { useState, useEffect } from 'react'
import Naira from 'react-naira'
import { usePaystackPayment } from 'react-paystack'
import { NotificationManager } from 'react-notifications'
import 'react-notifications/lib/notifications.css'
var InputNumber = require('rmc-input-number')

const ManageSubscription = ({}) => {
  const tabDefaultStyle =
    'text-white text-xs md:text-sm  md:text-gray-600 uppercase py-4 px-4 md:px-6 block hover:text-blue-500 focus:outline-none'
  const tabClickStyle =
    'uppercase text-sm md:text-sm  py-4 px-4 md:px-6 block md:hover:text-blue-500 focus:outline-none text-white md:text-blue-500 border-b-2 font-medium md:border-blue-500'

  const [document, setDocument] = useState([])
  const [classDocument, setClassDocument] = useState([])
  const [loading, setLoading] = useState(false)
  const [student, setStudent] = useState(0)
  const [slotPaid, setSlotPaid] = useState(0)
  const [plan, setPlan] = useState('')
  const [amountPaid, setAmountPaid] = useState(0)
  const [payment, setPayment] = useState(0)
  const [isNew, setIsNew] = useState()
  const [smsAmount, setSmsAmount] = useState()
  const [smsBalance, setSmsBalance] = useState(0)

  useEffect(() => {
    getStudents()
    getSubscription()
    getSMSBalance()
  }, [])

  const subscriptionconfig = {
    reference: new Date().getTime().toString(),
    email: reactLocalStorage.get('email'),
    amount: (student - slotPaid) * 10000,
    publicKey: 'pk_live_a98fa645f1b9c16cbf997c0236b7bf518a2371d6',
  }
  const smsconfig = {
    reference: new Date().getTime().toString(),
    email: reactLocalStorage.get('email'),
    amount: smsAmount * 100,
    publicKey: 'pk_live_a98fa645f1b9c16cbf997c0236b7bf518a2371d6',
  }

  const formatter = new Intl.NumberFormat('en-NG', {
    style: 'currency',
    currency: 'NGN',
    maximumFractionDigits: '0',
  })

  // you can call this function anything
  const onSuccess = (reference) => {
    // Implementation for whatever you want to do with reference and after success call.
    save(reference)
  }
  const onSuccessSMS = (reference) => {
    // Implementation for whatever you want to do with reference and after success call.
    saveSMS(reference)
  }
  // you can call this function anything
  const onClose = () => {
    // implementation for  whatever you want to do when the Paystack dialog closed.
    console.log('closed')
  }
  const PaystackHookExample = () => {
    const initializePayment = usePaystackPayment(subscriptionconfig)
    return (
      <div>
        <button
          className="flex mb-5 ml-5 justify-center outline-none focus:outline-none border py-1.5 px-3 rounded-full bg-primary text-white"
          onClick={() => {
            initializePayment(onSuccess, onClose)
          }}
        >
          Proceed with payment
        </button>
      </div>
    )
  }
  const PaystackHookExampleSMS = () => {
    const initializePayment = usePaystackPayment(smsconfig)
    return (
      <div>
        <button
          className="flex mt-5  justify-center outline-none focus:outline-none border py-1.5 px-3 rounded-full bg-primary text-white"
          onClick={() => {
            initializePayment(onSuccessSMS, onClose)
          }}
        >
          Proceed with payment
        </button>
      </div>
    )
  }


  const getSubscription = () => {
    setLoading(true)
    var db = firebase.firestore()
    var path = reactLocalStorage
      .get('current-school')
      .concat('_')
      .concat(reactLocalStorage.get('session').replace('/', '_'))
      .concat('_')
      .concat(reactLocalStorage.get('term').replace(' ', '_'))

    var query = db.collection('subscription')
    query
      .doc(path)
      .get()
      .then((doc) => {
        if (doc.exists) {
          setIsNew(false)
          setPlan(doc.data().plan)
          setSlotPaid(doc.data().slot)
          setAmountPaid(doc.data().amount)
           const plan = doc.data().plan
           const slotPaid = doc.data().slot
           const totalStudent = reactLocalStorage.get('total_students')

           if (
             plan === 'Trial' ||
             (plan === 'Paid' && parseInt(slotPaid) >= parseInt(totalStudent))
           ) {
             // setActivated(true)
             reactLocalStorage.set('activated', true)
           } else {
             //setActivated(false)
             reactLocalStorage.set('activated', false)
          }
          
        } else {
          setIsNew(true)
          setPlan('Paid')
        }
        setLoading(false)
      })
      .catch((error) => {
        console.log('Error getting document:', error)
        setLoading(false)
      })
  }
  const getSMSBalance = () => {
    setLoading(true)
    var db = firebase.firestore()
    var path = reactLocalStorage.get('current-school')
    var query = db.collection('sms-wallet')
    query
      .doc(path)
      .get()
      .then((doc) => {
        if (doc.exists) {
          setSmsBalance(doc.data().balance)
        } else {
          setSmsBalance(0)
        }
        setLoading(false)
      })
      .catch((error) => {
        setLoading(false)
      })
  }

  const getStudents = () => {
    setLoading(true)
    var db = firebase.firestore()
    var record = []
    db.collection('students')
      .where('school', '==', reactLocalStorage.get('current-school'))
      .get()
      .then((querySnapshot) => {
        setStudent(querySnapshot.size)
        setLoading(false)
      })
      .catch((error) => {
        setLoading(false)
        console.log('Error getting documents: ', error)
      })
  }
  const save = (reference) => {
    setLoading(true)
    var db = firebase.firestore()
    var path = reactLocalStorage
      .get('current-school')
      .concat('_')
      .concat(reactLocalStorage.get('session').replace('/', '_'))
      .concat('_')
      .concat(reactLocalStorage.get('term').replace(' ', '_'))

    db.collection('subscription')
      .doc(path)
      .set({
        reference: reference,
        session: reactLocalStorage.get('session'),
        term: reactLocalStorage.get('term'),
        amount: (student - slotPaid) * 100,
        plan: 'Paid',
        slot: student - slotPaid,
        email: reactLocalStorage.get('email'),
        school_name: reactLocalStorage.get('school-name'),
        school: reactLocalStorage.get('current-school'),
        phone: reactLocalStorage.get('phone'),
        date: firebase.firestore.Timestamp.now(),
      })
      .then((docRef) => {
        NotificationManager.info('Your subscription is  successful')
        setLoading(false)
        getStudents()
        getSubscription()
      })
      .catch((error) => {
        setLoading(false)
      })
  }
  const saveSMS = (reference) => {
    setLoading(true)
    var db = firebase.firestore()
    var path = reactLocalStorage.get('current-school')

    db.collection('sms-wallet')
      .doc(path)
      .set({
        reference: reference,
        balance: parseInt(smsBalance) + parseInt(smsAmount),
        email: reactLocalStorage.get('email'),
        school_name: reactLocalStorage.get('school-name'),
        school: reactLocalStorage.get('current-school'),
        phone: reactLocalStorage.get('phone'),
        date: firebase.firestore.Timestamp.now(),
      })
      .then((docRef) => {
        NotificationManager.info(
          'Your sms wallet has been funded with ',
          formatter.format(smsAmount)
        )
        setLoading(false)
        setSmsBalance(parseInt(smsBalance) + parseInt(smsAmount))
        getSMSBalance()
      })
      .catch((error) => {
        setLoading(false)
      })
  }

  const handleChange = (e) => {
    setSmsAmount(e.target.value)
  }

  if (loading) {
    return (
      <div className="grid justify-center items-center">
        <i className={' text-primary fas fa-circle-notch fa-spin text-5xl'}></i>
      </div>
    )
  }
  return (
    <div className="">
      {/* <div className="sticky top-0 z-30 mx-0 md:mx-12 md:my-3 select-none bg-primary md:bg-white  ">
        <p className="md:hidden py-3 md:py-0 ml-4 md:ml-0 text-white md:text-gray-800 font-medium text-lg">
          Subscription
        </p>
      </div> */}
      <div className="m-4 md:mx-12 md:my-5  ">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
          <div className="">
            Subscription Account
            <div className="grid grid-cols-2 rounded-md ">
              <div className="">
                <p>Plan</p>
                <p className="text-black font-semibold text-lg">{plan}</p>
              </div>
              <div className="">
                <p>Payment</p>
                <p className="text-black font-semibold text-lg">
                  <Naira>
                    {plan === 'Trial'
                      ? 0
                      : slotPaid === student
                      ? amountPaid
                      : (student - slotPaid) * 100}
                  </Naira>
                </p>
              </div>
            </div>
            <div className="my-5">
              <div className="grid grid-flow-col grid-cols-2">
                <p className="">Number of students </p>
                <p>{student}</p>
              </div>
              <div className="grid grid-flow-col grid-cols-2">
                <p>Slot paid </p>
                <p>{plan === 'Trial' ? 0 : slotPaid}</p>
              </div>
              <div className="grid grid-flow-col grid-cols-2">
                <p>Slot unpaid </p>
                <p>{plan === 'Trial' ? 0 : student - slotPaid}</p>
              </div>
              <div className="grid grid-flow-col grid-cols-2">
                <p>Status </p>
                <div className="flex">
                  {plan === 'Trial' ? (
                    <p>100%</p>
                  ) : student === 0 ? (
                    'Add students'
                  ) : (
                    <p className="">
                      {((slotPaid / student) * 100).toFixed(0)}%
                    </p>
                  )}

                  {plan !== 'Trial' && slotPaid < student ? (
                    <PaystackHookExample />
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="border-l border-gray-600 h-full w-full px-5">
            SMS Wallet Balance
            <p className="text-black font-semibold text-lg">
              {formatter.format(smsBalance)}
            </p>
            <p className="my-5 text-primary hover:text-red-500">Fund Wallet</p>
            <p>Amount</p>
            <input
              className="border  border-gray-300 outline-none rounded-md p-1.5"
              type="number"
              placeholder="1000"
              value={smsAmount}
              onChange={(e) => handleChange(e)}
            />
            <PaystackHookExampleSMS />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ManageSubscription
