import { Menu, Dialog, Transition } from '@headlessui/react'
import { Fragment, useEffect, useState, useRef } from 'react'
import firebase from 'firebase/app'
import { NotificationManager } from 'react-notifications'
import 'react-notifications/lib/notifications.css'
import { reactLocalStorage } from 'reactjs-localstorage'
import { ExclamationIcon } from '@heroicons/react/outline'

export default function DropMenuUser({
  user,
  users,
  setUsers,
  setUserCount,
  index,
}) {
  const [showDeleteDialog, setshowDeleteDialog] = useState(false)
  const [showEditDialog, setshowEditDialog] = useState(false)
  const cancelButtonRef = useRef()
  const [loading, setLoading] = useState(false)
  const [errorMessage, setError] = useState('')

  const [roleClick, setroleClick] = useState()
  const [infoClick, setinfoClick] = useState(true)
  const [userClick, setUserClick] = useState(false)
  const [roleField, setRoleField] = useState([])

  const [name, setName] = useState(user.name)
  const [email, setEmail] = useState(user.email)
  const [phone, setPhone] = useState(user.phone)
  const [gender, setGender] = useState(user.gender)

  const [people, setPeople] = useState(false)
  const [attendance, setAttendance] = useState(false)
  const [payment, setPayment] = useState(false)
  const [calendar, setCalendar] = useState(false)
  const [assessment, setAssessment] = useState(false)
  const [message, setMessage] = useState(false)
  const [school, setSchool] = useState(false)
  const [subscription, setSubscription] = useState(false)

  const tabDefaultStyle =
    'text-primary md:text-white text-xs md:text-sm uppercase py-4 px-4 md:px-6 block focus:outline-none'
  const tabClickStyle =
    'uppercase text-sm md:text-sm  py-4 px-4 md:px-6 block focus:outline-none text-primary md:text-white  border-b-2 font-medium border-primary md:border-white'
  const saveButtonDefault =
    'px-3 py-1.5  rounded-sm outline-none focus:outline-none text-gray-300  bg-gray-100'
  const saveButtonClick =
    'px-3 py-1.5  rounded-sm outline-none focus:outline-none text-white  bg-primary'

  useEffect(() => {
    setRoles()
  }, [])
  function setRoles() {
    user.roles.map((role) => {
      switch (role) {
        case 'people':
          {
            setPeople(true)
          }
          break
        case 'attendance':
          {
            setAttendance(true)
          }
          break
        case 'assessment':
          {
            setAssessment(true)
          }
          break
        case 'payment':
          {
            setPayment(true)
          }
          break
        case 'calendar':
          {
            setCalendar(true)
          }
          break
        case 'message':
          {
            setMessage(true)
          }
          break
        case 'school':
          {
            setSchool(true)
          }
          break
        case 'subscription':
          {
            setSubscription(true)
          }
          break
      }
    })
  }
  function Role() {
    return (
      <div className="max-h-96 overflow-y-auto mt-5">
        <div className="px-5">
          <div className="grid  grid-cols-3 justify-between py-1 ">
            <div className="flex space-x-2">
              <input
                type="checkbox"
                name="people"
                id="people"
                key="people"
                checked={people}
                value={people}
                onChange={() => setPeople(!people)}
              />
              <label htmlFor="people" className="py-1.5">
                People
              </label>
            </div>

            <div className="flex space-x-2">
              <input
                type="checkbox"
                name="attendance"
                id="attendance"
                key="attendance"
                checked={attendance}
                value={attendance}
                onChange={() => setAttendance(!attendance)}
              />
              <label htmlFor="attendance" className="py-1.5">
                Attendance
              </label>
            </div>
            <div className="flex space-x-2">
              <input
                type="checkbox"
                name="assessment"
                id="assessment"
                key="assessment"
                value={assessment}
                checked={assessment}
                onChange={() => setAssessment(!assessment)}
              />
              <label htmlFor="assessment" className="py-1.5">
                Assessment
              </label>
            </div>
          </div>

          <div className="grid  grid-cols-3 justify-between py-1 ">
            <div className="flex space-x-2">
              <input
                type="checkbox"
                name="payment"
                id="payment"
                key="payment"
                checked={payment}
                value={payment}
                onChange={() => setPayment(!payment)}
              />
              <label htmlFor="payment" className="py-1.5">
                Payment
              </label>
            </div>

            <div className="flex space-x-2">
              <input
                type="checkbox"
                name="calendar"
                id="calendar"
                key="calendar"
                checked={calendar}
                value={calendar}
                onChange={() => setCalendar(!calendar)}
              />
              <label htmlFor="calendar" className="py-1.5">
                Timetable
              </label>
            </div>
            <div className="flex space-x-2">
              <input
                type="checkbox"
                name="message"
                id="message"
                key="message"
                value={message}
                checked={message}
                onChange={() => setMessage(!message)}
              />
              <label htmlFor="message" className="py-1.5">
                Message
              </label>
            </div>
          </div>

          <div className="grid  grid-cols-3 justify-between py-1 ">
            <div className="flex space-x-2">
              <input
                type="checkbox"
                name="school"
                id="school"
                key="school"
                value={school}
                checked={school}
                onChange={() => setSchool(!school)}
              />
              <label htmlFor="school" className="py-1.5">
                School
              </label>
            </div>
            <div className="flex space-x-2">
              <input
                type="checkbox"
                name="subscription"
                id="subscription"
                key="subscription"
                value={subscription}
                checked={subscription}
                onChange={() => setSubscription(!subscription)}
              />
              <label htmlFor="subscription" className="py-1.5">
                Subscription
              </label>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const handleSave = () => {
    if (name !== '' && phone !== '' && email !== '' && gender !== '') {
      save()
    } else {
      NotificationManager.info('Enter the require fields')
    }
  }

  const save = () => {
    setLoading(true)
    setError('')
    setRoleField('')

    var role = []
    if (people) {
      role.push('people')
    }
    if (assessment) {
      role.push('assessment')
    }
    if (attendance) {
      role.push('attendance')
    }
    if (payment) {
      role.push('payment')
    }
    if (calendar) {
      role.push('calendar')
    }
    if (message) {
      role.push('message')
    }
    if (school) {
      role.push('school')
    }
    if (subscription) {
      role.push('subscription')
    }

    setRoleField(role)
    var db = firebase.firestore()
    db.collection('user')
      .doc(user.doc_id)
      .set({
        school: reactLocalStorage.get('current-school'),
        schoolname: reactLocalStorage.get('school-name'),
        name: name,
        phone: phone,
        email: email,
        gender: gender,
        active: true,
        roles: role,
        role: 'school-user',
        state: reactLocalStorage.get('state'),
        photo: reactLocalStorage.get('photo'),
        heading: reactLocalStorage.get('heading'),
        date: firebase.firestore.Timestamp.now(),
      })
      .then((docRef) => {
        let us = [...users]
        var update = us[index]

        update.name = name
        update.phone = phone
        update.email = email
        update.gender = gender
        update.active = true
        update.roles = role

        us[index] = update
        setUsers(us)

        NotificationManager.info('Updated Successful')
      })
      .catch((error) => {
        setError(error.message)
      })
    setLoading(false)
    closeEditModal()
  }

  const handleTabClick = (tab) => {
    switch (tab) {
      case 'info': {
        setinfoClick(true)
        setUserClick(false)
        break
      }
      case 'role': {
        setUserClick(true)
        setinfoClick(false)
        break
      }
    }
  }

  function closeEditModal() {
    setshowEditDialog(false)
    //  setName("");
    //  setPhone("");
    //  setEmail("");
    //  setAddress("");
    //  setGender("");
    //  setinfoClick(false);
    //  setroleClick(false);
    //  setRoleField([{ subject: "select", classvalue: "select" }]);
  }

  function openEditModal() {
    setinfoClick(true)
    setroleClick(false)
    setshowEditDialog(true)
  }

  const handleNext = () => {
    setinfoClick(false)
    setroleClick(true)
  }

  const deleteUser = () => {
    var db = firebase.firestore()
    setError('')
    setLoading(true)
    db.collection('user')
      .doc(user.doc_id)
      .delete()
      .then((querySnapshot) => {
        setUserCount(users.length - 1)
        var data = [...users]
        data.map((t) => {
          if (t.doc_id === user.doc_id) {
            data.pop()
          }
        })
        setUsers(data)
        setLoading(false)
        closeModal()
        NotificationManager.info('Deleted Successful')
      })
      .catch((error) => {
        setLoading(false)
        closeModal()
        setError(error.message)
      })
  }

  function closeModal() {
    setshowDeleteDialog(false)
  }

  function showDialogModal() {
    setshowDeleteDialog(true)
  }

  function EditInactiveIcon(props) {
    return (
      <svg
        {...props}
        width="20"
        height="20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2.5 14.375V17.5h3.125l9.217-9.217-3.125-3.125L2.5 14.375zm14.758-8.508a.83.83 0 000-1.175l-1.95-1.95a.83.83 0 00-1.175 0l-1.525 1.525 3.125 3.125 1.525-1.525z"
          fill="#000"
          fill-opacity=".7"
        />
      </svg>
    )
  }

  function EditActiveIcon(props) {
    return (
      <svg
        {...props}
        width="20"
        height="20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2.5 14.375V17.5h3.125l9.217-9.217-3.125-3.125L2.5 14.375zm14.758-8.508a.83.83 0 000-1.175l-1.95-1.95a.83.83 0 00-1.175 0l-1.525 1.525 3.125 3.125 1.525-1.525z"
          fill="#0064AF"
        />
      </svg>
    )
  }

  function ViewInactiveIcon(props) {
    return (
      <svg
        {...props}
        width="20"
        height="20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15.833 15.833H4.167V4.167H10V2.5H4.167c-.925 0-1.667.75-1.667 1.667v11.666c0 .917.742 1.667 1.667 1.667h11.666c.917 0 1.667-.75 1.667-1.667V10h-1.667v5.833zM11.667 2.5v1.667h2.991l-8.191 8.191 1.175 1.175 8.191-8.191v2.991H17.5V2.5h-5.833z"
          fill="#000"
          fill-opacity=".7"
        />
      </svg>
    )
  }

  function ViewActiveIcon(props) {
    return (
      <svg
        {...props}
        width="20"
        height="20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15.833 15.833H4.167V4.167H10V2.5H4.167c-.925 0-1.667.75-1.667 1.667v11.666c0 .917.742 1.667 1.667 1.667h11.666c.917 0 1.667-.75 1.667-1.667V10h-1.667v5.833zM11.667 2.5v1.667h2.991l-8.191 8.191 1.175 1.175 8.191-8.191v2.991H17.5V2.5h-5.833z"
          fill="#0064AF"
        />
      </svg>
    )
  }
  function DeleteInactiveIcon(props) {
    return (
      <svg
        {...props}
        width="20"
        height="20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5 15.833c0 .917.75 1.667 1.667 1.667h6.666c.917 0 1.667-.75 1.667-1.667v-10H5v10zM6.667 7.5h6.666v8.333H6.667V7.5zm6.25-4.167l-.834-.833H7.917l-.834.833H4.167V5h11.666V3.333h-2.916z"
          fill="#000"
          fill-opacity=".7"
        />
      </svg>
    )
  }

  function DeleteActiveIcon(props) {
    return (
      <svg
        {...props}
        width="20"
        height="20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5 15.833c0 .917.75 1.667 1.667 1.667h6.666c.917 0 1.667-.75 1.667-1.667v-10H5v10zM6.667 7.5h6.666v8.333H6.667V7.5zm6.25-4.167l-.834-.833H7.917l-.834.833H4.167V5h11.666V3.333h-2.916z"
          fill="#0064AF"
        />
      </svg>
    )
  }

  return (
    <div className="text-right ">
      <Menu as="div" className="relative inline-block text-left ">
        {({ open }) => (
          <>
            <div>
              <Menu.Button className="text-gray-500 hover:text-primary outline-none focus:outline-none">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z" />
                </svg>
              </Menu.Button>
            </div>
            <Transition
              show={open}
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items
                static
                className="absolute  right-0 bottom-0  w-48  mt-2 border-account_background border  bg-white divide-y divide-gray-100 rounded-md shadow-lg  focus:outline-none z-50"
              >
                {/* <div className="px-1 py-1">
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        className={`${
                          active
                            ? " text-secondary outline-none focus:outline-none"
                            : "text-gray-900 outline-none "
                        } group flex rounded-md items-center w-full px-2 py-2 text-sm outline-none focus:outline-none`}
                      >
                        {active ? (
                          <ViewActiveIcon
                            className="w-5 h-5 mr-2"
                            aria-hidden="true"
                          />
                        ) : (
                          <ViewInactiveIcon
                            className="w-5 h-5 mr-2"
                            aria-hidden="true"
                          />
                        )}
                        View
                      </button>
                    )}
                  </Menu.Item>
                </div> */}

                <div
                  className="p-1 cursor-pointer "
                  onClick={() => openEditModal()}
                >
                  <Menu.Item as="button">
                    {({ active }) => (
                      <button
                        className={`${
                          active
                            ? ' text-secondary outline-none focus:outline-none'
                            : 'text-gray-900'
                        } group flex rounded-md items-center w-full px-2 py-2 text-sm outline-none focus:outline-none`}
                      >
                        {active ? (
                          <EditActiveIcon
                            className="w-5 h-5 mr-2"
                            aria-hidden="true"
                          />
                        ) : (
                          <EditInactiveIcon
                            className="w-5 h-5 mr-2"
                            aria-hidden="true"
                          />
                        )}
                        Edit
                      </button>
                    )}
                  </Menu.Item>
                </div>

                <div
                  className="p-1 cursor-pointer"
                  onClick={() => showDialogModal()}
                >
                  <Menu.Item as="button">
                    {({ active }) => (
                      <button
                        className={`${
                          active
                            ? ' text-secondary outline-none focus:outline-none'
                            : 'text-gray-900'
                        } group flex rounded-md items-center w-full px-2 py-2 text-sm outline-none focus:outline-none`}
                      >
                        {active ? (
                          <DeleteActiveIcon
                            className="w-5 h-5 mr-2"
                            aria-hidden="true"
                          />
                        ) : (
                          <DeleteInactiveIcon
                            className="w-5 h-5 mr-2"
                            aria-hidden="true"
                          />
                        )}
                        Delete
                      </button>
                    )}
                  </Menu.Item>
                </div>
              </Menu.Items>
            </Transition>
          </>
        )}
      </Menu>

      <Transition show={showDeleteDialog} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-y-auto"
          initialFocus={cancelButtonRef}
          static
          showDialog={showDeleteDialog}
          open={showDeleteDialog}
          onClose={closeModal}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0  ">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block align-bottom bg-white text-left  transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full rounded-md shadow-lg ring-1 ring-black ring-opacity-5 ">
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4 ">
                  <div className="sm:flex sm:items-start ">
                    <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                      <ExclamationIcon
                        className="h-6 w-6 text-red-600"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left select-none">
                      <Dialog.Title
                        as="h3"
                        className="text-lg leading-6 font-medium text-gray-900"
                      >
                        Delete {user.name}
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500 select-none">
                          Are you sure you want to delete {user.name} account?
                          All of his data will be permanently removed. This
                          action cannot be undone.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse ">
                  <button
                    className="w-full  inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={() => deleteUser()}
                  >
                    {loading ? (
                      <i
                        className={'fas fa-circle-notch fa-spin text-base'}
                      ></i>
                    ) : (
                      <p className="2xl:text-lg">Delete</p>
                    )}
                  </button>
                  <button
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none  sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={() => closeModal()}
                    ref={cancelButtonRef}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>

      <Transition show={showEditDialog} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-y-auto"
          initialFocus={cancelButtonRef}
          static
          open={showEditDialog}
          onClose={closeEditModal}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-full max-w-md border border-gray-100  md:border-none text-sm  overflow-hidden text-left align-middle transition-all transform bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 py-3  md:text-white bg-white md:bg-primary"
                >
                  <nav class="flex flex-row justify-center">
                    <button
                      onClick={() => {
                        handleTabClick('info')
                      }}
                      className={infoClick ? tabClickStyle : tabDefaultStyle}
                    >
                      basic Information
                    </button>
                    <button
                      onClick={() => {
                        handleTabClick('role')
                      }}
                      className={userClick ? tabClickStyle : tabDefaultStyle}
                    >
                      Permission pages
                    </button>
                  </nav>
                </Dialog.Title>
                {infoClick ? (
                  <form className="flex flex-col px-5">
                    <label htmlFor="name" className="py-1.5">
                      Name
                    </label>
                    <input
                      className="rounded-sm p-2 focus:outline-none focus:ring-1 bg-account_background "
                      type="text"
                      placeholder="full name"
                      required
                      name="name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />

                    <label htmlFor="phone" className="py-1.5">
                      Phone
                    </label>
                    <input
                      disabled
                      className="rounded-sm p-2 focus:outline-none focus:ring-1 bg-account_background "
                      type="phone"
                      placeholder="phone number"
                      required
                      name="phone"
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                    />
                    <label htmlFor="email" className="py-1.5">
                      Email Address
                    </label>
                    <input
                      disabled
                      className="rounded-sm p-2 focus:outline-none focus:ring-1 bg-account_background "
                      type="email"
                      placeholder="Email address"
                      required
                      name="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <label htmlFor="gender" className="py-1.5">
                      Gender
                    </label>
                    <select
                      className="rounded-sm p-2 focus:outline-none focus:ring-1 bg-account_background"
                      placeholder="gender"
                      required
                      name="gender"
                      value={gender}
                      onChange={(e) => setGender(e.target.value)}
                    >
                      <option value="select" selected>
                        select
                      </option>
                      <option value="male">Male</option>
                      <option value="female">Female</option>
                    </select>
                  </form>
                ) : (
                  <Role />
                )}
                <div className="px-5 py-5 flex items-end justify-end space-x-5">
                  <button
                    onClick={closeEditModal}
                    className="px-3 py-1.5 rounded-sm outline-none focus:outline-none text-gray-500 hover:bg-gray-100"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={() => {
                      // handleSave();
                      infoClick ? handleNext() : handleSave()
                    }}
                    className={
                      name && phone && gender !== 'select' && email
                        ? saveButtonClick
                        : saveButtonDefault
                    }
                  >
                    {loading ? (
                      <i
                        className={'fas fa-circle-notch fa-spin text-base'}
                      ></i>
                    ) : (
                      <p className="2xl:text-lg">
                        {infoClick ? 'Next' : 'Save'}
                      </p>
                    )}
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </div>
  )
}
