import StudentDropMenu from './StudentDropMenu'
import firebase from 'firebase/app'
import { reactLocalStorage } from 'reactjs-localstorage'
import { usePagination } from 'use-pagination-firestore'
import { useEffect, useState } from 'react'
import { NotificationManager } from 'react-notifications'
import CurrencyFormat from 'react-currency-format'
import PayNow from './PayNow'
import Avatar from 'react-avatar'
import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer'
import PdfDocument from './generateInvoice/Invoice'
import InvoiceData from '../jsonData/InvoiceData'
import FeeDiscount from './FeeDiscount'

// import PdfDocument from './components/generateInvoice/Invoice'
// import InvoiceData from './jsonData/InvoiceData'

const UnPaidTable = ({
  classDocument,
  // unPaidCount,
  // setUnPaidCount,
  sessionValue,
  termValue,
  classValue,
  allStudent,
  //paidStudent,
  unpaid,
  amount,
  setUnPaidClick,
  fees,
  selectedFeesIndex,
  loading,
  setLoading,
}) => {
  const [studentList, setStudenList] = useState(unpaid)
  ////const [pageSize, setPageSize] = useState(5)
  // const [currentPage, setCurrentPage] = useState(0)
  const [refresh, setRefresh] = useState()
  // const [studentClass, setStudenClass] = useState()

  // const [studentCount, setTotalRecord] = useState();
  // const { items, isLoading, isStart, isEnd, getPrev, getNext } = usePagination(
  //   firebase
  //     .firestore()
  //     .collection('students')
  //     .where('school', '==', reactLocalStorage.get('current-school'))
  //     .orderBy('name'),
  //   {
  //     limit: pageSize,
  //   }
  // )

  useEffect(() => {
    //getAllStudent()
  }, [])

  // const gotoNext = () => {
  //   getNext()
  //   setCurrentPage(currentPage + 1)
  // }

  // const gotoPrevious = () => {
  //   getPrev()
  //   setCurrentPage(currentPage - 1)
  // }

  // if (loading) {
  //   return (
  //     <div className="grid justify-center items-center">
  //       <i className={'text-primary fas fa-circle-notch fa-spin text-5xl'}></i>
  //     </div>
  //   )
  // }

  return (
    <div className="">
      <div className="flex flex-col max-h-96  md:max-h-82 xl_1:max-h-120 2xl:max-h-130 overflow-y-auto   shadow-md border-b border-gray-200 rounded-sm  ">
        <div className="flex-grow  ">
          <table className="relative w-full min-w-full   divide-y divide-gray-200 ">
            <thead className="">
              <tr className=" ">
                <th className="sticky bg-gray-50 z-30 md:z-20 left-0  top-0  px-4  py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Student Name
                </th>
                <th className="sticky bg-gray-50 z-20 top-0 px-4 text-right   py-3  text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Fee (₦)
                </th>
                <th className="sticky bg-gray-50 z-20 top-0 px-4 text-right   py-3  text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Discount (₦)
                </th>
                <th className="sticky bg-gray-50 z-20 top-0 px-4 text-right   py-3  text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Total (₦)
                </th>
                <th className="sticky bg-gray-50 z-20 top-0 px-4   py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  {/* Amount Paid */}
                </th>
                {/* <th className="sticky bg-gray-50 z-20  top-0 px-4  py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Balance
                </th> */}
                <th className="sticky bg-gray-50 z-20 top-0 px-4 py-3">
                  <span className="sr-only">Edit</span>
                </th>
                <th className="sticky bg-gray-50 z-20 top-0 px-4 py-3">
                  <span className="sr-only">Edit</span>
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200 ">
              {studentList.map((student, index) => (
                <tr key={student.studentid}>
                  <td
                    key={1}
                    className="sticky left-0  bg-white px-4 py-1 whitespace-nowrap "
                  >
                    <div className="flex items-center">
                      <div className="flex-shrink-0 h-9 w-9">
                        {student.photo ? (
                          <img
                            key={student.studentid}
                            className="h-10 w-10 rounded-full"
                            src={student.photo}
                            alt=""
                          />
                        ) : (
                          <Avatar
                            name={student.name}
                            size="32"
                            textSizeRatio={1}
                            round={true}
                          />
                        )}
                      </div>
                      <div className="ml-4">
                        <div className="text-sm capitalize text-gray-900">
                          {student.name.toUpperCase()}
                        </div>
                      </div>
                    </div>
                  </td>

                  <td key={2} className="px-4 py-1 whitespace-nowrap">
                    <div className="text-sm uppercase text-gray-900 text-right">
                      {/* {student.gender} */}

                      <CurrencyFormat
                        value={amount}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={''}
                      />
                    </div>
                  </td>
                  <td key={3} className="px-4 py-1 whitespace-nowrap">
                    <div className="text-sm uppercase text-gray-900 text-right">
                      {/* {student.gender} */}

                      <CurrencyFormat
                        value={
                          student.discount
                            ? (student.discount / 100) * amount
                            : 0
                        }
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={''}
                      />
                    </div>
                  </td>
                  <td key={4} className="px-4 py-1 whitespace-nowrap">
                    <div className="text-sm uppercase text-gray-900 text-right">
                      {/* {student.gender} */}

                      <CurrencyFormat
                        value={
                          student.discount
                            ? amount - (student.discount / 100) * amount
                            : amount
                        }
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={''}
                      />
                    </div>
                  </td>

                  <td key={5} className="px-4 py-1 whitespace-nowrap">
                    <div className="mx-4 md:mx-0 text-primary bg-white text-sm ">
                      {!studentList[index].status ||
                      studentList[index].status === 'false' ? (
                        <PayNow
                          amount={amount}
                          session={sessionValue}
                          term={termValue}
                          student={student}
                          index={index}
                          studentList={studentList}
                          setUnPaidClick={setUnPaidClick}
                          setRefresh={setRefresh}
                          fees={fees}
                          selectedFeesIndex={selectedFeesIndex}

                          // studentClass={classValue}
                          //setStatus={setStatus}
                        />
                      ) : (
                        <div className="download-link">
                          {/* <PDFDownloadLink
                            document={<PdfDocument invoicedata={InvoiceData} />}
                            fileName={student.name
                              .concat('_')
                              .concat(student.class)
                              .replace(' ', '_')
                              .toLowerCase()
                              .concat('.pdf')}
                            setRefresh={setRefresh}
                          >
                            {({ blob, url, loading, error }) =>
                              loading ? 'Loading...' : 'Download Receipt'
                            }
                          </PDFDownloadLink> */}
                        </div>
                      )}
                    </div>
                  </td>

                  <td key={6}>
                    <FeeDiscount
                      amount={amount}
                      session={sessionValue}
                      term={termValue}
                      student={student}
                      index={index}
                      studentList={studentList}
                      setStudenList={setStudenList}
                      setUnPaidClick={setUnPaidClick}
                      setRefresh={setRefresh}
                      fees={fees}
                      selectedFeesIndex={selectedFeesIndex}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* PAGINATION */}
      {/* <div className="">
        <div className="grid justify-items-end  ">
          <div className="flex text-gray-400 py-5 px-4 md:px-0  space-x-4 md:space-x-8">
            <div className="flex">
              <p className="text-sm text-gray-600">Rows per page:</p>
              {items.length > 0 && studentCount > pageSize ? (
                <select
                  onChange={(e) => setPageSize(e.target.value)}
                  value={pageSize}
                  className="text-gray-600 bg-white text-sm text-center items-center justify-center px-4 rounded-sm f outline-none focus:outline-none"
                >
                  <option value="5">5</option>
                  <option value="10">10</option>
                  <option value="15">15</option>
                  <option value="20">20</option>
                </select>
              ) : (
                <select
                  onChange={(e) => setPageSize(e.target.value)}
                  value={pageSize}
                  disabled
                  className="text-gray-600 bg-white text-sm text-center items-center justify-center px-4 rounded-sm f outline-none focus:outline-none"
                >
                  <option value="5">5</option>
                  <option value="10">10</option>
                  <option value="15">15</option>
                  <option value="20">20</option>
                </select>
              )}
            </div>
            <div className="text-sm text-gray-600">
              <span>{parseInt(currentPage) * parseInt(pageSize) + 1} </span>
              <span> - </span>
              <span>
                {studentCount > pageSize &&
                  parseInt(currentPage) * parseInt(pageSize) +
                    parseInt(pageSize)}
                {studentCount <= pageSize && studentCount}
              </span>
              <span> of </span>
              <span>{studentCount}</span>
            </div>

            {items.length > 0 && currentPage > 0 && (
              <button
                onClick={gotoPrevious}
                className="outline-none focus:outline-none"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-5 w-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="#000000"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M15 19l-7-7 7-7"
                  />
                </svg>
              </button>
            )}
            {currentPage <= 0 && (
              <button className="outline-none focus:outline-none">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-5 w-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M15 19l-7-7 7-7"
                  />
                </svg>
              </button>
            )}

            {!isEnd && (
              <button
                onClick={gotoNext}
                className="outline-none focus:outline-none"
              >
                {' '}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-5 w-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="#000000"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M9 5l7 7-7 7"
                  />
                </svg>
              </button>
            )}
            {isEnd && (
              <button className="outline-none focus:outline-none">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-5 w-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M9 5l7 7-7 7"
                  />
                </svg>
              </button>
            )}
          </div>
        </div>
      </div> */}
    </div>
  )
}
export default UnPaidTable
