import firebase from 'firebase/app'
import { reactLocalStorage } from 'reactjs-localstorage'
import { useState, useEffect } from 'react'
import WebManual from './WebManual'
import MobileManual from './MobileManual'

const ManageManual = ({
  web,
  mobile,
  setSubTitle,
}) => {
  const [webClick, setWebClick] = useState(web)
  const [mobileClick, setMobileClick] = useState(mobile)
  const tabDefaultStyle =
    'text-xs md:text-sm  text-gray-600 uppercase py-4 px-4 md:px-6 block text-blue-500 focus:outline-none'
  const tabClickStyle =
    'uppercase text-sm   py-4 px-4 md:px-6 block hover:text-blue-500 focus:outline-none text-blue-500 border-b-2 font-medium border-blue-500'

  const [loading, setLoading] = useState(false)

  const handleTabClick = (tab) => {
    switch (tab) {
      case 'web': {
        setWebClick(true)
        setMobileClick(false)
        setSubTitle('Web Platform')
        break
      }
      
      default: {
        setMobileClick(true)
        setWebClick(false)
        setSubTitle('Mobile Platform')
      }
    }
  }

  useEffect(() => {
    //SET DEFAULT CLICK
    setWebClick(true)
    setMobileClick(false)
    setSubTitle('Web Platform')
  }, [])

  return (
    <div className="">
      <div className="">
        <div className="m:0 md:mx-12 md:my-5 ">
          <div class="sticky top-0 z-30 bg-gray-50">
            <nav class="flex flex-row">
           

              <button
                onClick={() => {
                  handleTabClick('web')
                }}
                className={webClick ? tabClickStyle : tabDefaultStyle}
              >
                Web platform
              </button>

              <button
                onClick={() => {
                  handleTabClick('mobile')
                }}
                className={mobileClick ? tabClickStyle : tabDefaultStyle}
              >
                Mobile platform
              </button>
            </nav>
          </div>

          <div className="my-5 max-h-115  md:max-h-115 xl_1:max-h-130 2xl:max-h-130 overflow-y-auto ">
            {webClick ? (
              <WebManual loading={loading} />
            ) : (
              <MobileManual loading={loading} />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ManageManual
