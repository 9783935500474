import AuthenticationSidenav from '../components/AuthenticationSidenav'
import { useEffect, useState } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import ShowDrawer from '../components/ShowDrawer'
//import firebase from 'firebase/app'
import { auth } from '../firebase'
import PasswordReset from '../pages/PasswordReset'

const EmailConfirmation = ({ mode, oobCode, continueUrl }) => {
  const [isOpen, setIsOpen] = useState(false)
  const closeClasses =
    'w-64 lg:w-72 2xl:w-96 absolute md:relative md:translate-x-0 inset-y-0 left-0 transform -translate-x-full transition duration-200 ease-in-out'
  const openClasses =
    'w-64 lg:w-72 2xl:w-96 absolute  md:relative md:translate-x-0 inset-y-0 left-0 transform transition duration-200 ease-in-out'

  const [codeValid, setCodeValid] = useState(false)

  const histrory = useHistory()
  const handleClick = () => {
    !isOpen ? setIsOpen(true) : setIsOpen(false)
  }

  function useQuery() {
    return new URLSearchParams(useLocation().search)
  }

  let query = useQuery()

  useEffect(() => {
    handleVerifyEmail(query.get('oobCode'), query.get('continueUrl'))
  }, [query])

  function handleVerifyEmail(actionCode, continueUrl) {
    console.log('actionCode:: ', query.get('oobCode'))

    auth
      .verifyPasswordResetCode(actionCode)
      .then(function (email) {
        setCodeValid(true)
        // Display a "new password" form with the user's email address
      })
      .catch(function (e) {
        // Invalid code
        console.log('Errrorororo: ', e)
      })

    // Localize the UI to the selected language as determined by the lang
    // parameter.
    // Try to apply the email verification code.
    auth
      .applyActionCode(actionCode)
      .then((resp) => {
        // Email address has been verified.
        // TODO: Display a confirmation message to the user.
        // You could also provide the user with a link back to the app.
        // TODO: If a continue URL is available, display a button which on
        // click redirects the user back to the app via continueUrl with
        // additional state determined from that URL's parameters.
      })
      .catch((error) => {
        // Code is invalid or expired. Ask the user to verify their email address
        // again.
      })
  }

  function handleNext() {
    histrory.push('/login')
  }

  return codeValid && query.get('mode') === 'resetPassword' ? (
    <PasswordReset code={query.get('oobCode')} />
  ) : codeValid && query.get('mode') === 'verifyEmail' ? (
    <div className="md:flex bg-white  md:bg-account_background   ">
      <ShowDrawer handleClick={handleClick} isOpen={isOpen} />

      <div className={!isOpen ? closeClasses : openClasses}>
        <AuthenticationSidenav type="authentication" />
      </div>

      <div className=" md:flex-1  ">
        <div className="grid px-5">
          <div className="grid lg:grid-cols-5 md:justify-center  md:place-content-center md:h-screen ">
            <div className=""></div>
            <div className="lg:col-span-3">
              <div className="md:bg-white  md:p-5 lg:p-10 2xl:p-16 rounded-sm  xl:mx-10 2xl:mx-20  3xl:mx-36  md:shadow-sm">
                <p className="text-xl 2xl:text-3xl text-gray-900 font-medium mb-3 p-5 md:py-10 md:pb-5 md:pt-0 ">
                  Email Verified
                </p>
                <form className="grid grid-flow-row  bg-account_background p-5  rounded-sm ">
                  <div className="grid justify-center items-center mb-5">
                    <svg
                      width="60"
                      height="60"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle
                        cx="30"
                        cy="30"
                        r="29"
                        stroke="#27C358"
                        strokeWidth="2"
                      />
                      <path
                        d="M26.25 35.25L21 30l-1.75 1.75 7 7 15-15L39.5 22 26.25 35.25z"
                        fill="#27C358"
                      />
                    </svg>
                  </div>
                  <div className="flex flex-col">
                    <p className="font-semibold text-center">Thank you</p>
                    <p className="text-center">You have verified your email</p>
                    <p>{mode}</p>
                    <p>{oobCode}</p>
                    <p>{continueUrl}</p>
                    {/* <ButtonPrimary title='Resent email' loading={loading} /> */}

                    <button
                      onClick={handleNext}
                      className="bg-primary text-white flex justify-center py-2 px-5 mt-6 rounded-md focus:outline-none 2xl:py-3 2xl:mt-10"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-6 w-6 xl:h-6  2xl:h-8"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M9 5l7 7-7 7"
                        />
                      </svg>

                      <p className="2xl:text-lg"> Continue</p>
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <div className=""></div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    ''
  )
}

export default EmailConfirmation
