import DropMenu from './DropMenu'
import firebase from 'firebase/app'
import { reactLocalStorage } from 'reactjs-localstorage'
import { usePagination } from 'use-pagination-firestore'
import { useEffect, useState } from 'react'
import { NotificationManager } from 'react-notifications'
import Avatar from 'react-avatar'
import DeactivateTeacher from './DeactivateTeacher'
import ActivateTeacher from './ActivateTeacher '

const TeacherTable = ({
  teacherCount,
  setTeacherCount,
  document,
  classDocument,
  teachers,
  filter,
  setFilter,
  notEmptyFilter,
  setNotEmptyFilter,
  setTeachers,
  loading,
  setLoading,
}) => {

  // const [pageSize, setPageSize] = useState(5)
  // const [currentPage, setCurrentPage] = useState(0)
  // const { items, isLoading, isStart, isEnd, getPrev, getNext } = usePagination(
  //   firebase
  //     .firestore()
  //     .collection('teachers')
  //     .where('school', '==', reactLocalStorage.get('current-school'))
  //     .orderBy('name'),
  //   {
  //     limit: pageSize,
  //   }
  // )

  // const gotoNext = () => {
  //   getNext()
  //   setCurrentPage(currentPage + 1)
  // }

  // const gotoPrevious = () => {
  //   getPrev()
  //   setCurrentPage(currentPage - 1)
  // }

  const showNotification = (type, title, message) => {
    switch (type) {
      case 'info':
        NotificationManager.info(message)
        break
      case 'success':
        NotificationManager.success(message, title)
        break
      case 'warning':
        NotificationManager.warning(message, title, 3000)
        break
      case 'error':
        NotificationManager.error(message, title, 5000, () => {
          alert('callback')
        })
        break
    }
  }

  if (loading) {
    return (
      <div className="grid justify-center items-center">
        <i className={' text-primary fas fa-circle-notch fa-spin text-5xl'}></i>
      </div>
    )
  }

  return (
    <div className="">
      <div className="flex flex-col max-h-96  md:max-h-82 xl_1:max-h-120 2xl:max-h-130 overflow-y-auto   shadow-md border-b border-gray-200 rounded-sm  ">
        <div className="flex-grow  ">
          <table className="relative w-full min-w-full   divide-y divide-gray-200 ">
            <thead key="theadteacher" className="">
              <tr className=" ">
                <th className="sticky bg-gray-50 z-30 md:z-20 left-0  top-0  px-4  py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Name
                </th>
                <th className="sticky bg-gray-50 z-20 top-0 px-4   py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  contact
                </th>
                <th className="sticky bg-gray-50 z-20 top-0 px-4   py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Subject taught
                </th>
                <th className="sticky bg-gray-50 z-20  top-0 px-4  py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Status
                </th>
                <th className="sticky bg-gray-50 z-20 top-0 px-4 py-3">
                  <span className="sr-only">Edit</span>
                </th>
              </tr>
            </thead>

            <tbody className="bg-white divide-y divide-gray-200 ">
              {notEmptyFilter
                ? filter.map((teacher, index) => (
                    <tr key={index + 10}>
                      <td
                        key={teacher.id}
                        className="sticky left-0  bg-white px-4 py-2 whitespace-nowrap"
                      >
                        <div className="flex items-center">
                          <div className="flex-shrink-0 h-10 w-10">
                            {teacher.image ? (
                              <img
                                className="h-10 w-10 rounded-full"
                                src={teacher.image}
                                alt=""
                              />
                            ) : (
                              <Avatar
                                name={teacher.name}
                                size="32"
                                textSizeRatio={1}
                                round={true}
                              />
                            )}
                          </div>
                          <div className="ml-4">
                            <div className="text-sm font-medium capitalize text-gray-900">
                              {teacher.name}
                            </div>
                            <div className="text-sm capitalize text-gray-500">
                              {teacher.gender}
                            </div>
                          </div>
                        </div>
                      </td>
                      <td
                        key={index + 1}
                        className="px-4 py-2 whitespace-nowrap"
                      >
                        <div className="text-sm text-gray-900">
                          {teacher.email}
                        </div>
                        <div className="text-sm text-gray-500">
                          {teacher.phone}
                        </div>
                      </td>

                      <td
                        key={index + 2}
                        className="px-4 py-2 whitespace-nowrap"
                      >
                        <div className="text-sm capitalize text-gray-900">
                          {/* {person.subject} */}
                          {teacher.roles.map(
                            (role, index) => role.subject + ', '
                          )}
                        </div>
                        <div className="text-sm capitalize text-gray-500">
                          {teacher.roles.map(
                            (role, index) => role.classvalue + ', '
                          )}
                        </div>
                      </td>
                      <td
                        key={index + 3}
                        className="px-4 py-2 whitespace-nowrap"
                      >
                        {teacher.active ? (
                          <DeactivateTeacher
                            teacher={teacher}
                            teachers={teachers}
                            setTeachers={setTeachers}
                            index={index}
                          />
                        ) : (
                          <ActivateTeacher
                            teacher={teacher}
                            teachers={teachers}
                            setTeachers={setTeachers}
                            index={index}
                          />
                        )}
                      </td>

                      <td
                        key={index + 4}
                        className="px-2  whitespace-nowrap text-right text-sm font-medium z-30"
                      >
                        <DropMenu
                          teacher={teacher}
                          teacherCount={teacherCount}
                          setTeacherCount={setTeacherCount}
                          document={document}
                          classDocument={classDocument}
                         
                        />
                      </td>
                    </tr>
                  ))
                : teachers.map((teacher, index) => (
                    <tr key={index + 10}>
                      <td
                        key={index}
                        className="sticky left-0  bg-white px-4 py-2 whitespace-nowrap"
                      >
                        <div className="flex items-center">
                          <div className="flex-shrink-0 h-10 w-10">
                            {teacher.image ? (
                              <img
                                className="h-10 w-10 rounded-full"
                                src={teacher.image}
                                alt=""
                              />
                            ) : (
                              <Avatar
                                name={teacher.name}
                                size="32"
                                textSizeRatio={1}
                                round={true}
                              />
                            )}
                          </div>
                          <div className="ml-4">
                            <div className="text-sm font-medium capitalize text-gray-900">
                              {teacher.name}
                            </div>
                            <div className="text-sm capitalize text-gray-500">
                              {teacher.gender}
                            </div>
                          </div>
                        </div>
                      </td>
                      <td
                        key={index + 1}
                        className="px-4 py-2 whitespace-nowrap"
                      >
                        <div className="text-sm text-gray-900">
                          {teacher.email}
                        </div>
                        <div className="text-sm text-gray-500">
                          {teacher.phone}
                        </div>
                      </td>

                      <td
                        key={index + 2}
                        className="px-4 py-2 whitespace-nowrap"
                      >
                        <div className="text-sm capitalize text-gray-900">
                          {/* {person.subject} */}
                          {teacher.roles.map(
                            (role, index) => role.subject + ', '
                          )}
                        </div>
                        <div className="text-sm capitalize text-gray-500">
                          {teacher.roles.map(
                            (role, index) => role.classvalue + ', '
                          )}
                        </div>
                      </td>
                      <td
                        key={index + 3}
                        className="px-4 py-2 whitespace-nowrap"
                      >
                        {teacher.active ? (
                          <DeactivateTeacher
                            teacher={teacher}
                            teachers={teachers}
                            setTeachers={setTeachers}
                            index={index}
                          />
                        ) : (
                          <ActivateTeacher
                            teacher={teacher}
                            teachers={teachers}
                            setTeachers={setTeachers}
                            index={index}
                          />
                        )}
                      </td>

                      <td
                        key={index + 4}
                        className="px-2  whitespace-nowrap text-right text-sm font-medium z-30"
                      >
                        <DropMenu
                          index={index}
                          teacher={teacher}
                          teachers={teachers}
                          setTeachers={setTeachers}
                          teacherCount={teacherCount}
                          setTeacherCount={setTeacherCount}
                          document={document}
                          classDocument={classDocument}
                        />
                      </td>
                    </tr>
                  ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* PAGINATION
      <div className="">
        <div className="grid justify-items-end  ">
          <div className="flex text-gray-400 py-5 px-4 md:px-0  space-x-4 md:space-x-8">
            <div className="flex">
              <p className="text-sm text-gray-600">Rows per page:</p>
              {items.length > 0 && teacherCount > pageSize ? (
                <select
                  onChange={(e) => setPageSize(e.target.value)}
                  value={pageSize}
                  className="text-gray-600 bg-white text-sm text-center items-center justify-center px-4 rounded-sm f outline-none focus:outline-none"
                >
                  <option value="5">5</option>
                  <option value="10">10</option>
                  <option value="15">15</option>
                  <option value="20">20</option>
                </select>
              ) : (
                <select
                  onChange={(e) => setPageSize(e.target.value)}
                  value={pageSize}
                  disabled
                  className="text-gray-600 bg-white text-sm text-center items-center justify-center px-4 rounded-sm f outline-none focus:outline-none"
                >
                  <option value="5">5</option>
                  <option value="10">10</option>
                  <option value="15">15</option>
                  <option value="20">20</option>
                </select>
              )}
            </div>
            <div className="text-sm text-gray-600">
              <span>{parseInt(currentPage) * parseInt(pageSize) + 1} </span>
              <span> - </span>
              <span>
                {teacherCount > pageSize &&
                  parseInt(currentPage) * parseInt(pageSize) +
                    parseInt(pageSize)}
                {teacherCount <= pageSize && teacherCount}
              </span>
              <span> of </span>
              <span>{teacherCount}</span>
            </div>

            {items.length > 0 && currentPage > 0 && (
              <button
                onClick={gotoPrevious}
                className="outline-none focus:outline-none"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-5 w-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="#000000"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M15 19l-7-7 7-7"
                  />
                </svg>
              </button>
            )}
            {currentPage <= 0 && (
              <button className="outline-none focus:outline-none">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-5 w-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M15 19l-7-7 7-7"
                  />
                </svg>
              </button>
            )}

            {!isEnd && (
              <button
                onClick={gotoNext}
                className="outline-none focus:outline-none"
              >
                {' '}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-5 w-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="#000000"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M9 5l7 7-7 7"
                  />
                </svg>
              </button>
            )}
            {isEnd && (
              <button className="outline-none focus:outline-none">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-5 w-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M9 5l7 7-7 7"
                  />
                </svg>
              </button>
            )}
          </div>
        </div>
      </div> */}
    </div>
  )
}
export default TeacherTable
