import { useState } from 'react'
import firebase from 'firebase/app'
import { reactLocalStorage } from 'reactjs-localstorage'
import { useEffect } from 'react'
import { NotificationManager } from 'react-notifications'
import 'react-notifications/lib/notifications.css'
import { renderToStaticMarkup } from 'react-dom/server'
import ReactHtmlParser from 'react-html-parser'
import QRCode from 'qrcode.react'
import { useLocation, useHistory, useParams } from 'react-router-dom'
import { useAuth } from '../services/AuthContext'
import { auth } from '../firebase'
import regular from '../assets/font/regular.ttf'
import bold from '../assets/font/bold.ttf'

import {
  Table,
  TableHeader,
  TableBody,
  DataTableCell,
  TableCell,
} from '@david.kucsai/react-pdf-table'

import {
  PDFDownloadLink,
  PDFViewer,
  Document,
  Page,
  Svg,
  Path,
  Text,
  View,
  StyleSheet,
  Image,
  Font,
} from '@react-pdf/renderer'
Font.register({
  family: 'Ubuntu',
  fonts: [
    {
      src: regular,
    },
    {
      src: bold,
      fontWeight: 'bold',
    },
    {
      src: 'https://fonts.gstatic.com/s/questrial/v13/QdVUSTchPBm7nuUeVf7EuStkm20oJA.ttf',
      fontWeight: 'normal',
      fontStyle: 'italic',
    },
  ],
})

const VerifyReport = () => {
  const [paidCount, setPaidCount] = useState()
  const [unPaidCount, setUnPaidCount] = useState()
  const [classValue, setClassValue] = useState()
  const [sessionValue, setSessionValue] = useState()
  const [termValue, setTermValue] = useState()
  const [classDocument, setClassDocument] = useState([])
  const [allStudent, setAllStudent] = useState([])
  const [paidStudent, setPaidStudent] = useState([])
  const [unPaidStudent, setUnPaidStudent] = useState([])
  const [loading, setLoading] = useState(false)
  const [loadingBroad, setLoadingBroad] = useState(false)
  const [amount, setAmount] = useState()
  const [fees, setFees] = useState()
  const [subject, setSubject] = useState([])
  const [subjectValue, setSubjectValue] = useState('select')
  const [selectedFeesIndex, setSelectedFeesIndex] = useState()
  const [isNewRecord, setIsNewRecord] = useState(false)
  const [assessment, setAssessment] = useState([])
  const [showDownload, setShowDownload] = useState(false)
  const [showDownloadBroad, setShowDownloadBroad] = useState(false)

  const [data, setData] = useState([])
  const [student, setStudent] = useState([])
  const [studentPos, setStudentPos] = useState([])
  const [termEnd, setTermEnd] = useState()
  const [nextTerm, setNextTerm] = useState()
  const [showPosition, setShowPosition] = useState(true)
  const [timesOpen, setTimesOpen] = useState()
  const [present, setPresent] = useState([])
  const [Nextfees, setNextFees] = useState()
  const [show, setShow] = useState()
  const [tableColour, setTableColour] = useState('#D3ECE6')

  const { signIn } = useAuth()

  const tabDefaultStyle =
    'text-white text-xs md:text-sm  md:text-gray-600 uppercase py-4 px-4 md:px-6 block hover:text-blue-500 focus:outline-none'
  const tabClickStyle =
    'uppercase text-sm md:text-sm  py-4 px-4 md:px-6 block md:hover:text-blue-500 focus:outline-none text-white md:text-blue-500 border-b-2 font-medium md:border-blue-500'
  const defaultStyle = 'px-3 py-1.5 bg-gray-50 select-none cursor-default'
  const clickStyle = 'px-3 py-1.5 bg-account_click select-none cursor-default'

  let success = [
    'He/she has shown outstanding progress in grade. Keep up the great work.',
    'He/she has been consistent, has exceeded expectations, and created a mark for himself/herself. It has been a pleasure teaching him/her.',
    'Amazed at the improvement and growth. Way to go.',
    'Have to appreciate his/her quality work and motivation to do better every day. Thoroughly enjoyed being his/her teacher.',
    'Teaching him/her was an absolute pleasure. He/she is focused, driven, and enthusiastic.',
    'I don’t have fingers to count his/her victories. Passionate, helpful, and super creative.',
    'Happy to see that he/she sets high standards for himself/herself and achieves them.',
    'Well deserved grades. Happy to see his/her success.',
    'Super creative, talented, and helpful. A perfect student!',
    'He/she has shown marked improvement in terms of grades and skills. Truly commendable.',
  ]

  let improvement = [
    'There’s scope for improvement. You can do better.',
    'More focus is required. Have the potential to grow',
    'I would say he/she is using only half of his/her potential',
    'Great things are waiting for you. You will reach them if you put in a little more effort.',
    'I would like to see him/her ask for help when he/she is struggling to understand the concepts or the work assigned.',
    'It has been noticed that he/she often struggles to remember lessons that have been covered in class and it would be great if you put some extra practice at home.',
    'I would like to see him/her apply consistent effort to his/her work.',
    'With more focus and attention, you can do great things.',
    'Is not using his/her full capacity. A little nudge is necessary.',
    'I would like to see him/her to be an active participant in his/her learning.',
  ]

  let assistance = [
    'I would like to see him/her ask for help when he/she is struggling to understand the concepts or the work assigned.',
    'Is determined to do things on own. However, it would be beneficial if he/she gets some extra help at home.',
    'Don’t be afraid to ask for assistance when required.',
    'A little extra effort is expected from a student with such potential.',
    'Spend more time reading the concepts at home.',
    'He/she is not yet able to work in small groups as he/she struggles to focus and engage with his/her classmates.',
    'A little extra push is needed.',
    'A good kid. Needs assistance with teamwork and confidence.',
    'Great efforts, keep asking for assistance.',
    'Would benefit from slowing down and reading the set questions carefully.',
  ]
  const publicHolidays = [
    '2023-01-02',
    '2023-04-07',
    '2023-04-10',
    '2023-04-21',
    '2023-05-01',
    '2023-05-29',
    '2023-06-12',
    '2023-06-28',
    '2023-06-29',
    '2023-09-27',
    '2023-10-02',
    '2023-12-25',
    '2023-12-26',
  ]

  //const passStudent = query.get('passStudent')

  // NO CHANGE
  const { pstu } = useParams()
  const [pstudent, setPstudent] = useState(pstu)

  useEffect(() => {
    if (
      pstudent !== null &&
      pstudent !== undefined &&
      pstudent !== 'undefined'
    ) {
      // searchRecord()
      handleSubmit()
    }
    //clearPref()
  }, [])

  async function handleSubmit() {
    try {
      setLoading(true)
      await signIn('m1@edusaf.com', '12345678')
      var user = auth.currentUser
      searchRecord()
    } catch (error) {
      setLoading(false)
    }
  }

  async function handleSignOut() {
    setLoading(true)

    firebase
      .auth()
      .signOut()
      .then(() => {
        // Sign-out successful.
        reactLocalStorage.clear()
        setLoading(false)
      })
      .catch((error) => {
        // An error happened.
        setLoading(false)
      })
  }

  let assessmentValues = []

  const searchRecord = (e) => {
    //e.preventDefault()
    if (pstudent !== null && pstudent !== '') {
      setLoading(true)
      getSchoolFirst(pstudent)
    }
  }

  const getSchoolFirst = (student) => {
    setLoading(true)
    var db = firebase.firestore()
    var docRef = db.collection('students').doc(student)
    docRef
      .get()
      .then((doc) => {
        if (doc.exists) {
          reactLocalStorage.set('school-search', doc.data().school)
          reactLocalStorage.set('class-search', doc.data().class)
          reactLocalStorage.set('sname-search', doc.data().name)
          reactLocalStorage.set('sstudentid-search', doc.data().studentid)
          reactLocalStorage.set('school-class-search', doc.data().school_class)

          getSchoolDetails(doc.data().school)
          setClassValue(doc.data().class)
          getSubjects(student)
        } else {
          // doc.data() will be undefined in this case
          setShow(false)
          NotificationManager.info('Record not found')
          setLoading(false)
        }
      })
      .catch((error) => {
        console.log('Error getting document:', error)
        setLoading(false)
      })
  }

  function getSchoolDetails(school) {
    setLoading(true)
    var db = firebase.firestore()
    var query = db.collection('persons').where('school', '==', school)
    query.get().then(function (querySnapshot) {
      if (querySnapshot.size > 0) {
        querySnapshot.forEach(function (doc) {
          var data = doc.data()
          // reactLocalStorage.set('school-search', data.school)
          reactLocalStorage.set('sch-name-search', data.schoolname)
          reactLocalStorage.set('head-search', data.heading)
          //histrory.replace('/landing')
          getTerm(data.school)
        })
      } else {
        //clearPref()
        setShow(false)
        NotificationManager.info('Record not found')
        setLoading(false)
      }
    })
  }
  const getTerm = (school) => {
    setLoading(true)
    var db = firebase.firestore()
    var query = db.collection('term')
    query
      .doc(school)
      .get()
      .then((doc) => {
        if (doc.exists) {
          setTermValue(doc.data().term)
          setSessionValue(doc.data().session)

          setTermEnd(doc.data().end)
          setNextTerm(doc.data().next)
          setShowPosition(doc.data().position)

          console.log('D:: ', doc.data().position)

          // var date1 = new Date(doc.data().start)
          // var date2 = new Date(doc.data().end)
          // var difference_In_Time = date2.getTime() - date1.getTime()
          // // To calculate the no. of days between two dates
          // var difference_In_Days = difference_In_Time / (1000 * 3600 * 24)
          // setTimesOpen(difference_In_Days)

          ///
          var difference_In_Days = getBusinessDaysDiff(
            doc.data().start,
            doc.data().end,
            publicHolidays
          )
          setTimesOpen(difference_In_Days)

          reactLocalStorage.set('end-search', doc.data().end)
          reactLocalStorage.set('next-search', doc.data().next)
          reactLocalStorage.set('position-search', doc.data().position)
          reactLocalStorage.set('open-search', difference_In_Days)
          reactLocalStorage.set('ses-search', doc.data().session)
          reactLocalStorage.set('ter-search', doc.data().term)
        }
        setLoading(false)
      })
      .catch((error) => {
        console.log('Error getting document:', error)
        setLoading(false)
      })
  }
  const getStudents = () => {
    setLoading(true)
    var db = firebase.firestore()
    db.collection('students')
      .where(
        'school_class',
        '==',
        reactLocalStorage
          .get('school-search')
          .concat(reactLocalStorage.get('class-search'))
      )
      .orderBy('studentid', 'asc')
      .get()
      .then((querySnapshot) => {
        var values = []
        querySnapshot.forEach((doc) => {
          //doc.data() is never undefined for query doc snapshots
          var dd = doc.data()
          dd.doc_id = doc.id
          values.push(checkAssessment(dd))
        })
        setAllStudent(values)
        setLoading(false)
      })
      .catch((error) => {
        console.log('Error getting documents: ', error)
        setLoading(false)
      })
  }

  // UPDATED FUNCTION 7/12/2023
  function checkPresent(id) {
    const student = allStudent.find((s) => s.doc_id === id)

    return student ? student.present || 0 : 0
  }

  const getSubjects = (pstudent) => {
    const currentSchoolName = reactLocalStorage.get('school-name')
    const alImanCollegeJos = 'Al-iman College Jos'
    setLoading(true)
    getStudents()

    var db = firebase.firestore()
    db.collection('assessment')
      .where(
        'sstc',
        '==',
        reactLocalStorage
          .get('school-search')
          .concat('_')
          .concat(reactLocalStorage.get('ses-search').replace('/', '_'))
          .concat('_')
          .concat(reactLocalStorage.get('ter-search').replace(' ', '_'))
          .concat('_')
          .concat(reactLocalStorage.get('class-search'))
      )
      .orderBy(
        'subject',
        currentSchoolName === alImanCollegeJos ? 'desc' : 'asc'
      )
      .get()
      .then((querySnapshot) => {
        var subjValues = []
        var stu = []
        var stuPos = []
        querySnapshot.forEach((doc, index) => {
          // doc.data() is never undefined for query doc snapshots

          subjValues.push({
            id: index,
            subject: doc.data().subject,
            click: true,
            students: doc.data().students,
          })
          doc.data().students.map((data) => {
            var studentName = findStudentNameById(assessmentValues, data.doc_id)
            if (data.doc_id === pstudent) {
              var rec = {
                id: data.studentid,
                name:
                  studentName !== 'Student not found'
                    ? studentName.name
                    : data.name,
                subject: data.subject,
                gender:
                  studentName !== 'Student not found'
                    ? studentName.gender
                    : data.gender,
                behaviour: data.behaviour,
                doc_id: data.doc_id,
              }
              stu.push(rec)

              setShow(true)
            }
            var rec = {
              id: data.studentid,
              name:
                studentName !== 'Student not found'
                  ? studentName.name
                  : data.name,
              subject: data.subject,
              gender:
                studentName !== 'Student not found'
                  ? studentName.gender
                  : data.gender,
              behaviour: data.behaviour,
              doc_id: data.doc_id,
            }
            stuPos.push(rec)

            setShow(true)
          })
        })
        setSubject(subjValues)
        setStudent(getUnique(stu, 'doc_id'))
        setStudentPos(getUnique(stuPos, 'doc_id'))
        getNextTermFees()

        setLoading(false)
      })
      .catch((error) => {
        console.log('Error getting documentsk: ', error)
        //setLoading(false)
      })
  }

  function getNextTermFees() {
    if (reactLocalStorage.get('ter-search') === 'First Term') {
      getFees('Second Term', reactLocalStorage.get('ses-search'))
    } else if (reactLocalStorage.get('ter-search') == 'Second Term') {
      getFees('Third Term', reactLocalStorage.get('ses-search'))
    } else {
      console.log('3rd')
    }
  }

  function getFees(term, session) {
    var db = firebase.firestore()
    var query = db.collection('fees')

    query
      .doc(
        reactLocalStorage
          .get('school-search')
          .concat('_')
          .concat(session.replace('/', '_'))
          .concat('_')
          .concat(term.replace(' ', '_'))
      )
      .get()
      .then((doc) => {
        if (doc.exists) {
          // setClassDocument(doc.data().classes)
          let values = []
          doc.data().classes.map((item, index) => {
            //values.push(item)
            if (reactLocalStorage.get('class-search') == item.name) {
              setNextFees(item.amount)
            }
          })
        } else {
          console.log('nnnnnnnnnnnnnnnn')
        }
      })
      .catch((error) => {
        console.log('Error getting document:', error)
      })
  }

  function checkAssessment(doc) {
    for (let index = 0; index < assessmentValues.length; index++) {
      const element = assessmentValues[index]
      if (element.doc_id === doc.doc_id) {
        doc.ass1 = element.ass1
        doc.ass2 = element.ass2
        doc.test = element.test
        doc.exam = element.exam
        doc.total = element.total
        doc.grade = element.grade
        doc.doc_id = element.doc_id
      } else {
        // doc.ass1 = '-'
        // doc.ass2 = '-'
        // doc.test = '-'
        // doc.exam = '-'
        // doc.total = '-'
        // doc.grade = '-'
      }
    }
    return doc
  }

  //END OF NO CHANGE

  const styles = StyleSheet.create({
    page: {
      flexDirection: 'column',
      paddingTop: 10,
      // paddingLeft: 50,
      // paddingRight: 50,
      paddingHorizontal: 30,
      fontFamily: 'Helvetica',
    },
    page2: {
      flexDirection: 'column',
      paddingTop: 10,
      // paddingLeft: 50,
      // paddingRight: 50,
      paddingHorizontal: 10,
      fontFamily: 'Helvetica',
    },

    section: {
      // marginVertical: 5,
      color: '#fff',
    },
    section2: {
      marginHorizontal: 20,
      color: '#fff',
    },
    logo: {
      // width: 100,
      // height: 100,
      marginLeft: 'auto',
      marginRight: 'auto',
    },

    school: {
      // marginVertical: 10,
      color: '#07173B',
      // letterSpacing: 1,
      fontSize: 40,
      fontWeight: 'heavy',
      textAlign: 'center',
      textTransform: 'uppercase',
      fontFamily: 'Times-Bold',
    },
    school2: {
      // marginVertical: 10,
      color: '#07173B',
      // letterSpacing: 1,
      fontSize: 20,
      fontWeight: 'heavy',
      textAlign: 'center',
      textTransform: 'uppercase',
      fontFamily: 'Times-Bold',
    },
    pupils: {
      // marginVertical: 10,
      color: '#07173B',
      // letterSpacing: 1,
      marginTop: 5,
      fontSize: 14,
      fontWeight: 'heavy',
      textAlign: 'left',
      textTransform: 'uppercase',
      fontFamily: 'Times-Bold',
    },

    title: {
      marginVertical: 10,
      color: '#3E8EDE',
      // letterSpacing: 1,
      fontSize: 15,
      textAlign: 'center',
      textTransform: 'uppercase',
      fontFamily: 'Helvetica',
    },
    subTitle: {
      // marginVertical: 5,
      marginTop: 15,
      color: '#3E8EDE',
      // letterSpacing: 1,
      fontSize: 10,
      // textAlign: 'center',
      textTransform: 'uppercase',
      fontFamily: 'Helvetica',
    },
    subTitleBlack: {
      // marginVertical: 5,
      marginTop: 15,
      // color: '#3E8EDE',
      // letterSpacing: 1,
      fontSize: 10,
      // textAlign: 'center',
      textTransform: 'uppercase',
      fontFamily: 'Helvetica',
    },
    remarkText: {
      // marginVertical: 5,
      marginTop: 20,
      // letterSpacing: 1,
      fontSize: 10,
      // textDecoration: 'underline',
      // textAlign: 'center',
      // textTransform: 'uppercase',
      fontFamily: 'Helvetica',
    },
    edusaf: {
      fontSize: 8,
      fontFamily: 'Helvetica',
      position: 'absolute',
      bottom: 5,
      right: 5,
    },

    table: {
      fontSize: 8,
      textAlign: 'center',
      fontFamily: 'Helvetica',
    },
    tableUbuntu: {
      fontSize: 8,
      textAlign: 'center',
      fontFamily: 'Ubuntu',
    },
    table2: {
      fontSize: 10,
      textAlign: 'center',
      fontFamily: 'Helvetica',
    },
    tableBehaviour: {
      fontSize: 8,
      textAlign: 'center',
      fontFamily: 'Helvetica',
      width: 250,
      flex: 1,
      // flexDirection: 'row',
    },
    tableRatingParent: {
      fontSize: 8,
      textAlign: 'center',
      fontFamily: 'Helvetica',
      width: 200,
      flex: 1,
      // flexDirection: 'row',
    },
    tableBehaviourParent: {
      fontSize: 8,
      textAlign: 'center',
      fontFamily: 'Helvetica',
      flexDirection: 'row',
      display: 'flex',
    },

    tableRating: {
      fontSize: 8,
      textAlign: 'left',
      paddingLeft: 5,
      fontFamily: 'Helvetica',
    },
    tableRating2: {
      fontSize: 10,
      textAlign: 'left',
      paddingLeft: 5,
      fontFamily: 'Helvetica',
    },
    tableLeft: {
      fontSize: 8,
      textAlign: 'left',
      fontFamily: 'Helvetica',
      paddingLeft: 5,
      backgroundColor: tableColour,
    },
    tableLeft2: {
      fontSize: 8,
      textAlign: 'left',
      fontFamily: 'Helvetica',
      paddingLeft: 5,
    },
    tableLeft3: {
      fontSize: 10,
      textAlign: 'left',
      fontFamily: 'Helvetica',
      paddingLeft: 5,
    },
    tableRight: {
      fontSize: 10,
      textAlign: 'right',
      fontFamily: 'Helvetica',
      paddingRight: 5,
    },
    student: {
      fontSize: 10,
      textAlign: 'left',
      fontFamily: 'Helvetica',
      paddingLeft: 5,
      paddingVertical: 2,
    },
    studentTable: {
      // marginVertical: 10,
    },
    total: {
      fontSize: 8,
      textAlign: 'center',
      fontFamily: 'Helvetica',
      backgroundColor: tableColour,
    },
    tablesubject: {
      fontSize: 8,
      textAlign: 'left',
      paddingLeft: 5,
      backgroundColor: tableColour,
      fontFamily: 'Helvetica',
    },
    tablesubjectUbuntu: {
      fontSize: 9,
      textAlign: 'left',
      paddingLeft: 5,
      backgroundColor: tableColour,
      fontFamily: 'Ubuntu',
    },
    tableNamesubject: {
      fontSize: 10,
      textAlign: 'left',
      paddingLeft: 5,
      backgroundColor: tableColour,
      fontFamily: 'Helvetica',
      paddingVertical: 2,
    },
    studentDetails: {
      fontSize: 10,
      textAlign: 'left',
      fontFamily: 'Helvetica',
      paddingLeft: 5,
      paddingVertical: 2,
    },
    studentDetailsUbuntu: {
      fontSize: 10,
      textAlign: 'left',
      paddingLeft: 5,
      paddingVertical: 2,
      fontFamily: 'Ubuntu',
    },
    qrcode: {
      width: 80,
      height: 80,
      marginLeft: 'auto',
      marginRight: 'auto',
      marginTop: 10,
    },
  })

  const MyDocument = () => {
    var logo = reactLocalStorage.get('head-search')
    const sch = reactLocalStorage.get('sch-name-search')
    if (sch === 'IMAMUL BUKHARI ACADEMY, GOMBE') {
      switch (classValue) {
        case 'IBN MAJAH':
        case "NASA'I":
        case 'TIRMIZI':
        case 'ABU DAWOOD':
        case 'AHMAD BN HAMBAL':
        case "IMAMUSH SHAFI'A":
        case 'MALIK BN ANAS':
        case 'ANAS BN MALIK':
        case 'MUSLIM BN HAJJAJ':
        case 'ABDULLAHI BN ABBAS':
        case 'ABDULLAHI BN AMRU BN ASS':
        case 'ABDULLAHI BN MAS’UD':
        case 'SA’AD BN ABI WAQQAS':
        case 'ABDULLAHI BN UMAR':
        case 'ABDULLAHI BN QAISIN':
          {
            logo =
              'https://firebasestorage.googleapis.com/v0/b/edusaf-629d9.appspot.com/o/heading%2FJsTijJ2meHpVfoAGeWC7_islamic.jpg?alt=media&token=d3b07cc4-a89c-4a26-888b-bf2e789ac8a5'
          }
          break
      }
    }
    const currentSchool = reactLocalStorage.get('school-search')
    const currentSchoolName = reactLocalStorage.get('sch-name-search')
    const modibbo = 'MODIBBO IDRIS ACADEMY, GOMBE'
    const annibras = 'An-Nibras Model School'
    const smartGombe = 'Smart Academy Gombe'
    const flyingColorsGombe = 'knNn0QpqbUaVBcOBUBFU'
    const zenithPrimary = 'FBzIYMgZ1nJlyMX2Btk1'
    const markazu = 'qSfZ9ljUp8bmYNJo8InQ'
    const blissGombe = 'BLISS SUCCESS MODEL SCHOOL'
    const sunshineGombe = 'SUNSHINE MODEL SCHOOL, AKKO GOMBE'
    const alImanCollegeJos = 'Al-iman College Jos'
    const alImanSchoolGirls = 'AL-IMAN GIRLS SECONDARY SCHOOL'
    const alImanSchoolBoys = 'AL-IMAN SECONDARY SCHOOL'
    const esteemJos = 'fhqa4oQYx5j5KotPOLWz'

    var alimanSec =
      (currentSchoolName === alImanSchoolBoys &&
        classValue.includes('J.S.S')) ||
      (currentSchoolName === alImanSchoolBoys && classValue.includes('S.S.S'))
    const caType =
      currentSchool === zenithPrimary ||
      currentSchool === markazu ||
      currentSchoolName === alImanSchoolGirls ||
      alimanSec ||
      currentSchool === esteemJos
        ? '4'
        : '3'

    const formatter = new Intl.NumberFormat('en-NG', {
      style: 'currency',
      currency: 'NGN',
      maximumFractionDigits: '0',
    })
    var fees = Nextfees ? formatter.format(Nextfees) : ''

    console.log('position now :: ', showPosition)

    return (
      <Document>
        {student.map((stu, index) => {
          return (
            <Page index={index} size="A4" style={styles.page}>
              <Image style={styles.logo} src={logo} />
              <View style={styles.school2}>
                <Text>{termValue} Result</Text>
              </View>
              <View style={styles.pupils}>
                <Text>PUPILS/STUDENT INFORMATION</Text>
              </View>
              {sch === smartGombe ? (
                <DetailsTable3
                  student={stu}
                  index={index}
                  size={student.length}
                  key={index}
                />
              ) : showPosition === true ? (
                <DetailsTable
                  student={stu}
                  index={index}
                  size={studentPos.length}
                  key={index}
                />
              ) : (
                <DetailsTable2
                  student={stu}
                  index={index}
                  size={student.length}
                  key={index}
                />
              )}

              <View style={styles.pupils}>
                <Text>Cognitive Domain</Text>
              </View>
              {showPosition === true ? (
                currentSchoolName == modibbo ? (
                  <MyTableModibbo student={stu} key={index} />
                ) : currentSchoolName == annibras ? (
                  <MyTableAnnibras student={stu} key={index} />
                ) : currentSchoolName === sunshineGombe ? (
                  <MyTableNoPositionSunshineGombe
                    student={stu}
                    key={index}
                    size={student.length}
                  />
                ) : currentSchoolName === alImanCollegeJos ? (
                  <MyTable4CAAlimanCollege student={stu} key={index} />
                ) : caType === '3' ? (
                  <MyTable student={stu} key={index} />
                ) : (
                  <MyTable4CA student={stu} key={index} />
                )
              ) : currentSchoolName === smartGombe ? (
                <MyTableSmartAcademy
                  student={stu}
                  key={index}
                  size={student.length}
                />
              ) : currentSchool === flyingColorsGombe ? (
                <MyTableNoPositionFlyingColors
                  student={stu}
                  key={index}
                  size={student.length}
                />
              ) : currentSchoolName === sunshineGombe ? (
                <MyTableNoPositionSunshineGombe
                  student={stu}
                  key={index}
                  size={student.length}
                />
              ) : currentSchoolName === blissGombe ? (
                <MyTableBliss student={stu} key={index} size={student.length} />
              ) : (
                <MyTableNoPosition student={stu} key={index} />
              )}

              {currentSchoolName === blissGombe ? (
                <View>
                  <View style={styles.tableBehaviourParent}>
                    <View style={styles.pupils}>
                      <Text>Affective Domain</Text>
                    </View>
                    <View>
                      <Text style={styles.section2}>''</Text>
                    </View>
                    <View style={styles.pupils}>
                      <Text>Psychomotor Domain</Text>
                    </View>
                  </View>

                  <View style={styles.tableBehaviourParent}>
                    <View style={styles.tableBehaviour}>
                      <BehaviouralTableBliss student={stu} key={index} />
                    </View>
                    <View>
                      <Text style={styles.section2}>''</Text>
                    </View>

                    <View style={styles.tableBehaviour}>
                      <PsychomotorTableBliss student={stu} key={index} />
                    </View>

                    <View>
                      <Text style={styles.section2}>''</Text>
                    </View>

                    <View style={styles.tableRatingParent}>
                      <GradingTableBliss student={stu} key={index} />
                    </View>
                  </View>
                </View>
              ) : (
                <View>
                  <View style={styles.pupils}>
                    <Text>Affective Domain</Text>
                  </View>
                  <View style={styles.tableBehaviourParent}>
                    {currentSchoolName === alImanCollegeJos ? (
                      <View style={styles.tableBehaviour}>
                        <BehaviouralTableAlImanCollege
                          student={stu}
                          key={index}
                        />
                      </View>
                    ) : (
                      <View style={styles.tableBehaviour}>
                        <BehaviouralTable student={stu} key={index} />
                      </View>
                    )}

                    <View>
                      <Text style={styles.section2}>''</Text>
                    </View>
                    {currentSchoolName == modibbo ? (
                      <View style={styles.tableRatingParent}>
                        <GradingTableModibbo student={stu} key={index} />
                        <Text style={styles.section2}>''</Text>
                        <RatingTableModibbo student={stu} key={index} />
                      </View>
                    ) : currentSchoolName == smartGombe ? (
                      <View style={styles.tableRatingParent}>
                        <GradingTableSmartAcademy student={stu} key={index} />
                        {/* <Text style={styles.section2}>''</Text>
                    <RatingTableSmartAcademy student={stu} key={index} /> */}
                      </View>
                    ) : (
                      <View style={styles.tableRatingParent}>
                        <GradingTable student={stu} key={index} />
                      </View>
                    )}
                  </View>
                </View>
              )}

              {/* <View>
                <Text style={styles.remarkText}>Remark: {getRemark(stu)}</Text>
              </View> */}
              <View>
                <Text style={styles.section2}>''</Text>
              </View>
              {showPosition === true ? (
                <View style={styles.tableRight}>
                  <Text>NEXT TERM FEES:{fees} </Text>
                  <Text style={styles.section2}>''</Text>
                </View>
              ) : (
                <View style={styles.tableRight}>
                  <Text style={styles.section2}>''</Text>
                </View>
              )}

              <RemarkTable
                student={stu}
                index={index}
                size={student.length}
                key={index}
              />

              <View>
                <PdfWithQrCode value={stu.id} />
              </View>
              <View style={styles.edusaf}>
                <Text>Powered by edutech</Text>
              </View>
            </Page>
          )
        })}
      </Document>
    )
  }

  const MyTable = ({ student }) => {
    var dt = getCAData(student)
    const currentSchoolName = reactLocalStorage.get('sch-name-search')
    const alImanSchoolGirls = 'AL-IMAN GIRLS SECONDARY SCHOOL'
    const alImanSchoolBoys = 'AL-IMAN SECONDARY SCHOOL'
    if (
      currentSchoolName === alImanSchoolBoys ||
      currentSchoolName === alImanSchoolGirls
    ) {
      setTableColour('#ffffff')
    }

    return (
      <Table style={styles.table} data={[...dt]}>
        <TableHeader>
          <TableCell weighting={0.04}>
            <View style={styles.tableLeft}>
              <Text>SN</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.33}>
            <View style={styles.tablesubject}>
              <Text>SUBJECT</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.07}>
            <View style={styles.table}>
              <Text>ASS 1</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.07}>
            <View style={styles.table}>
              <Text>ASS 2</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.07}>
            <View style={styles.table}>
              <Text>TEST</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.08}>
            <View style={styles.table}>
              <Text>EXAMS</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.08}>
            <View style={styles.total}>
              <Text>TOTAL</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.08}>
            <View style={styles.table}>
              <Text>GRADE</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.08}>
            <View style={styles.table}>
              <Text>POS</Text>
            </View>
          </TableCell>
          {/* <TableCell weighting={0.08}>
            <View style={styles.table}>
              <Text>Class AVG</Text>
            </View>
          </TableCell> */}
          <TableCell weighting={0.1}>
            <View style={styles.tableLeft}>
              <Text>REMARK</Text>
            </View>
          </TableCell>
        </TableHeader>
        <TableBody>
          <DataTableCell weighting={0.04} getContent={(r) => r.index} />
          <DataTableCell weighting={0.33} getContent={(r) => r.subject} />
          <DataTableCell weighting={0.07} getContent={(r) => r.ass1} />
          <DataTableCell weighting={0.07} getContent={(r) => r.ass2} />
          <DataTableCell weighting={0.07} getContent={(r) => r.test} />
          <DataTableCell weighting={0.08} getContent={(r) => r.exam} />
          <DataTableCell weighting={0.08} getContent={(r) => r.total} />
          <DataTableCell weighting={0.08} getContent={(r) => r.grade} />
          <DataTableCell weighting={0.08} getContent={(r) => r.pos} />
          {/* <DataTableCell weighting={0.08} getContent={(r) => r.avg} /> */}
          <DataTableCell weighting={0.1} getContent={(r) => r.remark} />
        </TableBody>
      </Table>
    )
  }

  const MyTable4CAAlimanCollege = ({ student }) => {
    setTableColour('#ffffff')

    if (
      classValue.includes('PRIMARY') ||
      classValue.includes('NURSERY') ||
      classValue.includes('SHUBA')
    ) {
      return <MyTable4CAAlimanCollegePrimary student={student} key={1} />
    }

    var dt = getCADataArabicSubjectName(student)
    return (
      <Table style={styles.table} data={[...dt]}>
        <TableHeader>
          <TableCell weighting={0.04}>
            <View style={styles.tableLeft}>
              <Text>SN</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.26}>
            <View style={styles.tablesubject}>
              <Text>SUBJECT</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.07}>
            <View style={styles.table}>
              <Text>ASS 1</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.07}>
            <View style={styles.table}>
              <Text>ASS 2</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.07}>
            <View style={styles.table}>
              <Text>TEST 1</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.07}>
            <View style={styles.table}>
              <Text>TEST 2</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.08}>
            <View style={styles.table}>
              <Text>EXAMS</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.08}>
            <View style={styles.total}>
              <Text>TOTAL</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.08}>
            <View style={styles.table}>
              <Text>GRADE</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.08}>
            <View style={styles.table}>
              <Text>POS</Text>
            </View>
          </TableCell>
          {/* <TableCell weighting={0.08}>
            <View style={styles.table}>
              <Text>Class AVG</Text>
            </View>
          </TableCell> */}
          <TableCell weighting={0.1}>
            <View style={styles.tableLeft}>
              <Text>REMARK</Text>
            </View>
          </TableCell>
        </TableHeader>
        <TableBody>
          <DataTableCell weighting={0.04} getContent={(r) => r.index} />
          <DataTableCell weighting={0.26} getContent={(r) => r.subject} />
          <DataTableCell weighting={0.07} getContent={(r) => r.ass1} />
          <DataTableCell weighting={0.07} getContent={(r) => r.ass2} />
          <DataTableCell weighting={0.07} getContent={(r) => r.test} />
          <DataTableCell weighting={0.07} getContent={(r) => r.test2} />
          <DataTableCell weighting={0.08} getContent={(r) => r.exam} />
          <DataTableCell weighting={0.08} getContent={(r) => r.total} />
          <DataTableCell weighting={0.08} getContent={(r) => r.grade} />
          <DataTableCell weighting={0.08} getContent={(r) => r.pos} />
          {/* <DataTableCell weighting={0.08} getContent={(r) => r.avg} /> */}
          <DataTableCell weighting={0.1} getContent={(r) => r.remark} />
        </TableBody>
      </Table>
    )
  }
  const MyTable4CAAlimanCollegePrimary = ({ student }) => {
    setTableColour('#ffffff')

    var dt = getCADataArabicSubjectName(student)
    return (
      <Table style={styles.table} data={[...dt]}>
        <TableHeader>
          <TableCell weighting={0.04}>
            <View style={styles.tableLeft}>
              <Text>SN</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.33}>
            <View style={styles.tablesubject}>
              <Text>SUBJECT</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.07}>
            <View style={styles.table}>
              <Text>ASS 1</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.07}>
            <View style={styles.table}>
              <Text>ASS 2</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.07}>
            <View style={styles.table}>
              <Text>TEST 1</Text>
            </View>
          </TableCell>
          {/* <TableCell weighting={0.07}>
            <View style={styles.table}>
              <Text>TEST 2</Text>
            </View>
          </TableCell> */}
          <TableCell weighting={0.08}>
            <View style={styles.table}>
              <Text>EXAMS</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.08}>
            <View style={styles.total}>
              <Text>TOTAL</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.08}>
            <View style={styles.table}>
              <Text>GRADE</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.08}>
            <View style={styles.table}>
              <Text>POS</Text>
            </View>
          </TableCell>
          {/* <TableCell weighting={0.08}>
            <View style={styles.table}>
              <Text>Class AVG</Text>
            </View>
          </TableCell> */}
          <TableCell weighting={0.1}>
            <View style={styles.tableLeft}>
              <Text>REMARK</Text>
            </View>
          </TableCell>
        </TableHeader>
        <TableBody>
          <DataTableCell weighting={0.04} getContent={(r) => r.index} />
          <DataTableCell weighting={0.33} getContent={(r) => r.subject} />
          <DataTableCell weighting={0.07} getContent={(r) => r.ass1} />
          <DataTableCell weighting={0.07} getContent={(r) => r.ass2} />
          <DataTableCell weighting={0.07} getContent={(r) => r.test} />
          {/* <DataTableCell weighting={0.07} getContent={(r) => r.test2} /> */}
          <DataTableCell weighting={0.08} getContent={(r) => r.exam} />
          <DataTableCell weighting={0.08} getContent={(r) => r.total} />
          <DataTableCell weighting={0.08} getContent={(r) => r.grade} />
          <DataTableCell weighting={0.08} getContent={(r) => r.pos} />
          {/* <DataTableCell weighting={0.08} getContent={(r) => r.avg} /> */}
          <DataTableCell weighting={0.1} getContent={(r) => r.remark} />
        </TableBody>
      </Table>
    )
  }

  const MyTable4CA = ({ student }) => {
    const currentSchoolName = reactLocalStorage.get('sch-name-search')
    const alImanSchoolGirls = 'AL-IMAN GIRLS SECONDARY SCHOOL'
    const alImanSchoolBoys = 'AL-IMAN SECONDARY SCHOOL'
    if (
      currentSchoolName === alImanSchoolBoys ||
      currentSchoolName === alImanSchoolGirls
    ) {
      setTableColour('#ffffff')
    }

    var dt = getCAData(student)
    return (
      <Table style={styles.table} data={[...dt]}>
        <TableHeader>
          <TableCell weighting={0.04}>
            <View style={styles.tableLeft}>
              <Text>SN</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.26}>
            <View style={styles.tablesubject}>
              <Text>SUBJECT</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.07}>
            <View style={styles.table}>
              <Text>ASS 1</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.07}>
            <View style={styles.table}>
              <Text>ASS 2</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.07}>
            <View style={styles.table}>
              <Text>TEST 1</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.07}>
            <View style={styles.table}>
              <Text>TEST 2</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.08}>
            <View style={styles.table}>
              <Text>EXAMS</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.08}>
            <View style={styles.total}>
              <Text>TOTAL</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.08}>
            <View style={styles.table}>
              <Text>GRADE</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.08}>
            <View style={styles.table}>
              <Text>POS</Text>
            </View>
          </TableCell>
          {/* <TableCell weighting={0.08}>
            <View style={styles.table}>
              <Text>Class AVG</Text>
            </View>
          </TableCell> */}
          <TableCell weighting={0.1}>
            <View style={styles.tableLeft}>
              <Text>REMARK</Text>
            </View>
          </TableCell>
        </TableHeader>
        <TableBody>
          <DataTableCell weighting={0.04} getContent={(r) => r.index} />
          <DataTableCell weighting={0.26} getContent={(r) => r.subject} />
          <DataTableCell weighting={0.07} getContent={(r) => r.ass1} />
          <DataTableCell weighting={0.07} getContent={(r) => r.ass2} />
          <DataTableCell weighting={0.07} getContent={(r) => r.test} />
          <DataTableCell weighting={0.07} getContent={(r) => r.test2} />
          <DataTableCell weighting={0.08} getContent={(r) => r.exam} />
          <DataTableCell weighting={0.08} getContent={(r) => r.total} />
          <DataTableCell weighting={0.08} getContent={(r) => r.grade} />
          <DataTableCell weighting={0.08} getContent={(r) => r.pos} />
          {/* <DataTableCell weighting={0.08} getContent={(r) => r.avg} /> */}
          <DataTableCell weighting={0.1} getContent={(r) => r.remark} />
        </TableBody>
      </Table>
    )
  }
  const MyTableSmartAcademy = ({ student, size }) => {
    var dt = getCADataSmartAcademy(student)

    return (
      <Table style={styles.table} data={[...dt]}>
        <TableHeader>
          <TableCell weighting={0.04}>
            <View style={styles.tableLeft}>
              <Text>SN</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.19}>
            <View style={styles.tablesubject}>
              <Text>SUBJECT</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.07}>
            <View style={styles.table}>
              <Text>C.A 1 (20%)</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.07}>
            <View style={styles.table}>
              <Text>C.A 2 (20%)</Text>
            </View>
          </TableCell>

          <TableCell weighting={0.07}>
            <View style={styles.table}>
              <Text>EXAMS (60%)</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.07}>
            <View style={styles.total}>
              <Text>TOTAL (100%)</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.07}>
            <View style={styles.table}>
              <Text>GRADE</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.06}>
            <View style={styles.table}>
              <Text>POS</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.07}>
            <View style={styles.table}>
              <Text>OUT OF</Text>
            </View>
          </TableCell>

          <TableCell weighting={0.07}>
            <View style={styles.table}>
              <Text>LOW. IN CLASS</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.07}>
            <View style={styles.table}>
              <Text>HIGH. IN CLASS</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.07}>
            <View style={styles.table}>
              <Text>CLASS AVG</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.09}>
            <View style={styles.tableLeft}>
              <Text>REMARK</Text>
            </View>
          </TableCell>
        </TableHeader>
        <TableBody>
          <DataTableCell weighting={0.04} getContent={(r) => r.index} />
          <DataTableCell weighting={0.19} getContent={(r) => r.subject} />
          <DataTableCell weighting={0.07} getContent={(r) => r.ass1} />
          <DataTableCell weighting={0.07} getContent={(r) => r.ass2} />
          <DataTableCell weighting={0.07} getContent={(r) => r.exam} />
          <DataTableCell weighting={0.07} getContent={(r) => r.total} />
          <DataTableCell weighting={0.07} getContent={(r) => r.grade} />
          <DataTableCell weighting={0.06} getContent={(r) => r.pos} />
          <DataTableCell weighting={0.07} getContent={(r) => r.out} />
          <DataTableCell weighting={0.07} getContent={(r) => r.lowest} />
          <DataTableCell weighting={0.07} getContent={(r) => r.highest} />
          <DataTableCell weighting={0.07} getContent={(r) => r.avg} />
          <DataTableCell weighting={0.09} getContent={(r) => r.remark} />
        </TableBody>
      </Table>
    )
  }
  const MyTableNoPosition = ({ student }) => {
    var dt = getCAData(student)

    return (
      <Table style={styles.table} data={[...dt]}>
        <TableHeader>
          <TableCell weighting={0.04}>
            <View style={styles.tableLeft2}>
              <Text>SN</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.34}>
            <View style={styles.tablesubject}>
              <Text>SUBJECT</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.08}>
            <View style={styles.table}>
              <Text>ASS 1</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.08}>
            <View style={styles.table}>
              <Text>ASS 2</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.08}>
            <View style={styles.table}>
              <Text>TEST</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.09}>
            <View style={styles.table}>
              <Text>EXAMS</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.09}>
            <View style={styles.total}>
              <Text>TOTAL</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.09}>
            <View style={styles.table}>
              <Text>GRADE</Text>
            </View>
          </TableCell>

          {/* <TableCell weighting={0.09}>
            <View style={styles.table}>
              <Text>Class AVG</Text>
            </View>
          </TableCell> */}
          <TableCell weighting={0.1}>
            <View style={styles.tableLeft}>
              <Text>REMARK</Text>
            </View>
          </TableCell>
        </TableHeader>
        <TableBody>
          <DataTableCell weighting={0.04} getContent={(r) => r.index} />
          <DataTableCell weighting={0.34} getContent={(r) => r.subject} />
          <DataTableCell weighting={0.08} getContent={(r) => r.ass1} />
          <DataTableCell weighting={0.08} getContent={(r) => r.ass2} />
          <DataTableCell weighting={0.08} getContent={(r) => r.test} />
          <DataTableCell weighting={0.09} getContent={(r) => r.exam} />
          <DataTableCell weighting={0.09} getContent={(r) => r.total} />
          <DataTableCell weighting={0.09} getContent={(r) => r.grade} />
          {/* <DataTableCell weighting={0.08} getContent={(r) => r.pos} /> */}
          {/* <DataTableCell weighting={0.09} getContent={(r) => r.avg} /> */}
          <DataTableCell weighting={0.1} getContent={(r) => r.remark} />
        </TableBody>
      </Table>
    )
  }

  const MyTableNoPositionFlyingColors = ({ student }) => {
    var dt = getCAData(student)
    return (
      <Table style={styles.table} data={[...dt]}>
        <TableHeader>
          <TableCell weighting={0.04}>
            <View style={styles.tableLeft2}>
              <Text>SN</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.34}>
            <View style={styles.tablesubject}>
              <Text>SUBJECT</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.09}>
            <View style={styles.table}>
              <Text>1st C.A</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.09}>
            <View style={styles.table}>
              <Text>2nd C.A</Text>
            </View>
          </TableCell>

          <TableCell weighting={0.1}>
            <View style={styles.table}>
              <Text>EXAMS</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.1}>
            <View style={styles.total}>
              <Text>TOTAL</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.1}>
            <View style={styles.table}>
              <Text>GRADE</Text>
            </View>
          </TableCell>

          {/* <TableCell weighting={0.09}>
            <View style={styles.table}>
              <Text>Class AVG</Text>
            </View>
          </TableCell> */}
          <TableCell weighting={0.11}>
            <View style={styles.tableLeft}>
              <Text>REMARK</Text>
            </View>
          </TableCell>
        </TableHeader>
        <TableBody>
          <DataTableCell weighting={0.04} getContent={(r) => r.index} />
          <DataTableCell weighting={0.34} getContent={(r) => r.subject} />
          <DataTableCell weighting={0.09} getContent={(r) => r.ass1} />
          <DataTableCell weighting={0.09} getContent={(r) => r.ass2} />
          {/* <DataTableCell weighting={0.08} getContent={(r) => r.test} /> */}
          <DataTableCell weighting={0.1} getContent={(r) => r.exam} />
          <DataTableCell weighting={0.1} getContent={(r) => r.total} />
          <DataTableCell weighting={0.1} getContent={(r) => r.grade} />
          {/* <DataTableCell weighting={0.08} getContent={(r) => r.pos} /> */}
          {/* <DataTableCell weighting={0.09} getContent={(r) => r.avg} /> */}
          <DataTableCell weighting={0.11} getContent={(r) => r.remark} />
        </TableBody>
      </Table>
    )
  }

  const MyTableNoPositionSunshineGombe = ({ student }) => {
    var dt = getCAData(student)
    setTableColour('#ffffff')
    return (
      <Table style={styles.table} data={[...dt]}>
        <TableHeader>
          <TableCell weighting={0.04}>
            <View style={styles.tableLeft2}>
              <Text>SN</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.34}>
            <View style={styles.tablesubject}>
              <Text>SUBJECT</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.09}>
            <View style={styles.table}>
              <Text>1st C.A</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.09}>
            <View style={styles.table}>
              <Text>2nd C.A</Text>
            </View>
          </TableCell>

          <TableCell weighting={0.1}>
            <View style={styles.table}>
              <Text>EXAMS</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.1}>
            <View style={styles.total}>
              <Text>TOTAL</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.1}>
            <View style={styles.table}>
              <Text>GRADE</Text>
            </View>
          </TableCell>

          {/* <TableCell weighting={0.09}>
            <View style={styles.table}>
              <Text>Class AVG</Text>
            </View>
          </TableCell> */}
          <TableCell weighting={0.11}>
            <View style={styles.tableLeft}>
              <Text>REMARK</Text>
            </View>
          </TableCell>
        </TableHeader>
        <TableBody>
          <DataTableCell weighting={0.04} getContent={(r) => r.index} />
          <DataTableCell weighting={0.34} getContent={(r) => r.subject} />
          <DataTableCell weighting={0.09} getContent={(r) => r.ass1} />
          <DataTableCell weighting={0.09} getContent={(r) => r.ass2} />
          {/* <DataTableCell weighting={0.08} getContent={(r) => r.test} /> */}
          <DataTableCell weighting={0.1} getContent={(r) => r.exam} />
          <DataTableCell weighting={0.1} getContent={(r) => r.total} />
          <DataTableCell weighting={0.1} getContent={(r) => r.grade} />
          {/* <DataTableCell weighting={0.08} getContent={(r) => r.pos} /> */}
          {/* <DataTableCell weighting={0.09} getContent={(r) => r.avg} /> */}
          <DataTableCell weighting={0.11} getContent={(r) => r.remark} />
        </TableBody>
      </Table>
    )
  }

  const MyTableModibbo = ({ student }) => {
    var dt = getCAData(student)

    return (
      <Table style={styles.table} data={[...dt]}>
        <TableHeader>
          <TableCell weighting={0.06}>
            <View style={styles.tableLeft}>
              <Text>SN</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.25}>
            <View style={styles.tablesubject}>
              <Text>SUBJECT</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.11}>
            <View style={styles.table}>
              <Text>C.A/30%</Text>
            </View>
          </TableCell>

          <TableCell weighting={0.11}>
            <View style={styles.table}>
              <Text>EXAMS/70%</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.11}>
            <View style={styles.total}>
              <Text>TOTAL/100%</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.11}>
            <View style={styles.table}>
              <Text>GRADE</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.11}>
            <View style={styles.table}>
              <Text>POS</Text>
            </View>
          </TableCell>

          <TableCell weighting={0.14}>
            <View style={styles.tableLeft}>
              <Text>REMARK</Text>
            </View>
          </TableCell>
        </TableHeader>
        <TableBody>
          <DataTableCell weighting={0.06} getContent={(r) => r.index} />
          <DataTableCell weighting={0.25} getContent={(r) => r.subject} />
          <DataTableCell weighting={0.11} getContent={(r) => r.camodibbo} />
          <DataTableCell weighting={0.11} getContent={(r) => r.exam} />
          <DataTableCell weighting={0.11} getContent={(r) => r.total} />
          <DataTableCell weighting={0.11} getContent={(r) => r.grade} />
          <DataTableCell weighting={0.11} getContent={(r) => r.pos} />
          <DataTableCell weighting={0.14} getContent={(r) => r.remark} />
        </TableBody>
      </Table>
    )
  }

  const MyTableAnnibras = ({ student }) => {
    var dt = getCAData(student)

    return (
      <Table style={styles.table} data={[...dt]}>
        <TableHeader>
          <TableCell weighting={0.04}>
            <View style={styles.tableLeft}>
              <Text>SN</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.22}>
            <View style={styles.tablesubject}>
              <Text>SUBJECT</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.12}>
            <View style={styles.table}>
              <Text>1st C.A/20%</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.12}>
            <View style={styles.table}>
              <Text>2nd C.A/20%</Text>
            </View>
          </TableCell>

          <TableCell weighting={0.12}>
            <View style={styles.table}>
              <Text>EXAMS/60%</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.12}>
            <View style={styles.total}>
              <Text>TOTAL/100%</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.08}>
            <View style={styles.table}>
              <Text>GRADE</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.08}>
            <View style={styles.table}>
              <Text>POS</Text>
            </View>
          </TableCell>

          <TableCell weighting={0.1}>
            <View style={styles.tableLeft}>
              <Text>REMARK</Text>
            </View>
          </TableCell>
        </TableHeader>
        <TableBody>
          <DataTableCell weighting={0.04} getContent={(r) => r.index} />
          <DataTableCell weighting={0.22} getContent={(r) => r.subject} />
          <DataTableCell weighting={0.12} getContent={(r) => r.ass1} />
          <DataTableCell weighting={0.12} getContent={(r) => r.ass2} />
          <DataTableCell weighting={0.12} getContent={(r) => r.exam} />
          <DataTableCell weighting={0.12} getContent={(r) => r.total} />
          <DataTableCell weighting={0.08} getContent={(r) => r.grade} />
          <DataTableCell weighting={0.08} getContent={(r) => r.pos} />
          <DataTableCell weighting={0.1} getContent={(r) => r.remark} />
        </TableBody>
      </Table>
    )
  }

  const MyTableBliss = ({ student }) => {
    var dt = getCAData(student)

    // if (currentSchoolName === esteemJos) {
    //   setTableColour('#1E8449')
    // }

    return (
      <Table style={styles.table} data={[...dt]}>
        <TableHeader>
          <TableCell weighting={0.04}>
            <View style={styles.tableLeft}>
              <Text>SN</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.33}>
            <View style={styles.tablesubject}>
              <Text>SUBJECT</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.07}>
            <View style={styles.table}>
              <Text>ASS 1</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.07}>
            <View style={styles.table}>
              <Text>ASS 2</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.07}>
            <View style={styles.table}>
              <Text>TEST</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.08}>
            <View style={styles.table}>
              <Text>EXAMS</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.08}>
            <View style={styles.total}>
              <Text>TOTAL</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.08}>
            <View style={styles.table}>
              <Text>GRADE</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.08}>
            <View style={styles.table}>
              <Text>POS</Text>
            </View>
          </TableCell>
          {/* <TableCell weighting={0.08}>
            <View style={styles.table}>
              <Text>Class AVG</Text>
            </View>
          </TableCell> */}
          <TableCell weighting={0.1}>
            <View style={styles.tableLeft}>
              <Text>REMARK</Text>
            </View>
          </TableCell>
        </TableHeader>
        <TableBody>
          <DataTableCell weighting={0.04} getContent={(r) => r.index} />
          <DataTableCell weighting={0.33} getContent={(r) => r.subject} />
          <DataTableCell weighting={0.07} getContent={(r) => r.ass1} />
          <DataTableCell weighting={0.07} getContent={(r) => r.ass2} />
          <DataTableCell weighting={0.07} getContent={(r) => r.test} />
          <DataTableCell weighting={0.08} getContent={(r) => r.exam} />
          <DataTableCell weighting={0.08} getContent={(r) => r.total} />
          <DataTableCell weighting={0.08} getContent={(r) => r.grade} />
          <DataTableCell weighting={0.08} getContent={(r) => r.pos} />
          {/* <DataTableCell weighting={0.08} getContent={(r) => r.avg} /> */}
          <DataTableCell weighting={0.1} getContent={(r) => r.remark} />
        </TableBody>
      </Table>
    )
  }
  function formatDateMonthName(date) {
    const month = [
      'JAN',
      'FEB',
      'MAR',
      'APR',
      'MAY',
      'JUN',
      'JUL',
      'AUG',
      'SEP',
      'OCT',
      'NOV',
      'DEC',
    ]
    let name = month[date.getMonth()]
    let day = date.getDate()
    let year = date.getFullYear()
    return name.concat(' ').concat(day).concat(', ').concat(year)
  }

  function formatDate2(date) {
    var parts = date.split('-')
    // Please pay attention to the month (parts[1]); JavaScript counts months from 0:
    // January - 0, February - 1, etc.
    const month = [
      'JAN',
      'FEB',
      'MAR',
      'APR',
      'MAY',
      'JUN',
      'JUL',
      'AUG',
      'SEP',
      'OCT',
      'NOV',
      'DEC',
    ]

    //var mydate = new Date(parts[0], parts[1] - 1, parts[2])

    let name = month[parts[1] - 1]
    let day = parts[2]
    let year = parts[0]
    return name.concat(' ').concat(day).concat(', ').concat(year)
  }

  const DetailsTable = ({ student, index, size }) => {
    const nameUpper = student.name.toUpperCase()
    const genderUpper = student.gender.toUpperCase()
    var timesPresent = checkPresent(student.doc_id)
    const hideAttendance = reactLocalStorage.get('hide-attendance')
    return (
      <Table
        data={[
          {
            label1: 'Admission No',
            label2: student.id,
            label3: 'Total Score',
            label4: getTotalScore(student),
            label5: 'Times Open',
            label6: timesOpen,
          },
          {
            label1: 'Gender',
            label2: genderUpper,
            label3: 'Average Score',
            label4: getAverage(student),
            label5: 'Times Present',
            label6: hideAttendance === 'hide' ? '-' : timesPresent,
          },
          {
            label1: 'Class',
            label2: classValue,
            label3: 'Final Grade',
            label4: getGradeDetails(student),
            label5: 'Times Absent',
            label6: hideAttendance === 'hide' ? '-' : timesOpen - timesPresent,
          },
          {
            label1: 'Term',
            label2: termValue,
            label3: 'Position',
            label4: showPosition ? getPosition(index) : '',
            label5: 'Term Ends',
            label6: formatDate2(termEnd),
          },
          {
            label1: 'Session',
            label2: sessionValue,
            label3: 'No in Class',
            label4: size,
            label5: 'Next Begins',
            label6: formatDate2(nextTerm),
          },
        ]}
      >
        <TableHeader>
          <TableCell weighting={0.18}>
            <View style={styles.tableNamesubject}>
              <Text>STUDENT NAME</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.82}>
            <View style={styles.student}>
              <Text>{nameUpper}</Text>
            </View>
          </TableCell>
          {/* <TableCell>DOB</TableCell>
          <TableCell>Country</TableCell> */}
        </TableHeader>
        <TableBody>
          <DataTableCell
            weighting={0.18}
            style={styles.tableNamesubject}
            getContent={(r) => r.label1}
          />
          <DataTableCell
            weighting={0.18}
            style={styles.studentDetails}
            getContent={(r) => r.label2}
          />
          <DataTableCell
            weighting={0.16}
            style={styles.tableNamesubject}
            getContent={(r) => r.label3}
          />
          <DataTableCell
            weighting={0.16}
            style={styles.studentDetails}
            getContent={(r) => r.label4}
          />
          <DataTableCell
            weighting={0.16}
            style={styles.tableNamesubject}
            getContent={(r) => r.label5}
          />
          <DataTableCell
            weighting={0.16}
            style={styles.studentDetails}
            getContent={(r) => r.label6}
          />
          {/* <DataTableCell getContent={(r) => r.score} />
          <DataTableCell getContent={(r) => r.average} /> */}
        </TableBody>
      </Table>
    )
  }
  const DetailsTable2 = ({ student, index, size }) => {
    const nameUpper = student.name.toUpperCase()
    const genderUpper = student.gender.toUpperCase()
    var timesPresent = checkPresent(student.doc_id)
    const schl = reactLocalStorage.get('sch-name-search')
    const hideAttendance = reactLocalStorage.get('hide-attendance')

    const currentSchool = reactLocalStorage.get('school-search')

    const flyingColorsGombe = 'knNn0QpqbUaVBcOBUBFU'

    // Create our number formatter.
    const formatter = new Intl.NumberFormat('en-NG', {
      style: 'currency',
      currency: 'NGN',
      maximumFractionDigits: '0',

      // These options are needed to round to whole numbers if that's what you want.
      //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
      //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    })

    var fees = Nextfees ? formatter.format(Nextfees) : ''
    var newNextTerm = Date.parse(nextTerm)

    return (
      <Table
        data={[
          {
            label1: 'Admission No',
            label2: student.id,
            label3: 'Total Score',
            label4: getTotalScore(student),
            label5: 'Times Open',
            label6: timesOpen,
          },
          {
            label1: 'Gender',
            label2: genderUpper,
            label3: 'Average Score',
            label4: getAverage(student),
            label5: 'Times Present',
            label6: hideAttendance === 'hide' ? '-' : timesPresent,
          },
          {
            label1: 'Class',
            label2: classValue,
            label3: 'Final Grade',
            label4: getGradeDetails(student),
            label5: 'Times Absent',
            label6: hideAttendance === 'hide' ? '-' : timesOpen - timesPresent,
          },
          {
            label1: 'Term',
            label2: termValue,
            label3: 'Term Ends',
            label4: formatDate2(termEnd),
            label5:
              currentSchool === flyingColorsGombe
                ? 'Next Term Begins'
                : 'Next Begins',
            label6: formatDate2(nextTerm),
          },
          {
            label1: 'Session',
            label2: sessionValue,
            label3: 'Approved Date',
            label4: formatDateMonthName(new Date()),
            label5: 'Next Term Fees',
            label6: fees,
          },
        ]}
      >
        <TableHeader>
          <TableCell weighting={0.25}>
            <View style={styles.tableNamesubject}>
              <Text>STUDENT NAME</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.77}>
            <View style={styles.student}>
              <Text>{nameUpper}</Text>
            </View>
          </TableCell>
          {/* <TableCell>DOB</TableCell>
          <TableCell>Country</TableCell> */}
        </TableHeader>
        <TableBody>
          <DataTableCell
            weighting={0.253}
            style={styles.tableNamesubject}
            getContent={(r) => r.label1}
          />
          <DataTableCell
            weighting={0.1506}
            style={styles.studentDetailsUbuntu}
            getContent={(r) => r.label2}
          />
          <DataTableCell
            weighting={0.1506}
            style={styles.tableNamesubject}
            getContent={(r) => r.label3}
          />
          <DataTableCell
            weighting={0.1506}
            style={styles.studentDetails}
            getContent={(r) => r.label4}
          />
          <DataTableCell
            weighting={0.1506}
            style={styles.tableNamesubject}
            getContent={(r) => r.label5}
          />
          <DataTableCell
            weighting={0.1506}
            style={styles.studentDetails}
            getContent={(r) => r.label6}
          />
          {/* <DataTableCell getContent={(r) => r.score} />
          <DataTableCell getContent={(r) => r.average} /> */}
        </TableBody>
      </Table>
    )
  }

  const DetailsTable3 = ({ student, index, size }) => {
    const nameUpper = student.name.toUpperCase()
    const genderUpper = student.gender.toUpperCase()
    var timesPresent = checkPresent(student.doc_id)
    const schl = reactLocalStorage.get('sch-name-search')
    const hideAttendance = reactLocalStorage.get('hide-attendance')

    setTableColour('#ffffff')
    // Create our number formatter.
    const formatter = new Intl.NumberFormat('en-NG', {
      style: 'currency',
      currency: 'NGN',
      maximumFractionDigits: '0',

      // These options are needed to round to whole numbers if that's what you want.
      //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
      //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    })

    var fees = Nextfees ? formatter.format(Nextfees) : ''
    var newNextTerm = Date.parse(nextTerm)

    return (
      <Table
        data={[
          {
            label1: 'Admission No',
            label2: student.id,
            label3: 'Total Score',
            label4: getTotalScore(student),
            label5: 'Times Open',
            label6: timesOpen,
          },
          {
            label1: 'Gender',
            label2: genderUpper,
            label3: 'Final Average',
            label4: getAverage(student),
            label5: 'Times Present',
            label6: hideAttendance === 'hide' ? '-' : timesPresent,
          },
          {
            label1: 'Class',
            label2: classValue,
            label3: 'Final Grade',
            label4: getGradeDetails(student),
            label5: 'Times Absent',
            label6: hideAttendance === 'hide' ? '-' : timesOpen - timesPresent,
          },
          {
            label1: 'Term',
            label2: termValue,
            label3: 'Term Ends',
            label4: formatDate2(termEnd),
            label5: 'Next Begins',
            label6: formatDate2(nextTerm),
          },
          {
            label1: 'Session',
            label2: sessionValue,
            label3: 'Approved Date',
            label4: formatDateMonthName(new Date()),
            label5: 'Next Term Fees',
            label6: fees,
          },
        ]}
      >
        <TableHeader>
          <TableCell weighting={0.25}>
            <View style={styles.tableNamesubject}>
              <Text>STUDENT NAME</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.77}>
            <View style={styles.student}>
              <Text>{nameUpper}</Text>
            </View>
          </TableCell>
          {/* <TableCell>DOB</TableCell>
          <TableCell>Country</TableCell> */}
        </TableHeader>
        <TableBody>
          <DataTableCell
            weighting={0.253}
            style={styles.tableNamesubject}
            getContent={(r) => r.label1}
          />
          <DataTableCell
            weighting={0.1506}
            style={styles.studentDetails}
            getContent={(r) => r.label2}
          />
          <DataTableCell
            weighting={0.1506}
            style={styles.tableNamesubject}
            getContent={(r) => r.label3}
          />
          <DataTableCell
            weighting={0.1506}
            style={styles.studentDetails}
            getContent={(r) => r.label4}
          />
          <DataTableCell
            weighting={0.1506}
            style={styles.tableNamesubject}
            getContent={(r) => r.label5}
          />
          <DataTableCell
            weighting={0.1506}
            style={styles.studentDetails}
            getContent={(r) => r.label6}
          />
          {/* <DataTableCell getContent={(r) => r.score} />
          <DataTableCell getContent={(r) => r.average} /> */}
        </TableBody>
      </Table>
    )
  }
  const RemarkTable = ({ student, index, size }) => {
    const currentSchoolName = reactLocalStorage.get('sch-name-search')
    const blissGombe = 'BLISS SUCCESS MODEL SCHOOL'
    const modibbo = 'MODIBBO IDRIS ACADEMY, GOMBE'
    return (
      <Table
        data={[
          {
            label1: ' ',
            label2: ' ',
          },
          {
            label1: "FORM TEACHER'S SIGNATURE",
            label2:
              classValue.includes('J.S.S') ||
              classValue.includes('S.S.S') ||
              classValue.includes('J.I.S') ||
              classValue.includes('S.I.S')
                ? "PRINCIPAL'S SIGNATURE"
                : currentSchoolName === blissGombe
                ? 'HEADMISTRESS SIGNATURE'
                : currentSchoolName === modibbo
                ? "HEAD TEACHER'S SIGNATURE"
                : "HEADMASTER'S SIGNATURE",
          },
        ]}
      >
        <TableHeader>
          <TableCell weighting={0.25}>
            <View style={styles.student}>
              <Text>COMMENT</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.75}>
            <View style={styles.student}>
              <Text>{getRemark(student)}</Text>
            </View>
          </TableCell>
          {/* <TableCell>DOB</TableCell>
          <TableCell>Country</TableCell> */}
        </TableHeader>
        <TableBody>
          <DataTableCell
            weighting={0.5}
            style={styles.student}
            getContent={(r) => r.label1}
          />
          <DataTableCell
            weighting={0.5}
            style={styles.studentDetails}
            getContent={(r) => r.label2}
          />
        </TableBody>
      </Table>
    )
  }

  const BehaviouralTable = ({ student }) => {
    const flyingColorsGombe = 'knNn0QpqbUaVBcOBUBFU'
    const currentSchool = reactLocalStorage.get('school-search')
    var dt = student.behaviour
    var punctuality = 'A'
    var attendance = 'A'
    var attentiveness = 'B'
    var assigment = 'B'
    var participation = 'B'
    var neatness = 'A'
    var honesty = 'A'
    var selfControl = 'C'
    var relationship = 'A'
    var leadership = 'B'
    var concentration = 'A'
    var games = 'A'
    if (dt !== undefined && dt !== null) {
      punctuality = dt.punctuality
      assigment = dt.assigment
      attendance = dt.attendance
      attentiveness = dt.attentiveness
      honesty = dt.honesty
      leadership = dt.leadership
      neatness = dt.neatness
      participation = dt.participation
      selfControl = dt.self_control
      concentration = dt.concentration
      relationship = dt.relationship
      if (dt.games !== undefined && dt.games !== null) {
        games = dt.games
      }
    }
    return (
      <Table
        data={[
          {
            label1: 'Punctuality',
            label2: punctuality,
          },
          {
            label1: 'Class Attendance',
            label2: attendance,
          },
          {
            label1: 'Attentiveness in class',
            label2: attentiveness,
          },
          {
            label1: 'Carrying out assigments',
            label2: assigment,
          },
          {
            label1: 'Participation in activities',
            label2: participation,
          },
          {
            label1: 'Neatness',
            label2: neatness,
          },
          {
            label1: 'Honesty',
            label2: honesty,
          },
          {
            label1: 'Seft-control',
            label2: selfControl,
          },
          {
            label1: 'Relationship with others',
            label2: 'A',
          },
          {
            label1: 'Leadership',
            label2: leadership,
          },
          // {
          //   label1: 'Games and Sports',
          //   label2: games,
          // },
          // {
          //   label1: 'Handling of lab tools',
          //   label2: 'C',
          // },
          // {
          //   label1: 'Cooperation',
          //   label2: 'B',
          // },
          // {
          //   label1: 'Concentration',
          //   label2: concentration,
          // },
        ]}
      >
        <TableHeader textAlign={'center'}>
          <TableCell style={styles.tablesubject}>RATINGS</TableCell>
        </TableHeader>
        <TableBody>
          <DataTableCell
            style={styles.tableRating}
            weighting={0.8}
            getContent={(r) => r.label1}
          />
          <DataTableCell
            style={styles.table}
            weighting={0.2}
            getContent={(r) => r.label2}
          />
        </TableBody>
      </Table>
    )
  }

  const BehaviouralTableBliss = ({ student }) => {
    var dt = student.behaviour
    var punctuality = 'A'
    var attendance = 'A'
    var attentiveness = 'B'
    var assigment = 'B'
    var participation = 'B'
    var neatness = 'A'
    var honesty = 'A'
    var selfControl = 'C'
    var relationship = 'A'
    var leadership = 'B'
    var concentration = 'A'
    var games = 'A'
    if (dt !== undefined && dt !== null) {
      punctuality = dt.punctuality
      assigment = dt.assigment
      attendance = dt.attendance
      attentiveness = dt.attentiveness
      honesty = dt.honesty
      leadership = dt.leadership
      neatness = dt.neatness
      participation = dt.participation
      selfControl = dt.self_control
      concentration = dt.concentration
      relationship = dt.relationship
      if (dt.games !== undefined && dt.games !== null) {
        games = dt.games
      }
    }
    return (
      <Table
        data={[
          {
            label1: 'Punctuality',
            label2: punctuality,
          },
          {
            label1: 'Class Attendance',
            label2: attendance,
          },
          {
            label1: 'Attentiveness in class',
            label2: attentiveness,
          },
          {
            label1: 'Carrying out assigments',
            label2: assigment,
          },
          {
            label1: 'Participation in activities',
            label2: participation,
          },
          {
            label1: 'Neatness',
            label2: neatness,
          },
          {
            label1: 'Honesty',
            label2: honesty,
          },
          {
            label1: 'Seft-control',
            label2: selfControl,
          },
          {
            label1: 'Relationship with others',
            label2: 'A',
          },
          {
            label1: 'Leadership',
            label2: leadership,
          },
          // {
          //   label1: 'Games and Sports',
          //   label2: games,
          // },
          // {
          //   label1: 'Handling of lab tools',
          //   label2: 'C',
          // },
          // {
          //   label1: 'Cooperation',
          //   label2: 'B',
          // },
          // {
          //   label1: 'Concentration',
          //   label2: concentration,
          // },
        ]}
      >
        <TableHeader textAlign={'center'}>
          <TableCell style={styles.tablesubject}>RATINGS</TableCell>
        </TableHeader>
        <TableBody>
          <DataTableCell
            style={styles.tableRating}
            weighting={0.8}
            getContent={(r) => r.label1}
          />
          <DataTableCell
            style={styles.table}
            weighting={0.2}
            getContent={(r) => r.label2}
          />
        </TableBody>
      </Table>
    )
  }
  const PsychomotorTableBliss = ({ student }) => {
    var dt = student.behaviour
    var verbal = 'A'
    var tools = 'A'
    var musics = 'B'
    var writting = 'A'
    var games = 'A'
    if (dt !== undefined && dt !== null) {
      verbal = dt.verbal ? dt.verbal : verbal
      tools = dt.tools ? dt.tools : tools
      musics = dt.musics ? dt.musics : musics
      writting = dt.writting ? dt.writting : writting
      if (dt.games !== undefined && dt.games !== null) {
        games = dt.games
      }
    }
    return (
      <Table
        data={[
          {
            label1: 'Games & Sport',
            label2: games,
          },
          {
            label1: 'Handwriting',
            label2: writting,
          },
          {
            label1: 'Musical Skills',
            label2: musics,
          },
          {
            label1: 'Handling tools',
            label2: tools,
          },
          {
            label1: 'Verbal Fluency',
            label2: verbal,
          },
        ]}
      >
        <TableHeader textAlign={'center'}>
          <TableCell style={styles.tablesubject}>RATINGS</TableCell>
        </TableHeader>
        <TableBody>
          <DataTableCell
            style={styles.tableRating}
            weighting={0.8}
            getContent={(r) => r.label1}
          />
          <DataTableCell
            style={styles.table}
            weighting={0.2}
            getContent={(r) => r.label2}
          />
        </TableBody>
      </Table>
    )
  }

  const BehaviouralTableAlImanCollege = ({ student }) => {
    var dt = student.behaviour
    var punctuality = 'A'
    var attendance = 'A'
    var attentiveness = 'B'
    var assigment = 'B'
    var participation = 'B'
    var neatness = 'A'
    var honesty = 'A'
    var selfControl = 'C'
    var relationship = 'A'
    var leadership = 'B'
    var concentration = 'A'
    var games = 'A'
    if (dt !== undefined && dt !== null) {
      punctuality = dt.punctuality
      assigment = dt.assigment
      attendance = dt.attendance
      attentiveness = dt.attentiveness
      honesty = dt.honesty
      leadership = dt.leadership
      neatness = dt.neatness
      participation = dt.participation
      selfControl = dt.self_control
      concentration = dt.concentration
      relationship = dt.relationship
      if (dt.games !== undefined && dt.games !== null) {
        games = dt.games
      }
    }
    return (
      <Table
        data={[
          {
            label1: 'Punctuality',
            label2: punctuality,
          },
          {
            label1: 'Class Attendance',
            label2: attendance,
          },
          {
            label1: 'Attentiveness in class',
            label2: attentiveness,
          },
          {
            label1: 'Carrying out assigments',
            label2: assigment,
          },
          {
            label1: 'Participation in activities',
            label2: participation,
          },
          {
            label1: 'Neatness',
            label2: neatness,
          },
          {
            label1: 'Honesty',
            label2: honesty,
          },
          // {
          //   label1: 'Seft-control',
          //   label2: selfControl,
          // },
          // {
          //   label1: 'Relationship with others',
          //   label2: 'A',
          // },
          // {
          //   label1: 'Leadership',
          //   label2: leadership,
          // },
          // {
          //   label1: 'Games and Sports',
          //   label2: games,
          // },
          // {
          //   label1: 'Handling of lab tools',
          //   label2: 'C',
          // },
          // {
          //   label1: 'Cooperation',
          //   label2: 'B',
          // },
          // {
          //   label1: 'Concentration',
          //   label2: concentration,
          // },
        ]}
      >
        <TableHeader textAlign={'center'}>
          <TableCell style={styles.tablesubject}>RATINGS</TableCell>
        </TableHeader>
        <TableBody>
          <DataTableCell
            style={styles.tableRating}
            weighting={0.8}
            getContent={(r) => r.label1}
          />
          <DataTableCell
            style={styles.table}
            weighting={0.2}
            getContent={(r) => r.label2}
          />
        </TableBody>
      </Table>
    )
  }

  const GradingTable = () => {
    return (
      <Table
        data={[
          {
            label1: 'Range',
            label2: 'Grade',
            label3: 'Remark',
          },
          {
            label1: '70 - 100',
            label2: 'A',
            label3: 'Excellent',
          },
          {
            label1: '60 - 69',
            label2: 'B',
            label3: 'Very Good',
          },
          {
            label1: '50 - 59',
            label2: 'C',
            label3: 'Good',
          },
          {
            label1: '45 - 49',
            label2: 'D',
            label3: 'Pass',
          },
          {
            label1: '40 - 44',
            label2: 'E',
            label3: 'Pass',
          },

          {
            label1: '39 - below',
            label2: 'F',
            label3: 'Fail',
          },
        ]}
      >
        <TableHeader textAlign={'center'}>
          <TableCell style={styles.tablesubject}>GRADING SYSTEM</TableCell>
        </TableHeader>
        <TableBody>
          <DataTableCell
            style={styles.tableRating}
            weighting={0.3}
            getContent={(r) => r.label1}
          />
          <DataTableCell
            style={styles.table}
            weighting={0.3}
            getContent={(r) => r.label2}
          />
          <DataTableCell
            style={styles.tableRating}
            weighting={0.4}
            getContent={(r) => r.label3}
          />
        </TableBody>
      </Table>
    )
  }
  const GradingTableBliss = () => {
    return (
      <Table
        data={[
          {
            label1: 'Range',
            label2: 'Grade',
          },
          {
            label1: '80 - 100',
            label2: 'A',
          },
          {
            label1: '70 - 79',
            label2: 'B',
          },
          {
            label1: '60 - 69',
            label2: 'C',
          },
          {
            label1: '50 - 59',
            label2: 'D',
          },
          {
            label1: '40 - 49',
            label2: 'E',
          },

          {
            label1: '39 - below',
            label2: 'F',
          },
        ]}
      >
        <TableHeader textAlign={'center'}>
          <TableCell style={styles.tablesubject}>GRADING SYSTEM</TableCell>
        </TableHeader>
        <TableBody>
          <DataTableCell
            style={styles.tableRating}
            weighting={0.5}
            getContent={(r) => r.label1}
          />
          <DataTableCell
            style={styles.table}
            weighting={0.5}
            getContent={(r) => r.label2}
          />
        </TableBody>
      </Table>
    )
  }

  const GradingTableModibbo = () => {
    return (
      <Table
        data={[
          {
            label1: 'Range',
            label2: 'Grade',
            label3: 'Remark',
          },
          {
            label1: '80 - 100',
            label2: 'A',
            label3: 'Excellent',
          },
          {
            label1: '70 - 79',
            label2: 'B',
            label3: 'Very Good',
          },
          {
            label1: '50 - 69',
            label2: 'C',
            label3: 'Good',
          },

          {
            label1: '0 - 49',
            label2: 'F',
            label3: 'Fail',
          },
        ]}
      >
        <TableHeader textAlign={'center'}>
          <TableCell style={styles.tablesubject}>GRADING SYSTEM</TableCell>
        </TableHeader>
        <TableBody>
          <DataTableCell
            style={styles.tableRating}
            weighting={0.3}
            getContent={(r) => r.label1}
          />
          <DataTableCell
            style={styles.table}
            weighting={0.3}
            getContent={(r) => r.label2}
          />
          <DataTableCell
            style={styles.tableRating}
            weighting={0.4}
            getContent={(r) => r.label3}
          />
        </TableBody>
      </Table>
    )
  }
  const GradingTableSmartAcademy = () => {
    return (
      <Table
        data={[
          {
            label1: 'Range',
            label2: 'Grade',
            label3: 'Remark',
          },
          {
            label1: '70 - 100',
            label2: 'A',
            label3: 'Excellent',
          },
          {
            label1: '60 - 69',
            label2: 'B',
            label3: 'Very Good',
          },
          {
            label1: '50 - 65',
            label2: 'C',
            label3: 'Good',
          },
          {
            label1: '45 - 49',
            label2: 'D',
            label3: 'Pass',
          },
          {
            label1: '40 - 44',
            label2: 'E',
            label3: 'Fair',
          },

          {
            label1: '0 - 49',
            label2: 'F',
            label3: 'Fail',
          },
        ]}
      >
        <TableHeader textAlign={'center'}>
          <TableCell style={styles.tablesubject}>GRADING SYSTEM</TableCell>
        </TableHeader>
        <TableBody>
          <DataTableCell
            style={styles.tableRating}
            weighting={0.3}
            getContent={(r) => r.label1}
          />
          <DataTableCell
            style={styles.table}
            weighting={0.3}
            getContent={(r) => r.label2}
          />
          <DataTableCell
            style={styles.tableRating}
            weighting={0.4}
            getContent={(r) => r.label3}
          />
        </TableBody>
      </Table>
    )
  }
  const GradingTableFlyingCols = () => {
    return (
      <Table
        data={[
          {
            label1: 'Range',
            label2: 'Grade',
            label3: 'Remark',
          },
          {
            label1: '80 - 100',
            label2: 'A1',
            label3: 'Excellent',
          },
          {
            label1: '70 - 79.9',
            label2: 'B2',
            label3: 'Very Good',
          },
          {
            label1: '65 - 69.9',
            label2: 'B3',
            label3: 'Good',
          },
          {
            label1: '60 - 64.9',
            label2: 'C4',
            label3: 'Credit',
          },
          {
            label1: '55 - 59.9',
            label2: 'C5',
            label3: 'Credit',
          },
          {
            label1: '50 - 54.9',
            label2: 'C6',
            label3: 'Credit',
          },
          {
            label1: '45 - 49.9',
            label2: 'D7',
            label3: 'Pass',
          },
          {
            label1: '40 - 44.9',
            label2: 'E8',
            label3: 'Pass',
          },

          {
            label1: '0 - 39.9',
            label2: 'F',
            label3: 'Fail',
          },
        ]}
      >
        <TableHeader textAlign={'center'}>
          <TableCell style={styles.tablesubject}>GRADING SYSTEM</TableCell>
        </TableHeader>
        <TableBody>
          <DataTableCell
            style={styles.tableRating}
            weighting={0.3}
            getContent={(r) => r.label1}
          />
          <DataTableCell
            style={styles.table}
            weighting={0.3}
            getContent={(r) => r.label2}
          />
          <DataTableCell
            style={styles.tableRating}
            weighting={0.4}
            getContent={(r) => r.label3}
          />
        </TableBody>
      </Table>
    )
  }

  const RatingTableModibbo = () => {
    return (
      <Table
        data={[
          {
            label2: 'Grade',
            label3: 'Remark',
          },
          {
            label2: 'A',
            label3: 'Excellent',
          },
          {
            label2: 'B',
            label3: 'Very Good',
          },
          {
            label2: 'C',
            label3: 'Good',
          },
          {
            label2: 'D',
            label3: 'Fair',
          },

          {
            label2: 'F',
            label3: 'Poor',
          },
        ]}
      >
        <TableHeader textAlign={'center'}>
          <TableCell style={styles.tablesubject}>RATING SYSTEM</TableCell>
        </TableHeader>
        <TableBody>
          <DataTableCell
            style={styles.table}
            weighting={0.3}
            getContent={(r) => r.label2}
          />
          <DataTableCell
            style={styles.tableRating}
            weighting={0.4}
            getContent={(r) => r.label3}
          />
        </TableBody>
      </Table>
    )
  }
  const RatingTableSmartAcademy = () => {
    return (
      <Table
        data={[
          {
            label2: 'Grade',
            label3: 'Remark',
          },
          {
            label2: 'A',
            label3: 'Excellent',
          },
          {
            label2: 'B',
            label3: 'Very Good',
          },
          {
            label2: 'C',
            label3: 'Good',
          },
          {
            label2: 'D',
            label3: 'Pass',
          },
          {
            label2: 'E',
            label3: 'Fair',
          },

          {
            label2: 'F',
            label3: 'Poor',
          },
        ]}
      >
        <TableHeader textAlign={'center'}>
          <TableCell style={styles.tablesubject}>RATING SYSTEM</TableCell>
        </TableHeader>
        <TableBody>
          <DataTableCell
            style={styles.table}
            weighting={0.3}
            getContent={(r) => r.label2}
          />
          <DataTableCell
            style={styles.tableRating}
            weighting={0.4}
            getContent={(r) => r.label3}
          />
        </TableBody>
      </Table>
    )
  }
  const MyTable3 = () => {
    return (
      <Table
        data={[
          {
            firstName: 'John',
            lastName: 'Smith',
            dob: new Date(2000, 1, 1),
            country: 'Australia',
            phoneNumber: 'xxx-0000-0000',
          },
        ]}
      >
        <TableHeader textAlign={'center'}>
          <TableCell weighting={0.08}>Student's Name</TableCell>
          <TableCell weighting={0.08}>Last Name</TableCell>
          <TableCell>DOB</TableCell>
          <TableCell>Country</TableCell>
          <TableCell>Phone Number</TableCell>
        </TableHeader>
        <TableBody>
          <DataTableCell weighting={0.08} getContent={(r) => r.firstName} />
          <DataTableCell weighting={0.08} getContent={(r) => r.lastName} />
          <DataTableCell getContent={(r) => r.dob.toLocaleString()} />
          <DataTableCell getContent={(r) => r.country} />
          <DataTableCell getContent={(r) => r.phoneNumber} />
        </TableBody>
      </Table>
    )
  }
  function getUnique(arr, index) {
    const unique = arr
      .map((e) => e[index])

      // store the keys of the unique objects
      .map((e, i, final) => final.indexOf(e) === i && i)

      // eliminate the dead keys & store unique objects
      .filter((e) => arr[e])
      .map((e) => arr[e])

    return unique
  }

  function getPosition(index) {
    var scores = []

    studentPos.map((stu) => {
      scores.push(getTotalScore(stu))
    })

    var positions = findRanks(scores)
    return positions[index]
  }
  function getTotalScore(record) {
    var totalScore = 0
    subject.map((subj) => {
      const uniqueStatesArray = removeDuplicates(
        subj.students,
        'doc_id',
        'subject'
      )
      uniqueStatesArray.map((stud) => {
        if (record.doc_id == stud.doc_id) {
          totalScore = totalScore + Number(stud.total)
        }
      })
    })

    return totalScore
  }
  function getAverage(record) {
    var totalScore = 0
    var count = 0
    var avg = 0
    subject.map((subj) => {
      const uniqueStatesArray = removeDuplicates(
        subj.students,
        'doc_id',
        'subject'
      )
      uniqueStatesArray.map((stud) => {
        if (record.doc_id == stud.doc_id) {
          totalScore = totalScore + Number(stud.total)
          count = count + 1
        }
      })
    })
    avg = totalScore / count
    var avgFixed = avg.toFixed(1)
    return avgFixed
  }
  function getGrade(totalValue) {
    var grade = ''
    if (totalValue >= 70) {
      grade = 'A'
    } else if (totalValue >= 60) {
      grade = 'B'
    } else if (totalValue >= 50) {
      grade = 'C'
    } else if (totalValue >= 45) {
      grade = 'D'
    } else if (totalValue >= 40) {
      grade = 'E'
    } else {
      grade = 'F'
    }
    return grade
  }

  function getGradeDetails(record) {
    const currentSchoolName = reactLocalStorage.get('school-name')
    const modibbo = 'MODIBBO IDRIS ACADEMY, GOMBE'

    var totalScore = 0
    var totalValue = 0
    var size = 0
    subject.map((subj) => {
      const uniqueStatesArray = removeDuplicates(
        subj.students,
        'doc_id',
        'subject'
      )
      uniqueStatesArray.map((stud) => {
        if (record.doc_id == stud.doc_id) {
          totalScore = totalScore + Number(stud.total)
        }
      })
      size = subject.length
    })

    totalValue = totalScore / size

    if (currentSchoolName === modibbo) {
      return getGradeModibbo(totalValue)
    }

    var grade = ''
    if (totalValue >= 70) {
      grade = 'A'
    } else if (totalValue >= 60) {
      grade = 'B'
    } else if (totalValue >= 50) {
      grade = 'C'
    } else if (totalValue >= 45) {
      grade = 'D'
    } else if (totalValue >= 40) {
      grade = 'E'
    } else {
      grade = 'F'
    }
    return grade
  }

  function getGradeModibbo(totalValue) {
    var grade = ''
    if (totalValue >= 80) {
      grade = 'A'
    } else if (totalValue >= 70) {
      grade = 'B'
    } else if (totalValue >= 50) {
      grade = 'C'
    } else {
      grade = 'F'
    }
    return grade
  }
  function getSubjectRemark(totalValue) {
    var grade = ''
    if (totalValue >= 70) {
      grade = 'Excellent'
    } else if (totalValue >= 60) {
      grade = 'Very Good'
    } else if (totalValue >= 50) {
      grade = 'Good'
    } else if (totalValue >= 45) {
      grade = 'Pass'
    } else if (totalValue >= 40) {
      grade = 'Pass'
    } else {
      grade = 'Fail'
    }
    return grade
  }
  function getRemark(record) {
    const currentSchool = reactLocalStorage.get('school-search')
    const currentSchoolName = reactLocalStorage.get('sch-name-search')
    const blissGombe = 'BLISS SUCCESS MODEL SCHOOL'
    var totalScore = 0
    var count = 0
    var avg = 0
    var name = ''
    var gender = ''
    if (currentSchoolName === blissGombe) {
      return BlissRemark(record)
    }

    subject.map((subj) => {
      const uniqueStatesArray = removeDuplicates(
        subj.students,
        'doc_id',
        'subject'
      )
      uniqueStatesArray.map((stud) => {
        if (record.doc_id == stud.doc_id) {
          var studentName = findStudentNameById(student, stud.doc_id)
          totalScore = totalScore + Number(stud.total)
          name = stud.name
          count = count + 1
          gender =
            studentName !== 'Student not found'
              ? studentName.gender
              : stud.gender
        }
      })
    })
    avg = totalScore / count
    var avgFixed = avg.toFixed(1)

    var remark = ''
    if (avgFixed >= 70) {
      remark = success[randomNumberInRange()]
    } else if (avgFixed >= 60) {
      remark = success[randomNumberInRange()]
    } else if (avgFixed >= 50) {
      remark = improvement[randomNumberInRange()]
    } else if (avgFixed >= 45) {
      remark = assistance[randomNumberInRange()]
    } else if (avgFixed >= 40) {
      remark = assistance[randomNumberInRange()]
    } else {
      remark = assistance[randomNumberInRange()]
    }

    //remark = remark.replaceAll('has', name)
    //remark = remark.replaceAll('Has', name)
    if (gender.toUpperCase() === 'MALE') {
      remark = remark.replaceAll('himself/herself', 'himself')
      remark = remark.replaceAll('his/her', 'his')
      remark = remark.replaceAll('he/she', 'he')
      remark = remark.replaceAll('him/her', 'him')
      remark = remark.replaceAll('He/she', 'He')
    } else {
      remark = remark.replaceAll('himself/herself', 'herself')
      remark = remark.replaceAll('his/her', 'her')
      remark = remark.replaceAll('he/she', 'she')
      remark = remark.replaceAll('him/her', 'her')
      remark = remark.replaceAll('He/she', 'She')
    }
    return remark
  }
  function BlissRemark(record) {
    var totalScore = 0
    var count = 0
    var avg = 0
    var name = ''
    var gender = ''

    subject.map((subj) => {
      const uniqueStatesArray = removeDuplicates(
        subj.students,
        'doc_id',
        'subject'
      )
      uniqueStatesArray.map((stud) => {
        if (record.doc_id == stud.doc_id) {
          totalScore = totalScore + Number(stud.total)
          name = stud.name
          count = count + 1
          gender = stud.gender
        }
      })
    })
    avg = totalScore / count
    var avgFixed = avg.toFixed(1)

    var remark = ''
    if (avgFixed >= 80) {
      remark = 'An excellent Result Keep it up'
    } else if (avgFixed >= 70) {
      remark = 'A very Good Result, Keep it up'
    } else if (avgFixed >= 60) {
      remark = 'A good result, Put in more effort next term'
    } else if (avgFixed >= 50) {
      remark = 'An Average Result, Put in more effort next term'
    } else if (avgFixed >= 40) {
      remark = 'A passed result, work harder on all Subjects'
    } else {
      remark = 'A Failed Result, work harder on all subjects'
    }

    return remark
  }
  function getSubjectsAverage(subjValue) {
    var totals = []
    subject.map((sub) => {
      const uniqueStatesArray = removeDuplicates(
        sub.students,
        'doc_id',
        'subject'
      )
      if (subjValue === sub.subject) {
        //console.log('::', sub.students)
        uniqueStatesArray.map((stu) => {
          totals.push(stu.total)
        })
      }
    })
    return totals
  }

  // Function to remove duplicates based on 'name' and 'code' properties
  function removeDuplicates(array, key1, key2) {
    const seen = new Set()
    return array.filter((item) => {
      const identifier = item[key1] + item[key2]
      if (!seen.has(identifier)) {
        seen.add(identifier)
        return true
      }
      return false
    })
  }

  function getCAData(record) {
    //setLoading(false)
    var dat = []
    var count = 1

    subject.map((subj) => {
      const uniqueStatesArray = removeDuplicates(
        subj.students,
        'doc_id',
        'subject'
      )
      uniqueStatesArray.map((stud, index) => {
        if (record.doc_id == stud.doc_id) {
          if (!stud.test2) {
            stud.test2 = 0
          }

          var sumCaMo =
            parseInt(stud.ass1 !== '-' ? stud.ass1 : 0) +
            parseInt(stud.ass2 !== '-' ? stud.ass2 : 0) +
            parseInt(stud.test !== '-' ? stud.test : 0) +
            parseInt(stud.test2 !== '-' ? stud.test2 : 0)
          dat.push({
            index: (
              <View style={styles.tableLeft2}>
                <Text>{count}</Text>
              </View>
            ),
            name: (
              <View style={styles.tableLeft2}>
                <Text>{stud.name.toUpperCase()}</Text>
              </View>
            ),
            subject: (
              <View style={styles.tablesubject}>
                <Text>{subj.subject}</Text>
              </View>
            ),
            ass1: (
              <View style={styles.table}>
                <Text>{stud.ass1}</Text>
              </View>
            ),
            ass2: (
              <View style={styles.table}>
                <Text>{stud.ass2}</Text>
              </View>
            ),
            test: (
              <View style={styles.table}>
                <Text>{stud.test}</Text>
              </View>
            ),

            test2: (
              <View style={styles.table}>
                <Text>{stud.test2}</Text>
              </View>
            ),
            camodibbo: (
              <View style={styles.table}>
                <Text>{sumCaMo !== 0 ? sumCaMo : '-'}</Text>
              </View>
            ),
            exam: (
              <View style={styles.table}>
                <Text>{stud.exam}</Text>
              </View>
            ),
            total: (
              <View style={styles.total}>
                <Text>{stud.total}</Text>
              </View>
            ),
            total2: (
              <View style={styles.table}>
                <Text>{stud.total}</Text>
              </View>
            ),
            grade: (
              <View style={styles.table}>
                <Text>{getGrade(stud.total)}</Text>
              </View>
            ),
            pos: (
              <View style={styles.table}>
                <Text>{stud.position}</Text>
              </View>
            ),
            out: (
              <View style={styles.table}>
                <Text>{subj.students.length}</Text>
              </View>
            ),

            avg: (
              <View style={styles.table}>
                <Text>{stud.class_average}</Text>
              </View>
            ),
            remark: (
              <View style={styles.tableLeft}>
                <Text>{getSubjectRemark(stud.total)}</Text>
              </View>
            ),
          })
          count++
        }
        // console.log(subj.subject, '::', averages.length)
      })
    })
    //setData(dat)
    //setLoading(false)
    // setShowDownload(true)

    return dat
  }
  function getCADataArabicSubjectName(record) {
    //setLoading(false)
    var dat = []
    var count = 1

    subject.map((subj) => {
      const uniqueStatesArray = removeDuplicates(
        subj.students,
        'doc_id',
        'subject'
      )
      uniqueStatesArray.map((stud, index) => {
        if (record.doc_id == stud.doc_id) {
          if (!stud.test2) {
            stud.test2 = 0
          }

          var sumCaMo =
            parseInt(stud.ass1 !== '-' ? stud.ass1 : 0) +
            parseInt(stud.ass2 !== '-' ? stud.ass2 : 0) +
            parseInt(stud.test !== '-' ? stud.test : 0) +
            parseInt(stud.test2 !== '-' ? stud.test2 : 0) +
            dat.push({
              index: (
                <View style={styles.tableLeft2}>
                  <Text>{count}</Text>
                </View>
              ),
              name: (
                <View style={styles.tableLeft2}>
                  <Text>{stud.name.toUpperCase()}</Text>
                </View>
              ),
              subject: (
                <View style={styles.tablesubjectUbuntu}>
                  <Text>{subj.subject}</Text>
                </View>
              ),
              ass1: (
                <View style={styles.table}>
                  <Text>{stud.ass1}</Text>
                </View>
              ),
              ass2: (
                <View style={styles.table}>
                  <Text>{stud.ass2}</Text>
                </View>
              ),
              test: (
                <View style={styles.table}>
                  <Text>{stud.test}</Text>
                </View>
              ),

              test2: (
                <View style={styles.table}>
                  <Text>{stud.test2}</Text>
                </View>
              ),
              camodibbo: (
                <View style={styles.table}>
                  <Text>{sumCaMo !== 0 ? sumCaMo : '-'}</Text>
                </View>
              ),
              exam: (
                <View style={styles.table}>
                  <Text>{stud.exam}</Text>
                </View>
              ),
              total: (
                <View style={styles.total}>
                  <Text>{stud.total}</Text>
                </View>
              ),
              total2: (
                <View style={styles.table}>
                  <Text>{stud.total}</Text>
                </View>
              ),
              grade: (
                <View style={styles.table}>
                  <Text>{getGrade(stud.total)}</Text>
                </View>
              ),
              pos: (
                <View style={styles.table}>
                  <Text>{stud.position}</Text>
                </View>
              ),
              out: (
                <View style={styles.table}>
                  <Text>{subj.students.length}</Text>
                </View>
              ),

              avg: (
                <View style={styles.table}>
                  <Text>{stud.class_average}</Text>
                </View>
              ),
              remark: (
                <View style={styles.tableLeft}>
                  <Text>{getSubjectRemark(stud.total)}</Text>
                </View>
              ),
            })
          count++
        }
        // console.log(subj.subject, '::', averages.length)
      })
    })

    return dat
  }
  function getCADataSmartAcademy(record) {
    //setLoading(false)
    var dat = []
    var count = 1

    subject.map((subj) => {
      const uniqueStatesArray = removeDuplicates(
        subj.students,
        'doc_id',
        'subject'
      )
      uniqueStatesArray.map((stud, index) => {
        if (record.doc_id == stud.doc_id) {
          var arr = getSubjectsAverage(subj.subject)
          var max = arr.reduce(function (a, b) {
            return Math.max(a, b)
          })
          var min = arr.reduce(function (a, b) {
            return Math.min(a, b)
          })

          dat.push({
            index: (
              <View style={styles.tableLeft2}>
                <Text>{count}</Text>
              </View>
            ),
            name: (
              <View style={styles.tableLeft2}>
                <Text>{stud.name.toUpperCase()}</Text>
              </View>
            ),
            subject: (
              <View style={styles.tablesubject}>
                <Text>{subj.subject}</Text>
              </View>
            ),
            ass1: (
              <View style={styles.table}>
                <Text>{stud.ass1}</Text>
              </View>
            ),
            ass2: (
              <View style={styles.table}>
                <Text>{stud.ass2}</Text>
              </View>
            ),
            test: (
              <View style={styles.table}>
                <Text>{stud.test}</Text>
              </View>
            ),
            exam: (
              <View style={styles.table}>
                <Text>{stud.exam}</Text>
              </View>
            ),
            total: (
              <View style={styles.total}>
                <Text>{stud.total}</Text>
              </View>
            ),
            total2: (
              <View style={styles.table}>
                <Text>{stud.total}</Text>
              </View>
            ),
            grade: (
              <View style={styles.table}>
                <Text>{stud.grade}</Text>
              </View>
            ),
            pos: (
              <View style={styles.table}>
                <Text>{stud.position}</Text>
              </View>
            ),
            out: (
              <View style={styles.table}>
                <Text>{subj.students.length}</Text>
              </View>
            ),
            highest: (
              <View style={styles.table}>
                <Text>{max}</Text>
              </View>
            ),
            lowest: (
              <View style={styles.table}>
                <Text>{min}</Text>
              </View>
            ),
            avg: (
              <View style={styles.table}>
                <Text>{stud.class_average}</Text>
              </View>
            ),
            remark: (
              <View style={styles.tableLeft}>
                <Text>{stud.remark}</Text>
              </View>
            ),
          })
          count++
        }
        // console.log(subj.subject, '::', averages.length)
      })
    })
    //setData(dat)
    //setLoading(false)
    // setShowDownload(true)
    return dat
  }

  // NO CHANGE

  //THIS IS NEW METHOD 12/7/2023
  // Function to find studentname by studentid
  const findStudentNameById = (values, id) => {
    const foundStudent = values.find((student) => student.doc_id == id)

    // Check if a student with the given id was found
    if (foundStudent) {
      return { name: foundStudent.name, gender: foundStudent.gender }
    } else {
      return 'Student not found' // You can customize the message accordingly
    }
  }

  const findRanks = (arr = []) => {
    const { length } = arr
    let sortArray = arr.slice()
    sortArray.sort((a, b) => b - a)
    const result = []
    for (let i = 0; i < length; i++) {
      const j = sortArray.indexOf(arr[i])
      result.push(j + 1)
    }

    return result
  }

  function getBusinessDaysDiff(startDate, endDate, publicHolidays) {
    const currentDate = new Date(startDate)
    const finalDate = new Date(endDate)

    let businessDays = 0

    while (currentDate <= finalDate) {
      const dayOfWeek = currentDate.getDay() // 0 = Sunday, 1 = Monday, ..., 6 = Saturday

      // Check if it's not a Saturday (6) or Sunday (0) and not a public holiday
      if (
        dayOfWeek !== 0 &&
        dayOfWeek !== 6 &&
        !isPublicHoliday(currentDate, publicHolidays)
      ) {
        businessDays++
      }

      currentDate.setDate(currentDate.getDate() + 1)
    }

    return businessDays
  }

  function isPublicHoliday(date, publicHolidays) {
    // Convert the date to a string in 'yyyy-mm-dd' format
    const dateString = date.toISOString().split('T')[0]

    // Check if the date string matches any public holiday date
    return publicHolidays.includes(dateString)
  }
  function randomNumberInRange() {
    // 👇️ get number between min (inclusive) and max (inclusive)
    return Math.floor(Math.random() * (9 - 0 + 1)) + 0
  }

  function PdfWithQrCode({ value }) {
    var school = reactLocalStorage.get('school-search')
    var newValue = `https://edusaf-629d9.web.app/v/${value}`

    const qrCodeComponent = <QRCode value={newValue} renderAs="svg" size={50} />

    const qrCodeComponentStaticMarkup = renderToStaticMarkup(qrCodeComponent)

    const parsedQrCodeSvg = parseQrCodeMarkup(qrCodeComponentStaticMarkup)
    if (!parsedQrCodeSvg) {
      return null
    }

    return (
      <View>
        <Svg style={styles.qrcode} viewBox="0 0 29 29">
          {parsedQrCodeSvg.props.children
            .filter((c) => c.type === 'path')
            .map((child, index) => (
              <Path key={index} d={child.props.d} fill={child.props.fill} />
            ))}
        </Svg>
      </View>
    )
  }

  const parseQrCodeMarkup = (markup) => {
    let parsedQrCodeSvg = null

    ReactHtmlParser(markup).forEach((el) => {
      const { type } = el
      if (type === 'svg') {
        parsedQrCodeSvg = el
      }
    })

    return parsedQrCodeSvg
  }

  return (
    <div className="">
      <div className="p-3 bg-primary">
        <p className="ml-4  text-white font-medium text-lg">
          RESULT VERIFICATION
        </p>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2">
        {loading ? (
          <div className="grid min-h-screen justify-center items-center">
            <i
              className={' text-primary fas fa-circle-notch fa-spin text-5xl'}
            ></i>
          </div>
        ) : (
          ''
        )}

        <form className="flex flex-col px-5 pt-5">
          {/* <div className=" flex mb-3 ">
            <div className="border bg-white text-primary py-2  px-4 flex text-center items-center rounded-sm">
              <svg
                className="text-primary"
                width="16"
                height="14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.917 8.444h-.659l-.233-.202c.817-.852 1.308-1.959 1.308-3.163 0-2.685-2.425-4.861-5.416-4.861C2.925.218.5 2.394.5 5.079.5 7.764 2.925 9.94 5.917 9.94a5.767 5.767 0 003.525-1.174l.225.21v.59l4.166 3.732 1.242-1.114-4.158-3.74zm-5 0c-2.075 0-3.75-1.503-3.75-3.365 0-1.862 1.675-3.365 3.75-3.365s3.75 1.503 3.75 3.365c0 1.862-1.675 3.365-3.75 3.365z"
                  fill="#B7B9BE"
                />
              </svg>
              <input
                className="bg-white text-primary outline-none text-sm pl-2 w-32 md:w-52"
                type="text"
                placeholder="enter student admission number"
                onChange={(e) => setPstudent(e.target.value)}
              />
            </div>
            <button
              type="button"
              onClick={(e) => {
                searchRecord(e)
              }}
              className="outline-none mx-4 focus:outline-none border py-2 px-4 rounded-sm"
            >
              {loading ? (
                <i className={'fas fa-circle-notch fa-spin text-base'}></i>
              ) : (
                'Search'
              )}
            </button>
          </div> */}

          {show ? (
            <table class="table-fixed border-collapse border border-slate-400">
              <tbody>
                <tr>
                  <td className="border border-slate-300 p-2">School</td>
                  <td className="border border-slate-300 p-2 uppercase ">
                    {reactLocalStorage.get('sch-name-search')}
                  </td>
                </tr>
                <tr>
                  <td className="border border-slate-300 p-2">ID</td>
                  <td className="border border-slate-300 p-2 uppercase ">
                    {reactLocalStorage.get('sstudentid-search')}
                  </td>
                </tr>
                <tr>
                  <td className="border border-slate-300 p-2 ">Name</td>
                  <td className="border border-slate-300 p-2 uppercase ">
                    {reactLocalStorage.get('sname-search')}
                  </td>
                </tr>
                <tr>
                  <td className="border border-slate-300 p-2 ">Class</td>
                  <td className="border border-slate-300 p-2 ">
                    {reactLocalStorage.get('class-search')}
                  </td>
                </tr>
                <tr>
                  <td className="border border-slate-300 p-2 ">Session</td>
                  <td className="border border-slate-300 p-2 ">
                    {reactLocalStorage.get('ses-search')}
                  </td>
                </tr>
                <tr>
                  <td className="border border-slate-300 p-2 ">Term</td>
                  <td className="border border-slate-300 p-2 ">
                    {reactLocalStorage.get('ter-search')}
                  </td>
                </tr>
              </tbody>
            </table>
          ) : (
            ''
          )}
        </form>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 justify-end items-end justify-items-end ">
        {show ? (
          <button
            onClick={(e) => {
              setShowDownload(true)
            }}
            className="px-3 py-1.5 my-5 mx-5 w-32   rounded-sm outline-none focus:outline-none text-white  bg-primary"
          >
            {loading ? (
              <i className={'fas fa-circle-notch fa-spin text-base'}></i>
            ) : !showDownload ? (
              <p>DOWNLOAD</p>
            ) : (
              <div>
                <div className="download-link">
                  <PDFDownloadLink
                    document={<MyDocument />}
                    fileName={pstudent.concat('.pdf')}
                  >
                    {({ blob, url, loading, error }) =>
                      loading ? 'Loading...' : 'DOWNLOAD'
                    }
                  </PDFDownloadLink>
                </div>
              </div>
            )}
          </button>
        ) : (
          ''
        )}
      </div>
    </div>
  )
}

export default VerifyReport
