import AuthenticationSidenav from '../components/AuthenticationSidenav'
import { useState, useRef } from 'react'
import ShowDrawer from '../components/ShowDrawer'
import ButtonPrimary from '../buttons/ButtonPrimary'
import { useAuth } from '../services/AuthContext'
import { Link, useHistory } from 'react-router-dom'
import { auth } from '../firebase'
import firebase from 'firebase/app'
import { NotificationManager } from 'react-notifications'
import 'react-notifications/lib/notifications.css'
import { reactLocalStorage } from 'reactjs-localstorage'

const Signin = () => {
  const emailRef = useRef()
  const passwordRef = useRef()

  const [isOpen, setIsOpen] = useState(false)
  const closeClasses =
    'w-64 lg:w-72 2xl:w-96 absolute md:relative md:translate-x-0 inset-y-0 left-0 transform -translate-x-full transition duration-200 ease-in-out'
  const openClasses =
    'w-64 lg:w-72 2xl:w-96 absolute  md:relative md:translate-x-0 inset-y-0 left-0 transform transition duration-200 ease-in-out'

  const { signIn } = useAuth()
  const [errorMessage, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const histrory = useHistory()

  const handleClick = () => {
    !isOpen ? setIsOpen(true) : setIsOpen(false)
  }

  var provider
  function signInWithGoogle() {
    provider = new firebase.auth.GoogleAuthProvider()
    signInWithSocialWithPopop(provider)
  }

  // function signInWithFacebook() {
  //   provider = new firebase.auth.FacebookAuthProvider();
  //   signInWithSocialWithPopop(provider);
  // }

  function signInWithSocialWithPopop(provider) {
    setError('')
    setLoading(true)
    firebase
      .auth()
      .signInWithPopup(provider)
      .then((result) => {
        /** @type {firebase.auth.OAuthCredential} */
        //var credential = result.credential;

        // This gives you a Google Access Token. You can use it to access the Google API.
        //var token = credential.accessToken;
        // The signed-in user info.
        var user = result.user
        checkCompletedAccount(user)
        // if (user.emailVerified) {
        //   checkCompletedAccount(user)
        // } else {
        //   histrory.push('/reverify')
        // }
        // setLoading(false)
      })
      .catch((error) => {
        // Handle Errors here.
        //var errorCode = error.code;
        //var errorMessage = error.message;
        // The email of the user's account used.
        //var email = error.email;
        // The firebase.auth.AuthCredential type that was used.
        //var credential = error.credential;
        // ...
        setError(error.message)
        setLoading(false)
      })
  }

  async function handleSubmit(e) {
    e.preventDefault()
    try {
      setError('')
      setLoading(true)
      await signIn(emailRef.current.value, passwordRef.current.value)
      var user = auth.currentUser
      reactLocalStorage.clear()
      checkCompletedAccount(user)
    } catch (error) {
      setError(error.message)
      setLoading(false)
    }
  }
  // async function handleSubmit(e) {
  //   e.preventDefault()
  //   try {
  //     setError('')
  //     setLoading(true)
  //     await signIn(emailRef.current.value, passwordRef.current.value)

  //     var user = auth.currentUser

  //     if (user.emailVerified) {
  //       checkCompletedAccount(user)
  //     } else {
  //       checkTeacherAccount(user)
  //       //histrory.push('/reverify')
  //     }
  //     setLoading(false)
  //   } catch (error) {
  //     setError(error.message)
  //     setLoading(false)
  //   }
  // }

  function checkCompletedAccount(user) {
    var db = firebase.firestore()
    var query = db.collection('persons').where('email', '==', user.email)
    query.get().then(function (querySnapshot) {
      if (querySnapshot.size > 0) {
        querySnapshot.forEach(function (doc) {
          var data = doc.data()
          reactLocalStorage.set('current-school', data.school)
          reactLocalStorage.set('name', data.name)
          reactLocalStorage.set('email', data.email)
          reactLocalStorage.set('role', data.role)
          reactLocalStorage.set('school-name', data.schoolname)
          reactLocalStorage.set('address', data.address)
          reactLocalStorage.set('phone', data.phone)
          reactLocalStorage.set('state', data.state)
          reactLocalStorage.set('lga', data.lga)
          reactLocalStorage.set('photo', data.photo ? data.photo : '')
          reactLocalStorage.set(
            'heading',
            data.heading
              ? data.heading
              : 'https://firebasestorage.googleapis.com/v0/b/edutechng.appspot.com/o/heading%2FIMG-20240225-WA0020-removebg-preview.png?alt=media&token=5ed5f43c-1fba-4273-8a1a-0b96cac49f7c'
          )
          //histrory.replace('/landing')
          getTerm(data.school)
        })
      } else {
        // NotificationManager.info('Record not found')
        // setLoading(false)
        checkUserAccount(user)
      }
    })
  }

  const getTerm = (school) => {
    setLoading(true)
    var db = firebase.firestore()
    var query = db.collection('term')
    query
      .doc(school)
      .get()
      .then((doc) => {
        if (doc.exists) {
          reactLocalStorage.set('term', doc.data().term)
          reactLocalStorage.set('session', doc.data().session)
        }
        getStudents()
      })
      .catch((error) => {
        console.log('Error getting document:', error)
        setLoading(false)
      })
  }

  function checkUserAccount(user) {
    var db = firebase.firestore()
    var query = db.collection('user').where('email', '==', user.email)
    query.get().then(function (querySnapshot) {
      if (querySnapshot.size > 0) {
        querySnapshot.forEach(function (doc) {
          var data = doc.data()
          reactLocalStorage.set('current-school', data.school)
          reactLocalStorage.set('name', data.name)
          reactLocalStorage.set('email', data.email)
          reactLocalStorage.set('role', data.role)
          reactLocalStorage.set('school-name', data.schoolname)
          reactLocalStorage.set('address', data.address)
          reactLocalStorage.set('phone', data.phone)
          reactLocalStorage.set('state', data.state)
          reactLocalStorage.set('photo', data.photo ? data.photo : '')
          reactLocalStorage.set(
            'heading',
            data.heading
              ? data.heading
              : 'https://firebasestorage.googleapis.com/v0/b/edutechng.appspot.com/o/heading%2FIMG-20240225-WA0020-removebg-preview.png?alt=media&token=5ed5f43c-1fba-4273-8a1a-0b96cac49f7c'
          )
          const roles = data.roles
          roles.map((role) => {
            reactLocalStorage.set(role, role)
          })
          getTerm(data.school)
        })
      } else {
        NotificationManager.info('Record not found')
        setLoading(false)
      }
    })
  }

  const getStudents = () => {
    setLoading(true)
    var db = firebase.firestore()
    var record = []
    db.collection('students')
      .where('school', '==', reactLocalStorage.get('current-school'))
      .get()
      .then((querySnapshot) => {
        reactLocalStorage.set('total_students', querySnapshot.size)
        //setLoading(false)
        getSubscription()
      })
      .catch((error) => {
        setLoading(false)
        console.log('Error getting documents: ', error)
      })
  }

  const getSubscription = async () => {
    try {
      //setLoading(true)

      var db = firebase.firestore()
      const path = `${reactLocalStorage.get(
        'current-school'
      )}_${reactLocalStorage
        .get('session')
        .replace('/', '_')}_${reactLocalStorage.get('term').replace(' ', '_')}`

      const doc = await db.collection('subscription').doc(path).get()

      if (doc.exists) {
        const plan = doc.data().plan
        const slotPaid = doc.data().slot
        const totalStudent = reactLocalStorage.get('total_students')

        if (
          plan === 'Trial' ||
          (plan === 'Paid' && parseInt(slotPaid) >= parseInt(totalStudent))
        ) {
          // setActivated(true)
          reactLocalStorage.set('activated', true)
          histrory.replace('/landing')
        } else {
          //setActivated(false)
          histrory.replace('/landing')
          reactLocalStorage.set('activated', false)
        }
      } else {
        reactLocalStorage.set('activated', false)
        histrory.replace('/landing')
      }

      setLoading(false)
    } catch (error) {
      console.log('Error getting document:', error)
      setLoading(false)
      // Handle error gracefully (e.g., display an error message to the user)
    }
  }

  // function checkTeacherAccount(user) {
  //   var db = firebase.firestore()
  //   var query = db.collection('teachers').where('email', '==', user.email)
  //   query.get().then(function (querySnapshot) {
  //     if (querySnapshot.size > 0) {
  //       NotificationManager.info('Please use mobile app')

  //       // querySnapshot.forEach(function (doc) {
  //       //   var data = doc.data()
  //       //   reactLocalStorage.set('current-school', data.school)
  //       //   reactLocalStorage.set('name', data.name)
  //       //   reactLocalStorage.set('email', data.email)
  //       //   reactLocalStorage.set('role', data.role)
  //       //   reactLocalStorage.set('school-name', data.schoolname)
  //       //   reactLocalStorage.set('address', data.address)
  //       //   reactLocalStorage.set('phone', data.phone)
  //       //   reactLocalStorage.set('state', data.state)
  //       //   reactLocalStorage.set('lga', data.lga)
  //       //   reactLocalStorage.set('photo', data.photo ? data.photo : '')

  //       //   // histrory.push('/landing')
  //       //   if (data.role === 'accountant') {
  //       //     histrory.push('/login')
  //       //   } else if (data.role === 'admin') {
  //       //     histrory.push('/landing')
  //       //   }
  //       // })
  //     } else {
  //       checkCompletedAccount(user)
  //       //histrory.replace('/signupcompletion')
  //     }
  //     setLoading(false)
  //   })
  // }

  //   const getSchoolId = () => {
  //     var user = auth.currentUser;
  //     var db = firebase.firestore();
  //     var query = db.collection("persons").where("id", "==", user.uid);
  //     query.get().then(function (querySnapshot) {
  //       querySnapshot.forEach(function (doc) {
  //         var data = doc.data();
  //         reactLocalStorage.remove("current-school");
  //         reactLocalStorage.set("current-school", data.school);
  //         histrory.push("/landing");
  //       });
  //     });
  //   };

  return (
    <div className="md:flex bg-white  md:bg-account_background   ">
      <ShowDrawer handleClick={handleClick} isOpen={isOpen} />

      <div className={!isOpen ? closeClasses : openClasses}>
        <AuthenticationSidenav type="authentication" />
      </div>

      <div className=" md:flex-1  ">
        <div className="grid px-5 ">
          <div className="grid lg:grid-cols-5 md:justify-center  md:place-content-center md:h-screen">
            <div className=""></div>
            <div className="lg:col-span-3">
              <div className="md:bg-white md:p-5 lg:p-10 2xl:p-16  xl:mx-24 2xl:mx-14 3xl:mx-36  shadow-sm  rounded-md">
                <p className="text-xl 2xl:text-3xl text-gray-900 font-medium mb-3 pt-5 md:py-10 md:pb-5 md:pt-0  ">
                  Sign in
                </p>
                <form className="flex flex-col" onSubmit={handleSubmit}>
                  <label htmlFor="email" className="2xl:text-lg 2xl:py-2">
                    Email Address
                  </label>
                  <input
                    ref={emailRef}
                    className="rounded-sm p-2 focus:outline-none focus:ring-1 bg-account_background 2xl:text-lg"
                    type="email"
                    id="email"
                    placeholder="Email address"
                    required
                  />

                  <label
                    htmlFor="password"
                    className="mt-3 2xl:text-lg 2xl:py-2"
                  >
                    Password
                  </label>
                  <input
                    ref={passwordRef}
                    className="rounded-sm p-2 focus:outline-none focus:ring-1 bg-account_background 2xl:text-lg"
                    type="password"
                    id="password"
                    placeholder="Password"
                    required
                  />
                  <p className="text-center mt-2 text-red-600">
                    {errorMessage}{' '}
                  </p>
                  <ButtonPrimary
                    title="Sign in with your email"
                    loading={loading}
                  />

                  <div className="pt-2 grid grid-cols-2 justify-items-end items-center  md:text-sm 2xl:text-lg">
                    <span></span>
                    <span className="text-secondary underline">
                      <Link to="/forgotpassword">Forget Password</Link>
                    </span>
                  </div>
                </form>

                <div className="  flex flex-col">
                  <div className="grid grid-cols-5 justify-center items-center py-2">
                    <hr className="col-span-2 "></hr>
                    <span className="text-center w 2xl:text-lg">Or</span>
                    <hr className="col-span-2"></hr>
                  </div>

                  <button
                    onClick={signInWithGoogle}
                    className="bg-white text-white flex justify-center py-2 px-5 mt-3 rounded-md focus:outline-none border border-gray-300 2xl:py-3"
                  >
                    <svg
                      class="h-6 w-6 mr-2 2xl:h-7"
                      width="20"
                      height="20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0)">
                        <path
                          d="M4.432 12.086l-.696 2.6-2.544.053A9.955 9.955 0 010 10c0-1.658.403-3.222 1.118-4.599l2.266.415.992 2.252A5.944 5.944 0 004.056 10c0 .734.132 1.437.376 2.086z"
                          fill="#FBBB00"
                        />
                        <path
                          d="M19.825 8.132a10.018 10.018 0 01-.044 3.956 9.998 9.998 0 01-3.52 5.71h-.001l-2.853-.146-.404-2.52a5.96 5.96 0 002.564-3.044H10.22V8.132h9.605z"
                          fill="#518EF8"
                        />
                        <path
                          d="M16.26 17.798A9.958 9.958 0 0110 20a9.998 9.998 0 01-8.808-5.261l3.24-2.653a5.946 5.946 0 008.57 3.045l3.258 2.667z"
                          fill="#28B446"
                        />
                        <path
                          d="M16.383 2.302l-3.24 2.652a5.948 5.948 0 00-8.767 3.114L1.12 5.401h-.001A9.998 9.998 0 0110 0c2.426 0 4.651.864 6.383 2.302z"
                          fill="#F14336"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0">
                          <path fill="#fff" d="M0 0h20v20H0z" />
                        </clipPath>
                      </defs>
                    </svg>
                    <p className="text-gray-500 mr-4 2xl:text-lg">
                      Sign in with Google
                    </p>
                  </button>

                  {/* <button
                    onClick={signInWithFacebook}
                    className="bg-white text-white flex justify-center  py-2 px-5 mt-6 rounded-md focus:outline-none border border-gray-300 2xl:py-3"
                  >
                    <svg
                      class="h-6 w-6 mr-2 2xl:h-7"
                      width="20"
                      height="20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10 20c5.523 0 10-4.477 10-10S15.523 0 10 0 0 4.477 0 10s4.477 10 10 10z"
                        fill="#3B5998"
                      />
                      <path
                        d="M12.514 10.392H10.73v6.537H8.026v-6.537H6.74V8.094h1.286V6.607c0-1.063.505-2.728 2.728-2.728l2.002.009v2.23h-1.453c-.238 0-.573.119-.573.626v1.352h2.02l-.236 2.296z"
                        fill="#fff"
                      />
                    </svg>
                    <p className="text-gray-500 2xl:text-lg">
                      Sign in with Facebook
                    </p>
                  </button>
                */}
                </div>
              </div>
            </div>
            <div className="mt-auto  ">
              {/* <div className="hidden md:flex">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="45"
                  height="45"
                  fill="none"
                >
                  <path
                    fill="#07173B"
                    d="M22.5 43.125 16.875 37.5h-7.5c-1.031 0-1.914-.367-2.65-1.102-.734-.736-1.101-1.618-1.1-2.648V7.5c0-1.031.367-1.914 1.103-2.65.735-.734 1.617-1.101 2.647-1.1h26.25c1.031 0 1.914.367 2.65 1.103.734.735 1.101 1.617 1.1 2.647v26.25c0 1.031-.367 1.914-1.102 2.65-.736.734-1.618 1.101-2.648 1.1h-7.5L22.5 43.125ZM9.375 33.75h9l4.125 4.125 4.125-4.125h9V7.5H9.375v26.25Zm13.031-2.25c.657 0 1.212-.227 1.665-.68.454-.454.68-1.009.679-1.664 0-.656-.226-1.211-.679-1.665a2.254 2.254 0 0 0-1.665-.678c-.656 0-1.21.226-1.663.68a2.273 2.273 0 0 0-.68 1.663c0 .657.226 1.212.68 1.665.454.454 1.008.68 1.663.679Zm1.782-7.219c0-1.125.125-1.953.375-2.484.25-.531.78-1.203 1.593-2.016 1.094-1.093 1.867-2.008 2.32-2.743.452-.735.679-1.57.68-2.507 0-1.656-.562-2.992-1.687-4.008-1.125-1.017-2.64-1.524-4.547-1.523-1.719 0-3.18.422-4.382 1.266-1.203.843-2.039 2.015-2.509 3.515L19.125 15c.219-.844.656-1.524 1.313-2.04.656-.516 1.421-.774 2.296-.773.844 0 1.547.227 2.11.681.562.454.843 1.055.843 1.804 0 .531-.171 1.094-.515 1.687-.344.594-.922 1.25-1.735 1.97-1.03.905-1.742 1.773-2.133 2.602-.391.828-.586 1.945-.585 3.35h3.468Z"
                  />
                </svg>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Signin
