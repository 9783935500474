import { Dialog, Transition } from '@headlessui/react'
import { CheckIcon } from '@heroicons/react/outline'
import { Fragment, useRef, useState } from 'react'
import firebase from 'firebase/app'
import { reactLocalStorage } from 'reactjs-localstorage'
import { NotificationManager } from 'react-notifications'
import 'react-notifications/lib/notifications.css'
import CurrencyFormat from 'react-currency-format'
import { usePaystackPayment } from 'react-paystack'
import InvoiceData from '../jsonData/InvoiceData'
import dateFormat, { masks } from 'dateformat'

export default function PayNow({
  amount,
  session,
  term,
  student,
  studentList,
  index,
  setRefresh,
  setUnPaidClick,
  fees,
  selectedFeesIndex,
  //setStatus,
}) {
  const [open, setOpen] = useState(false)
  const [open2, setOpen2] = useState(false)
  const [showDeleteDialog, setshowDeleteDialog] = useState(false)

  const cancelButtonRef = useRef()
  const deleteCancelButtonRef = useRef()

  const tabClickStyle =
    'uppercase text-sm md:text-sm  py-4 px-4 md:px-6 block  focus:outline-none text-primary md:text-white  border-b-2 font-medium border-primary md:border-white'
  const saveButtonDefault =
    'px-3 py-1.5  rounded-sm outline-none focus:outline-none text-gray-300  bg-gray-100'
  const saveButtonClick =
    'px-3 py-1.5 rounded-sm outline-none focus:outline-none text-white bg-primary rounded-full'
  const [errorMessage, setError] = useState('')
  const [loading, setLoading] = useState(false)

  function closeModal() {
    setOpen(false)
  }

  function openModal() {
    setOpen(true)
    //  JSON.stringify(data)
    setReceiptData()
  }

  var NumberFormat = require('react-currency-format')

  function setReceiptData() {
    const now = new Date()

    InvoiceData.fullname = student.name
    InvoiceData.id = student.studentid
    InvoiceData.class = student.class
    InvoiceData.session = session
    InvoiceData.term = term
    InvoiceData.amount = student.amount
    InvoiceData.balance = '0.00'
    InvoiceData.total = (
      <NumberFormat
        value={amount}
        displayType={'text'}
        thousandSeparator={true}
        prefix={''}
      />
    )
    InvoiceData.trans_date = dateFormat(now, 'mmmm dS, yyyy, h:MM:ss TT')
    InvoiceData.invoice_no = new Date().getTime().toString()
    InvoiceData.items = [
      {
        sno: 1,
        desc: 'SCHOOL FEES',
        amount: (
          <NumberFormat
            value={amount}
            displayType={'text'}
            thousandSeparator={true}
            prefix={''}
          />
        ),
      },
      {
        sno: 2,
        desc: 'TOTAL PAID',
        amount: (
          <NumberFormat
            value={amount}
            displayType={'text'}
            thousandSeparator={true}
            prefix={''}
          />
        ),
      },
      {
        sno: 3,
        desc: 'BALANCE',
        amount: (
          <NumberFormat
            value={0.0}
            displayType={'text'}
            thousandSeparator={true}
            prefix={''}
          />
        ),
      },
    ]
  }

  function closeModal2() {
    setOpen2(false)
  }

  function openModal2() {
    closeDialogModal()
    setOpen2(true)
  }

  function closeDialogModal() {
    //console.log('index:: ', unpaid[index])
    studentList[index].status = true
    setRefresh(true)
    setUnPaidClick(studentList)
    updateFees()
    setshowDeleteDialog(false)
  }

  const updateFees = () => {
    setLoading(true)
    setError('')
    const newObj = { ...student, fees: amount, amount: amount, balance: 0 }
    fees.classes[selectedFeesIndex].paid.push(newObj)

    var db = firebase.firestore()
    db.collection('fees')
      .doc(
        reactLocalStorage
          .get('current-school')
          .concat('_')
          .concat(session.replace('/', '_'))
          .concat('_')
          .concat(term.replace(' ', '_'))
      )
      .update({
        classes: fees.classes,
        date: firebase.firestore.Timestamp.now(),
      })
      .then((docRef) => {
        NotificationManager.info('Saved Successful')
        setLoading(false)
      })
      .catch((error) => {
        setError(error.message)
        setLoading(false)
      })
  }

  function cancelDialogModal() {
    //console.log('index:: ', unpaid[index])
    setshowDeleteDialog(false)
  }

  function showDialogModal() {
    setshowDeleteDialog(true)
  }

  const handleSave = () => {
    // if (name !== "") {
    //   const values = [
    //     ...document,
    //     {
    //       name: name,
    //       id: name,
    //       click: true,
    //       deletable: true,
    //     },
    //   ];
    //   setDocument(values);
    //   save(values);
    // } else {
    //   NotificationManager.info("Enter the require fields");
    // }
  }

  const save = (values) => {
    setLoading(true)
    setError('')
    var db = firebase.firestore()
    db.collection('school-subjects')
      .doc(reactLocalStorage.get('current-school'))
      .update({
        subject: values,
        date: firebase.firestore.Timestamp.now(),
      })
      .then((docRef) => {
        NotificationManager.info('Added Successful')
        setLoading(false)
      })
      .catch((error) => {
        setError(error.message)
        setLoading(false)
      })
    closeModal()
  }
  const config = {
    reference: new Date().getTime().toString(),
    email: 'hashim.ismaila@gmail.com',
    amount: amount * 100,
    publicKey: 'pk_live_a98fa645f1b9c16cbf997c0236b7bf518a2371d6',
  }

  // you can call this function anything
  const onSuccess = (reference) => {
    // Implementation for whatever you want to do with reference and after success call.
    console.log(reference)
  }

  // you can call this function anything
  const onClose = () => {
    // implementation for  whatever you want to do when the Paystack dialog closed.
    console.log('closed')
  }

  const PaystackHookExample = () => {
    const initializePayment = usePaystackPayment(config)
    return (
      <div>
        <button
          className="px-5 py-1.5  outline-none focus:outline-none text-white bg-primary rounded-full"
          onClick={() => {
            initializePayment(onSuccess, onClose)
          }}
        >
          Online
        </button>
      </div>
    )
  }

  return (
    <>
      <div className="">
        <button
          type="button"
          onClick={openModal}
          className="space-x-1 flex justify-center outline-none focus:outline-none border py-1.5 px-4 rounded-full bg-white text-primary"
        >
          {/* <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-5 w-5"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
              clipRule="evenodd"
            />
          </svg> */}
          Pay Now
        </button>
      </div>

      {/* Student information */}
      <Transition show={open} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-y-auto "
          initialFocus={cancelButtonRef}
          static
          open={open}
          onClose={closeModal}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-full max-w-md border border-gray-100  md:border-none text-sm  overflow-hidden text-left align-middle transition-all transform bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 py-3  md:text-white bg-white md:bg-primary"
                >
                  <nav class="flex flex-row justify-center">
                    <button onClick={() => {}} className={tabClickStyle}>
                      Student Information
                    </button>
                  </nav>
                </Dialog.Title>
                {
                  <form className="flex flex-col px-5 pt-5">
                    <table class="table-fixed border-collapse border border-slate-400">
                      <tbody>
                        <tr>
                          <td className="border border-slate-300 p-2">
                            Student ID
                          </td>
                          <td className="border border-slate-300 p-2 uppercase ">
                            {student.studentid}
                          </td>
                        </tr>
                        <tr>
                          <td className="border border-slate-300 p-2 ">
                            Student Name
                          </td>
                          <td className="border border-slate-300 p-2 uppercase ">
                            {student.name}
                          </td>
                        </tr>
                        <tr>
                          <td className="border border-slate-300 p-2 ">
                            Class
                          </td>
                          <td className="border border-slate-300 p-2 ">
                            {student.class}
                          </td>
                        </tr>
                        <tr>
                          <td className="border border-slate-300 p-2 ">
                            Session
                          </td>
                          <td className="border border-slate-300 p-2 ">
                            {session}
                          </td>
                        </tr>
                        <tr>
                          <td className="border border-slate-300 p-2 ">Term</td>
                          <td className="border border-slate-300 p-2 ">
                            {term}
                          </td>
                        </tr>
                        <tr>
                          <td className="border border-slate-300 p-2 ">Fee</td>
                          <td className="border border-slate-300 p-2 ">
                            {/* <span className="text-lg">&#8358;</span> */}
                            <CurrencyFormat
                              className="text-lg"
                              value={
                                student.discount
                                  ? amount - (student.discount / 100) * amount
                                  : amount
                              }
                              displayType={'text'}
                              thousandSeparator={true}
                              prefix={'₦'}
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </form>
                }
                <div className="px-5 py-5 flex items-end justify-end space-x-5">
                  <button
                    onClick={closeModal}
                    className="px-3 py-1.5 rounded-sm outline-none focus:outline-none text-gray-500 hover:bg-gray-100"
                  >
                    Cancel
                  </button>

                  <button
                    onClick={() => {
                      showDialogModal()
                    }}
                    className="px-3 py-1.5  outline-none focus:outline-none text-white bg-primary rounded-full"
                  >
                    {loading ? (
                      <i
                        className={'fas fa-circle-notch fa-spin text-base'}
                      ></i>
                    ) : (
                      <p className="2xl:text-lg">Confirm</p>
                    )}
                  </button>

                  <PaystackHookExample />
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>

      {/* Confirmation */}
      <Transition show={showDeleteDialog} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-y-auto"
          initialFocus={deleteCancelButtonRef}
          static
          open={showDeleteDialog}
          onClose={cancelDialogModal}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0 ">
            <div className="rounded-md">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
              </Transition.Child>
            </div>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block align-bottom bg-white text-left  transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full rounded-md shadow-lg ring-1 ring-black ring-opacity-5 ">
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4 ">
                  <div className="sm:flex sm:items-start ">
                    <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-primary sm:mx-0 sm:h-10 sm:w-10">
                      <CheckIcon
                        className="h-6 w-6  text-white"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left select-none">
                      <Dialog.Title
                        as="h3"
                        className="text-lg leading-6 font-medium text-gray-900"
                      >
                        Confirm {student.name}
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500 select-none">
                          Are you sure you want to confirm this transaction?
                          This action cannot be undone.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse ">
                  <button
                    className="w-full  inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary text-base font-medium text-white hover:bg-blue-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={() => closeDialogModal()}
                  >
                    {loading ? (
                      <i
                        className={'fas fa-circle-notch fa-spin text-base'}
                      ></i>
                    ) : (
                      <p className="2xl:text-lg">Confirm</p>
                    )}
                  </button>
                  <button
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none  sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={() => cancelDialogModal()}
                    ref={deleteCancelButtonRef}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}
