import firebase from 'firebase/app'
import { reactLocalStorage } from 'reactjs-localstorage'
import { useState, useRef, useEffect } from 'react'
import axios from 'axios'
import { NotificationManager } from 'react-notifications'
import 'react-notifications/lib/notifications.css'

const MessageParent = () => {
  const [messages, setMessages] = useState([])
  const [loading, setLoading] = useState(false)
  const [load, setLoad] = useState()
  const [all, setAll] = useState(false)
  const [inApp, setInApp] = useState(true)
  const [sms, setSms] = useState(false)
  const [error, setError] = useState('')
  const [loadingStudent, setLoadingStudent] = useState()
  const [classDocument, setClassDocument] = useState([])
  const [classValue, setClassValue] = useState('select')
  const [students, setStudents] = useState([])
  const [studentCount, setStudentCount] = useState()

  const defaultStyle = 'px-3 py-1.5 bg-gray-50 select-none cursor-default'
  const clickStyle = 'py-1.5 cursor-default'
  const messageRef = useRef()

  useEffect(() => {
    getMessages()
    getClasses()
  }, [])

  const getClasses = () => {
    setLoading(true)
    var db = firebase.firestore()
    var query = db.collection('school-classes')
    query
      .doc(reactLocalStorage.get('current-school'))
      .get()
      .then((doc) => {
        if (doc.exists) {
          setClassDocument(doc.data().classes)
        }
        setLoading(false)
      })
      .catch((error) => {
        console.log('Error getting document:', error)
      })
  }

  const activated = reactLocalStorage.get('activated')

  const handleClassSelection = (e) => {
    setClassValue(e.target.value)
    getStudents(e.target.value)
  }

  const getStudents = (selectClass) => {
    setLoadingStudent(true)
    var db = firebase.firestore()
    var record = []
    db.collection('students')
      .where(
        'school_class',
        '==',
        reactLocalStorage.get('current-school').concat(selectClass)
      )
      .orderBy('name', 'asc')
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          var s = doc.data()
          ///console.log('S:: ', s.parent[0].phone1)
          //update(s, doc.id)
          //deleteStudent(doc.id)
          s.doc_id = doc.id
          if (s.state.length === 0) {
            s.state = 'Gombe'
            s.lga = 'Gombe'
          }

          record.push(s)
        })
        setStudents(record)
        setStudentCount(record.length)
        setLoadingStudent(false)
      })
      .catch((error) => {
        setLoadingStudent(false)
        console.log('Error getting documents: ', error)
      })
  }

  const getMessages = () => {
    setLoading(true)
    var db = firebase.firestore()
    var record = []
    db.collection('message-parent')
      .orderBy('date', 'desc')
      .limit(10)
      .where('school', '==', reactLocalStorage.get('current-school'))
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          record.push(doc.data())
        })
        setMessages(record)
        setLoading(false)
      })
      .catch((error) => {
        console.log('Error getting documents: ', error)
      })
  }

  const selectAll = () => {
    setAll(!all)
    var record = []
    students.map((student) => {
      var t = student
      if (t.phone && t.phone.length >= 11) {
        t.click = all ? false : true
        record.push(t)
      } else {
        t.click = false
        record.push(t)
      }
    })
    setStudents(record)
  }

  function getSelected() {
    var record = []
    students.map((student) => {
      var t = student
      if (t.click && t.phone && t.phone.length >= 11) {
        record.push({
          doc_id: t.doc_id,
          name: t.name,
          phone: t.phone,
          fcm_token: t.fcm_token ? t.fcm_token : '',
        })
      }
    })
    return record
  }

  function updateSelection(indexPass) {
    setAll(false)
    var record = []
    students.map((student, index) => {
      var t = student
      if (index === indexPass) {
        t.click = !t.click
      }
      record.push(t)
    })
    setStudents(record)
  }

  const LastMessage = ({ message }) => {
    return (
      <div className="">
        <div className="my-2 grid grid-flow-col grid-cols-5 justify-center">
          <hr className="col-span-2 mt-2" />
          <span className="text-xs text-center">
            {message.displayMonth}
          </span>{' '}
          <hr className="col-span-2  mt-2" />
        </div>
        <div className="bg-account_click text-sm ml-20 rounded-md p-2">
          <p className=" ">{message.body}</p>
          <p className="text-right">{message.time}</p>
        </div>
      </div>
    )
  }

  function sendNotification() {
    if (activated) {
      if (all) {
        sendAll()
      } else {
        if (messageRef.current.value !== '' && getSelected().length > 0) {
          sendOneByOne()
        } else if (messageRef.current.value === '') {
          NotificationManager.error('Enter message')
        } else {
          NotificationManager.error('Select students')
        }
      }
    } else {
      NotificationManager.error(
        'Pay your school termly subscription to send message'
      )
    }
  }

  async function sendAll() {
    const school = reactLocalStorage.get('current-school')
    if (messageRef.current.value !== '' && getSelected().length > 0) {
      setLoad(true)
      await axios
        .post(
          'https://fcm.googleapis.com/fcm/send',
          {
            data: {},
            notification: {
              title: reactLocalStorage.get('school-name'),
              body: messageRef.current.value,
            },
            to: `/topics/${school}`,
          },
          {
            headers: {
              Authorization:
                'key=AAAAfgQduWg:APA91bGm34eHAmrncrOaRRE0IVWdq4Lg7vWy0iIRZYuHYdQseQsbxWp2mbhQMFivYdmvJE0o3UQXMarq0fOvBfKBwSIHLnpm0bA_NdSD7R8vICbPXrAARYbkNb8X2uU6ISpD2siOJIvZ',
            },
          }
        )
        .then((response) => {
          if (response.status === 200) {
            saveMessage()
          }
        })
    } else if (messageRef.current.value === '') {
      NotificationManager.error('Enter message')
    } else {
      NotificationManager.error('Select students')
    }
  }

  async function sendOneByOne() {
    var count = 0
    setLoad(true)
    var selectedTeachers = getSelected()
    selectedTeachers.map((student) => {
      count++
      if (typeof student.fcm_token != 'undefined' && student.fcm_token !== '') {
        axios
          .post(
            'https://fcm.googleapis.com/fcm/send',
            {
              data: {},
              notification: {
                title: reactLocalStorage.get('school-name'),
                body: messageRef.current.value,
              },
              to: `${student.fcm_token}`,
            },
            {
              headers: {
                Authorization:
                  'key=AAAAfgQduWg:APA91bGm34eHAmrncrOaRRE0IVWdq4Lg7vWy0iIRZYuHYdQseQsbxWp2mbhQMFivYdmvJE0o3UQXMarq0fOvBfKBwSIHLnpm0bA_NdSD7R8vICbPXrAARYbkNb8X2uU6ISpD2siOJIvZ',
              },
            }
          )
          .then((response) => {})
      }
    })
    if (count == selectedTeachers.length) {
      saveMessage()
    }
    // else if(count) {
    //   setLoad(false)
    //   //NotificationManager.error('Messages Some selected student are yet to signup')
    // }
  }

  function formatAMPM(date) {
    var hours = date.getHours()
    var minutes = date.getMinutes()
    var ampm = hours >= 12 ? 'pm' : 'am'
    hours = hours % 12
    hours = hours ? hours : 12 // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes
    var strTime = hours + ':' + minutes + ' ' + ampm
    return strTime
  }

  function formatDateMonthName(date) {
    const month = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ]
    let name = month[date.getMonth()]
    let day = date.getDate()
    let year = date.getFullYear()
    return name.concat(' ').concat(day).concat(', ').concat(year)
  }
  const saveMessage = () => {
    var db = firebase.firestore()
    var selected = getSelected()
    db.collection('message-parent')
      .add({
        school: reactLocalStorage.get('current-school'),
        type: inApp ? 'In-App' : 'SMS',
        body: messageRef.current.value,
        time: formatAMPM(new Date()),
        displayMonth: formatDateMonthName(new Date()),
        recipient: selected,
        recipient_number: selected.length,
        date: firebase.firestore.Timestamp.now(),
      })
      .then((docRef) => {
        var allMessages = [...messages]
        var newMessage = {
          school: reactLocalStorage.get('current-school'),
          type: inApp ? 'In-App' : 'SMS',
          body: messageRef.current.value,
          time: formatAMPM(new Date()),
          displayMonth: formatDateMonthName(new Date()),
          recipient: selected,
          recipient_number: selected.length,
          date: firebase.firestore.Timestamp.now(),
        }
        allMessages.unshift(newMessage)
        setMessages(allMessages)

        setLoad(false)
        // messageRef.current.value = ''
        NotificationManager.info('Send Successful')
      })
      .catch((error) => {
        setError(error.message)
      })
  }
  const updateSmsBalance = (smsAmount) => {
    setLoading(true)
    var db = firebase.firestore()
    var path = reactLocalStorage.get('current-school')

    var smsBalance = reactLocalStorage.get('sms-balance')
    //var smsAmount = parseInt(getSelected().length) * page * 5

    db.collection('sms-wallet')
      .doc(path)
      .update({
        balance: parseInt(smsBalance) - parseInt(smsAmount),
        date: firebase.firestore.Timestamp.now(),
      })
      .then((docRef) => {
        setLoading(false)
        reactLocalStorage.set(
          'sms-balance',
          parseInt(smsBalance) - parseInt(smsAmount)
        )
        //getSMSBalance()
      })
      .catch((error) => {
        setLoading(false)
      })
  }
  function getNumbers() {
    var selectedStudents = getSelected()
    if (messageRef.current.value !== '' && selectedStudents.length > 0) {
      var numbers = []
      selectedStudents.forEach((student, index) => {
        var mobile = student.phone
        var first = student.phone[0]
        if (first === '0') {
          mobile = student.phone.replace(first, '234')
        } else {
          mobile = student.phone
        }
        numbers.push(mobile)
      })
    } else if (messageRef.current.value === '') {
      NotificationManager.error('Enter message')
    } else {
      NotificationManager.error('Select students')
    }

    return numbers.join()
  }

  async function sendSMS() {
    var smsBalance = reactLocalStorage.get('sms-balance')
    var size = messageRef.current.value.trim().length <= 160 ? 1 : 2
    var smsAmount = parseInt(getSelected().length) * size * 4

    if (smsAmount > smsBalance) {
      NotificationManager.error('Fund your wallet to continue')
      return
    }
    const numbers = getNumbers()

    if (activated && numbers !== '') {
      setLoad(true)
      const message = messageRef.current.value.trim()
      await axios
        .post(
          `https://www.bulksmsnigeria.com/api/v1/sms/create?api_token=XBm5xvGn3hwDgY4oQJ8jZMEaWco6aUZIXX6j2HDKNXgh7XBsMwkOMnJnFAZS &from=EDUSAF&to=${numbers}&body=${message}&dnd=2`
        )
        .then((response) => {
          if (response.status === 200) {
            saveMessage()
            updateSmsBalance(smsAmount)
          }
        })
    } else if (!activated) {
      NotificationManager.error(
        'Pay your school termly subscription to send message'
      )
    } else {
      NotificationManager.info('Update student record with phone number')
    }
  }

  // if (loading) {
  //   return (
  //     <div className="grid  justify-center items-center ">
  //       <i className={' text-primary fas fa-circle-notch fa-spin text-5xl'}></i>
  //     </div>
  //   )
  // }
  return (
    <div className="">
      <div className="">
        <div className="m:0 md:mx-12 md:my-5 ">
          <div className="my-5 max-h-120  md:max-h-120 xl_1:max-h-130 2xl:max-h-130 overflow-y-auto ">
            <div className="grid gap-5 grid-flow-row md:grid-flow-col md:grid-cols-3 mx-3">
              <div className="  md:col-span-1">
                {loadingStudent ? (
                  <div className="grid  justify-center items-center ">
                    <i
                      className={
                        ' text-primary fas fa-circle-notch fa-spin text-5xl'
                      }
                    ></i>
                  </div>
                ) : (
                  <div className="overflow-y-auto max-h-115   ">
                    <div className="">
                      <select
                        className="rounded-sm p-2 focus:outline-none focus:ring-1 w-full border bg-white"
                        placeholder="class"
                        required
                        name="classValue"
                        value={classValue}
                        onChange={(e) => handleClassSelection(e)}
                      >
                        <option value="select" selected>
                          select
                        </option>
                        {classDocument.map((classes, index) =>
                          classes.click ? (
                            // classes.section == sectionValue ? (
                            <option
                              className="uppercase"
                              key={classes.id}
                              value={classes.name}
                            >
                              {classes.name}
                            </option>
                          ) : (
                            // ) : (
                            //   ''
                            // )
                            ''
                          )
                        )}
                      </select>
                    </div>
                    <input
                      className="my-3"
                      type="checkbox"
                      name="select"
                      checked={all}
                      onChange={() => selectAll()}
                    />
                    <span className="px-1.5 text-primary font-semibold text-sm">
                      All Students ({students.length})
                    </span>
                    {students.map((student, index) => (
                      <div key={student.id} className={clickStyle}>
                        <div className="flex justify-between">
                          <div className="">
                            <input
                              type="checkbox"
                              id={student.id}
                              value={student.name}
                              checked={student.click}
                              disabled={
                                student.phone && student.phone.length >= 11
                                  ? false
                                  : true
                              }
                              onChange={() => {
                                updateSelection(index)
                              }}
                              // onChange={() => {
                              //   var all = { ...students }
                              //   var current = all[index]
                              //   current.click = !student.click

                              //   //Object.assign(all, { index: current })
                              //   setStudents(all)
                              //   student.click = false
                              // }}
                            />{' '}
                            <label className="capitalize text-sm">
                              {student.name}
                            </label>
                          </div>
                        </div>
                        <hr />
                      </div>
                    ))}
                  </div>
                )}
              </div>

              <div className="bg-gray-50 p-3  col-span-2 ">
                <div className="  grid ">
                  <textarea
                    ref={messageRef}
                    name=""
                    id=""
                    cols="30"
                    rows="5"
                    placeholder="Send message to students"
                    className="min-w-full outline-none p-2 bg-white rounded-md rounded-b-none text-sm"
                  ></textarea>
                  <div className="bg-white rounded-md rounded-t-none mt-1 py-2 px-3 ">
                    <div className="grid  grid-flow-col grid-cols-3 gap-2">
                      <div className="col-span-1   px-2"></div>
                      <div className="col-span-1   px-2"></div>

                      <div
                        onClick={() => {
                          sendSMS()
                        }}
                        className="col-span-1 grid grid-flow-col justify-end items-center bg-gray-50 border-gray-50 border-2 rounded-sm px-2"
                      >
                        {load ? (
                          <i
                            className={'fas fa-circle-notch fa-spin text-base'}
                          ></i>
                        ) : (
                          <div className="flex">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="18"
                              fill="none"
                              className=""
                            >
                              <path
                                fill="#07173B"
                                fill-opacity=".47"
                                d="m1.914 2.886 7.153 3.066L1.905 5l.01-2.114Zm7.143 8.305-7.152 3.066v-2.114l7.152-.953ZM.01 0 0 6.667 14.286 8.57 0 10.476l.01 6.667L20 8.57.01 0Z"
                              />
                            </svg>
                            <label className="text-sm">Send</label>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="overflow-y-auto max-h-80">
                  {messages.map((message) => (
                    <LastMessage message={message} />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MessageParent
