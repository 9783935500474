import { useState, useEffect } from 'react'
import AddClasses from './AddClasses'
import firebase from 'firebase/app'
import { reactLocalStorage } from 'reactjs-localstorage'
import { NotificationManager } from 'react-notifications'
import 'react-notifications/lib/notifications.css'

const Promotion = ({
  classDocument,
  classEmptyRecord,
  loading,
  setClassDocument,
}) => {
  const defaultStyle = 'px-3 py-1.5 bg-gray-50 select-none cursor-default'
  const clickStyle = 'px-3 py-1.5 select-none cursor-default'

  const [load, setLoad] = useState()
  const [error, setError] = useState()
  const [classValue, setClassValue] = useState('select')
  const [promotedclassValue, setPromotedClassValue] = useState('select')
  const [termValue, setTermValue] = useState('First Term')
  const [loading2, setLoading2] = useState(false)
  const [all, setAll] = useState(false)

  const [students, setStudents] = useState([])

  //const [paid, setPaid]= useState([])

  useEffect(() => {}, [])

  const selectAll = () => {
    setAll(!all)
    var record = []
    students.map((student) => {
      var t = student
      t.click = !t.click
      record.push(t)
    })
    setStudents(record)
  }
  function updateSelection(indexPass) {
    setAll(false)
    var record = []
    students.map((student, index) => {
      var t = student
      if (index === indexPass) {
        t.click = !t.click
      }
      record.push(t)
    })
    setStudents(record)
  }

  const handleClassSelection = (e) => {
    setClassValue(e.target.value)
    getStudents(e.target.value)
  }
  const handlePromotedClassSelection = (e) => {
    setPromotedClassValue(e.target.value)
  }

  const update = () => {
    var db = firebase.firestore()
    setLoad(true)
    students.map((student) => {
      if (student.click) {
        db.collection('students')
          .doc(student.doc_id)
          .update({
            school: reactLocalStorage.get('current-school'),
            school_class: reactLocalStorage
              .get('current-school')
              .concat(promotedclassValue),
            name: student.name,
            studentid: student.studentid,
            class: promotedclassValue,
            gender: student.gender,
            dob: student.dob,
            state: student.state,
            lga: student.lga,
            status: false,
            phone: student.phone ? student.phone : '',
            previous_class: student.class,
            promoted_session: reactLocalStorage
              .get('session')
              .concat(reactLocalStorage.get('term')),
            parent: [],
            date: firebase.firestore.Timestamp.now(),
          })
          .then((docRef) => {})
          .catch((error) => {
            setLoad(false)
          })
        setLoad(false)

        // var stu = [...students]
        // setStudents(stu)
      } else {
        setLoad(false)
        NotificationManager.info('Select student to promoted')
      }
    })
    getStudents(classValue)
    NotificationManager.info('Selected students have been promoted')
  }
  const getStudents = (selectClass) => {
    setLoad(true)
    var db = firebase.firestore()
    var record = []
    db.collection('students')
      .where(
        'school_class',
        '==',
        reactLocalStorage.get('current-school').concat(selectClass)
      )
      .orderBy('studentid', 'asc')
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          var session = reactLocalStorage.get('session')
          var term = reactLocalStorage.get('term')
          var s = doc.data()

          var promtedParam = s.promoted_session
          if (promtedParam !== session.concat(term)) {
            s.click = false
            s.doc_id = doc.id
            record.push(s)
          }
        })
        setStudents(record)
        setLoad(false)
      })
      .catch((error) => {
        setLoad(false)
        console.log('Error getting documents: ', error)
      })
  }

  if (loading) {
    return (
      <div className="grid  justify-center items-center ">
        <i className={' text-primary fas fa-circle-notch fa-spin text-5xl'}></i>
      </div>
    )
  }

  return (
    <div className="mx-4 md:mx-0">
      <div className=" grid grid-flow-col  justify-start items-center mb-5">
        <div className="text-primary  flex text-center items-center rounded-sm space-x-4">
          <select
            className="rounded-sm p-2 w-40 md:w-56 focus:outline-none focus:ring-1 bg-gray-50 w-full"
            placeholder="class"
            required
            name="classValue"
            value={classValue}
            onChange={(e) => handleClassSelection(e)}
          >
            <option value="from">From</option>
            {/* {[...classDocument].reverse().map((item) => {
              return (
                <option id={item.id} value={item.name}>
                  {item.name}
                </option>
              )
            })} */}

            {classDocument.map((subject, index) => (
              <option id={index} value={subject.name}>
                {subject.name}
              </option>
            ))}
          </select>

          <select
            className="rounded-sm p-2 w-40 md:w-56 focus:outline-none focus:ring-1 bg-gray-50 w-full"
            placeholder="promotedclassValue"
            required
            name="promotedclassValue"
            value={promotedclassValue}
            onChange={(e) => handlePromotedClassSelection(e)}
          >
            <option value="to">To</option>
            {classDocument.map((subject, index) =>
              subject.name !== classValue ? (
                <option id={index} value={subject.name}>
                  {subject.name}
                </option>
              ) : (
                ''
              )
            )}
            <option value="graduated">Graduated</option>
          </select>

          <div className="">
            <input
              type="checkbox"
              name="select"
              checked={all}
              onChange={() => selectAll()}
            />
            <span className="px-1.5 ">All ({students.length})</span>
          </div>

          {/* <button
            type="button"
            onClick={() => getTerm()}
            className="space-x-1 flex justify-center outline-none focus:outline-none border py-2 px-4 rounded-sm"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              class="h-5 w-5"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
              />
            </svg>
            Search
          </button> */}
        </div>
      </div>
      {!load ? (
        <div className="justify-start items-center mb-5  ">
          <div className="flex flex-col max-h-96  md:max-h-82 xl_1:max-h-120 2xl:max-h-130 overflow-y-auto    shadow-md border-b border-gray-200 rounded-sm ">
            <table className="relative w-full min-w-full   divide-y divide-gray-200 ">
              <tbody className="bg-white divide-y divide-gray-200  ">
                {students.map((student, index) => (
                  <tr key={student.id}>
                    <td
                      key={1}
                      className="sticky left-0  bg-white px-4 py-2 whitespace-nowrap "
                    >
                      <div className="flex items-center">
                        <div className="ml-4">
                          <div className="text-sm font-medium capitalize text-gray-900">
                            {student.name}
                          </div>
                          <div className="text-sm capitalize text-gray-500">
                            {student.studentid}
                          </div>
                        </div>
                      </div>
                    </td>

                    <td
                      key={2}
                      className=" px-5  whitespace-nowrap text-right text-sm font-medium z-30"
                    >
                      <input
                        type="checkbox"
                        value={student.id}
                        checked={student.click}
                        onChange={() => {
                          updateSelection(index)
                        }}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        <div className="grid  justify-center items-center ">
          <i
            className={' text-primary fas fa-circle-notch fa-spin text-5xl'}
          ></i>
        </div>
      )}

      {students.length > 0 ? (
        <div className="grid  px-4 md:px-0  justify-end">
          <button
            onClick={() => {
              promotedclassValue !== 'select' && promotedclassValue !== 'to'
                ? update()
                : NotificationManager.info('Select class to be promoted')
            }}
            className="px-5 py-2  rounded-sm outline-none focus:outline-none text-white  bg-primary"
          >
            {load ? (
              <i className={'fas fa-circle-notch fa-spin text-base'}></i>
            ) : (
              <p className="2xl:text-lg">Promote</p>
            )}
          </button>
        </div>
      ) : (
        ''
      )}
    </div>
  )
}

export default Promotion
