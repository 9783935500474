import { Menu, Transition } from '@headlessui/react'
import { Fragment, useRef, useState } from 'react'
import { reactLocalStorage } from 'reactjs-localstorage'
import { useAuth } from '../services/AuthContext'
import { Link, useHistory } from 'react-router-dom'
import firebase from 'firebase/app'
import { storage, auth } from '../firebase'
import Resizer from 'react-image-file-resizer'
import Avatar from 'react-avatar'

export default function ProfileMenu() {
  const [open, setOpen] = useState(false)
  const cancelButtonRef = useRef()
  const { currentUser } = useAuth()
  const [image, setImage] = useState()
  const [imageUrl, setImageUrl] = useState(reactLocalStorage.get('photo'))

  const [errorMessage, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const histrory = useHistory()

  const closeModal = () => {
    setOpen(false)
  }

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        100,
        100,
        'JPEG',
        100,
        0,
        (uri) => {
          resolve(uri)
        },
        'file'
      )
    })

  async function handleSignOut() {
    setError('')
    setLoading(true)

    firebase
      .auth()
      .signOut()
      .then(() => {
        // Sign-out successful.
        reactLocalStorage.remove('activated')
        histrory.replace('/login')
        setLoading(false)
      })
      .catch((error) => {
        // An error happened.
        setError(error.message)
        setLoading(false)
      })
  }

  const changeProfile = async (e) => {
    // setImage(e.target.files[0]);
    setImageUrl(URL.createObjectURL(e.target.files[0]))
    const img = await resizeFile(e.target.files[0])
    var user = auth.currentUser

    const uploadTask = storage.ref(`images/${user.uid}`).put(img)
    uploadTask.on(
      'state_changed',
      (snapshot) => {},
      (error) => {},
      () => {
        storage
          .ref('images')
          .child(user.uid)
          .getDownloadURL()
          .then((url) => {
            updateProfile(url)
          })
      }
    )
  }

  const updateProfile = (url) => {
    var db = firebase.firestore()
    var user = auth.currentUser
    db.collection('persons')
      .doc(user.uid)
      .update({
        photo: url,
      })
      .then((docRef) => {
        setImageUrl(url)
        reactLocalStorage.set('photo', url)
      })
      .catch((error) => {
        console.log('Error: ', error)
      })
  }

  function EditInactiveIcon(props) {
    return (
      <svg
        {...props}
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4 13V16H7L16 7L13 4L4 13Z"
          fill="#EDE9FE"
          stroke="#A78BFA"
          strokeWidth="2"
        />
      </svg>
    )
  }

  function EditActiveIcon(props) {
    return (
      <svg
        {...props}
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4 13V16H7L16 7L13 4L4 13Z"
          fill="#8B5CF6"
          stroke="#C4B5FD"
          strokeWidth="2"
        />
      </svg>
    )
  }

  function DuplicateInactiveIcon(props) {
    return (
      <svg
        {...props}
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4 4H12V12H4V4Z"
          fill="#EDE9FE"
          stroke="#A78BFA"
          strokeWidth="2"
        />
        <path
          d="M8 8H16V16H8V8Z"
          fill="#EDE9FE"
          stroke="#A78BFA"
          strokeWidth="2"
        />
      </svg>
    )
  }

  function DuplicateActiveIcon(props) {
    return (
      <svg
        {...props}
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4 4H12V12H4V4Z"
          fill="#8B5CF6"
          stroke="#C4B5FD"
          strokeWidth="2"
        />
        <path
          d="M8 8H16V16H8V8Z"
          fill="#8B5CF6"
          stroke="#C4B5FD"
          strokeWidth="2"
        />
      </svg>
    )
  }

  function ArchiveInactiveIcon(props) {
    return (
      <svg
        {...props}
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="5"
          y="8"
          width="10"
          height="8"
          fill="#EDE9FE"
          stroke="#A78BFA"
          strokeWidth="2"
        />
        <rect
          x="4"
          y="4"
          width="12"
          height="4"
          fill="#EDE9FE"
          stroke="#A78BFA"
          strokeWidth="2"
        />
        <path d="M8 12H12" stroke="#A78BFA" strokeWidth="2" />
      </svg>
    )
  }

  function ArchiveActiveIcon(props) {
    return (
      <svg
        {...props}
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="5"
          y="8"
          width="10"
          height="8"
          fill="#8B5CF6"
          stroke="#C4B5FD"
          strokeWidth="2"
        />
        <rect
          x="4"
          y="4"
          width="12"
          height="4"
          fill="#8B5CF6"
          stroke="#C4B5FD"
          strokeWidth="2"
        />
        <path d="M8 12H12" stroke="#A78BFA" strokeWidth="2" />
      </svg>
    )
  }

  function MoveInactiveIcon(props) {
    return (
      <svg
        {...props}
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M10 4H16V10" stroke="#A78BFA" strokeWidth="2" />
        <path d="M16 4L8 12" stroke="#A78BFA" strokeWidth="2" />
        <path d="M8 6H4V16H14V12" stroke="#A78BFA" strokeWidth="2" />
      </svg>
    )
  }

  function MoveActiveIcon(props) {
    return (
      <svg
        {...props}
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M10 4H16V10" stroke="#C4B5FD" strokeWidth="2" />
        <path d="M16 4L8 12" stroke="#C4B5FD" strokeWidth="2" />
        <path d="M8 6H4V16H14V12" stroke="#C4B5FD" strokeWidth="2" />
      </svg>
    )
  }

  function DeleteInactiveIcon(props) {
    return (
      <svg
        {...props}
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="5"
          y="6"
          width="10"
          height="10"
          fill="#EDE9FE"
          stroke="#A78BFA"
          strokeWidth="2"
        />
        <path d="M3 6H17" stroke="#A78BFA" strokeWidth="2" />
        <path d="M8 6V4H12V6" stroke="#A78BFA" strokeWidth="2" />
      </svg>
    )
  }

  function DeleteActiveIcon(props) {
    return (
      <svg
        {...props}
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="5"
          y="6"
          width="10"
          height="10"
          fill="#8B5CF6"
          stroke="#C4B5FD"
          strokeWidth="2"
        />
        <path d="M3 6H17" stroke="#C4B5FD" strokeWidth="2" />
        <path d="M8 6V4H12V6" stroke="#C4B5FD" strokeWidth="2" />
      </svg>
    )
  }

  return (
    <div className="w-56 md:w-100 text-right">
      <Menu
        as="div"
        className="mr-4 md:mr-12 relative inline-block text-left z-50"
      >
        {({ open }) => (
          <>
            <div>
              <Menu.Button className="outline-none focus:outline-none items-center justify-center justify-items-center">
                {imageUrl ? (
                  <img
                    className="rounded-full h-9 content-center"
                    src={imageUrl}
                    alt=""
                  />
                ) : (
                  <Avatar
                    className="h-9"
                    name={reactLocalStorage.get('name')}
                    size="30"
                    textSizeRatio={1}
                    round={true}
                  />
                )}
              </Menu.Button>
            </div>
            <Transition
              show={open}
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items
                static
                className="absolute right-0 w-56 md:w-100 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
              >
                <div className="grid justify-center items-center  ">
                  <div className="grid justify-center items-center p-5">
                    {imageUrl ? (
                      <img
                        className="rounded-full h-12 cursor-pointer"
                        src={imageUrl}
                        alt=""
                        id="profile-img"
                      />
                    ) : (
                      <Avatar
                        className="h-12"
                        name={reactLocalStorage.get('name')}
                        size="40"
                        textSizeRatio={1}
                        round={true}
                      />
                    )}
                    {/* <div
                      id="fileInput"
                      className="absolute left-32 top-12 cursor-pointer"
                    >
                      <label for="FileID">
                        {" "}
                        <svg
                          width="30"
                          height="30"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g
                            clip-path="url(#clip0)"
                            fill="#000"
                            fill-opacity=".78"
                          >
                            <path d="M29.002 6.873a3.226 3.226 0 00-2.367-.998h-4.734v-.058c0-.712-.285-1.397-.77-1.853a2.61 2.61 0 00-1.853-.77h-8.556A2.627 2.627 0 008.07 5.817v.058H3.365c-.941 0-1.768.37-2.367.998A3.39 3.39 0 000 9.24V23.44c0 .941.37 1.768.998 2.367a3.39 3.39 0 002.367.998h23.27c.941 0 1.768-.37 2.367-.998A3.39 3.39 0 0030 23.441V9.24c0-.942-.37-1.769-.998-2.367zm-.485 16.568h-.028c0 .513-.2.97-.542 1.312a1.83 1.83 0 01-1.312.542H3.365c-.513 0-.97-.2-1.312-.542a1.83 1.83 0 01-.542-1.312V9.24c0-.514.2-.97.542-1.312a1.83 1.83 0 011.312-.542h5.504c.428 0 .77-.342.77-.77v-.827c0-.314.114-.599.313-.799.2-.2.485-.313.799-.313h8.527c.313 0 .598.114.798.313.2.2.314.485.314.799v.827c0 .428.342.77.77.77h5.503c.514 0 .97.2 1.312.542.342.342.542.798.542 1.312V23.44z" />
                            <path d="M15 9.325c-1.94 0-3.707.799-4.962 2.053a6.96 6.96 0 00-2.053 4.962c0 1.94.798 3.707 2.053 4.962A6.959 6.959 0 0015 23.355c1.94 0 3.707-.798 4.962-2.053a6.96 6.96 0 002.053-4.962c0-1.939-.798-3.707-2.053-4.962A7.028 7.028 0 0015 9.325zm3.878 10.922A5.573 5.573 0 0115 21.844a5.573 5.573 0 01-3.878-1.597 5.452 5.452 0 01-1.597-3.878c0-1.512.627-2.88 1.597-3.878A5.452 5.452 0 0115 10.893c1.512 0 2.88.628 3.878 1.598a5.452 5.452 0 011.597 3.878c.029 1.511-.599 2.88-1.597 3.878zM25.152 12.091a1.397 1.397 0 100-2.795 1.397 1.397 0 000 2.795z" />
                          </g>
                        </svg>
                      </label>
                      <input
                        onChange={(e) => changeProfile(e)}
                        type="file"
                        id="FileID"
                        accept="image/*"
                        className="text-white outline-none focus:outline-none bg-white hidden"
                      />
                    </div> */}
                  </div>
                  <div>
                    <div className=" grid items-center justify-center text-center py-2 px-3  select-none ">
                      <p className="font-medium capitalize">
                        {reactLocalStorage.get('name')}
                      </p>
                      <p>{reactLocalStorage.get('school-name')}</p>
                      {/* <p>{reactLocalStorage.get("email")}</p> */}
                    </div>
                  </div>
                </div>
                <div className="px-5 py-3">
                  <div>
                    <button
                      onClick={() => {
                        handleSignOut()
                      }}
                      className="text-center uppercase py-1.5 w-full  outline-none focus:outline-none border rounded-full font-medium"
                    >
                      sign out
                    </button>
                  </div>
                </div>
              </Menu.Items>
            </Transition>
          </>
        )}
      </Menu>
    </div>
  )
}
