import { useState, useEffect } from 'react'
import AddStudent from './AddStudent'
import StudentTable from './StudentTable'
import firebase from 'firebase/app'
import { reactLocalStorage } from 'reactjs-localstorage'
import logo from '../assets/icon/logo.JPG'
import { OutTable, ExcelRenderer } from 'react-excel-renderer'
import { NotificationManager } from 'react-notifications'
import 'react-notifications/lib/notifications.css'
import regular from '../assets/font/regular.ttf'
import bold from '../assets/font/bold.ttf'
import {
  PDFDownloadLink,
  PDFViewer,
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
  Font,
} from '@react-pdf/renderer'
import {
  Table,
  TableHeader,
  TableBody,
  DataTableCell,
  TableCell,
} from '@david.kucsai/react-pdf-table'

// Register Font
// Font.register({
//   family: 'ArabicFont',
//   src: arabicFont,
// })

Font.register({
  family: 'Ubuntu',
  fonts: [
    {
      src: regular,
    },
    {
      src: bold,
      fontWeight: 'bold',
    },
    {
      src: 'https://fonts.gstatic.com/s/questrial/v13/QdVUSTchPBm7nuUeVf7EuStkm20oJA.ttf',
      fontWeight: 'normal',
      fontStyle: 'italic',
    },
  ],
})

const Students = ({ studentCount, setStudentCount }) => {
  const [classDocument, setClassDocument] = useState([])
  const [loading, setLoading] = useState(false)
  const [classValue, setClassValue] = useState('select')
  const [sectionValue, setSectionValue] = useState('select')
  const [students, setStudents] = useState([])
  const [filter, setFilter] = useState([])
  const [notEmptyFilter, setNotEmptyFilter] = useState(false)
  const fileName = classValue.replace(' ', '_').concat('.pdf')
  const [cols, setCols] = useState()
  const [rows, setRows] = useState()
  const [searchParam, setSearchParam] = useState('')

  useEffect(() => {
    //getSchool()
    getClasses()
  }, [])
  const getClasses = () => {
    setLoading(true)
    var db = firebase.firestore()
    var query = db.collection('school-classes')
    query
      .doc(reactLocalStorage.get('current-school'))
      .get()
      .then((doc) => {
        if (doc.exists) {
          setClassDocument(doc.data().classes)
        }
        setLoading(false)
      })
      .catch((error) => {
        console.log('Error getting document:', error)
      })
  }

  const handleClassSelection = (e) => {
    setClassValue(e.target.value)
    getStudents(e.target.value)
  }

  const activated = reactLocalStorage.get('activated')

  function filterStudent(value) {
    const filtered = students.filter((student) => {
      if (value !== '') {
        setSearchParam(value)
        setNotEmptyFilter(true)
        return student.name.toLowerCase().includes(value.toLowerCase())
      } else {
        setNotEmptyFilter(false)
        return false
      }
    })
    setFilter(filtered)
  }
  const getStudents = (selectClass) => {
    var orderBy = ''
    var schoolName = reactLocalStorage.get('school-name')

    setLoading(true)
    var db = firebase.firestore()
    var record = []
    db.collection('students')
      .where(
        'school_class',
        '==',
        reactLocalStorage.get('current-school').concat(selectClass)
      )
      .orderBy('name', 'asc')
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          var s = doc.data()
          ///console.log('S:: ', s.parent[0].phone1)
          //update(s, doc.id)
          //deleteStudent(doc.id)
          s.doc_id = doc.id
          if (s.state.length === 0) {
            s.state = 'Plateau'
            s.lga = 'Jos-North'
          }

          // s.name = s.name.toUpperCase()

          //REMOVE STUDENT WITH SAME NAME FROM CLASS
          // const existingStudent = record.find((st) => st.name === s.name)
          // if (!existingStudent) {
          //   record.push(s)
          //   console.log(`Student ${s.name} added.`)
          // } else {
          //   // Call your delete function here
          //   deleteStudent(s.doc_id)
          //   console.log(`Student ${s.name} already exists. Deleting...`)
          // }

          record.push(s)
        })
        setStudents(record)
        setStudentCount(record.length)
        setLoading(false)
      })
      .catch((error) => {
        setLoading(false)
        console.log('Error getting documents: ', error)
      })
  }

  const searchAllStudent = () => {
    setLoading(true)
    var db = firebase.firestore()
    var record = []

    db.collection('students')
      .where('school', '==', reactLocalStorage.get('current-school'))
      .orderBy('name')
      .startAt(searchParam)
      .endAt(searchParam + '\uf8ff')
      .get()
      .then((querySnapshot) => {
        const record = []

        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          var s = doc.data()
          s.doc_id = doc.id

          if (s.state.length === 0) {
            s.state = 'Plateau'
            s.lga = 'Jos-North'
          }

          s.name = s.name.toUpperCase()
          record.push(s)
        })

        setStudents(record)
        setStudentCount(record.length)
        setLoading(false)
      })
      .catch((error) => {
        setLoading(false)
        console.log('Error getting documents: ', error)
      })
  }

  const deleteStudent = (doc) => {
    var db = firebase.firestore()
    db.collection('students')
      .doc(doc)
      .delete()
      .then((querySnapshot) => {
        console.log('deleted...........')
      })
      .catch((error) => {})
  }

  const update = (student, docid) => {
    var db = firebase.firestore()
    if (!student.dob || student.dob.length === 0) {
      student.dob = '22/11/2023'
    }
    db.collection('students')
      .doc(docid)
      .update({
        school: reactLocalStorage.get('current-school'),
        school_class: reactLocalStorage
          .get('current-school')
          .concat(student.class),

        name: student.name.trimStart(),
        studentid: student.studentid,
        class: student.class,
        gender: student.gender,
        dob: student.dob,
        // state: 'Plateau',
        // lga: 'Jos-North',
        status: false,
        // phone: student.parent[0].phone1
        //   ? student.parent[0].phone1
        //   : student.parent[0].phone2
        //   ? student.parent[0].phone2
        //   : '',

        parent: [],
        date: firebase.firestore.Timestamp.now(),
      })
      .then((docRef) => {
        console.log('updated:: ', docRef)
      })
      .catch((error) => {})
    setLoading(false)
  }

  // const getSchool = () => {
  //   //setLoading(true)
  //   var db = firebase.firestore()
  //   var items = []
  //   var query = db
  //     .collection('schools')
  //     .doc(reactLocalStorage.get('current-school'))
  //   query
  //     .get()
  //     .then((doc) => {
  //       if (doc.exists) {
  //         if (doc.data().nursery == 'true') {
  //           items.push({ index: '0', value: 'Nursery' })
  //         }
  //         if (doc.data().primary == 'true') {
  //           items.push({ index: '1', value: 'Primary' })
  //         }
  //         if (doc.data().secondary == 'true') {
  //           items.push({ index: '2', value: 'Secondary' })
  //         }
  //         if (doc.data().college == 'true') {
  //           items.push({ index: '3', value: 'College' })
  //         }
  //         if (doc.data().nce == 'true') {
  //           items.push({ index: '4', value: 'NCE' })
  //         }

  //       } else {
  //         // doc.data() will be undefined in this case
  //         //setLoading(false)
  //       }
  //     })
  //     .catch((error) => {
  //       //setLoading(false)
  //       console.log('Error getting document:', error)
  //     })
  // }

  const styles = StyleSheet.create({
    page: {
      flexDirection: 'column',
      paddingTop: 30,
      // paddingLeft: 50,
      // paddingRight: 50,
      paddingHorizontal: 30,
      fontFamily: 'Helvetica',
    },

    section: {
      // marginVertical: 5,
      color: '#fff',
    },
    section2: {
      marginHorizontal: 20,
      color: '#fff',
    },
    logo: {
      // width: 100,
      // height: 100,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    logo2: {
      marginLeft: 'auto',
      marginRight: 'auto',
      height: '90px',
    },

    school: {
      // marginVertical: 10,
      color: '#07173B',
      // letterSpacing: 1,
      fontSize: 40,
      fontWeight: 'heavy',
      textAlign: 'center',
      textTransform: 'uppercase',
      fontFamily: 'Times-Bold',
    },

    title: {
      marginTop: 5,
      color: '#3E8EDE',
      // letterSpacing: 1,
      fontSize: 15,
      textAlign: 'center',
      textTransform: 'uppercase',
      fontFamily: 'Helvetica',
    },
    subTitle: {
      // marginVertical: 5,
      marginTop: 15,
      color: '#3E8EDE',
      // letterSpacing: 1,
      fontSize: 12,
      // textAlign: 'center',
      textTransform: 'uppercase',
      fontFamily: 'Helvetica',
    },
    subSubjetClass: {
      marginVertical: 5,
      // color: '#3E8EDE',
      // letterSpacing: 1,
      fontSize: 12,
      // textAlign: 'center',
      textTransform: 'uppercase',
      fontFamily: 'Ubuntu',
    },
    remarkText: {
      // marginVertical: 5,
      marginTop: 20,
      // letterSpacing: 1,
      fontSize: 12,
      // textDecoration: 'underline',
      // textAlign: 'center',
      // textTransform: 'uppercase',
      fontFamily: 'Helvetica',
    },
    edusaf: {
      fontSize: 8,
      fontFamily: 'Helvetica',
      position: 'absolute',
      bottom: 5,
      right: 5,
    },

    table: {
      fontSize: 12,
      textAlign: 'center',
      fontFamily: 'Helvetica',
    },
    tableBehaviour: {
      fontSize: 12,
      textAlign: 'center',
      fontFamily: 'Helvetica',
      width: 250,
      flex: 1,
      // flexDirection: 'row',
    },
    tableRatingParent: {
      fontSize: 12,
      textAlign: 'center',
      fontFamily: 'Helvetica',
      width: 200,
      flex: 1,
      // flexDirection: 'row',
    },
    tableBehaviourParent: {
      fontSize: 12,
      textAlign: 'center',
      fontFamily: 'Helvetica',
      flexDirection: 'row',
      display: 'flex',
    },

    tableRating: {
      fontSize: 12,
      textAlign: 'left',
      paddingLeft: 5,
      fontFamily: 'Helvetica',
    },
    tableLeft: {
      fontSize: 10,
      textAlign: 'left',
      fontFamily: 'Helvetica',
      paddingLeft: 5,
      // backgroundColor: '#D3ECE6',
    },
    student: {
      fontSize: 12,
      textAlign: 'left',
      fontFamily: 'Helvetica',
      paddingLeft: 5,
      paddingVertical: 2,
    },
    studentTable: {
      // marginVertical: 10,
    },
    total: {
      fontSize: 12,
      textAlign: 'center',
      fontFamily: 'Helvetica',
      // backgroundColor: '#D3ECE6',
    },
    tablesubject: {
      fontSize: 12,
      textAlign: 'left',
      paddingLeft: 5,
      // backgroundColor: '#D3ECE6',
      fontFamily: 'Helvetica',
    },
    tableNamesubject: {
      fontSize: 12,
      textAlign: 'left',
      paddingLeft: 5,
      // backgroundColor: '#D3ECE6',
      fontFamily: 'Helvetica',
      paddingVertical: 2,
    },
    studentDetails: {
      fontSize: 12,
      textAlign: 'left',
      fontFamily: 'Helvetica',
      paddingLeft: 5,
      paddingVertical: 2,
    },
  })

  function getCAData() {
    // setLoading(false)
    var dat = []
    students.map((stud, index) => {
      dat.push({
        index: (
          <View style={styles.tableLeft}>
            <Text>{index + 1}</Text>
          </View>
        ),
        studentid: (
          <View style={styles.tableLeft}>
            <Text>{stud.studentid}</Text>
          </View>
        ),
        name: (
          <View style={styles.tableLeft}>
            <Text>{stud.name.toUpperCase()}</Text>
          </View>
        ),
        gender: (
          <View style={styles.tableLeft}>
            <Text>
              {stud.gender.charAt(0).toUpperCase() + stud.gender.slice(1)}
            </Text>
          </View>
        ),
        dob: (
          <View style={styles.tableLeft}>
            <Text>{stud.dob}</Text>
          </View>
        ),
        phone: (
          <View style={styles.tableLeft}>
            <Text>{stud.phone ? stud.phone : ''}</Text>
          </View>
        ),
      })
    })

    return dat
  }

  const MyTable = () => {
    var dt = getCAData()

    return (
      <Table style={styles.table} data={[...dt]}>
        <TableHeader>
          <TableCell weighting={0.04}>
            <View style={styles.tableLeft}>
              <Text>SN</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.2}>
            <View style={styles.tableLeft}>
              <Text>ADMISSION NO.</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.48}>
            <View style={styles.tableLeft}>
              <Text>NAME</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.12}>
            <View style={styles.tableLeft}>
              <Text>GENDER</Text>
            </View>
          </TableCell>
          {/* <TableCell weighting={0.15}>
            <View style={styles.tableLeft}>
              <Text>DOB</Text>
            </View>
          </TableCell> */}
          <TableCell weighting={0.15}>
            <View style={styles.tableLeft}>
              <Text>PHONE</Text>
            </View>
          </TableCell>
        </TableHeader>
        <TableBody>
          <DataTableCell weighting={0.04} getContent={(r) => r.index} />
          <DataTableCell weighting={0.2} getContent={(r) => r.studentid} />
          <DataTableCell weighting={0.48} getContent={(r) => r.name} />
          <DataTableCell weighting={0.12} getContent={(r) => r.gender} />
          {/* <DataTableCell weighting={0.15} getContent={(r) => r.dob} /> */}
          <DataTableCell weighting={0.15} getContent={(r) => r.phone} />
        </TableBody>
      </Table>
    )
  }

  const MyDocument = () => {
    var logo = reactLocalStorage.get('heading')
    const sch = reactLocalStorage.get('school-name')
    const currentSchool = reactLocalStorage.get('current-school')
    const albayan = 'ATi6GodoPjPJkLfzU5OQ'
    if (sch === 'IMAMUL BUKHARI ACADEMY, GOMBE') {
      switch (classValue) {
        case 'IBN MAJAH':
        case "NASA'I":
        case 'TIRMIZI':
        case 'ABU DAWOOD':
        case 'AHMAD BN HAMBAL':
        case "IMAMUSH SHAFI'A":
        case 'MALIK BN ANAS':
        case 'ANAS BN MALIK':
        case 'MUSLIM BN HAJJAJ':
        case 'ABDULLAHI BN ABBAS':
        case 'ABDULLAHI BN AMRU BN ASS':
        case 'ABDULLAHI BN MAS’UD':
        case 'SA’AD BN ABI WAQQAS':
        case 'ABDULLAHI BN UMAR':
        case 'ABDULLAHI BN QAISIN':
          {
            logo =
              'https://firebasestorage.googleapis.com/v0/b/edusaf-629d9.appspot.com/o/heading%2FJsTijJ2meHpVfoAGeWC7_islamic.jpg?alt=media&token=d3b07cc4-a89c-4a26-888b-bf2e789ac8a5'
          }
          break
      }
    }

    return (
      <Document>
        <Page size="A4" style={styles.page}>
          <Image
            style={currentSchool !== albayan ? styles.logo : styles.logo2}
            src={logo}
          />
          {/* <View style={styles.school}>
            <Text>MY SCHOOL NAME</Text>
          </View> */}

          <View style={styles.subSubjetClass}>
            <Text>Class: {classValue}</Text>
          </View>

          <MyTable />

          <View style={styles.edusaf}>
            <Text>Powered by edutech</Text>
          </View>
        </Page>
      </Document>
    )
  }

  function handleFile(e) {
    let fileObj = e.target.files[0]
    //just pass the fileObj as parameter
    ExcelRenderer(fileObj, (err, resp) => {
      if (err) {
        console.log(err)
      } else {
        setLoading(true)
        resp.rows.map((row, index) => {
          var id = row[1]
          var name = row[2]
          var gender = row[3]
          // var classes = row[4]
          //var dob = row[5]
          save(id, name, gender)
          // save(id, name, gender, classes, dob)
        })
        setLoading(false)
        NotificationManager.info('Added Successful')
      }
    })
  }

  const save = (id, name, gender) => {
    var db = firebase.firestore()
    db.collection('students')
      .add({
        school: reactLocalStorage.get('current-school'),
        school_class: reactLocalStorage
          .get('current-school')
          .concat(classValue),
        name: name.toUpperCase().trim(),
        studentid: id.toUpperCase(),
        class: classValue,
        gender: gender,
        dob: '22/11/2023',
        state: 'Plateau',
        lga: 'Jos-North',
        status: false,
        phone: '',
        parent: [],
        date: firebase.firestore.Timestamp.now(),
      })
      .then((docRef) => {
        console.log('doc:: ', docRef)
      })
      .catch((error) => {
        console.log('error:: ', error)
      })
  }

  const handleInputChange = (e) => {
    setSearchParam(e.target.value)
    if (classValue !== 'select') {
      filterStudent(e.target.value)
    } else {
      //searchAllStudent(e.target)
    }
  }

  return (
    <div className="">
      <div className="grid grid-flow-col justify-between items-center">
        <div className="grid grid-flow-row md:grid-flow-col space-x-5">
          <div className="  border bg-white text-primary py-2 mx-5 my-5 md:my-0  px-2 md:px-4 grid grid-flow-col  md:flex text-center items-center rounded-sm">
            <svg
              className="text-primary"
              width="16"
              height="14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.917 8.444h-.659l-.233-.202c.817-.852 1.308-1.959 1.308-3.163 0-2.685-2.425-4.861-5.416-4.861C2.925.218.5 2.394.5 5.079.5 7.764 2.925 9.94 5.917 9.94a5.767 5.767 0 003.525-1.174l.225.21v.59l4.166 3.732 1.242-1.114-4.158-3.74zm-5 0c-2.075 0-3.75-1.503-3.75-3.365 0-1.862 1.675-3.365 3.75-3.365s3.75 1.503 3.75 3.365c0 1.862-1.675 3.365-3.75 3.365z"
                fill="#B7B9BE"
              />
            </svg>
            <input
              className="bg-white text-primary outline-none text-sm pl-2  md:w-52"
              type="text"
              placeholder="Search"
              value={searchParam}
              onChange={handleInputChange}
              // onChange={(e) => filterStudent(e.target.value)}
            />
            <button
              hidden={classValue !== 'select'}
              className="border rounded-full px-3 py-1"
              onClick={searchAllStudent}
            >
              Search
            </button>
          </div>

          <div className="grid grid-flow-col space-x-5">
            <div className="flex flex-col w-full ">
              <select
                className="rounded-sm py-2 px-4 focus:outline-none focus:ring-1 w-full border bg-white"
                placeholder="class"
                required
                name="classValue"
                value={classValue}
                onChange={(e) => handleClassSelection(e)}
              >
                <option value="select" selected>
                  select
                </option>
                {classDocument.map((classes, index) =>
                  classes.click ? (
                    // classes.section == sectionValue ? (
                    <option
                      className="uppercase"
                      key={classes.id}
                      value={classes.name}
                    >
                      {classes.name}
                    </option>
                  ) : (
                    // ) : (
                    //   ''
                    // )
                    ''
                  )
                )}
              </select>
            </div>

            <div className="mx-4 md:mx-0 text-primary bg-white text-sm">
              <AddStudent
                classDocument={classDocument}
                studentCount={studentCount}
                setStudentCount={setStudentCount}
                classValue={classValue}
                setClassValue={setClassValue}
                students={notEmptyFilter ? filter : students}
                setStudents={notEmptyFilter ? setFilter : setStudents}
              />
            </div>
            {/* <input
              type="file"
              id="input"
              onChange={(e) => {
                handleFile(e)
              }}
            /> */}
          </div>
        </div>
      </div>

      <div className="pt-6">
        <div className="">
          <StudentTable
            classDocument={classDocument}
            students={notEmptyFilter ? filter : students}
            setStudents={notEmptyFilter ? setFilter : setStudents}
            classValue={classValue}
            sectionValue={sectionValue}
            setClassValue={setClassValue}
            // filter={filter}
            // setFilter={setFilter}
            // notEmptyFilter={notEmptyFilter}
            // setNotEmptyFilter={setNotEmptyFilter}
            load={loading}
          />
        </div>

        <div className="grid py-5 px-4 md:px-0  justify-end">
          {activated === 'true' && students.length !== 0 ? (
            <button className="px-3 py-1.5  rounded-sm outline-none focus:outline-none text-white  bg-primary">
              <PDFDownloadLink document={<MyDocument />} fileName={fileName}>
                {({ blob, url, loading, error }) => 'Download Students'}
              </PDFDownloadLink>
            </button>
          ) : (
            ''
          )}
        </div>
      </div>
    </div>
  )
}

export default Students
