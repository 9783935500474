import AddUser from './AddUser'
import { useState, useEffect } from 'react'
import firebase from 'firebase/app'
import { reactLocalStorage } from 'reactjs-localstorage'
import UserTable from './UserTable'

const User = ({ setUserCount }) => {
  const [users, setUsers] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => { 
    getUser()
  }, [])
  
  const getUser = () => {
    setLoading(true)
    var db = firebase.firestore()
    var record = []
    db.collection('user')
      .where('school', '==', reactLocalStorage.get('current-school'))
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
           var t = doc.data()
           t.doc_id = doc.id
           record.push(t)
        })
        setUsers(record)
        setUserCount(record.length)
        setLoading(false)
      })
      .catch((error) => {
        console.log('Error getting documents: ', error)
      })
  }

  return (
    <div className="">
      <div className="grid grid-flow-col justify-between items-center">
        <div className="w-40 md:w-56 border bg-white text-primary py-2 mx-4 md:mx-0 px-2 md:px-4 flex text-center items-center rounded-sm">
          <svg
            className="text-primary"
            width="16"
            height="14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.917 8.444h-.659l-.233-.202c.817-.852 1.308-1.959 1.308-3.163 0-2.685-2.425-4.861-5.416-4.861C2.925.218.5 2.394.5 5.079.5 7.764 2.925 9.94 5.917 9.94a5.767 5.767 0 003.525-1.174l.225.21v.59l4.166 3.732 1.242-1.114-4.158-3.74zm-5 0c-2.075 0-3.75-1.503-3.75-3.365 0-1.862 1.675-3.365 3.75-3.365s3.75 1.503 3.75 3.365c0 1.862-1.675 3.365-3.75 3.365z"
              fill="#B7B9BE"
            />
          </svg>
          <input
            className="bg-white text-primary outline-none text-sm pl-2 w-32 md:w-52"
            type="text"
            placeholder="Search"
          />
        </div>

        <div className="mx-4 md:mx-0 text-primary bg-white text-sm">
          <AddUser users={users} setUsers={setUsers} />
        </div>
      </div>

      <div className="pt-6">
        <div className="">
          <UserTable
            key="teacher-table"
            users={users}
            setUsers={setUsers}
            loading={loading}
            setUserCount={setUserCount}
          />
        </div>
      </div>
    </div>
  )
}

export default User
