import { useState } from 'react'
import firebase from 'firebase/app'
import { reactLocalStorage } from 'reactjs-localstorage'
import { useEffect } from 'react'
import AssessmentTable from './AssessmentTable'
import { NotificationManager } from 'react-notifications'
import 'react-notifications/lib/notifications.css'
import {
  PDFDownloadLink,
  PDFViewer,
  Document,
  Page,
  Svg,
  Path,
  Text,
  View,
  StyleSheet,
  Image,
  Font,
} from '@react-pdf/renderer'
import {
  Table,
  TableHeader,
  TableBody,
  DataTableCell,
  TableCell,
} from '@david.kucsai/react-pdf-table'
import regular from '../assets/font/regular.ttf'
import bold from '../assets/font/bold.ttf'
Font.register({
  family: 'Ubuntu',
  fonts: [
    {
      src: regular,
    },
    {
      src: bold,
      fontWeight: 'bold',
    },
    {
      src: 'https://fonts.gstatic.com/s/questrial/v13/QdVUSTchPBm7nuUeVf7EuStkm20oJA.ttf',
      fontWeight: 'normal',
      fontStyle: 'italic',
    },
  ],
})

const ManageAssessment = ({ setSubTitle, setClickPeople, casheet, report }) => {
  const [paidCount, setPaidCount] = useState()
  const [unPaidCount, setUnPaidCount] = useState()
  const [classValue, setClassValue] = useState('select')
  const [sessionValue, setSessionValue] = useState(
    reactLocalStorage.get('session')
  )
  const [termValue, setTermValue] = useState(reactLocalStorage.get('term'))
  const [classDocument, setClassDocument] = useState([])
  const [allStudent, setAllStudent] = useState([])
  const [paidStudent, setPaidStudent] = useState([])
  const [unPaidStudent, setUnPaidStudent] = useState([])
  const [loading, setLoading] = useState(false)
  const [amount, setAmount] = useState()
  const [fees, setFees] = useState()
  const [subject, setSubject] = useState([])
  const [subjectValue, setSubjectValue] = useState('select')
  const [selectedFeesIndex, setSelectedFeesIndex] = useState()
  const [isNewRecord, setIsNewRecord] = useState(false)
  const [assessment, setAssessment] = useState([])
  const [assRanks, setAssRanks] = useState([])

  const tabDefaultStyle =
    'text-white text-xs md:text-sm  md:text-gray-600 uppercase py-4 px-4 md:px-6 block hover:text-blue-500 focus:outline-none'
  const tabClickStyle =
    'uppercase text-sm md:text-sm  py-4 px-4 md:px-6 block md:hover:text-blue-500 focus:outline-none text-white md:text-blue-500 border-b-2 font-medium md:border-blue-500'

  useEffect(() => {
    getClasses()
    getSubjects()
    getTerm()
  }, [])

  const activated = reactLocalStorage.get('activated')

  let assessmentValues = []

  const getClasses = () => {
    var db = firebase.firestore()
    var items = []
    setLoading(true)
    var query = db
      .collection('school-classes')
      .doc(reactLocalStorage.get('current-school'))
    query
      .get()
      .then((doc) => {
        if (doc.exists) {
          doc.data().classes.forEach((dot) => {
            if (dot.click) {
              items.push({
                name: dot.name,
                id: dot.id,
                section: dot.section,
                click: true,
              })
            }
          })

          setClassDocument(items)
          setLoading(false)
        } else {
          // doc.data() will be undefined in this case
          setLoading(false)
        }
      })
      .catch((error) => {
        setLoading(false)
        console.log('Error getting document:', error)
      })
  }

  const getStudents = (name, e) => {
    setLoading(true)
    setAssessment([])
    //var orderBy = ''
    //var schoolName = reactLocalStorage.get('school-name')
    // switch (schoolName) {
    //   case 'Al-iman School Bauchi':
    //   case "FLYING COLOURS INT'L ACADEMY, GOMBE":
    //     {
    //       orderBy = 'name'
    //     }
    //     break
    //   default: {
    //     orderBy = 'studentid'
    //   }
    // }
    assessmentValues = []
    var db = firebase.firestore()
    if (name === 'classValue') {
      setClassValue(e.target.value)
      if (subjectValue !== 'select') {
        getAssessment(name, e)
      }
    } else {
      setSubjectValue(e.target.value)
      if (classValue !== 'select') {
        getAssessment(name, e)
      }
    }

    db.collection('students')
      .where(
        'school_class',
        '==',
        reactLocalStorage
          .get('current-school')
          .concat(name === 'classValue' ? e.target.value : classValue)
      )
      .orderBy('name', 'asc')
      .get()
      .then((querySnapshot) => {
        var values = []
        querySnapshot.forEach((doc) => {
          //doc.data() is never undefined for query doc snapshots

          var dd = doc.data()
          dd.doc_id = doc.id
          var s = checkAssessment(dd)
          values.push(s)
        })

        setAllStudent(values)
        setLoading(false)
      })
      .catch((error) => {
        console.log('Error getting documents::::: ', error.message)
        setLoading(false)
      })
  }

  const getSubjects = () => {
    setLoading(true)
    var db = firebase.firestore()
    var query = db.collection('school-subjects')
    query
      .doc(reactLocalStorage.get('current-school'))
      .get()
      .then((doc) => {
        if (doc.exists) {
          setSubject(doc.data().subject)
          setLoading(false)
        }
      })
      .catch((error) => {
        setLoading(false)
        console.log('Error getting document:', error)
      })
  }

  const getAssessment = (name, e) => {
    setLoading(true)
    var db = firebase.firestore()
    var query = db.collection('assessment')
    query
      .doc(
        reactLocalStorage
          .get('current-school')
          .concat('_')
          .concat(sessionValue.replaceAll('/', '_'))
          .concat('_')
          .concat(termValue.replaceAll(' ', '_'))
          .concat('_')
          .concat(name === 'classValue' ? e.target.value : classValue)
          .concat('_')
          .concat(
            name === 'subject'
              ? e.target.value.replaceAll(' ', '_')
              : subjectValue.replaceAll(' ', '_')
          )
      )
      .get()
      .then((doc) => {
        if (doc.exists) {
          // setClassDocument(doc.data().classes)
          assessmentValues = []

          doc.data().students.map((student, index) => {
            assessmentValues.push(student)
            //  scores.push(student.total)
          })

          // setAssRanks(findRanks(scores))

          setAssessment(assessmentValues)

          //setPaid(doc.data().paid)
          setLoading(false)
          setIsNewRecord(false)
        } else {
          NotificationManager.info('No record found')
          setIsNewRecord(true)
          setLoading(false)
          // doc.data() will be undefined in this case
          //  getClasses()
          //  setClassEmptyRecord(true)
        }
      })
      .catch((error) => {
        console.log('Error getting document:', error)
      })
  }

  function checkAssessment(doc) {
    for (let index = 0; index < assessmentValues.length; index++) {
      const element = assessmentValues[index]
      if (element.doc_id === doc.doc_id) {
        doc.ass1 = element.ass1
        doc.ass2 = element.ass2
        doc.test = element.test
        if (element.test2) {
          doc.test2 = element.test2
        }
        doc.exam = element.exam
        doc.total = element.total
        doc.grade = element.grade
        //  doc.position = element.position
        var scores = []
        assessmentValues.map((student, index) => {
          scores.push(student.total)
        })

        var positions = findRanks(scores)

        doc.position = positions[index]
        doc.remark = element.remark
      } else {
        // doc.ass1 = '-'
        // doc.ass2 = '-'
        // doc.test = '-'
        // doc.exam = '-'
        // doc.total = '-'
        // doc.grade = '-'
      }
    }
    return doc
  }

  const findRanks = (arr = []) => {
    const { length } = arr
    let sortArray = arr.slice()
    sortArray.sort((a, b) => b - a)
    const result = []
    for (let i = 0; i < length; i++) {
      const j = sortArray.indexOf(arr[i])
      result.push(j + 1)
    }
    // setAssRanks(result)
    return result
  }

  async function getTerm() {
    setLoading(true)
    var db = firebase.firestore()
    var query = db.collection('term')
    query
      .doc(reactLocalStorage.get('current-school'))
      .get()
      .then((doc) => {
        if (doc.exists) {
          reactLocalStorage.set('session', doc.data().session)
          reactLocalStorage.set('term', doc.data().term)
          reactLocalStorage.set('start', doc.data().start)
          reactLocalStorage.set('end', doc.data().end)
          reactLocalStorage.set('next', doc.data().next)
          reactLocalStorage.set('position', doc.data().position)
          if (typeof doc.data().hide_attendance !== 'undefined') {
            reactLocalStorage.set('hide-attendance', 'hide')
          } else {
            reactLocalStorage.set('hide-attendance', 'unhide')
          }
        } else {
          NotificationManager.info('Session and term not set')
        }
      })
      .catch((error) => {
        console.log('Error getting document:', error)
      })
    setLoading(true)
  }
  const styles = StyleSheet.create({
    page: {
      flexDirection: 'column',
      paddingTop: 30,
      // paddingLeft: 50,
      // paddingRight: 50,
      paddingHorizontal: 30,
      fontFamily: 'Helvetica',
    },

    section: {
      // marginVertical: 5,
      color: '#fff',
    },
    section2: {
      marginHorizontal: 20,
      color: '#fff',
    },
    logo: {
      // width: 100,
      // height: 100,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    logo2: {
      marginLeft: 'auto',
      marginRight: 'auto',
      height: '90px',
    },

    school: {
      // marginVertical: 10,
      color: '#07173B',
      // letterSpacing: 1,
      fontSize: 40,
      fontWeight: 'heavy',
      textAlign: 'center',
      textTransform: 'uppercase',
      fontFamily: 'Times-Bold',
    },

    title: {
      marginTop: 5,
      color: '#3E8EDE',
      // letterSpacing: 1,
      fontSize: 15,
      textAlign: 'center',
      textTransform: 'uppercase',
      fontFamily: 'Helvetica',
    },
    subTitle: {
      // marginVertical: 5,
      marginTop: 15,
      color: '#3E8EDE',
      // letterSpacing: 1,
      fontSize: 10,
      // textAlign: 'center',
      textTransform: 'uppercase',
      fontFamily: 'Helvetica',
    },
    subSubjetClass: {
      // marginVertical: 5,
      // marginTop: 15,
      // color: '#3E8EDE',
      // letterSpacing: 1,
      fontSize: 12,
      // textAlign: 'center',
      textTransform: 'uppercase',
      // fontFamily: 'Helvetica',
      fontFamily: 'Ubuntu',
    },
    remarkText: {
      // marginVertical: 5,
      marginTop: 20,
      // letterSpacing: 1,
      fontSize: 10,
      // textDecoration: 'underline',
      // textAlign: 'center',
      // textTransform: 'uppercase',
      fontFamily: 'Helvetica',
    },
    edusaf: {
      fontSize: 10,
      fontFamily: 'Helvetica',
      position: 'absolute',
      bottom: 5,
      right: 5,
    },

    table: {
      fontSize: 10,
      textAlign: 'center',
      fontFamily: 'Helvetica',
    },
    tableBehaviour: {
      fontSize: 10,
      textAlign: 'center',
      fontFamily: 'Helvetica',
      width: 250,
      flex: 1,
      // flexDirection: 'row',
    },
    tableRatingParent: {
      fontSize: 10,
      textAlign: 'center',
      fontFamily: 'Helvetica',
      width: 200,
      flex: 1,
      // flexDirection: 'row',
    },
    tableBehaviourParent: {
      fontSize: 10,
      textAlign: 'center',
      fontFamily: 'Helvetica',
      flexDirection: 'row',
      display: 'flex',
    },

    tableRating: {
      fontSize: 10,
      textAlign: 'left',
      paddingLeft: 5,
      fontFamily: 'Helvetica',
    },
    tableLeft: {
      fontSize: 10,
      textAlign: 'left',
      fontFamily: 'Helvetica',
      paddingLeft: 5,
      // backgroundColor: '#D3ECE6',
    },
    student: {
      fontSize: 10,
      textAlign: 'left',
      fontFamily: 'Helvetica',
      paddingLeft: 5,
      paddingVertical: 2,
    },
    studentTable: {
      // marginVertical: 10,
    },
    total: {
      fontSize: 10,
      textAlign: 'center',
      fontFamily: 'Helvetica',
      // backgroundColor: '#D3ECE6',
    },
    tablesubject: {
      fontSize: 10,
      textAlign: 'left',
      paddingLeft: 5,
      // backgroundColor: '#D3ECE6',
      fontFamily: 'Helvetica',
    },
    tableNamesubject: {
      fontSize: 10,
      textAlign: 'left',
      paddingLeft: 5,
      // backgroundColor: '#D3ECE6',
      fontFamily: 'Helvetica',
      paddingVertical: 2,
    },
    studentDetails: {
      fontSize: 10,
      textAlign: 'left',
      fontFamily: 'Helvetica',
      paddingLeft: 5,
      paddingVertical: 2,
    },
  })

  function getCAData() {
    // setLoading(false)
    var dat = []
    allStudent.map((stud, index) => {
      dat.push({
        index: (
          <View style={styles.tableLeft}>
            <Text>{index + 1}</Text>
          </View>
        ),
        subject: (
          <View style={styles.tablesubject}>
            <Text>{stud.name.toUpperCase()}</Text>
          </View>
        ),
        ass1: (
          <View style={styles.table}>
            <Text>{stud.ass1 ? stud.ass1 : ''}</Text>
          </View>
        ),
        ass2: (
          <View style={styles.table}>
            <Text>{stud.ass2 ? stud.ass2 : ''}</Text>
          </View>
        ),
        test: (
          <View style={styles.table}>
            <Text>{stud.test ? stud.test : ''}</Text>
          </View>
        ),
        test2: (
          <View style={styles.table}>
            <Text>{stud.test2 ? stud.test2 : ''}</Text>
          </View>
        ),
        exam: (
          <View style={styles.table}>
            <Text>{stud.exam ? stud.exam : ''}</Text>
          </View>
        ),
        total: (
          <View style={styles.total}>
            <Text>{stud.total ? stud.total : ''}</Text>
          </View>
        ),
        grade: (
          <View style={styles.table}>
            <Text>{stud.grade ? stud.grade : ''}</Text>
          </View>
        ),
        pos: (
          <View style={styles.table}>
            <Text>{stud.position ? stud.position : ''}</Text>
          </View>
        ),
        avg: (
          <View style={styles.table}>
            <Text>{stud.class_average ? stud.class_average : ''}</Text>
          </View>
        ),
        remark: (
          <View style={styles.tableLeft}>
            <Text>{stud.remark ? stud.remark : ''}</Text>
          </View>
        ),
      })
    })
    //setData(dat)
    //setLoading(false)
    // setShowDownload(true)
    return dat
  }

  const MyTable = () => {
    var dt = getCAData()
    const currentSchool = reactLocalStorage.get('current-school')
    const currentSchoolName = reactLocalStorage.get('school-name')
    const modibbo = 'MODIBBO IDRIS ACADEMY, GOMBE'
    const annibras = 'An-Nibras Model School'
    const smartGombe = 'Smart Academy Gombe'
    const flyingColorsGombe = 'knNn0QpqbUaVBcOBUBFU'
    const zenithPrimary = 'FBzIYMgZ1nJlyMX2Btk1'
    const markazu = 'qSfZ9ljUp8bmYNJo8InQ'
    const blissGombe = 'BLISS SUCCESS MODEL SCHOOL'
    const sunshineGombe = 'SUNSHINE MODEL SCHOOL, AKKO GOMBE'
    const alImanCollegeJos = 'Al-iman College Jos'
    const alImanSchoolGirls = 'AL-IMAN GIRLS SECONDARY SCHOOL'
    const alImanSchoolBoys = 'AL-IMAN SECONDARY SCHOOL'
    const esteemJos = 'fhqa4oQYx5j5KotPOLWz'
    const presteenJos = 'voZ4XR9kBq6txi85vzAB'
    const albayan = 'ATi6GodoPjPJkLfzU5OQ'
    const iqraaJos = 'H8ER5nzwQIHmWH1dz3I3'
    const asasulIslam = 'f5Fb6AredH18z4mkvnY6'

    var format = ''
    switch (currentSchoolName) {
      case 'Al-iman School Bauchi':
        {
          format = 1
        }
        break
      default: {
        format = 2
      }
    }

    var alimanSec =
      (currentSchoolName === alImanSchoolBoys &&
        classValue.includes('J.S.S')) ||
      (currentSchoolName === alImanSchoolBoys && classValue.includes('S.S.S'))
    const caType =
      currentSchool === zenithPrimary ||
      currentSchool === markazu ||
      currentSchoolName === alImanSchoolGirls ||
      alimanSec ||
      currentSchool === esteemJos || currentSchool === presteenJos || currentSchool === albayan || currentSchool === iqraaJos || currentSchool === asasulIslam
        ? '4'
        : '3'

    if (caType === '4') {
      return (
        <Table style={styles.table} data={[...dt]}>
          <TableHeader>
            <TableCell weighting={0.04}>
              <View style={styles.tableLeft}>
                <Text>SN</Text>
              </View>
            </TableCell>
            <TableCell weighting={0.3}>
              <View style={styles.tablesubject}>
                <Text>NAME</Text>
              </View>
            </TableCell>
            <TableCell weighting={0.07}>
              <View style={styles.table}>
                <Text> {format === 1 ? 'TEST 1' : currentSchool === albayan ? '1st ASS': 'ASS 1'} </Text>
              </View>
            </TableCell>
            <TableCell weighting={0.07}>
              <View style={styles.table}>
                <Text> {format === 1 ? 'TEST 2' : currentSchool === albayan ? '1st TEST': 'ASS 2'} </Text>
              </View>
            </TableCell>
            <TableCell weighting={0.07}>
              <View style={styles.table}>
                <Text> {format === 1 ? 'ASS' : currentSchool === albayan ? '2nd ASS': 'TEST 1'} </Text>
              </View>
            </TableCell>
            <TableCell weighting={0.07}>
              <View style={styles.table}>
                <Text> {format === 1 ? 'ASS' : currentSchool === albayan ? '2nd TEST': 'TEST 2'} </Text>
              </View>
            </TableCell>

            <TableCell weighting={0.07}>
              <View style={styles.table}>
                <Text>EXAMS</Text>
              </View>
            </TableCell>
            <TableCell weighting={0.07}>
              <View style={styles.total}>
                <Text>TOTAL</Text>
              </View>
            </TableCell>
            <TableCell weighting={0.07}>
              <View style={styles.table}>
                <Text>GRADE</Text>
              </View>
            </TableCell>
            <TableCell weighting={0.07}>
              <View style={styles.table}>
                <Text>POS</Text>
              </View>
            </TableCell>
            {/* <TableCell weighting={0.08}>
            <View style={styles.table}>
              <Text>Class AVG</Text>
            </View>
          </TableCell> */}
            <TableCell weighting={0.1}>
              <View style={styles.tableLeft}>
                <Text>REMARK</Text>
              </View>
            </TableCell>
          </TableHeader>
          <TableBody>
            <DataTableCell weighting={0.04} getContent={(r) => r.index} />
            <DataTableCell weighting={0.3} getContent={(r) => r.subject} />
            <DataTableCell weighting={0.07} getContent={(r) => r.ass1} />
            <DataTableCell weighting={0.07} getContent={(r) => r.ass2} />
            <DataTableCell weighting={0.07} getContent={(r) => r.test} />
            <DataTableCell weighting={0.07} getContent={(r) => r.test2} />

            <DataTableCell weighting={0.07} getContent={(r) => r.exam} />
            <DataTableCell weighting={0.07} getContent={(r) => r.total} />
            <DataTableCell weighting={0.07} getContent={(r) => r.grade} />
            <DataTableCell weighting={0.07} getContent={(r) => r.pos} />
            {/* <DataTableCell weighting={0.08} getContent={(r) => r.avg} /> */}
            <DataTableCell weighting={0.1} getContent={(r) => r.remark} />
          </TableBody>
        </Table>
      )
    }

    return (
      <Table style={styles.table} data={[...dt]}>
        <TableHeader>
          <TableCell weighting={0.04}>
            <View style={styles.tableLeft}>
              <Text>SN</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.37}>
            <View style={styles.tablesubject}>
              <Text>NAME</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.07}>
            <View style={styles.table}>
              <Text> {format === 1 ? 'TEST 1' : 'ASS 1'} </Text>
            </View>
          </TableCell>
          <TableCell weighting={0.07}>
            <View style={styles.table}>
              <Text> {format === 1 ? 'TEST 2' : 'ASS 2'} </Text>
            </View>
          </TableCell>
          <TableCell weighting={0.07}>
            <View style={styles.table}>
              <Text> {format === 1 ? 'ASS' : 'TEST 1'} </Text>
            </View>
          </TableCell>
          <TableCell weighting={0.07}>
            <View style={styles.table}>
              <Text>EXAMS</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.07}>
            <View style={styles.total}>
              <Text>TOTAL</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.07}>
            <View style={styles.table}>
              <Text>GRADE</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.07}>
            <View style={styles.table}>
              <Text>POS</Text>
            </View>
          </TableCell>
          {/* <TableCell weighting={0.08}>
            <View style={styles.table}>
              <Text>Class AVG</Text>
            </View>
          </TableCell> */}
          <TableCell weighting={0.1}>
            <View style={styles.tableLeft}>
              <Text>REMARK</Text>
            </View>
          </TableCell>
        </TableHeader>
        <TableBody>
          <DataTableCell weighting={0.04} getContent={(r) => r.index} />
          <DataTableCell weighting={0.37} getContent={(r) => r.subject} />
          <DataTableCell weighting={0.07} getContent={(r) => r.ass1} />
          <DataTableCell weighting={0.07} getContent={(r) => r.ass2} />
          <DataTableCell weighting={0.07} getContent={(r) => r.test} />
          <DataTableCell weighting={0.07} getContent={(r) => r.exam} />
          <DataTableCell weighting={0.07} getContent={(r) => r.total} />
          <DataTableCell weighting={0.07} getContent={(r) => r.grade} />
          <DataTableCell weighting={0.07} getContent={(r) => r.pos} />
          {/* <DataTableCell weighting={0.08} getContent={(r) => r.avg} /> */}
          <DataTableCell weighting={0.1} getContent={(r) => r.remark} />
        </TableBody>
      </Table>
    )
  }

  const MyDocument = () => {
    var logo = reactLocalStorage.get('heading')
    const sch = reactLocalStorage.get('school-name')
    const currentSchool = reactLocalStorage.get('current-school')
    const albayan = 'ATi6GodoPjPJkLfzU5OQ'
    if (sch === 'IMAMUL BUKHARI ACADEMY, GOMBE') {
      switch (classValue) {
        case 'IBN MAJAH':
        case "NASA'I":
        case 'TIRMIZI':
        case 'ABU DAWOOD':
        case 'AHMAD BN HAMBAL':
        case "IMAMUSH SHAFI'A":
        case 'MALIK BN ANAS':
        case 'ANAS BN MALIK':
        case 'MUSLIM BN HAJJAJ':
        case 'ABDULLAHI BN ABBAS':
        case 'ABDULLAHI BN AMRU BN ASS':
        case 'ABDULLAHI BN MAS’UD':
        case 'SA’AD BN ABI WAQQAS':
        case 'ABDULLAHI BN UMAR':
        case 'ABDULLAHI BN QAISIN':
          {
            logo =
              'https://firebasestorage.googleapis.com/v0/b/edusaf-629d9.appspot.com/o/heading%2FJsTijJ2meHpVfoAGeWC7_islamic.jpg?alt=media&token=d3b07cc4-a89c-4a26-888b-bf2e789ac8a5'
          }
          break
      }
    }
    return (
      <Document>
        <Page size="A4" orientation="portrait" style={styles.page}>
          <Image
            style={currentSchool !== albayan ? styles.logo : styles.logo2}
            src={logo}
          />
          {/* <View style={styles.school}>
            <Text>MY SCHOOL NAME</Text>
          </View> */}
          <View style={styles.title}>
            {/* <Text>First Term Result</Text> */}
          </View>
          <View style={styles.subSubjetClass}>
            <Text>Class: {classValue}</Text>
            {subjectValue !== 'select' ? (
              <Text>Subject: {subjectValue}</Text>
            ) : (
              ''
            )}
          </View>

          <View>
            <Text style={styles.section}>''</Text>
          </View>
          <MyTable />

          <View style={styles.edusaf}>
            <Text>Powered by edutech</Text>
          </View>
        </Page>
      </Document>
    )
  }

  return (
    <div className="">
      <div className="mx-0 md:mx-12 md:my-3 bg-primary md:bg-white  ">
        <p className="md:hidden py-3 md:py-0 ml-4 md:ml-0 text-white md:text-gray-800 font-medium text-lg">
          People
        </p>
      </div>

      <div className="">
        <div className="m:0 md:mx-12 md:my-5  ">
          <div className="grid grid-flow-col items-center mb-5">
            <div className="grid grid-cols-2 gap-2 md:flex text-center items-center text-primary rounded-sm px-3 md:px-0  md:space-x-4">
              {/* <select
                className="rounded-sm p-2 w-full md:w-56 focus:outline-none focus:ring-1 bg-gray-50"
                placeholder="session"
                required
                name="sessionValue"
                value={sessionValue}
                onChange={(e) => setSessionValue(e.target.value)}
              >
                
                <option value="2022/2023">2022/2023</option>
                <option value="2023/2024">2023/2024</option>
                <option value="2024/2025">2024/2025</option>
              </select>

              <select
                className="rounded-sm p-2 w-full md:w-56 focus:outline-none focus:ring-1 bg-gray-50"
                placeholder="term"
                required
                name="termValue"
                value={termValue}
                onChange={(e) => setTermValue(e.target.value)}
              >
                <option value="First Term">First Term</option>
                <option value="Second Term">Second Term</option>
                <option value="Third Term">Third Term</option>
              </select> */}

              <select
                className="rounded-sm p-2 w-full md:w-56 focus:outline-none focus:ring-1 bg-gray-50"
                placeholder="class"
                required
                name="classValue"
                value={classValue}
                onChange={(e) => getStudents('classValue', e)}
              >
                <option value="select" disabled selected>
                  select
                </option>
                {classDocument.map((classes, index) =>
                  classes.click ? (
                    <option
                      className="uppercase"
                      key={classes.id}
                      value={classes.name}
                    >
                      {classes.name}
                    </option>
                  ) : (
                    ''
                  )
                )}
              </select>

              <select
                className="rounded-sm p-2 w-full md:w-56 focus:outline-none focus:ring-1 bg-gray-50"
                placeholder="subject"
                required
                name="subject"
                value={subjectValue}
                onChange={(e) => getStudents('subject', e)}
              >
                <option value="select" disabled selected>
                  select
                </option>
                {subject.map((sub, index) =>
                  sub.click ? (
                    <option className="uppercase" key={sub.id} value={sub.name}>
                      {sub.name}
                    </option>
                  ) : (
                    ''
                  )
                )}
              </select>
            </div>
          </div>

          <div className="my-5 ">
            <div className="">
              <AssessmentTable
                allStudent={allStudent}
                setAllStudent={setAllStudent}
                classValue={classValue}
                sessionValue={sessionValue}
                termValue={termValue}
                subjectValue={subjectValue}
                isNewRecord={isNewRecord}
                setIsNewRecord={setIsNewRecord}
                assessment={assessment}
                setAssessment={setAssessment}
                loading={loading}
              />
            </div>
            <div className="grid py-5 px-4 md:px-0  justify-end">
              {activated === 'true' && allStudent.length !== 0 ? (
                <button className="px-3 py-1.5  rounded-sm outline-none focus:outline-none text-white  bg-primary">
                  <PDFDownloadLink
                    document={<MyDocument />}
                    fileName={classValue
                      .replace(' ', '_')
                      .concat('_')
                      .concat(termValue.replace(' ', '_'))
                      .concat('_')
                      .concat(sessionValue.replace(' ', '_'))
                      .concat('.pdf')}
                  >
                    {({ blob, url, loading, error }) => 'Download C.A Sheet'}
                  </PDFDownloadLink>
                </button>
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ManageAssessment
