import firebase from 'firebase/app'
import { reactLocalStorage } from 'reactjs-localstorage'
import { useState, useEffect } from 'react'
import Avatar from 'react-avatar'
import { NotificationManager } from 'react-notifications'
import 'react-notifications/lib/notifications.css'

import {
  PDFDownloadLink,
  PDFViewer,
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
} from '@react-pdf/renderer'
import {
  Table,
  TableHeader,
  TableBody,
  DataTableCell,
  TableCell,
} from '@david.kucsai/react-pdf-table'

const ManageAttendance = () => {
  const [presentClick, setPresentClick] = useState()
  const [absentClick, setAbsentClick] = useState()
  const [classDocument, setClassDocument] = useState([])
  const [classValue, setClassValue] = useState('select')
  const [selectedDate, setSelectedDate] = useState()
  const [presentsData, setPresentData] = useState([])
  const [absentData, setAbsentData] = useState([])
  const [students, setStudents] = useState([])
  const [allAttendance, setAllAttendance] = useState([])
  const [sessionValue, setSessionValue] = useState()
  const [termValue, setTermValue] = useState()
  const [dates, setDates] = useState([])
  const [uniqueStatus, setUniqueStatus] = useState([])

  const [loading, setLoading] = useState(false)
  const [load, setLoad] = useState()
  const [error, setError] = useState()
  var myDate = null
  const tabDefaultStyle =
    'text-white text-xs md:text-sm  md:text-gray-600 uppercase py-4 px-4 md:px-6 block hover:text-blue-500 focus:outline-none'
  const tabClickStyle =
    'uppercase text-sm md:text-sm  py-4 px-4 md:px-6 block md:hover:text-blue-500 focus:outline-none text-white md:text-blue-500 border-b-2 font-medium md:border-blue-500'

  const handleTabClick = (tab) => {
    switch (tab) {
      case 'present': {
        setPresentClick(true)
        setAbsentClick(false)

        break
      }

      default: {
        setAbsentClick(true)
        setPresentClick(false)
      }
    }
  }

  useEffect(() => {
    getClasses()
    getTerm()
    setPresentClick(true)
    setAbsentClick(false)
  }, [])
  const activated = reactLocalStorage.get('activated')

  const getClasses = () => {
    setLoading(true)
    var db = firebase.firestore()
    var query = db.collection('school-classes')
    query
      .doc(reactLocalStorage.get('current-school'))
      .get()
      .then((doc) => {
        if (doc.exists) {
          setClassDocument(doc.data().classes)
          setLoading(false)
        }
      })
      .catch((error) => {
        console.log('Error getting document:', error)
      })
  }

  const handledateSelection = (e) => {
    setSelectedDate(e.target.value)

    const myArray = e.target.value.split('-')
    var day = parseInt(myArray[2])
    var month = parseInt(myArray[1])
    var year = parseInt(myArray[0])
    myDate = day
      .toString()
      .concat('-')
      .concat(month.toString())
      .concat('-')
      .concat(year.toString())
    if (classValue !== 'select') {
      getAttendance(myDate, classValue)
    }
  }
  const handleClassSelection = (e) => {
    if (activated === 'true') {
      setClassValue(e.target.value)
      if (typeof selectedDate !== 'undefined' && selectedDate != null) {
        //(e.target.value)
        const myArray = selectedDate.split('-')
        var day = parseInt(myArray[2])
        var month = parseInt(myArray[1])
        var year = parseInt(myArray[0])
        myDate = day
          .toString()
          .concat('-')
          .concat(month.toString())
          .concat('-')
          .concat(year.toString())

        getAttendance(myDate, e.target.value)
      } else {
        console.log('Undefined or Null')
      }
    } else {
      NotificationManager.info('Pay termly subscription to view attendance')
    }
  }

  const getCurrentDateInput = () => {
    const dateObj = new Date()

    // get the month in this format of 04, the same for months
    const month = ('0' + (dateObj.getMonth() + 1)).slice(-2)
    const day = ('0' + dateObj.getDate()).slice(-2)
    const year = dateObj.getFullYear()

    const shortDate = `${year}-${month}-${day}`

    myDate = `${day}-${month}-${year}`

    return shortDate
  }

  const getTerm = () => {
    setLoading(true)
    var db = firebase.firestore()
    var query = db.collection('term')
    query
      .doc(reactLocalStorage.get('current-school'))
      .get()
      .then((doc) => {
        if (doc.exists) {
          setTermValue(doc.data().term)
          setSessionValue(doc.data().session)
          setLoading(false)
        }
      })
      .catch((error) => {
        console.log('Error getting document:', error)
        setLoading(false)
      })
  }

  const getAttendance = (dateValue, myClass) => {
    setLoading(true)
    var db = firebase.firestore()
    db.collection('attendance')
      .doc(
        reactLocalStorage
          .get('current-school')
          .concat('_')
          .concat(sessionValue.replace('/', '_'))
          .concat('_')
          .concat(termValue.replace(' ', '_'))
          .concat('_')
          .concat(myClass)
          .concat('_')
          .concat(dateValue)
      )
      .get()
      .then((doc) => {
        if (doc.exists) {
          var pre = []
          var abs = []
          //var allAttendance = []
          doc.data().students.forEach((stu) => {
            //  allAttendance.push(stu)
            if (stu.status === 'false' || stu.status === '') {
              abs.push(stu)
            } else {
              pre.push(stu)
            }
          })
          setPresentData(pre)
          setAbsentData(abs)
          

          // setStudents(allAttendance)
          setLoading(false)
        } else {
          NotificationManager.info(
            'No record found for the selected date and class'
          )
          setPresentData([])
          //setLoading(false)
          getAllAttendance(myClass)
        }
      })
      .catch((error) => {
        console.log('Error getting documents: ', error)
      })
  }
  const getAllAttendance = (myClass) => {
    setLoading(true)
    var db = firebase.firestore()
    db.collection('attendance')
      .where(
        'sstc',
        '==',
        reactLocalStorage
          .get('current-school')
          .concat('_')
          .concat(sessionValue.replace('/', '_'))
          .concat('_')
          .concat(termValue.replace(' ', '_'))
          .concat('_')
          .concat(myClass)
      )
      .orderBy('date', 'asc')
      .get()
      .then((querySnapshot) => {
        let allItems = []
        querySnapshot.forEach((doc) => {
          const items = doc.data().students

          const myArray = doc.id.split('_')
          //console.log('array: ',myArray[6]);
          const firebaseTimestamp = doc.data().date

          // Convert Firebase timestamp to JavaScript Date object
          const date = firebaseTimestamp.toDate()

          // Format the date as "DD/MM/YYYY"
          const formattedDate = `${date.getDate()}/${
            date.getMonth() + 1
          }/${date.getFullYear()}`

          // Add the formatted date to each object in the array
          const itemsWithDate = items.map((item) => ({
            ...item,
            date: myArray[6],
          }))

          allItems = allItems.concat(itemsWithDate)
          //console.log('ddd:: ',allItems);
        })
        var uniqueStatus = countUniqueStatus(allItems)
        var attendace = extractStudentDetails(allItems)
        var uniqueNames = getUniqueNames(allItems)
        var uniqueDate = getUniqueDates(allItems)
        setDates(uniqueDate)
        setStudents(uniqueNames)
        setAllAttendance(attendace)
        setUniqueStatus(uniqueStatus)
        setLoading(false)
      })
      .catch((error) => {
        setLoading(false)
        console.log('Error getting document:', error)
      })
  }

  function dayOfWeek(dateString) {
    // Define the date string
    //const dateString = '21-8-2023'

    // Split the date string into day, month, and year components
    const [day, month, year] = dateString.split('-')

    // Create a new Date object using the parsed components (months are 0-based in JavaScript)
    const date = new Date(year, month - 1, day)

    // Define an array of weekdays
    const weekdays = ['S', 'M', 'T', 'W', 'TH', 'F', 'SA']

    // Get the day of the week from the Date object
    const dayOfWeekIndex = date.getDay()
    const dayOfWeek = weekdays[dayOfWeekIndex]

    return dayOfWeek
  }

  function extractStudentDetails(items) {
    const studentDetails = []

    items.forEach((item) => {
      const { doc_id, name, date, status } = item
      studentDetails.push({ doc_id, name, date, status })
    })

    return studentDetails
  }

  function countUniqueStatus(items) {
    const counts = {}

    items.forEach((item) => {
      const { doc_id, name, date, status } = item

      // Initialize the count object if not exists
      if (!counts[doc_id]) {
        counts[doc_id] = {
          doc_id,
          name,
          date,
          presentCount: 0,
          absenceCount: 0,
        }
      }

      // Increment the count based on status
      if (status === 'true') {
        counts[doc_id].presentCount++
      } else {
        counts[doc_id].absenceCount++
      }
    })

    // Convert counts object to an array
    const result = Object.values(counts)

    // Add status field based on count values
    result.forEach((count) => {
      if (count.presentCount > 0 && count.absenceCount > 0) {
        count.status = 'Present & Absent'
      } else if (count.presentCount > 0) {
        count.status = 'Present'
      } else {
        count.status = 'Absent'
      }
    })

    return result
  }

  function getUniqueNames(items) {
    const uniqueDocs = {}

    items.forEach((item) => {
      const { doc_id, name } = item
      uniqueDocs[doc_id] = name
    })

    const uniqueDocsList = Object.keys(uniqueDocs).map((doc_id) => ({
      doc_id,
      name: uniqueDocs[doc_id],
    }))

    return uniqueDocsList
  }

  function getUniqueDates(items) {
    const uniqueDates = {}

    items.forEach((item) => {
      const { date } = item

      uniqueDates[date] = true
    })

    const uniqueDatesList = Object.keys(uniqueDates)

    return uniqueDatesList
  }

  const AttendanceTable = ({ students }) => {
    if (students.length === 0 && classValue === 'select') {
      return <p>Select class to view attendance</p>
    }
    if (students.length === 0) {
      return <p>No record found</p>
    }
    return (
      <div className="">
        <div className="flex flex-col max-h-96  md:max-h-82 xl_1:max-h-120 2xl:max-h-130 overflow-y-auto   shadow-md border-b border-gray-200 rounded-sm  ">
          <div className="flex-grow  ">
            <table className="relative w-full min-w-full   divide-y divide-gray-200 ">
              {/* <thead className="">
                <tr className=" ">
                  <th className="sticky bg-gray-50 z-30 md:z-20 left-0  top-0  px-4  py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Name
                  </th>
                </tr>
              </thead> */}

              <tbody className="bg-white divide-y divide-gray-200 ">
                {/* {sectionValue == 'select'
                ? ''
                : filterSection()} */}

                {students.map((student) => (
                  <tr key={student.id}>
                    <td
                      key={1}
                      className="sticky left-0  bg-white px-4 py-2 whitespace-nowrap "
                    >
                      <div className="flex items-center">
                        <div className="flex-shrink-0 h-10 w-10">
                          {student.photo ? (
                            <img
                              key={student.studentid}
                              className="h-10 w-10 rounded-full"
                              src={student.photo}
                              alt=""
                            />
                          ) : (
                            <Avatar
                              name={student.name.toUpperCase()}
                              size="32"
                              textSizeRatio={1}
                              round={true}
                            />
                          )}
                        </div>
                        <div className="ml-4">
                          <div className="text-sm font-medium capitalize text-gray-900">
                            {student.name}
                          </div>
                          <div className="text-sm capitalize text-gray-500">
                            {student.studentid}
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    )
  }

  const styles = StyleSheet.create({
    page: {
      flexDirection: 'column',
      paddingTop: 10,
      // paddingLeft: 50,
      // paddingRight: 50,
      paddingHorizontal: 20,
      fontFamily: 'Helvetica',
    },
    section: {
      // marginVertical: 5,
      color: '#fff',
    },
    section2: {
      marginHorizontal: 20,
      color: '#fff',
    },
    logo: {
      // width: 100,
      // height: 100,
      marginLeft: 'auto',
      marginRight: 'auto',
    },

    school: {
      // marginVertical: 10,
      color: '#07173B',
      // letterSpacing: 1,
      fontSize: 40,
      fontWeight: 'heavy',
      textAlign: 'center',
      textTransform: 'uppercase',
      fontFamily: 'Times-Bold',
    },
    school2: {
      // marginVertical: 10,
      color: '#07173B',
      // letterSpacing: 1,
      fontSize: 20,
      fontWeight: 'heavy',
      textAlign: 'center',
      textTransform: 'uppercase',
      fontFamily: 'Times-Bold',
    },

    title: {
      marginTop: 5,
      color: '#3E8EDE',
      // letterSpacing: 1,
      fontSize: 15,
      textAlign: 'center',
      textTransform: 'uppercase',
      fontFamily: 'Helvetica',
    },
    subTitle: {
      // marginVertical: 5,
      marginTop: 15,
      color: '#3E8EDE',
      // letterSpacing: 1,
      fontSize: 12,
      // textAlign: 'center',
      textTransform: 'uppercase',
      fontFamily: 'Helvetica',
    },
    subSubjetClass: {
      marginVertical: 5,
      // color: '#3E8EDE',
      // letterSpacing: 1,
      fontSize: 12,
      // textAlign: 'center',
      textTransform: 'uppercase',
      fontFamily: 'Helvetica',
    },
    remarkText: {
      // marginVertical: 5,
      marginTop: 20,
      // letterSpacing: 1,
      fontSize: 12,
      // textDecoration: 'underline',
      // textAlign: 'center',
      // textTransform: 'uppercase',
      fontFamily: 'Helvetica',
    },
    edusaf: {
      fontSize: 8,
      fontFamily: 'Helvetica',
      position: 'absolute',
      bottom: 5,
      right: 5,
    },

    table: {
      fontSize: 12,
      textAlign: 'center',
      fontFamily: 'Helvetica',
    },
    table3: {
      fontSize: 12,
      textAlign: 'center',
      fontFamily: 'Helvetica',
      writingMode: 'vertical-rl',
    },
    tableBehaviour: {
      fontSize: 12,
      textAlign: 'center',
      fontFamily: 'Helvetica',
      width: 250,
      flex: 1,
      // flexDirection: 'row',
    },
    tableRatingParent: {
      fontSize: 12,
      textAlign: 'center',
      fontFamily: 'Helvetica',
      width: 200,
      flex: 1,
      // flexDirection: 'row',
    },
    tableBehaviourParent: {
      fontSize: 12,
      textAlign: 'center',
      fontFamily: 'Helvetica',
      flexDirection: 'row',
      display: 'flex',
    },

    tableRating: {
      fontSize: 12,
      textAlign: 'left',
      paddingLeft: 5,
      fontFamily: 'Helvetica',
    },
    tableLeft: {
      fontSize: 10,
      textAlign: 'left',
      fontFamily: 'Helvetica',
      // paddingLeft: 5,
      // backgroundColor: '#D3ECE6',
    },
    student: {
      fontSize: 12,
      textAlign: 'left',
      fontFamily: 'Helvetica',
      paddingLeft: 5,
      paddingVertical: 2,
    },
    studentTable: {
      // marginVertical: 10,
    },
    total: {
      fontSize: 12,
      textAlign: 'center',
      fontFamily: 'Helvetica',
      // backgroundColor: '#D3ECE6',
    },
    tablesubject: {
      fontSize: 12,
      textAlign: 'left',
      paddingLeft: 5,
      // backgroundColor: '#D3ECE6',
      fontFamily: 'Helvetica',
    },
    tableNamesubject: {
      fontSize: 12,
      textAlign: 'left',
      paddingLeft: 5,
      // backgroundColor: '#D3ECE6',
      fontFamily: 'Helvetica',
      paddingVertical: 2,
    },
    studentDetails: {
      fontSize: 12,
      textAlign: 'left',
      fontFamily: 'Helvetica',
      paddingLeft: 5,
      paddingVertical: 2,
    },
  })

  function getCAData() {
    var dat = []
    if (allAttendance.length !== 0 && uniqueStatus.length !== 0) {
      //console.log('unique:: ',uniqueStatus);
      setLoading(false)
      // var presentResult = attendace.presentResult
      // var absenceResult = attendace.absenceResult

      students.map((stud, index) => {
        var unique = getObjectByDocId(uniqueStatus, stud.doc_id)
        dat.push({
          index: index + 1,
          name: stud.name,
          attend: getAtt(allAttendance, stud.doc_id),
          presentCount: unique.presentCount,
          absenceCount: unique.absenceCount,
        })
      })
    }

    return dat
  }

  function getAtt(attendance, doc_id) {
    var att = []
    attendance.forEach((stud) => {
      if (doc_id === stud.doc_id) {
        // Check if status is defined and not null before accessing it
        const status =
          stud.status !== undefined && stud.status !== null
            ? stud.status
            : 'false'
        att.push(status)
      }
    })
    return att
  }

  function getObjectByDocId(array, doc_id) {
    // Iterate through the array of objects
    for (let i = 0; i < array.length; i++) {
      // Check if the current object's doc_id matches the provided doc_id
      if (array[i].doc_id === doc_id) {
        // Return the object if a match is found
        return array[i]
      }
    }
    // Return null if no matching object is found
    return null
  }

  const BroadSheetHeading = () => {
    return (
      <Table
        data={[
          {
            label1: 'CLASS: '.concat(classValue.toUpperCase()),
            label2: 'TERM: '.concat(termValue.toUpperCase()),
            label3: 'SESSION: '.concat(sessionValue),
          },
        ]}
      >
        <TableBody>
          <DataTableCell
            weighting={0.33}
            style={styles.studentDetails}
            getContent={(r) => r.label1}
          />
          <DataTableCell
            weighting={0.33}
            style={styles.studentDetails}
            getContent={(r) => r.label2}
          />
          <DataTableCell
            weighting={0.34}
            style={styles.studentDetails}
            getContent={(r) => r.label3}
          />
        </TableBody>
      </Table>
    )
  }
  const MyTable = () => {
    var dt = getCAData()
    var columnWidth = parseFloat(0.88) / parseFloat(dates.length)
    return (
      <Table data={[...dt]}>
        <TableHeader>
          <TableCell weighting={0.02}>
            <View style={styles.table}>
              <Text>SN</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.1}>
            <View style={styles.table}>
              <Text>NAME</Text>
            </View>
          </TableCell>

          {dates.map((date) => {
            return (
              <TableCell weighting={columnWidth} style={styles.table3}>
                <View>
                  <Text>{dayOfWeek(date)}</Text>
                </View>
              </TableCell>
            )
          })}

          <TableCell weighting={columnWidth}>
            <View style={styles.table}>
              <Text>PRESENT</Text>
            </View>
          </TableCell>
          <TableCell weighting={columnWidth}>
            <View style={styles.table}>
              <Text>ABSENT</Text>
            </View>
          </TableCell>
        </TableHeader>
        <TableBody>
          <DataTableCell
            style={styles.table}
            weighting={0.02}
            getContent={(r) => r.index}
          />
          <DataTableCell
            style={styles.table}
            weighting={0.1}
            getContent={(r) => r.name}
          />

          {dates.map((date, index) => {
            return (
              <DataTableCell
                style={styles.table}
                weighting={columnWidth}
                getContent={(r) => (r.attend[index] === 'true' ? '/' : 'O')}
              />
            )
          })}

          <DataTableCell
            style={styles.table}
            weighting={columnWidth}
            getContent={(r) => r.presentCount}
          />
          <DataTableCell
            style={styles.table}
            weighting={columnWidth}
            getContent={(r) => r.absenceCount}
          />
        </TableBody>
      </Table>
    )
  }

  const MyDocument = () => {
    const logo = reactLocalStorage.get('heading')
    return (
      <Document>
        <Page size="A0" orientation="landscape" style={styles.page}>
          <Image style={styles.logo} src={logo} />
          {/* <View style={styles.school}>
            <Text>MY SCHOOL NAME</Text>
          </View> */}

          <View style={styles.school2}>
            <Text>ATTENDANCE</Text>
          </View>
          <BroadSheetHeading />
          <View>
            <Text style={styles.section}>''</Text>
          </View>
          <MyTable />

          <View style={styles.edusaf}>
            <Text>Powered by edutech</Text>
          </View>
        </Page>
      </Document>
    )
  }

  function isEmpty(obj) {
    for (const prop in obj) {
      if (Object.hasOwn(obj, prop)) {
        return false
      }
    }

    return true
  }

  return (
    <div className="">
      <div className="">
        <div className="grid m:0 p-5 md:p-0 gap-5 md:mx-12 md:my-5 grid-flow-col  justify-end">
          <p className="md:hidden">select date</p>
          <input
            className="rounded-sm p-2 w-full md:w-56 focus:outline-none focus:ring-1 bg-gray-50"
            type="date"
            required
            name="selectedDate"
            value={selectedDate}
            // defaultValue={getCurrentDateInput()}
            onChange={(e) => handledateSelection(e)}
          />
          <select
            className="rounded-sm p-2 focus:outline-none focus:ring-1  border bg-white"
            placeholder="class"
            required
            name="classValue"
            value={classValue}
            onChange={(e) => handleClassSelection(e)}
          >
            <option value="select">select</option>
            {classDocument.map((classes, index) =>
              classes.click ? (
                // classes.section == sectionValue ? (
                <option
                  className="uppercase"
                  key={classes.id}
                  value={classes.name}
                >
                  {classes.name}
                </option>
              ) : (
                // ) : (
                //   ''
                // )
                ''
              )
            )}
          </select>
        </div>
        <div className="m:0 md:mx-12 md:my-5 ">
          <div class="sticky top-0 z-30 bg-primary md:bg-gray-50">
            <nav class="flex flex-row">
              <button
                onClick={() => {
                  handleTabClick('present')
                }}
                className={presentClick ? tabClickStyle : tabDefaultStyle}
              >
                present({presentsData.length})
              </button>

              <button
                onClick={() => {
                  handleTabClick('absent')
                }}
                className={absentClick ? tabClickStyle : tabDefaultStyle}
              >
                absent({absentData.length})
              </button>
            </nav>
          </div>

          <div className="my-5 max-h-115  md:max-h-115 xl_1:max-h-130 2xl:max-h-130 overflow-y-auto ">
            {loading ? (
              <div className="grid  justify-center items-center ">
                <i
                  className={
                    ' text-primary fas fa-circle-notch fa-spin text-5xl'
                  }
                ></i>
              </div>
            ) : presentClick ? (
              <AttendanceTable students={presentsData} />
            ) : (
              <AttendanceTable students={absentData} />
            )}
          </div>

          <div className="grid py-5 px-4 md:px-0  justify-end">
            {activated === 'true' && students.length !== 0 ? (
              <button className="px-3 py-1.5  rounded-sm outline-none focus:outline-none text-white  bg-primary">
                <PDFDownloadLink
                  document={<MyDocument />}
                  fileName="attendance.pdf"
                >
                  {({ blob, url, loading, error }) => 'Download Attendance'}
                </PDFDownloadLink>
              </button>
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ManageAttendance
