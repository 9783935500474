import AuthenticationSidenav from '../components/AuthenticationSidenav'
import { useState, useRef } from 'react'
import ShowDrawer from '../components/ShowDrawer'
import ButtonPrimary from '../buttons/ButtonPrimary'
import { useAuth } from '../services/AuthContext'
import { Link } from 'react-router-dom'
import { auth } from '../firebase'
import { useLocation, useHistory } from 'react-router-dom'

import { NotificationManager } from 'react-notifications'
import 'react-notifications/lib/notifications.css'
const ForgotPassword = ({ code }) => {
  const emailRef = useRef()
  const passwordRef = useRef()
  const confirmRef = useRef()

  const [isOpen, setIsOpen] = useState(false)
  const closeClasses =
    'w-64 lg:w-72 2xl:w-96 absolute md:relative md:translate-x-0 inset-y-0 left-0 transform -translate-x-full transition duration-200 ease-in-out'
  const openClasses =
    'w-64 lg:w-72 2xl:w-96 absolute  md:relative md:translate-x-0 inset-y-0 left-0 transform transition duration-200 ease-in-out'

  const [errorMessage, setError] = useState('')
  const [sent, setSent] = useState(false)
    const [loading, setLoading] = useState(false)
      const histrory = useHistory()


  const handleClick = () => {
    !isOpen ? setIsOpen(true) : setIsOpen(false)
  }

  async function handleSubmit(e) {
    e.preventDefault()
    setError('')
    setSent(false)
    setLoading(true)
    auth
      .confirmPasswordReset(code, passwordRef.current.value)
      .then(function () {
        // Success
        histrory.push('/login')
        NotificationManager.info('Password has been reset successful')

        setSent(true)
      })
      .catch(function (error) {
        // Invalid code
        setSent(false)
        setError(error.message)
      })

    // try {
    //   setError('')
    //   setSent(false)
    //   setLoading(true)
    //   user
    //     .updatePassword(passwordRef.current.value)
    //     .then(() => {
    //       setSent(true)
    //       // Update successful.
    //     })
    //     .catch((error) => {
    //       // An error occurred
    //       setSent(false)
    //       setError(error.message)
    //     })
    // } catch (error) {
    //   setSent(false)
    //   setError(error.message)
    // }
    setLoading(false)
  }
  //document.body.className = 'bg-account_background';

  return (
    <div className="md:flex bg-white  md:bg-account_background   ">
      <ShowDrawer handleClick={handleClick} isOpen={isOpen} />

      <div className={!isOpen ? closeClasses : openClasses}>
        <AuthenticationSidenav type="authentication" />
      </div>

      <div className=" md:flex-1  ">
        <div className="grid px-5 ">
          <div className="grid lg:grid-cols-5 md:justify-center  md:place-content-center md:h-screen ">
            <div className=""></div>
            <div className="lg:col-span-3">
              <div className="md:bg-white md:p-5 lg:p-10 2xl:p-16 rounded-sm  xl:mx-24 2xl:mx-14 3xl:mx-36  md:shadow-sm">
                <p className="text-xl 2xl:text-3xl text-gray-900 font-medium mb-3 pt-5 md:py-10 md:pb-5 md:pt-0 ">
                  Forgot Password
                </p>
                <form className=" flex flex-col  " onSubmit={handleSubmit}>
                  <label htmlFor="email" className="2xl:text-lg 2xl:py-2">
                    New Password
                  </label>
                  <input
                    ref={passwordRef}
                    className="rounded-sm p-2 focus:outline-none focus:ring-1 bg-account_background 2xl:text-lg"
                    type="password"
                    id="password"
                    placeholder="Password"
                    required
                  />

                  {sent ? (
                    <p className="text-center mt-2 font-normal">
                      Password has been reset successful{' '}
                    </p>
                  ) : (
                    <p className="text-center mt-2 text-red-600">
                      {errorMessage}{' '}
                    </p>
                  )}
                  <ButtonPrimary
                    title="Reset password link"
                    loading={loading}
                  />
                </form>
              </div>
            </div>
            <div className=""></div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ForgotPassword
