import { useState } from 'react'
import firebase from 'firebase/app'
import { reactLocalStorage } from 'reactjs-localstorage'
import { useEffect } from 'react'
import { NotificationManager } from 'react-notifications'
import 'react-notifications/lib/notifications.css'
import { renderToStaticMarkup } from 'react-dom/server'
import ReactHtmlParser from 'react-html-parser'
import QRCode from 'qrcode.react'

import {
  Table,
  TableHeader,
  TableBody,
  DataTableCell,
  TableCell,
} from '@david.kucsai/react-pdf-table'

import {
  PDFDownloadLink,
  PDFViewer,
  Document,
  Page,
  Svg,
  Path,
  Text,
  View,
  StyleSheet,
  Image,
  Font,
  pdf ,
} from '@react-pdf/renderer'
import regular from '../assets/font/regular.ttf'
import bold from '../assets/font/bold.ttf'
import { saveAs } from 'file-saver';

Font.register({
  family: 'Ubuntu',
  fonts: [
    {
      src: regular,
    },
    {
      src: bold,
      fontWeight: 'bold',
    },
    {
      src: 'https://fonts.gstatic.com/s/questrial/v13/QdVUSTchPBm7nuUeVf7EuStkm20oJA.ttf',
      fontWeight: 'normal',
      fontStyle: 'italic',
    },
  ],
})

const AssessmentReport = ({ setSubTitle, setClickPeople, casheet, report }) => {
  const [paidCount, setPaidCount] = useState()
  const [unPaidCount, setUnPaidCount] = useState()
  const [classValue, setClassValue] = useState('select')
  const [sessionValue, setSessionValue] = useState(
    reactLocalStorage.get('session')
  )
  const [termValue, setTermValue] = useState(reactLocalStorage.get('term'))
  const [classDocument, setClassDocument] = useState([])
  const [allStudent, setAllStudent] = useState([])
  const [paidStudent, setPaidStudent] = useState([])
  const [unPaidStudent, setUnPaidStudent] = useState([])
  const [loading, setLoading] = useState(false)
  const [loadingBroad, setLoadingBroad] = useState(false)
  const [amount, setAmount] = useState()
  const [fees, setFees] = useState()
  const [subject, setSubject] = useState([])
  const [subjectValue, setSubjectValue] = useState('select')
  const [selectedFeesIndex, setSelectedFeesIndex] = useState()
  const [isNewRecord, setIsNewRecord] = useState(false)
  const [assessment, setAssessment] = useState([])
  const [showDownload, setShowDownload] = useState(false)
  const [showDownloadBroad, setShowDownloadBroad] = useState(false)
  const [tableColour, setTableColour] = useState('#D3ECE6')
  const [tableBackground, setTableBackground] = useState('#ffffff')
  const [paddingTop, setPaddingTop] = useState(10)
  const [paddingHorizontal, setPaddingHorizontal] = useState(20)
  const [schoolSubject, setSchoolSubject] = useState([])

  const [data, setData] = useState([])
  const [student, setStudent] = useState([])
  const [termEnd, setTermEnd] = useState()
  const [nextTerm, setNextTerm] = useState()
  const [showPosition, setShowPosition] = useState(true)
  const [timesOpen, setTimesOpen] = useState()
  const [present, setPresent] = useState([])
  const [allAttendance, setAllAttendance] = useState([])
  const [Nextfees, setNextFees] = useState()
  const [activated, setActivated] = useState(false)

  const tabDefaultStyle =
    'text-white text-xs md:text-sm  md:text-gray-600 uppercase py-4 px-4 md:px-6 block hover:text-blue-500 focus:outline-none'
  const tabClickStyle =
    'uppercase text-sm md:text-sm  py-4 px-4 md:px-6 block md:hover:text-blue-500 focus:outline-none text-white md:text-blue-500 border-b-2 font-medium md:border-blue-500'
  const defaultStyle = 'px-3 py-1.5 bg-gray-50 select-none cursor-default'
  const clickStyle = 'px-3 py-1.5 bg-account_click select-none cursor-default'

  let success = [
    'He/she has shown outstanding progress in grade. Keep up the great work.',
    'He/she has been consistent, has exceeded expectations, and created a mark for himself/herself. It has been a pleasure teaching him/her.',
    'Amazed at the improvement and growth. Way to go.',
    'Have to appreciate his/her quality work and motivation to do better every day. Thoroughly enjoyed being his/her teacher.',
    'Teaching him/her was an absolute pleasure. He/she is focused, driven, and enthusiastic.',
    'I don’t have fingers to count his/her victories. Passionate, helpful, and super creative.',
    'Happy to see that he/she sets high standards for himself/herself and achieves them.',
    'Well deserved grades. Happy to see his/her success.',
    'Super creative, talented, and helpful. A perfect student!',
    'He/she has shown marked improvement in terms of grades and skills. Truly commendable.',
  ]

  let improvement = [
    'There’s scope for improvement. You can do better.',
    'More focus is required. Have the potential to grow',
    'I would say he/she is using only half of his/her potential',
    'Great things are waiting for you. You will reach them if you put in a little more effort.',
    'I would like to see him/her ask for help when he/she is struggling to understand the concepts or the work assigned.',
    'It has been noticed that he/she often struggles to remember lessons that have been covered in class and it would be great if you put some extra practice at home.',
    'I would like to see him/her apply consistent effort to his/her work.',
    'With more focus and attention, you can do great things.',
    'He/she is not using his/her full capacity. A little nudge is necessary.',
    'I would like to see him/her to be an active participant in his/her learning.',
  ]

  let assistance = [
    'I would like to see him/her ask for help when he/she is struggling to understand the concepts or the work assigned.',
    'Is determined to do things on own. However, it would be beneficial if he/she gets some extra help at home.',
    'Don’t be afraid to ask for assistance when required.',
    'A little extra effort is expected from a student with such potential.',
    'Spend more time reading the concepts at home.',
    'He/she is not yet able to work in small groups as he/she struggles to focus and engage with his/her classmates.',
    'A little extra push is needed.',
    'A good kid. Needs assistance with teamwork and confidence.',
    'Great efforts, keep asking for assistance.',
    'Would benefit from slowing down and reading the set questions carefully.',
  ]
  const fileName = classValue
    .replace(' ', '_')
    .concat('_')
    .concat(termValue.replace(' ', '_'))
    .concat('_')
    .concat(sessionValue.replace(' ', '_'))
    .concat('.pdf')

  useEffect(() => {
    // getTotalStudent()
    // getClasses()
    // getTerm()


    // Perform asynchronous operations here
    const fetchData = async () => {
      try {
        getTotalStudent()
        getClasses()
        getTerm()
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData(); // Call the async function
    

    // getSubjects()
  }, [])


  let assessmentValues = []

  const getTotalStudent = () => {
    setLoading(true)
    var db = firebase.firestore()
    var record = []
    db.collection('students')
      .where('school', '==', reactLocalStorage.get('current-school'))
      .get()
      .then((querySnapshot) => {
        //reactLocalStorage.set('total_students', querySnapshot.size)
        //setLoading(false)
        getSubscription(querySnapshot.size)
      })
      .catch((error) => {
        setLoading(false)
        console.log('Error getting documents: ', error)
      })
  }

  const getSubscription = async (totalStudent) => {
    try {
      //setLoading(true)

      var db = firebase.firestore()
      const path = `${reactLocalStorage.get(
        'current-school'
      )}_${reactLocalStorage
        .get('session')
        .replace('/', '_')}_${reactLocalStorage.get('term').replace(' ', '_')}`

      const doc = await db.collection('subscription').doc(path).get()

      if (doc.exists) {
        const plan = doc.data().plan
        const slotPaid = doc.data().slot

        if (
          plan === 'Trial' ||
          (plan === 'Paid' && parseInt(slotPaid) >= parseInt(totalStudent))
        ) {
          setActivated(true)
          //console.log('activate::::: ',activated);
          //reactLocalStorage.set('activated', true)
          // histrory.replace('/landing')
        } else {
          setActivated(false)
          // histrory.replace('/landing')
          // reactLocalStorage.set('activated', false)
        }
      } else {
         setActivated(false)
        // reactLocalStorage.set('activated', false)
        // histrory.replace('/landing')
      }

      //setLoading(false)
    } catch (error) {
      console.log('Error getting document:', error)
      //setLoading(false)
      // Handle error gracefully (e.g., display an error message to the user)
    }
  }

  const getClasses = () => {
    var db = firebase.firestore()
    var items = []
    setLoading(true)
    var query = db
      .collection('school-classes')
      .doc(reactLocalStorage.get('current-school'))
    query
      .get()
      .then((doc) => {
        if (doc.exists) {
          doc.data().classes.forEach((dot) => {
            if (dot.click) {
              items.push({
                name: dot.name,
                id: dot.id,
                section: dot.section,
                click: true,
              })
            }
          })

          if (items && items.length > 0) {
            setClassDocument(items)
          }

          setLoading(false)
        } else {
          // doc.data() will be undefined in this case
          setLoading(false)
        }
      })
      .catch((error) => {
        setLoading(false)
        console.log('Error getting document:', error)
      })
  }

  const getStudents = async (e) => {
    const currentSchool = reactLocalStorage.get('current-school')
    const albayan = 'ATi6GodoPjPJkLfzU5OQ'

    return new Promise((resolve, reject) => {
      // setLoading(true)
      var db = firebase.firestore()

      var orderBy = ''
      var schoolName = reactLocalStorage.get('school-name')
      switch (schoolName) {
        case 'Al-iman School Bauchi':
        case "FLYING COLOURS INT'L ACADEMY, GOMBE":
          orderBy = 'name'
          break
        default:
          orderBy = 'studentid'
      }

      db.collection('students')
        .where(
          'school_class',
          '==',
          reactLocalStorage.get('current-school').concat(e.target.value)
        )
        .orderBy(orderBy, 'asc')
        .get()
        .then((querySnapshot) => {
          var values = []
          querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            var dd = doc.data()
            dd.doc_id = doc.id
            values.push(checkAssessment(dd))
          })

          // Resolve the promise with the assessment values
          resolve(values)

          setAllStudent(values)
          // setLoading(false)

          if (currentSchool === albayan) {
            getSchoolSubjects()
          }
        })

        .catch((error) => {
          console.log('Error getting documents: ', error)
          // setLoading(false)
          reject(error)
        })
    })
  }

  // UPDATED FUNCTION 7/12/2023
  function checkPresent(id) {
    const student = allAttendance.find((s) => s.doc_id === id)
    return student ? student.count || 0 : 0
  }

  //THIS IS NEW METHOD 12/7/2023
  // Function to find studentname by studentid
  const findStudentNameById = (values, id) => {
    const foundStudent = values.find((student) => student.doc_id == id)

    // Check if a student with the given id was found
    if (foundStudent) {
      return { name: foundStudent.name, gender: foundStudent.gender }
    } else {
      return 'Student not found' // You can customize the message accordingly
    }
  }

  const getSubjects = async (e) => {
    setShowDownload(false)
    setLoading(true)
    const currentSchoolName = reactLocalStorage.get('school-name')
    const currentSchool = reactLocalStorage.get('current-school')
    const alImanCollegeJos = 'Al-iman College Jos'
    const albayan = 'ATi6GodoPjPJkLfzU5OQ'
    const sunnahJosDogonDutse = 'dF1Ve6tDZfykDTtl8Xs7'

    var db = firebase.firestore()
    setClassValue(e.target.value)
    getAttendance(e.target.value)
    getStudents(e)
      .then((assessmentValues) => {
        // Do something with the assessment values
        db.collection('assessment')
          .where(
            'sstc',
            '==',
            reactLocalStorage
              .get('current-school')
              .concat('_')
              .concat(sessionValue.replace('/', '_'))
              .concat('_')
              .concat(termValue.replace(' ', '_'))
              .concat('_')
              .concat(e.target.value)
          )
          .orderBy(
            'subject',
            currentSchoolName === alImanCollegeJos || currentSchool === albayan ||currentSchool === sunnahJosDogonDutse
              ? 'desc'
              : 'asc'
          )
          .get()
          .then((querySnapshot) => {
            var subjValues = []
            var stu = []
            querySnapshot.forEach((doc, index) => {
              // doc.data() is never undefined for query doc snapshots

              subjValues.push({
                id: index,
                subject: doc.data().subject,
                click: true,
                students: doc.data().students,
              })
              doc.data().students.map((data) => {
                var studentName = findStudentNameById(
                  assessmentValues,
                  data.doc_id
                )
                var rec = {
                  id: data.studentid,
                  name:
                    studentName !== 'Student not found'
                      ? studentName.name
                      : data.name,
                  subject: data.subject,
                  gender:
                    studentName !== 'Student not found'
                      ? studentName.gender
                      : data.gender,
                  behaviour: data.behaviour,
                  doc_id: data.doc_id,
                }
                stu.push(rec)
              })
            })

            setSubject(subjValues)
            setStudent(getUnique(stu, 'doc_id'))
            getNextTermFees(e.target.value)

            setLoading(false)
          })
          .catch((error) => {
            console.log('Error getting documents: ', error)
            setLoading(false)
          })
      })
      .catch((error) => {
        console.error('Error:', error)
        // Handle the error
      })
  }

  const getAttendance = (myClass) => {
    setLoading(true)
    var db = firebase.firestore()
    db.collection('attendance')
      .where(
        'sstc',
        '==',
        reactLocalStorage
          .get('current-school')
          .concat('_')
          .concat(sessionValue.replace('/', '_'))
          .concat('_')
          .concat(termValue.replace(' ', '_'))
          .concat('_')
          .concat(myClass)
      )
      .get()
      .then((querySnapshot) => {
        let allItems = []
        querySnapshot.forEach((doc) => {
          const items = doc.data().students
          allItems = allItems.concat(items)
        })
        var all = countUniqueStatus(allItems)
        setAllAttendance(all)
      })
      .catch((error) => {
        console.log('Error getting document:', error)
      })
  }

  function countUniqueStatus(items) {
    const statusCounts = {}

    items.forEach((item) => {
      const { doc_id, status } = item

      // Check if status is "true" and increment the count
      if (status === 'true') {
        statusCounts[doc_id] = (statusCounts[doc_id] || 0) + 1
      }
    })

    // Convert the object to an array
    const result = Object.keys(statusCounts).map((doc_id) => ({
      doc_id,
      count: statusCounts[doc_id],
    }))

    return result
  }

  function checkAssessment(doc) {
    for (let index = 0; index < assessmentValues.length; index++) {
      const element = assessmentValues[index]
      if (element.doc_id === doc.doc_id) {
        doc.ass1 = element.ass1
        doc.ass2 = element.ass2
        doc.test = element.test
        doc.exam = element.exam
        doc.total = element.total
        doc.grade = element.grade
        doc.doc_id = element.doc_id
      } else {
        // doc.ass1 = '-'
        // doc.ass2 = '-'
        // doc.test = '-'
        // doc.exam = '-'
        // doc.total = '-'
        // doc.grade = '-'
      }
    }
    return doc
  }

  const handleClick = (sub, id, click, index) => {
    const values = [...subject]
    values[index] = {
      name: sub,
      id: id,
      click: !click,
    }
    setSubject(values)
  }

  const styles = StyleSheet.create({
    page: {
      flexDirection: 'column',
      paddingTop: paddingTop,
      // paddingLeft: 50,
      // paddingRight: 50,
      paddingHorizontal: paddingHorizontal,
      fontFamily: 'Helvetica',
      backgroundColor: tableBackground,
    },
    page2: {
      flexDirection: 'column',
      paddingTop: 10,
      // paddingLeft: 50,
      // paddingRight: 50,
      paddingHorizontal: 10,
      fontFamily: 'Helvetica',
    },

    section: {
      // marginVertical: 5,
      color: '#fff',
    },
    section2: {
      marginHorizontal: 20,
      color: '#fff',
    },
    logo: {
      // width: 100,
      // height: 100,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    logo2: {
      marginLeft: 'auto',
      marginRight: 'auto',
      height: '90px',
    },
    school: {
      // marginVertical: 10,
      color: '#07173B',
      // letterSpacing: 1,
      fontSize: 40,
      fontWeight: 'heavy',
      textAlign: 'center',
      textTransform: 'uppercase',
      fontFamily: 'Times-Bold',
    },
    school2: {
      // marginVertical: 10,
      color: '#07173B',
      // letterSpacing: 1,
      fontSize: 15,
      fontWeight: 'heavy',
      textAlign: 'center',
      textTransform: 'uppercase',
      fontFamily: 'Times-Bold',
    },
    pupils: {
      // marginVertical: 10,
      color: '#07173B',
      // letterSpacing: 1,
      marginTop: 5,
      fontSize: 12,
      fontWeight: 'heavy',
      textAlign: 'left',
      textTransform: 'uppercase',
      fontFamily: 'Times-Bold',
    },

    title: {
      marginVertical: 10,
      color: '#3E8EDE',
      // letterSpacing: 1,
      fontSize: 15,
      textAlign: 'center',
      textTransform: 'uppercase',
      fontFamily: 'Helvetica',
    },
    subTitle: {
      // marginVertical: 5,
      marginTop: 15,
      color: '#3E8EDE',
      // letterSpacing: 1,
      fontSize: 10,
      // textAlign: 'center',
      textTransform: 'uppercase',
      fontFamily: 'Helvetica',
    },
    subTitleBlack: {
      // marginVertical: 5,
      marginTop: 15,
      // color: '#3E8EDE',
      // letterSpacing: 1,
      fontSize: 10,
      // textAlign: 'center',
      textTransform: 'uppercase',
      fontFamily: 'Helvetica',
    },
    remarkText: {
      // marginVertical: 5,
      marginTop: 20,
      // letterSpacing: 1,
      fontSize: 10,
      // textDecoration: 'underline',
      // textAlign: 'center',
      // textTransform: 'uppercase',
      fontFamily: 'Helvetica',
    },
    edusaf: {
      fontSize: 8,
      fontFamily: 'Helvetica',
      position: 'absolute',
      bottom: 5,
      right: 5,
    },

    table: {
      fontSize: 8,
      textAlign: 'center',
      fontFamily: 'Helvetica',
    },
    table9: {
      fontSize: 9,
      textAlign: 'center',
      fontFamily: 'Helvetica',
    },
    tableUbuntu: {
      fontSize: 8,
      textAlign: 'center',
      fontFamily: 'Ubuntu',
    },
    tableUbuntuCenterBig: {
      fontSize: 9,
      textAlign: 'center',
      fontFamily: 'Ubuntu',
    },
    table2: {
      fontSize: 10,
      textAlign: 'center',
      fontFamily: 'Helvetica',
    },
    tableBehaviour: {
      fontSize: 8,
      textAlign: 'center',
      fontFamily: 'Helvetica',
      width: 250,
      flex: 1,
      // flexDirection: 'row',
    },
    tableRatingParent: {
      fontSize: 8,
      textAlign: 'center',
      fontFamily: 'Helvetica',
      width: 200,
      flex: 1,
      // flexDirection: 'row',
    },
    tableBehaviourParent: {
      fontSize: 8,
      textAlign: 'center',
      fontFamily: 'Helvetica',
      flexDirection: 'row',
      display: 'flex',
    },

    tableRating: {
      fontSize: 8,
      textAlign: 'left',
      paddingLeft: 5,
      fontFamily: 'Helvetica',
    },
    tableRatingUbuntu: {
      fontSize: 4,
      textAlign: 'right',
      paddingRight: 5,
      fontFamily: 'Ubuntu',
    },
    tableRatingUbuntu7: {
      fontSize: 7,
      textAlign: 'right',
      paddingRight: 5,
      fontFamily: 'Ubuntu',
    },
    tableRating2: {
      fontSize: 10,
      textAlign: 'left',
      paddingLeft: 5,
      fontFamily: 'Helvetica',
    },
    tableLeft: {
      fontSize: 8,
      textAlign: 'left',
      fontFamily: 'Helvetica',
      paddingLeft: 5,
      backgroundColor: tableColour,
    },
    tableLeft8: {
      fontSize: 8,
      textAlign: 'left',
      fontFamily: 'Helvetica',
      paddingLeft: 5,
    },
    tableLeft9: {
      fontSize: 9,
      textAlign: 'left',
      fontFamily: 'Helvetica',
      paddingLeft: 5,
    },
    tableLeftUbuntu: {
      fontSize: 9,
      textAlign: 'left',
      fontFamily: 'Ubuntu',
      paddingLeft: 5,
    },
    tableLeftUbuntu8: {
      fontSize: 8,
      textAlign: 'left',
      fontFamily: 'Ubuntu',
      paddingLeft: 5,
    },
    tableLeft2: {
      fontSize: 8,
      textAlign: 'left',
      fontFamily: 'Helvetica',
      paddingLeft: 5,
    },

    tableLeft3: {
      fontSize: 10,
      textAlign: 'left',
      fontFamily: 'Helvetica',
      paddingLeft: 5,
    },
    tableRight: {
      fontSize: 10,
      textAlign: 'right',
      fontFamily: 'Helvetica',
      paddingRight: 5,
    },
    student: {
      fontSize: 10,
      textAlign: 'left',
      fontFamily: 'Helvetica',
      paddingLeft: 5,
      paddingVertical: 2,
    },
    student8: {
      fontSize: 8,
      textAlign: 'left',
      fontFamily: 'Helvetica',
      paddingLeft: 5,
      paddingVertical: 2,
    },
    studentUbuntu: {
      fontSize: 8,
      textAlign: 'left',
      fontFamily: 'Ubuntu',
      paddingLeft: 5,
      paddingVertical: 2,
    },
    studentTable: {
      // marginVertical: 10,
    },
    total: {
      fontSize: 8,
      textAlign: 'center',
      fontFamily: 'Helvetica',
      backgroundColor: tableColour,
    },
    total9: {
      fontSize: 9,
      textAlign: 'center',
      fontFamily: 'Helvetica',
      backgroundColor: tableColour,
    },
    totalUbuntu: {
      fontSize: 9,
      textAlign: 'center',
      fontFamily: 'Ubuntu',
      backgroundColor: tableColour,
    },
    totalUbuntu8: {
      fontSize: 8,
      textAlign: 'center',
      fontFamily: 'Ubuntu',
      backgroundColor: tableColour,
    },
    tablesubject: {
      fontSize: 8,
      textAlign: 'left',
      paddingLeft: 5,
      backgroundColor: tableColour,
      fontFamily: 'Helvetica',
    },
    tablesubject9: {
      fontSize: 9,
      textAlign: 'left',
      paddingLeft: 5,
      backgroundColor: tableColour,
      fontFamily: 'Helvetica',
    },
    tablesubjectUbuntu: {
      fontSize: 9,
      textAlign: 'left',
      paddingLeft: 5,
      backgroundColor: tableColour,
      fontFamily: 'Ubuntu',
    },
    tablesubjectUbuntu8: {
      fontSize: 8,
      textAlign: 'left',
      paddingLeft: 5,
      backgroundColor: tableColour,
      fontFamily: 'Ubuntu',
    },
    tablesubjectUbuntuRight: {
      fontSize: 9,
      textAlign: 'right',
      paddingRight: 5,
      backgroundColor: tableColour,
      fontFamily: 'Ubuntu',
    },
    tablesubjectUbuntuRight8: {
      fontSize: 8,
      textAlign: 'right',
      paddingRight: 5,
      backgroundColor: tableColour,
      fontFamily: 'Ubuntu',
    },
    tableNamesubject: {
      fontSize: 10,
      textAlign: 'left',
      paddingLeft: 5,
      backgroundColor: tableColour,
      fontFamily: 'Helvetica',
      paddingVertical: 2,
    },
    tableNamesubject9: {
      fontSize: 9,
      textAlign: 'left',
      paddingLeft: 5,
      backgroundColor: tableColour,
      fontFamily: 'Helvetica',
      paddingVertical: 2,
    },
    tableNamesubject8: {
      fontSize: 8,
      textAlign: 'left',
      paddingLeft: 5,
      backgroundColor: tableColour,
      fontFamily: 'Helvetica',
      paddingVertical: 2,
    },
    tableNamesubjectArabic: {
      fontSize: 10,
      textAlign: 'right',
      paddingRight: 5,
      backgroundColor: tableColour,
      fontFamily: 'Ubuntu',
      paddingVertical: 2,
    },
    tableNamesubjectArabic9: {
      fontSize: 9,
      textAlign: 'right',
      paddingRight: 5,
      backgroundColor: tableColour,
      fontFamily: 'Ubuntu',
      paddingVertical: 2,
    },
    tableNamesubjectArabic8: {
      fontSize: 8,
      textAlign: 'right',
      paddingRight: 5,
      backgroundColor: tableColour,
      fontFamily: 'Ubuntu',
      paddingVertical: 2,
    },

    hide: {
      opacity: 0,
      display: 'none',
      border: 'none',
    },
    studentDetails: {
      fontSize: 10,
      textAlign: 'left',
      fontFamily: 'Helvetica',
      paddingLeft: 5,
      paddingVertical: 2,
    },

    studentDetailsUbuntu: {
      fontSize: 10,
      textAlign: 'left',
      paddingLeft: 5,
      paddingVertical: 2,
      fontFamily: 'Ubuntu',
    },
    studentDetailsUbuntu8: {
      fontSize: 8,
      textAlign: 'left',
      paddingLeft: 5,
      paddingVertical: 2,
      fontFamily: 'Ubuntu',
    },
    studentDetailsCenterUbuntu: {
      fontSize: 10,
      textAlign: 'center',
      // paddingLeft: 5,
      paddingVertical: 2,
      fontFamily: 'Ubuntu',
    },
    studentDetailsCenter: {
      fontSize: 10,
      textAlign: 'center',
      fontFamily: 'Helvetica',
      paddingVertical: 2,
    },
    studentDetailsCenter9: {
      fontSize: 9,
      textAlign: 'center',
      fontFamily: 'Helvetica',
      paddingVertical: 2,
    },
    studentDetailsCenter8: {
      fontSize: 8,
      textAlign: 'center',
      fontFamily: 'Helvetica',
      paddingVertical: 2,
    },
    qrcode: {
      width: 80,
      height: 80,
      marginLeft: 'auto',
      marginRight: 'auto',
      marginTop: 10,
    },
  })

  const MyDocument =  () => {
    
    var logo = reactLocalStorage.get('heading')
    const sch = reactLocalStorage.get('school-name')
    
    const currentSchool = reactLocalStorage.get('current-school')
    const currentSchoolName = reactLocalStorage.get('school-name')
    const modibbo = 'MODIBBO IDRIS ACADEMY, GOMBE'
    const annibras = 'An-Nibras Model School'
    const smartGombe = 'Smart Academy Gombe'
    const flyingColorsGombe = 'knNn0QpqbUaVBcOBUBFU'
    const zenithPrimary = 'FBzIYMgZ1nJlyMX2Btk1'
    const markazu = 'qSfZ9ljUp8bmYNJo8InQ'
    const blissGombe = 'BLISS SUCCESS MODEL SCHOOL'
    const sunshineGombe = 'SUNSHINE MODEL SCHOOL, AKKO GOMBE'
    const alImanCollegeJos = 'Al-iman College Jos'
    const alImanSchoolGirls = 'AL-IMAN GIRLS SECONDARY SCHOOL'
    const alImanSchoolBoys = 'AL-IMAN SECONDARY SCHOOL'
    const esteemJos = 'fhqa4oQYx5j5KotPOLWz'
    const albayan = 'ATi6GodoPjPJkLfzU5OQ'
    const presteenJos = 'voZ4XR9kBq6txi85vzAB'
    const iqraaJos = 'H8ER5nzwQIHmWH1dz3I3'
    const sunnahJos = '8B2gLb0TzZBOzkLc5byX'
    const sunnahJosDogonDutse = 'dF1Ve6tDZfykDTtl8Xs7'
    const aljanahain = '1s88nt53GcZ93JzUocJK'
    const darusssalam = 'fml1iFqhog7GIW01e2Cc'
    const asasulIslam = 'f5Fb6AredH18z4mkvnY6'
    if (currentSchool === albayan) {
      //setTableBackground('#FFFFEC')
      setPaddingTop(0)
      setPaddingHorizontal(10)
    }

    var alimanSec =
      (currentSchoolName === alImanSchoolBoys &&
        classValue.includes('J.S.S')) ||
      (currentSchoolName === alImanSchoolBoys && classValue.includes('S.S.S'))

    const caType =
      currentSchool === zenithPrimary ||
      currentSchool === markazu ||
      currentSchoolName === alImanSchoolGirls || 
      alimanSec ||
      currentSchool === esteemJos ||
     currentSchool === presteenJos ||
      currentSchool === iqraaJos || 
      currentSchool === sunnahJosDogonDutse || currentSchool === asasulIslam
        ? '4'
        : '3'

        

    const formatter = new Intl.NumberFormat('en-NG', {
      style: 'currency',
      currency: 'NGN',
      maximumFractionDigits: '0',
    })
    var fees = Nextfees ? formatter.format(Nextfees) : ''

    return (
      <Document>
        {student.map((stu, index) => {
          return (
            <Page index={index} size="A4" style={styles.page}>
              <Image
                style={currentSchool !== albayan ? styles.logo : styles.logo2}
                src={logo}
              />
              <View style={styles.school2}>
                {/* <Text>{ termValue} Result</Text> */}
                <Text>
                  {termValue}
                  {currentSchool !== albayan
                    ? ' Result'
                    : ' Continuous Assessment'}
                </Text>
              </View>
              <View style={styles.pupils}>
                <Text>PUPILS/STUDENT INFORMATION</Text>
              </View>
              {sch === smartGombe ? (
                <DetailsTable3
                  student={stu}
                  index={index}
                  size={student.length}
                  key={index}
                />
              ) : currentSchool === albayan ? (
                <DetailsAlbayan
                  student={stu}
                  index={index}
                  size={student.length}
                  key={index}
                />
              ) : showPosition == 'true' ? (
                <DetailsTable
                  student={stu}
                  index={index}
                  size={student.length}
                  key={index}
                />
              ) : (
                <DetailsTable2
                  student={stu}
                  index={index}
                  size={student.length}
                  key={index}
                />
              )}
              <View style={styles.pupils}>
                <Text>Cognitive Domain</Text>
              </View>
              {showPosition == 'true' ? (
                currentSchoolName == modibbo ? (
                  <MyTableModibbo student={stu} key={index} />
                ) : currentSchoolName == annibras ? (
                  <MyTableAnnibras student={stu} key={index} />
                ) : currentSchoolName === sunshineGombe ? (
                  <MyTableNoPositionSunshineGombe
                    student={stu}
                    key={index}
                    size={student.length}
                  />
                ) : currentSchool === albayan ? (
                  <MyTableAlbayanFirstAssessment
                    student={stu}
                    key={index}
                    size={student.length}
                  />
                ) : currentSchoolName === alImanCollegeJos ? (
                  <MyTable4CAAlimanCollege student={stu} key={index} />
                ) : currentSchool === sunnahJosDogonDutse ? (
                  <MyTable4CASunnahJosDogonDutse student={stu} key={index} />
                ) : caType === '3'|| currentSchool === aljanahain ? (
                  <MyTable student={stu} key={index} />
                ) : (currentSchool === esteemJos &&
                    classValue.includes('Nursery')) ||
                  (currentSchool === esteemJos &&
                    classValue.includes('Primary')) ? (
                  <MyTableEsteemNurseryPrimary student={stu} key={index} />
                ) : currentSchool === sunnahJos ? (
                  <MyTableSunnahJos student={stu} key={index} />
                ) : (
                  <MyTable4CA student={stu} key={index} />
                )
              ) : currentSchoolName === smartGombe ? (
                <MyTableSmartAcademy
                  student={stu}
                  key={index}
                  size={student.length}
                />
              ) : currentSchool === flyingColorsGombe ? (
                <MyTableNoPositionFlyingColors
                  student={stu}
                  key={index}
                  size={student.length}
                />
              ) : currentSchoolName === sunshineGombe ? (
                <MyTableNoPositionSunshineGombe
                  student={stu}
                  key={index}
                  size={student.length}
                />
              ) : currentSchoolName === blissGombe ? (
                <MyTableBliss student={stu} key={index} size={student.length} />
              ) : (
                <MyTableNoPosition student={stu} key={index} />
              )}
              {/* {currentSchool === albayan? <Text></Text>:''} */}
              {currentSchool === albayan ? (
                <TotalTableAlbayan
                  student={stu}
                  index={index}
                  size={student.length}
                  key={index}
                />
              ) : (
                <Text></Text>
              )}
              {currentSchoolName === blissGombe ? (
                <View>
                  <View style={styles.tableBehaviourParent}>
                    <View style={styles.pupils}>
                      <Text>Affective Domain</Text>
                    </View>
                    <View>
                      <Text style={styles.section2}>''</Text>
                    </View>
                    <View style={styles.pupils}>
                      <Text>Psychomotor Domain</Text>
                    </View>
                  </View>

                  <View style={styles.tableBehaviourParent}>
                    <View style={styles.tableBehaviour}>
                      <BehaviouralTableBliss student={stu} key={index} />
                    </View>
                    <View>
                      <Text style={styles.section2}>''</Text>
                    </View>

                    <View style={styles.tableBehaviour}>
                      <PsychomotorTableBliss student={stu} key={index} />
                    </View>

                    <View>
                      <Text style={styles.section2}>''</Text>
                    </View>

                    <View style={styles.tableRatingParent}>
                      <GradingTableBliss student={stu} key={index} />
                    </View>
                  </View>
                </View>
              ) : (
                <View>
                  {currentSchool !== albayan ? (
                    <View style={styles.pupils}>
                      <Text>Affective Domain</Text>
                    </View>
                  ) : (
                    <View>
                      <Text style={styles.section2}>''</Text>
                    </View>
                  )}
                  <View style={styles.tableBehaviourParent}>
                    {currentSchoolName === alImanCollegeJos ? (
                      <View style={styles.tableBehaviour}>
                        <BehaviouralTableAlImanCollege
                          student={stu}
                          key={index}
                        />
                      </View>
                    ) : currentSchool === albayan ? (
                      <View style={styles.tableRatingParent}>
                        <GradingTableAlbayan student={stu} key={index} />
                      </View>
                    ) : (
                      <View style={styles.tableBehaviour}>
                        <BehaviouralTable student={stu} key={index} />
                      </View>
                    )}

                    <View>
                      <Text style={styles.section2}>''</Text>
                    </View>

                    {currentSchoolName == modibbo ? (
                      <View style={styles.tableRatingParent}>
                        <GradingTableModibbo student={stu} key={index} />
                        <Text style={styles.section2}>''</Text>
                        <RatingTableModibbo student={stu} key={index} />
                      </View>
                    ) : currentSchoolName == smartGombe ? (
                      <View style={styles.tableRatingParent}>
                        <GradingTableSmartAcademy student={stu} key={index} />
                        {/* <Text style={styles.section2}>''</Text>
                    <RatingTableSmartAcademy student={stu} key={index} /> */}
                      </View>
                    ) : currentSchool === albayan ? (
                      <View style={styles.tableRatingParent}>
                        <GradingTableAlbayanContinue
                          student={stu}
                          key={index}
                        />
                      </View>
                    ) : (
                      <View style={styles.tableRatingParent}>
                        <GradingTable student={stu} key={index} />
                      </View>
                    )}
                  </View>
                </View>
              )}
              {/* <View>
                <Text style={styles.remarkText}>Remark: {getRemark(stu)}</Text>
              </View> */}
              <View>
                <Text style={styles.section2}>''</Text>
              </View>

              {showPosition == 'true' && currentSchool !== albayan ? (
                <View style={styles.tableRight}>
                  <Text>NEXT TERM FEES:{fees} </Text>
                  <Text style={styles.section2}>''</Text>
                </View>
              ) : currentSchool !== albayan ? (
                <View style={styles.tableRight}>
                  <Text style={styles.section2}>''</Text>
                </View>
              ) : (
                <View></View>
              )}
              {currentSchool === albayan ? (
                <RemarkTableAlbayan
                  student={stu}
                  index={index}
                  size={student.length}
                  key={index}
                />
              ) : (
                <RemarkTable
                  student={stu}
                  index={index}
                  size={student.length}
                  key={index}
                />
              )}
              {currentSchool !== albayan ? (
                <View>
                  <PdfWithQrCode value={stu.doc_id} />
                </View>
              ) : (
                <Text></Text>
              )}

              <View style={styles.edusaf}>
                <Text>Powered by edutech</Text>
              </View>
            </Page>
          )
        })}
      </Document>
    )
  }

  const MyTable = ({ student }) => {
    setTableColour('#ffffff')

    var dt = getCAData(student)
    const currentSchoolName = reactLocalStorage.get('school-name')
    const alImanSchoolGirls = 'AL-IMAN GIRLS SECONDARY SCHOOL'
    const alImanSchoolBoys = 'AL-IMAN SECONDARY SCHOOL'
    const esteemJos = 'Esteem International Academy'
  
    // if (currentSchoolName === esteemJos) {
    //   setTableColour('#1E8449')
    // }

    return (
      <Table style={styles.table} data={[...dt]}>
        <TableHeader>
          <TableCell weighting={0.04}>
            <View style={styles.tableLeft}>
              <Text>SN</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.33}>
            <View style={styles.tablesubject}>
              <Text>SUBJECT</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.07}>
            <View style={styles.table}>
              <Text>ASS 1</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.07}>
            <View style={styles.table}>
              <Text>ASS 2</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.07}>
            <View style={styles.table}>
              <Text>TEST</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.08}>
            <View style={styles.table}>
              <Text>EXAMS</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.08}>
            <View style={styles.total}>
              <Text>TOTAL</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.08}>
            <View style={styles.table}>
              <Text>GRADE</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.08}>
            <View style={styles.table}>
              <Text>POS</Text>
            </View>
          </TableCell>
          {/* <TableCell weighting={0.08}>
            <View style={styles.table}>
              <Text>Class AVG</Text>
            </View>
          </TableCell> */}
          <TableCell weighting={0.1}>
            <View style={styles.tableLeft}>
              <Text>REMARK</Text>
            </View>
          </TableCell>
        </TableHeader>
        <TableBody>
          <DataTableCell weighting={0.04} getContent={(r) => r.index} />
          <DataTableCell weighting={0.33} getContent={(r) => r.subject} />
          <DataTableCell weighting={0.07} getContent={(r) => r.ass1} />
          <DataTableCell weighting={0.07} getContent={(r) => r.ass2} />
          <DataTableCell weighting={0.07} getContent={(r) => r.test} />
          <DataTableCell weighting={0.08} getContent={(r) => r.exam} />
          <DataTableCell weighting={0.08} getContent={(r) => r.total} />
          <DataTableCell weighting={0.08} getContent={(r) => r.grade} />
          <DataTableCell weighting={0.08} getContent={(r) => r.pos} />
          {/* <DataTableCell weighting={0.08} getContent={(r) => r.avg} /> */}
          <DataTableCell weighting={0.1} getContent={(r) => r.remark} />
        </TableBody>
      </Table>
    )
  }
  const MyTableEsteemNurseryPrimary = ({ student }) => {
    var dt = getCAData(student)
    const currentSchoolName = reactLocalStorage.get('school-name')
    const alImanSchoolGirls = 'AL-IMAN GIRLS SECONDARY SCHOOL'
    const alImanSchoolBoys = 'AL-IMAN SECONDARY SCHOOL'
    const esteemJos = 'Esteem International Academy'
    if (
      currentSchoolName === alImanSchoolBoys ||
      currentSchoolName === alImanSchoolGirls
    ) {
      setTableColour('#ffffff')
    }
    // if (currentSchoolName === esteemJos) {
    //   setTableColour('#1E8449')
    // }

    return (
      <Table style={styles.table} data={[...dt]}>
        <TableHeader>
          <TableCell weighting={0.04}>
            <View style={styles.tableLeft}>
              <Text>SN</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.33}>
            <View style={styles.tablesubject}>
              <Text>SUBJECT</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.09}>
            <View style={styles.table}>
              <Text>ASS 1</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.09}>
            <View style={styles.table}>
              <Text>ASS 2</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.09}>
            <View style={styles.table}>
              <Text>EXAMS</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.09}>
            <View style={styles.total}>
              <Text>TOTAL</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.09}>
            <View style={styles.table}>
              <Text>GRADE</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.08}>
            <View style={styles.table}>
              <Text>POS</Text>
            </View>
          </TableCell>
          {/* <TableCell weighting={0.08}>
            <View style={styles.table}>
              <Text>Class AVG</Text>
            </View>
          </TableCell> */}
          <TableCell weighting={0.1}>
            <View style={styles.tableLeft}>
              <Text>REMARK</Text>
            </View>
          </TableCell>
        </TableHeader>
        <TableBody>
          <DataTableCell weighting={0.04} getContent={(r) => r.index} />
          <DataTableCell weighting={0.33} getContent={(r) => r.subject} />
          <DataTableCell weighting={0.09} getContent={(r) => r.ass1} />
          <DataTableCell weighting={0.09} getContent={(r) => r.ass2} />
          {/* <DataTableCell weighting={0.07} getContent={(r) => r.test} /> */}
          <DataTableCell weighting={0.09} getContent={(r) => r.exam} />
          <DataTableCell weighting={0.09} getContent={(r) => r.total} />
          <DataTableCell weighting={0.09} getContent={(r) => r.grade} />
          <DataTableCell weighting={0.08} getContent={(r) => r.pos} />
          {/* <DataTableCell weighting={0.08} getContent={(r) => r.avg} /> */}
          <DataTableCell weighting={0.1} getContent={(r) => r.remark} />
        </TableBody>
      </Table>
    )
  }

  const MyTableSunnahJos = ({ student }) => {
    //var dt = getCAData(student)
    var dt = getCADataArabicSubjectNameSunnah(student)
    const currentSchool = reactLocalStorage.get('current-school')
    const sunnahJos = '8B2gLb0TzZBOzkLc5byX'
    setTableColour('#ffffff')

    return (
      <Table style={styles.table} data={[...dt]}>
        <TableHeader>
          <TableCell weighting={0.05}>
            <View style={styles.tableLeft}>
              <Text>SN</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.35}>
            <View style={styles.tablesubject}>
              <Text>SUBJECT</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.1}>
            <View style={styles.table}>
              <Text>ASS (30%) </Text>
            </View>
          </TableCell>

          <TableCell weighting={0.1}>
            <View style={styles.table}>
              <Text>EXAMS (70%)</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.1}>
            <View style={styles.total}>
              <Text>TOTAL (100%)</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.1}>
            <View style={styles.table}>
              <Text>GRADE</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.1}>
            <View style={styles.table}>
              <Text>POS</Text>
            </View>
          </TableCell>
          {/* <TableCell weighting={0.08}>
            <View style={styles.table}>
              <Text>Class AVG</Text>
            </View>
          </TableCell> */}
          <TableCell weighting={0.1}>
            <View style={styles.tableLeft}>
              <Text>REMARK</Text>
            </View>
          </TableCell>
        </TableHeader>
        <TableBody>
          <DataTableCell weighting={0.05} getContent={(r) => r.index} />
          <DataTableCell weighting={0.35} getContent={(r) => r.subject} />
          <DataTableCell weighting={0.1} getContent={(r) => r.ass1} />
          {/* <DataTableCell weighting={0.09} getContent={(r) => r.ass2} /> */}
          {/* <DataTableCell weighting={0.07} getContent={(r) => r.test} /> */}
          <DataTableCell weighting={0.1} getContent={(r) => r.exam} />
          <DataTableCell weighting={0.1} getContent={(r) => r.total} />
          <DataTableCell weighting={0.1} getContent={(r) => r.grade} />
          <DataTableCell weighting={0.1} getContent={(r) => r.pos} />
          {/* <DataTableCell weighting={0.08} getContent={(r) => r.avg} /> */}
          <DataTableCell weighting={0.1} getContent={(r) => r.remark} />
        </TableBody>
      </Table>
    )
  }

  const MyTable4CAAlimanCollege = ({ student }) => {
    setTableColour('#ffffff')
    if (
      classValue.includes('PRIMARY') ||
      classValue.includes('NURSERY') ||
      classValue.includes('SHUBA')
    ) {
      return <MyTable4CAAlimanCollegePrimary student={student} key={1} />
    }

    var dt = getCADataArabicSubjectName(student)
    return (
      <Table style={styles.table} data={[...dt]}>
        <TableHeader>
          <TableCell weighting={0.04}>
            <View style={styles.tableLeft}>
              <Text>SN</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.26}>
            <View style={styles.tablesubject}>
              <Text>SUBJECT</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.07}>
            <View style={styles.table}>
              <Text>ASS 1</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.07}>
            <View style={styles.table}>
              <Text>ASS 2</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.07}>
            <View style={styles.table}>
              <Text>TEST 1</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.07}>
            <View style={styles.table}>
              <Text>TEST 2</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.08}>
            <View style={styles.table}>
              <Text>EXAMS</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.08}>
            <View style={styles.total}>
              <Text>TOTAL</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.08}>
            <View style={styles.table}>
              <Text>GRADE</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.08}>
            <View style={styles.table}>
              <Text>POS</Text>
            </View>
          </TableCell>
          {/* <TableCell weighting={0.08}>
            <View style={styles.table}>
              <Text>Class AVG</Text>
            </View>
          </TableCell> */}
          <TableCell weighting={0.1}>
            <View style={styles.tableLeft}>
              <Text>REMARK</Text>
            </View>
          </TableCell>
        </TableHeader>
        <TableBody>
          <DataTableCell weighting={0.04} getContent={(r) => r.index} />
          <DataTableCell weighting={0.26} getContent={(r) => r.subject} />
          <DataTableCell weighting={0.07} getContent={(r) => r.ass1} />
          <DataTableCell weighting={0.07} getContent={(r) => r.ass2} />
          <DataTableCell weighting={0.07} getContent={(r) => r.test} />
          <DataTableCell weighting={0.07} getContent={(r) => r.test2} />
          <DataTableCell weighting={0.08} getContent={(r) => r.exam} />
          <DataTableCell weighting={0.08} getContent={(r) => r.total} />
          <DataTableCell weighting={0.08} getContent={(r) => r.grade} />
          <DataTableCell weighting={0.08} getContent={(r) => r.pos} />
          {/* <DataTableCell weighting={0.08} getContent={(r) => r.avg} /> */}
          <DataTableCell weighting={0.1} getContent={(r) => r.remark} />
        </TableBody>
      </Table>
    )
  }
  const MyTable4CAAlimanCollegePrimary = ({ student }) => {
    setTableColour('#ffffff')

    var dt = getCADataArabicSubjectName(student)
    return (
      <Table style={styles.table} data={[...dt]}>
        <TableHeader>
          <TableCell weighting={0.04}>
            <View style={styles.tableLeft}>
              <Text>SN</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.33}>
            <View style={styles.tablesubject}>
              <Text>SUBJECT</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.07}>
            <View style={styles.table}>
              <Text>ASS 1</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.07}>
            <View style={styles.table}>
              <Text>ASS 2</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.07}>
            <View style={styles.table}>
              <Text>TEST 1</Text>
            </View>
          </TableCell>
          {/* <TableCell weighting={0.07}>
            <View style={styles.table}>
              <Text>TEST 2</Text>
            </View>
          </TableCell> */}
          <TableCell weighting={0.08}>
            <View style={styles.table}>
              <Text>EXAMS</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.08}>
            <View style={styles.total}>
              <Text>TOTAL</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.08}>
            <View style={styles.table}>
              <Text>GRADE</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.08}>
            <View style={styles.table}>
              <Text>POS</Text>
            </View>
          </TableCell>
          {/* <TableCell weighting={0.08}>
            <View style={styles.table}>
              <Text>Class AVG</Text>
            </View>
          </TableCell> */}
          <TableCell weighting={0.1}>
            <View style={styles.tableLeft}>
              <Text>REMARK</Text>
            </View>
          </TableCell>
        </TableHeader>
        <TableBody>
          <DataTableCell weighting={0.04} getContent={(r) => r.index} />
          <DataTableCell weighting={0.33} getContent={(r) => r.subject} />
          <DataTableCell weighting={0.07} getContent={(r) => r.ass1} />
          <DataTableCell weighting={0.07} getContent={(r) => r.ass2} />
          <DataTableCell weighting={0.07} getContent={(r) => r.test} />
          {/* <DataTableCell weighting={0.07} getContent={(r) => r.test2} /> */}
          <DataTableCell weighting={0.08} getContent={(r) => r.exam} />
          <DataTableCell weighting={0.08} getContent={(r) => r.total} />
          <DataTableCell weighting={0.08} getContent={(r) => r.grade} />
          <DataTableCell weighting={0.08} getContent={(r) => r.pos} />
          {/* <DataTableCell weighting={0.08} getContent={(r) => r.avg} /> */}
          <DataTableCell weighting={0.1} getContent={(r) => r.remark} />
        </TableBody>
      </Table>
    )
  }

  const MyTable4CASunnahJosDogonDutse = ({ student }) => {
    setTableColour('#ffffff')
    var dt = getCADataArabicSubjectName(student)
    return (
      <Table style={styles.table} data={[...dt]}>
        <TableHeader>
          <TableCell weighting={0.04}>
            <View style={styles.tableLeft}>
              <Text>SN</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.26}>
            <View style={styles.tablesubject}>
              <Text>SUBJECT</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.07}>
            <View style={styles.table}>
              <Text>1st ASS</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.07}>
            <View style={styles.table}>
              <Text>1st TEST</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.07}>
            <View style={styles.table}>
              <Text>2nd ASS</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.07}>
            <View style={styles.table}>
              <Text>2nd TEST</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.08}>
            <View style={styles.table}>
              <Text>EXAMS</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.08}>
            <View style={styles.total}>
              <Text>TOTAL</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.08}>
            <View style={styles.table}>
              <Text>GRADE</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.08}>
            <View style={styles.table}>
              <Text>POS</Text>
            </View>
          </TableCell>
          {/* <TableCell weighting={0.08}>
            <View style={styles.table}>
              <Text>Class AVG</Text>
            </View>
          </TableCell> */}
          <TableCell weighting={0.1}>
            <View style={styles.tableLeft}>
              <Text>REMARK</Text>
            </View>
          </TableCell>
        </TableHeader>
        <TableBody>
          <DataTableCell weighting={0.04} getContent={(r) => r.index} />
          <DataTableCell weighting={0.26} getContent={(r) => r.subject} />
          <DataTableCell weighting={0.07} getContent={(r) => r.ass1} />
          <DataTableCell weighting={0.07} getContent={(r) => r.ass2} />
          <DataTableCell weighting={0.07} getContent={(r) => r.test} />
          <DataTableCell weighting={0.07} getContent={(r) => r.test2} />
          <DataTableCell weighting={0.08} getContent={(r) => r.exam} />
          <DataTableCell weighting={0.08} getContent={(r) => r.total} />
          <DataTableCell weighting={0.08} getContent={(r) => r.grade} />
          <DataTableCell weighting={0.08} getContent={(r) => r.pos} />
          {/* <DataTableCell weighting={0.08} getContent={(r) => r.avg} /> */}
          <DataTableCell weighting={0.1} getContent={(r) => r.remark} />
        </TableBody>
      </Table>
    )
  }

  const MyTable4CA = ({ student }) => {
    const currentSchoolName = reactLocalStorage.get('school-name')
    const alImanSchoolGirls = 'AL-IMAN GIRLS SECONDARY SCHOOL'
    const alImanSchoolBoys = 'AL-IMAN SECONDARY SCHOOL'
    const esteemJos = 'Esteem International Academy'
    if (
      currentSchoolName === alImanSchoolBoys ||
      currentSchoolName === alImanSchoolGirls
    ) {
      setTableColour('#ffffff')
    }
    //  if (currentSchoolName === esteemJos) {
    //   setTableColour('#DC241C')
    // }
    var dt = getCAData(student)
    return (
      <Table style={styles.table} data={[...dt]}>
        <TableHeader>
          <TableCell weighting={0.04}>
            <View style={styles.tableLeft}>
              <Text>SN</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.26}>
            <View style={styles.tablesubject}>
              <Text>SUBJECT</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.07}>
            <View style={styles.table}>
              <Text>ASS 1</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.07}>
            <View style={styles.table}>
              <Text>ASS 2</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.07}>
            <View style={styles.table}>
              <Text>TEST 1</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.07}>
            <View style={styles.table}>
              <Text>TEST 2</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.08}>
            <View style={styles.table}>
              <Text>EXAMS</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.08}>
            <View style={styles.total}>
              <Text>TOTAL</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.08}>
            <View style={styles.table}>
              <Text>GRADE</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.08}>
            <View style={styles.table}>
              <Text>POS</Text>
            </View>
          </TableCell>
          {/* <TableCell weighting={0.08}>
            <View style={styles.table}>
              <Text>Class AVG</Text>
            </View>
          </TableCell> */}
          <TableCell weighting={0.1}>
            <View style={styles.tableLeft}>
              <Text>REMARK</Text>
            </View>
          </TableCell>
        </TableHeader>
        <TableBody>
          <DataTableCell weighting={0.04} getContent={(r) => r.index} />
          <DataTableCell weighting={0.26} getContent={(r) => r.subject} />
          <DataTableCell weighting={0.07} getContent={(r) => r.ass1} />
          <DataTableCell weighting={0.07} getContent={(r) => r.ass2} />
          <DataTableCell weighting={0.07} getContent={(r) => r.test} />
          <DataTableCell weighting={0.07} getContent={(r) => r.test2} />
          <DataTableCell weighting={0.08} getContent={(r) => r.exam} />
          <DataTableCell weighting={0.08} getContent={(r) => r.total} />
          <DataTableCell weighting={0.08} getContent={(r) => r.grade} />
          <DataTableCell weighting={0.08} getContent={(r) => r.pos} />
          {/* <DataTableCell weighting={0.08} getContent={(r) => r.avg} /> */}
          <DataTableCell weighting={0.1} getContent={(r) => r.remark} />
        </TableBody>
      </Table>
    )
  }
  const MyTableSmartAcademy = ({ student, size }) => {
    var dt = getCADataSmartAcademy(student)

    return (
      <Table style={styles.table} data={[...dt]}>
        <TableHeader>
          <TableCell weighting={0.04}>
            <View style={styles.tableLeft}>
              <Text>SN</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.19}>
            <View style={styles.tablesubject}>
              <Text>SUBJECT</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.07}>
            <View style={styles.table}>
              <Text>C.A 1 (20%)</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.07}>
            <View style={styles.table}>
              <Text>C.A 2 (20%)</Text>
            </View>
          </TableCell>

          <TableCell weighting={0.07}>
            <View style={styles.table}>
              <Text>EXAMS (60%)</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.07}>
            <View style={styles.total}>
              <Text>TOTAL (100%)</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.07}>
            <View style={styles.table}>
              <Text>GRADE</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.06}>
            <View style={styles.table}>
              <Text>POS</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.07}>
            <View style={styles.table}>
              <Text>OUT OF</Text>
            </View>
          </TableCell>

          <TableCell weighting={0.07}>
            <View style={styles.table}>
              <Text>LOW. IN CLASS</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.07}>
            <View style={styles.table}>
              <Text>HIGH. IN CLASS</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.07}>
            <View style={styles.table}>
              <Text>CLASS AVG</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.09}>
            <View style={styles.tableLeft}>
              <Text>REMARK</Text>
            </View>
          </TableCell>
        </TableHeader>
        <TableBody>
          <DataTableCell weighting={0.04} getContent={(r) => r.index} />
          <DataTableCell weighting={0.19} getContent={(r) => r.subject} />
          <DataTableCell weighting={0.07} getContent={(r) => r.ass1} />
          <DataTableCell weighting={0.07} getContent={(r) => r.ass2} />
          <DataTableCell weighting={0.07} getContent={(r) => r.exam} />
          <DataTableCell weighting={0.07} getContent={(r) => r.total} />
          <DataTableCell weighting={0.07} getContent={(r) => r.grade} />
          <DataTableCell weighting={0.06} getContent={(r) => r.pos} />
          <DataTableCell weighting={0.07} getContent={(r) => r.out} />
          <DataTableCell weighting={0.07} getContent={(r) => r.lowest} />
          <DataTableCell weighting={0.07} getContent={(r) => r.highest} />
          <DataTableCell weighting={0.07} getContent={(r) => r.avg} />
          <DataTableCell weighting={0.09} getContent={(r) => r.remark} />
        </TableBody>
      </Table>
    )
  }
  const MyTableNoPosition = ({ student }) => {
    var dt = getCAData(student)

    return (
      <Table style={styles.table} data={[...dt]}>
        <TableHeader>
          <TableCell weighting={0.04}>
            <View style={styles.tableLeft2}>
              <Text>SN</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.34}>
            <View style={styles.tablesubject}>
              <Text>SUBJECT</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.08}>
            <View style={styles.table}>
              <Text>ASS 1</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.08}>
            <View style={styles.table}>
              <Text>ASS 2</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.08}>
            <View style={styles.table}>
              <Text>TEST</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.09}>
            <View style={styles.table}>
              <Text>EXAMS</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.09}>
            <View style={styles.total}>
              <Text>TOTAL</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.09}>
            <View style={styles.table}>
              <Text>GRADE</Text>
            </View>
          </TableCell>

          {/* <TableCell weighting={0.09}>
            <View style={styles.table}>
              <Text>Class AVG</Text>
            </View>
          </TableCell> */}
          <TableCell weighting={0.1}>
            <View style={styles.tableLeft}>
              <Text>REMARK</Text>
            </View>
          </TableCell>
        </TableHeader>
        <TableBody>
          <DataTableCell weighting={0.04} getContent={(r) => r.index} />
          <DataTableCell weighting={0.34} getContent={(r) => r.subject} />
          <DataTableCell weighting={0.08} getContent={(r) => r.ass1} />
          <DataTableCell weighting={0.08} getContent={(r) => r.ass2} />
          <DataTableCell weighting={0.08} getContent={(r) => r.test} />
          <DataTableCell weighting={0.09} getContent={(r) => r.exam} />
          <DataTableCell weighting={0.09} getContent={(r) => r.total} />
          <DataTableCell weighting={0.09} getContent={(r) => r.grade} />
          {/* <DataTableCell weighting={0.08} getContent={(r) => r.pos} /> */}
          {/* <DataTableCell weighting={0.09} getContent={(r) => r.avg} /> */}
          <DataTableCell weighting={0.1} getContent={(r) => r.remark} />
        </TableBody>
      </Table>
    )
  }

  const MyTableNoPositionFlyingColors = ({ student }) => {
    var dt = getCAData(student)
    return (
      <Table style={styles.table} data={[...dt]}>
        <TableHeader>
          <TableCell weighting={0.04}>
            <View style={styles.tableLeft2}>
              <Text>SN</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.34}>
            <View style={styles.tablesubject}>
              <Text>SUBJECT</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.09}>
            <View style={styles.table}>
              <Text>1st C.A</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.09}>
            <View style={styles.table}>
              <Text>2nd C.A</Text>
            </View>
          </TableCell>

          <TableCell weighting={0.1}>
            <View style={styles.table}>
              <Text>EXAMS</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.1}>
            <View style={styles.total}>
              <Text>TOTAL</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.1}>
            <View style={styles.table}>
              <Text>GRADE</Text>
            </View>
          </TableCell>

          {/* <TableCell weighting={0.09}>
            <View style={styles.table}>
              <Text>Class AVG</Text>
            </View>
          </TableCell> */}
          <TableCell weighting={0.11}>
            <View style={styles.tableLeft}>
              <Text>REMARK</Text>
            </View>
          </TableCell>
        </TableHeader>
        <TableBody>
          <DataTableCell weighting={0.04} getContent={(r) => r.index} />
          <DataTableCell weighting={0.34} getContent={(r) => r.subject} />
          <DataTableCell weighting={0.09} getContent={(r) => r.ass1} />
          <DataTableCell weighting={0.09} getContent={(r) => r.ass2} />
          {/* <DataTableCell weighting={0.08} getContent={(r) => r.test} /> */}
          <DataTableCell weighting={0.1} getContent={(r) => r.exam} />
          <DataTableCell weighting={0.1} getContent={(r) => r.total} />
          <DataTableCell weighting={0.1} getContent={(r) => r.grade} />
          {/* <DataTableCell weighting={0.08} getContent={(r) => r.pos} /> */}
          {/* <DataTableCell weighting={0.09} getContent={(r) => r.avg} /> */}
          <DataTableCell weighting={0.11} getContent={(r) => r.remark} />
        </TableBody>
      </Table>
    )
  }

  const MyTableNoPositionSunshineGombe = ({ student }) => {
    var dt = getCAData(student)
    setTableColour('#ffffff')
    return (
      <Table style={styles.table} data={[...dt]}>
        <TableHeader>
          <TableCell weighting={0.04}>
            <View style={styles.tableLeft2}>
              <Text>SN</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.34}>
            <View style={styles.tablesubject}>
              <Text>SUBJECT</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.09}>
            <View style={styles.table}>
              <Text>1st C.A</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.09}>
            <View style={styles.table}>
              <Text>2nd C.A</Text>
            </View>
          </TableCell>

          <TableCell weighting={0.1}>
            <View style={styles.table}>
              <Text>EXAMS</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.1}>
            <View style={styles.total}>
              <Text>TOTAL</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.1}>
            <View style={styles.table}>
              <Text>GRADE</Text>
            </View>
          </TableCell>

          {/* <TableCell weighting={0.09}>
            <View style={styles.table}>
              <Text>Class AVG</Text>
            </View>
          </TableCell> */}
          <TableCell weighting={0.11}>
            <View style={styles.tableLeft}>
              <Text>REMARK</Text>
            </View>
          </TableCell>
        </TableHeader>
        <TableBody>
          <DataTableCell weighting={0.04} getContent={(r) => r.index} />
          <DataTableCell weighting={0.34} getContent={(r) => r.subject} />
          <DataTableCell weighting={0.09} getContent={(r) => r.ass1} />
          <DataTableCell weighting={0.09} getContent={(r) => r.ass2} />
          {/* <DataTableCell weighting={0.08} getContent={(r) => r.test} /> */}
          <DataTableCell weighting={0.1} getContent={(r) => r.exam} />
          <DataTableCell weighting={0.1} getContent={(r) => r.total} />
          <DataTableCell weighting={0.1} getContent={(r) => r.grade} />
          {/* <DataTableCell weighting={0.08} getContent={(r) => r.pos} /> */}
          {/* <DataTableCell weighting={0.09} getContent={(r) => r.avg} /> */}
          <DataTableCell weighting={0.11} getContent={(r) => r.remark} />
        </TableBody>
      </Table>
    )
  }

  const MyTableModibbo = ({ student }) => {
    var dt = getCAData(student)

    return (
      <Table style={styles.table} data={[...dt]}>
        <TableHeader>
          <TableCell weighting={0.06}>
            <View style={styles.tableLeft}>
              <Text>SN</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.25}>
            <View style={styles.tablesubject}>
              <Text>SUBJECT</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.11}>
            <View style={styles.table}>
              <Text>C.A/30%</Text>
            </View>
          </TableCell>

          <TableCell weighting={0.11}>
            <View style={styles.table}>
              <Text>EXAMS/70%</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.11}>
            <View style={styles.total}>
              <Text>TOTAL/100%</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.11}>
            <View style={styles.table}>
              <Text>GRADE</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.11}>
            <View style={styles.table}>
              <Text>POS</Text>
            </View>
          </TableCell>

          <TableCell weighting={0.14}>
            <View style={styles.tableLeft}>
              <Text>REMARK</Text>
            </View>
          </TableCell>
        </TableHeader>
        <TableBody>
          <DataTableCell weighting={0.06} getContent={(r) => r.index} />
          <DataTableCell weighting={0.25} getContent={(r) => r.subject} />
          <DataTableCell weighting={0.11} getContent={(r) => r.camodibbo} />
          <DataTableCell weighting={0.11} getContent={(r) => r.exam} />
          <DataTableCell weighting={0.11} getContent={(r) => r.total} />
          <DataTableCell weighting={0.11} getContent={(r) => r.grade} />
          <DataTableCell weighting={0.11} getContent={(r) => r.pos} />
          <DataTableCell weighting={0.14} getContent={(r) => r.remark} />
        </TableBody>
      </Table>
    )
  }

  const MyTableAnnibras = ({ student }) => {
    var dt = getCAData(student)

    return (
      <Table style={styles.table} data={[...dt]}>
        <TableHeader>
          <TableCell weighting={0.04}>
            <View style={styles.tableLeft}>
              <Text>SN</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.22}>
            <View style={styles.tablesubject}>
              <Text>SUBJECT</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.12}>
            <View style={styles.table}>
              <Text>1st C.A/20%</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.12}>
            <View style={styles.table}>
              <Text>2nd C.A/20%</Text>
            </View>
          </TableCell>

          <TableCell weighting={0.12}>
            <View style={styles.table}>
              <Text>EXAMS/60%</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.12}>
            <View style={styles.total}>
              <Text>TOTAL/100%</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.08}>
            <View style={styles.table}>
              <Text>GRADE</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.08}>
            <View style={styles.table}>
              <Text>POS</Text>
            </View>
          </TableCell>

          <TableCell weighting={0.1}>
            <View style={styles.tableLeft}>
              <Text>REMARK</Text>
            </View>
          </TableCell>
        </TableHeader>
        <TableBody>
          <DataTableCell weighting={0.04} getContent={(r) => r.index} />
          <DataTableCell weighting={0.22} getContent={(r) => r.subject} />
          <DataTableCell weighting={0.12} getContent={(r) => r.ass1} />
          <DataTableCell weighting={0.12} getContent={(r) => r.ass2} />
          <DataTableCell weighting={0.12} getContent={(r) => r.exam} />
          <DataTableCell weighting={0.12} getContent={(r) => r.total} />
          <DataTableCell weighting={0.08} getContent={(r) => r.grade} />
          <DataTableCell weighting={0.08} getContent={(r) => r.pos} />
          <DataTableCell weighting={0.1} getContent={(r) => r.remark} />
        </TableBody>
      </Table>
    )
  }

  const MyTableBliss = ({ student }) => {
    var dt = getCAData(student)

    // if (currentSchoolName === esteemJos) {
    //   setTableColour('#1E8449')
    // }

    return (
      <Table style={styles.table} data={[...dt]}>
        <TableHeader>
          <TableCell weighting={0.04}>
            <View style={styles.tableLeft}>
              <Text>SN</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.33}>
            <View style={styles.tablesubject}>
              <Text>SUBJECT</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.07}>
            <View style={styles.table}>
              <Text>ASS 1</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.07}>
            <View style={styles.table}>
              <Text>ASS 2</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.07}>
            <View style={styles.table}>
              <Text>TEST</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.08}>
            <View style={styles.table}>
              <Text>EXAMS</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.08}>
            <View style={styles.total}>
              <Text>TOTAL</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.08}>
            <View style={styles.table}>
              <Text>GRADE</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.08}>
            <View style={styles.table}>
              <Text>POS</Text>
            </View>
          </TableCell>
          {/* <TableCell weighting={0.08}>
            <View style={styles.table}>
              <Text>Class AVG</Text>
            </View>
          </TableCell> */}
          <TableCell weighting={0.1}>
            <View style={styles.tableLeft}>
              <Text>REMARK</Text>
            </View>
          </TableCell>
        </TableHeader>
        <TableBody>
          <DataTableCell weighting={0.04} getContent={(r) => r.index} />
          <DataTableCell weighting={0.33} getContent={(r) => r.subject} />
          <DataTableCell weighting={0.07} getContent={(r) => r.ass1} />
          <DataTableCell weighting={0.07} getContent={(r) => r.ass2} />
          <DataTableCell weighting={0.07} getContent={(r) => r.test} />
          <DataTableCell weighting={0.08} getContent={(r) => r.exam} />
          <DataTableCell weighting={0.08} getContent={(r) => r.total} />
          <DataTableCell weighting={0.08} getContent={(r) => r.grade} />
          <DataTableCell weighting={0.08} getContent={(r) => r.pos} />
          {/* <DataTableCell weighting={0.08} getContent={(r) => r.avg} /> */}
          <DataTableCell weighting={0.1} getContent={(r) => r.remark} />
        </TableBody>
      </Table>
    )
  }

  const MyTableAlbayan = ({ student }) => {
    // setTableColour('#F2CE7F')
    setTableColour('#FFFFFF')
    var dt = getCADataArabicSubjectNameAlbayan(student)
    return (
      <Table style={styles.table} data={[...dt]}>
        <TableHeader>
          <TableCell weighting={0.04}>
            <View style={styles.tableLeftUbuntu8}>
              <Text>SN</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.24}>
            <View style={styles.tablesubjectUbuntu8}>
              <Text>SUBJECT</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.0625}>
            <View style={styles.tableUbuntu}>
              <Text>ASS 1</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.0625}>
            <View style={styles.tableUbuntu}>
              <Text>TEST 1</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.0625}>
            <View style={styles.tableUbuntu}>
              <Text>ASS 2</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.0625}>
            <View style={styles.tableUbuntu}>
              <Text>TEST 2</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.0625}>
            <View style={styles.tableUbuntu}>
              <Text>EXAMS</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.0625}>
            <View style={styles.totalUbuntu8}>
              <Text>TOTAL</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.0625}>
            <View style={styles.tableUbuntu}>
              <Text>GRADE</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.0525}>
            <View style={styles.tableUbuntu}>
              <Text>POS</Text>
            </View>
          </TableCell>

          <TableCell weighting={0.1}>
            <View style={styles.tableLeftUbuntu8}>
              <Text>REMARK</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.17}>
            <View style={styles.tablesubjectUbuntuRight8}>
              <Text>المواد</Text>
            </View>
          </TableCell>
        </TableHeader>
        <TableBody>
          <DataTableCell weighting={0.04} getContent={(r) => r.index} />
          <DataTableCell weighting={0.24} getContent={(r) => r.subject} />
          <DataTableCell weighting={0.0625} getContent={(r) => r.ass1} />
          <DataTableCell weighting={0.0625} getContent={(r) => r.ass2} />
          <DataTableCell weighting={0.0625} getContent={(r) => r.test} />
          <DataTableCell weighting={0.0625} getContent={(r) => r.test2} />
          <DataTableCell weighting={0.0625} getContent={(r) => r.exam} />
          <DataTableCell weighting={0.0625} getContent={(r) => r.total} />
          <DataTableCell weighting={0.0625} getContent={(r) => r.grade} />
          <DataTableCell weighting={0.0525} getContent={(r) => r.pos} />
          <DataTableCell weighting={0.1} getContent={(r) => r.remark} />
          <DataTableCell weighting={0.17} getContent={(r) => r.subjectArabic} />
        </TableBody>
      </Table>
    )
  }
  const MyTableAlbayanFirstAssessment = ({ student }) => {
     //setTableColour('#F2CE7F')
    setTableColour('#FFFFFF')
    var dt = getCADataArabicSubjectNameAlbayanFirstAssessment(student)
    return (
      <Table style={styles.table} data={[...dt]}>
        <TableHeader>
          <TableCell weighting={0.04}>
            <View style={styles.tableLeftUbuntu8}>
              <Text>SN</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.22}>
            <View style={styles.tablesubjectUbuntu8}>
              <Text>SUBJECT</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.07}>
            <View style={styles.tableUbuntu}>
              <Text>ASS. 1 (05%)</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.07}>
            <View style={styles.tableUbuntu}>
              <Text>TEST 1 (10%)</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.07}>
            <View style={styles.tableUbuntu}>
              <Text>ASS 2 (05%)</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.07}>
            <View style={styles.tableUbuntu}>
              <Text>TEST 2 (10%)</Text>
            </View>
          </TableCell>
          {/* <TableCell weighting={0.0625}>
            <View style={styles.tableUbuntu}>
              <Text>EXAMS</Text>
            </View>
          </TableCell> */}
          <TableCell weighting={0.07}>
            <View style={styles.totalUbuntu8}>
              <Text>TOTAL (30%)</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.07}>
            <View style={styles.tableUbuntu}>
              <Text>GRADE</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.07}>
            <View style={styles.tableUbuntu}>
              <Text>POS</Text>
            </View>
          </TableCell>

          <TableCell weighting={0.1}>
            <View style={styles.tableLeftUbuntu8}>
              <Text>REMARK</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.15}>
            <View style={styles.tablesubjectUbuntuRight8}>
              <Text>المواد</Text>
            </View>
          </TableCell>
        </TableHeader>
        <TableBody>
          <DataTableCell weighting={0.04} getContent={(r) => r.index} />
          <DataTableCell weighting={0.22} getContent={(r) => r.subject} />
          <DataTableCell weighting={0.07} getContent={(r) => r.ass1} />
          <DataTableCell weighting={0.07} getContent={(r) => r.ass2} />
          <DataTableCell weighting={0.07} getContent={(r) => r.test} />
          <DataTableCell weighting={0.07} getContent={(r) => r.test2} />
          {/* <DataTableCell weighting={0.0625} getContent={(r) => r.exam} /> */}
          <DataTableCell weighting={0.07} getContent={(r) => r.total} />
          <DataTableCell weighting={0.07} getContent={(r) => r.grade} />
          <DataTableCell weighting={0.07} getContent={(r) => r.pos} />
          <DataTableCell weighting={0.1} getContent={(r) => r.remark} />
          <DataTableCell weighting={0.15} getContent={(r) => r.subjectArabic} />
        </TableBody>
      </Table>
    )
  }
  function formatDateMonthName(date) {
    const month = [
      'JAN',
      'FEB',
      'MAR',
      'APR',
      'MAY',
      'JUN',
      'JUL',
      'AUG',
      'SEP',
      'OCT',
      'NOV',
      'DEC',
    ]
    let name = month[date.getMonth()]
    let day = date.getDate()
    let year = date.getFullYear()
    return name.concat(' ').concat(day).concat(', ').concat(year)
  }

  function formatDate2(date) {
    var parts = date.split('-')
    // Please pay attention to the month (parts[1]); JavaScript counts months from 0:
    // January - 0, February - 1, etc.
    const month = [
      'JAN',
      'FEB',
      'MAR',
      'APR',
      'MAY',
      'JUN',
      'JUL',
      'AUG',
      'SEP',
      'OCT',
      'NOV',
      'DEC',
    ]

    //var mydate = new Date(parts[0], parts[1] - 1, parts[2])

    let name = month[parts[1] - 1]
    let day = parts[2]
    let year = parts[0]
    return name.concat(' ').concat(day).concat(', ').concat(year)
  }

  function getNextTermFees(value) {
    if (termValue === 'First Term') {
      getFees(value, 'Second Term', sessionValue)
    } else if (termValue == 'Second Term') {
      getFees(value, 'Third Term', sessionValue)
    } else {
      console.log('3rd')
    }
  }

  function getFees(value, term, session) {
    var db = firebase.firestore()
    var query = db.collection('fees')
    query
      .doc(
        reactLocalStorage
          .get('current-school')
          .concat('_')
          .concat(session.replace('/', '_'))
          .concat('_')
          .concat(term.replace(' ', '_'))
      )
      .get()
      .then((doc) => {
        if (doc.exists) {
          // setClassDocument(doc.data().classes)
          let values = []
          doc.data().classes.map((item, index) => {
            //values.push(item)
            if (value == item.name) {
              setNextFees(item.amount)
            }
          })
        }
      })
      .catch((error) => {
        console.log('Error getting document:', error)
      })
  }

  const DetailsTable = ({ student, index, size }) => {
    const nameUpper = student.name.toUpperCase()
    const genderUpper = student.gender.toUpperCase()
    var timesPresent = checkPresent(student.doc_id)
    const hideAttendance = reactLocalStorage.get('hide-attendance')

    const currentSchoolName = reactLocalStorage.get('school-name')
    const alImanCollegeJos = 'Al-iman College Jos'
    const alImanSchoolGirls = 'AL-IMAN GIRLS SECONDARY SCHOOL'
    const alImanSchoolBoys = 'AL-IMAN SECONDARY SCHOOL'
    var isAlIman =
      currentSchoolName === alImanSchoolBoys ||
      currentSchoolName === alImanSchoolGirls ||
      currentSchoolName === alImanCollegeJos
    return (
      <Table
        data={[
          {
            label1: 'Admission No',
            label2: student.id,
            label3: 'Total Score',
            label4: getTotalScore(student),
            label5: isAlIman ? '' : 'Times Open',
            label6: isAlIman ? '' : timesOpen,
          },
          {
            label1: 'Gender',
            label2: genderUpper,
            label3: 'Average Score',
            label4: getAverage(student),
            label5: isAlIman ? '' : 'Times Present',
            label6: isAlIman
              ? ''
              : hideAttendance === 'hide'
              ? '-'
              : timesPresent,
          },
          {
            label1: 'Class',
            label2: classValue,
            label3: 'Final Grade',
            label4: getGradeDetails(student),
            label5: isAlIman ? '' : 'Times Absent',
            label6: isAlIman
              ? ''
              : hideAttendance === 'hide'
              ? '-'
              : timesOpen - timesPresent,
          },
          {
            label1: 'Term',
            label2: termValue,
            label3: 'Position',
            label4: showPosition ? getPosition(index) : '',
            label5: isAlIman ? '' : 'Term Ends',
            label6: isAlIman ? '' : formatDate2(termEnd),
          },
          {
            label1: 'Session',
            label2: sessionValue,
            label3: 'No in Class',
            label4: size,
            label5: 'Next Begins',
            label6: formatDate2(nextTerm),
          },
        ]}
      >
        <TableHeader>
          <TableCell weighting={0.18}>
            <View style={styles.tableNamesubject}>
              <Text>STUDENT NAME</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.82}>
            <View style={styles.student}>
              <Text>{nameUpper}</Text>
            </View>
          </TableCell>
          {/* <TableCell>DOB</TableCell>
          <TableCell>Country</TableCell> */}
        </TableHeader>
        <TableBody>
          <DataTableCell
            weighting={0.18}
            style={styles.tableNamesubject}
            getContent={(r) => r.label1}
          />
          <DataTableCell
            weighting={0.18}
            style={styles.studentDetails}
            getContent={(r) => r.label2}
          />
          <DataTableCell
            weighting={0.16}
            style={styles.tableNamesubject}
            getContent={(r) => r.label3}
          />
          <DataTableCell
            weighting={0.16}
            style={styles.studentDetails}
            getContent={(r) => r.label4}
          />
          <DataTableCell
            weighting={0.16}
            style={styles.tableNamesubject}
            getContent={(r) => r.label5}
          />
          <DataTableCell
            weighting={0.16}
            style={styles.studentDetails}
            getContent={(r) => r.label6}
          />
          {/* <DataTableCell getContent={(r) => r.score} />
          <DataTableCell getContent={(r) => r.average} /> */}
        </TableBody>
      </Table>
    )
  }
  const DetailsTable2 = ({ student, index, size }) => {
    const nameUpper = student.name.toUpperCase()
    const genderUpper = student.gender.toUpperCase()
    var timesPresent = checkPresent(student.doc_id)
    const schl = reactLocalStorage.get('school-name')
    const hideAttendance = reactLocalStorage.get('hide-attendance')

    const currentSchool = reactLocalStorage.get('current-school')

    const flyingColorsGombe = 'knNn0QpqbUaVBcOBUBFU'

    const currentSchoolName = reactLocalStorage.get('school-name')
    const alImanCollegeJos = 'Al-iman College Jos'
    const alImanSchoolGirls = 'AL-IMAN GIRLS SECONDARY SCHOOL'
    const alImanSchoolBoys = 'AL-IMAN SECONDARY SCHOOL'
    var isAlIman =
      currentSchoolName === alImanSchoolBoys ||
      currentSchoolName === alImanSchoolGirls ||
      currentSchoolName === alImanCollegeJos

    // Create our number formatter.
    const formatter = new Intl.NumberFormat('en-NG', {
      style: 'currency',
      currency: 'NGN',
      maximumFractionDigits: '0',

      // These options are needed to round to whole numbers if that's what you want.
      //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
      //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    })

    var fees = Nextfees ? formatter.format(Nextfees) : ''
    var newNextTerm = Date.parse(nextTerm)

    return (
      <Table
        data={[
          {
            label1: 'Admission No',
            label2: student.id,
            label3: 'Total Score',
            label4: getTotalScore(student),
            label5: isAlIman ? '' : 'Times Open',
            label6: isAlIman ? '' : timesOpen,
          },
          {
            label1: 'Gender',
            label2: genderUpper,
            label3: 'Average Score',
            label4: getAverage(student),
            label5: isAlIman ? '' : 'Times Present',
            label6: isAlIman
              ? ''
              : hideAttendance === 'hide'
              ? '-'
              : timesPresent,
          },
          {
            label1: 'Class',
            label2: classValue,
            label3: 'Final Grade',
            label4: getGradeDetails(student),
            label5: isAlIman ? '' : 'Times Absent',
            label6: isAlIman
              ? ''
              : hideAttendance === 'hide'
              ? '-'
              : timesOpen - timesPresent,
          },
          {
            label1: 'Term',
            label2: termValue,
            label3: 'Term Ends',
            label4: formatDate2(termEnd),
            label5: isAlIman
              ? ''
              : currentSchool === flyingColorsGombe
              ? 'Next Term Begins'
              : 'Next Begins',
            label6: isAlIman ? '' : formatDate2(nextTerm),
          },
          {
            label1: 'Session',
            label2: sessionValue,
            label3: 'Approved Date',
            label4: formatDateMonthName(new Date()),
            label5: 'Next Term Fees',
            label6: fees,
          },
        ]}
      >
        <TableHeader>
          <TableCell weighting={0.25}>
            <View style={styles.tableNamesubject}>
              <Text>STUDENT NAME</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.77}>
            <View style={styles.student}>
              <Text>{nameUpper}</Text>
            </View>
          </TableCell>
          {/* <TableCell>DOB</TableCell>
          <TableCell>Country</TableCell> */}
        </TableHeader>
        <TableBody>
          <DataTableCell
            weighting={0.253}
            style={styles.tableNamesubject}
            getContent={(r) => r.label1}
          />
          <DataTableCell
            weighting={0.1506}
            style={styles.studentDetailsUbuntu}
            getContent={(r) => r.label2}
          />
          <DataTableCell
            weighting={0.1506}
            style={styles.tableNamesubject}
            getContent={(r) => r.label3}
          />
          <DataTableCell
            weighting={0.1506}
            style={styles.studentDetails}
            getContent={(r) => r.label4}
          />
          <DataTableCell
            weighting={0.1506}
            style={styles.tableNamesubject}
            getContent={(r) => r.label5}
          />
          <DataTableCell
            weighting={0.1506}
            style={styles.studentDetails}
            getContent={(r) => r.label6}
          />
          {/* <DataTableCell getContent={(r) => r.score} />
          <DataTableCell getContent={(r) => r.average} /> */}
        </TableBody>
      </Table>
    )
  }

  const DetailsTable3 = ({ student, index, size }) => {
    const nameUpper = student.name.toUpperCase()
    const genderUpper = student.gender.toUpperCase()
    var timesPresent = checkPresent(student.doc_id)
    const schl = reactLocalStorage.get('school-name')
    const hideAttendance = reactLocalStorage.get('hide-attendance')

    const currentSchoolName = reactLocalStorage.get('school-name')
    const alImanCollegeJos = 'Al-iman College Jos'
    const alImanSchoolGirls = 'AL-IMAN GIRLS SECONDARY SCHOOL'
    const alImanSchoolBoys = 'AL-IMAN SECONDARY SCHOOL'
    var isAlIman =
      currentSchoolName === alImanSchoolBoys ||
      currentSchoolName === alImanSchoolGirls ||
      currentSchoolName === alImanCollegeJos

    setTableColour('#ffffff')
    // Create our number formatter.
    const formatter = new Intl.NumberFormat('en-NG', {
      style: 'currency',
      currency: 'NGN',
      maximumFractionDigits: '0',

      // These options are needed to round to whole numbers if that's what you want.
      //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
      //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    })

    var fees = Nextfees ? formatter.format(Nextfees) : ''
    var newNextTerm = Date.parse(nextTerm)

    return (
      <Table
        data={[
          {
            label1: 'Admission No',
            label2: student.id,
            label3: 'Total Score',
            label4: getTotalScore(student),
            label5: isAlIman ? '' : 'Times Open',
            label6: isAlIman ? '' : timesOpen,
          },
          {
            label1: 'Gender',
            label2: genderUpper,
            label3: 'Final Average',
            label4: getAverage(student),
            label5: isAlIman ? '' : 'Times Present',
            label6: isAlIman
              ? ''
              : hideAttendance === 'hide'
              ? '-'
              : timesPresent,
          },
          {
            label1: 'Class',
            label2: classValue,
            label3: 'Final Grade',
            label4: getGradeDetails(student),
            label5: isAlIman ? '' : 'Times Absent',
            label6: isAlIman
              ? ''
              : hideAttendance === 'hide'
              ? '-'
              : timesOpen - timesPresent,
          },
          {
            label1: 'Term',
            label2: termValue,
            label3: 'Term Ends',
            label4: formatDate2(termEnd),
            label5: isAlIman ? '' : 'Next Begins',
            label6: isAlIman ? '' : formatDate2(nextTerm),
          },
          {
            label1: 'Session',
            label2: sessionValue,
            label3: 'Approved Date',
            label4: formatDateMonthName(new Date()),
            label5: 'Next Term Fees',
            label6: fees,
          },
        ]}
      >
        <TableHeader>
          <TableCell weighting={0.25}>
            <View style={styles.tableNamesubject}>
              <Text>STUDENT NAME</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.77}>
            <View style={styles.student}>
              <Text>{nameUpper}</Text>
            </View>
          </TableCell>
          {/* <TableCell>DOB</TableCell>
          <TableCell>Country</TableCell> */}
        </TableHeader>
        <TableBody>
          <DataTableCell
            weighting={0.253}
            style={styles.tableNamesubject}
            getContent={(r) => r.label1}
          />
          <DataTableCell
            weighting={0.1506}
            style={styles.studentDetails}
            getContent={(r) => r.label2}
          />
          <DataTableCell
            weighting={0.1506}
            style={styles.tableNamesubject}
            getContent={(r) => r.label3}
          />
          <DataTableCell
            weighting={0.1506}
            style={styles.studentDetails}
            getContent={(r) => r.label4}
          />
          <DataTableCell
            weighting={0.1506}
            style={styles.tableNamesubject}
            getContent={(r) => r.label5}
          />
          <DataTableCell
            weighting={0.1506}
            style={styles.studentDetails}
            getContent={(r) => r.label6}
          />
          {/* <DataTableCell getContent={(r) => r.score} />
          <DataTableCell getContent={(r) => r.average} /> */}
        </TableBody>
      </Table>
    )
  }

  const DetailsAlbayan = ({ student, index, size }) => {
    const nameUpper = student.name.toUpperCase()
    var timesPresent = checkPresent(student.doc_id)
    const hideAttendance = reactLocalStorage.get('hide-attendance')
    return (
      <Table
        data={[
          {
            label1: 'STUDENT NAME',
            label2: nameUpper,
            label3: 'اسم الطالب',
          },
          {
            label1: 'STUDENT ID',
            label2: student.id,
            label3: 'رقم القبول',
          },

          {
            label1: 'CLASS',
            label2: classValue,
            label3: 'الفصل',
          },

          {
            label1: 'POSITION',
            label2: getPosition(index),
            label3: 'الترتيب',
          },
          {
            label1: 'NUMBER IN CLASS',
            label2: size,
            label3: 'عدد الطلاب',
          },
          {
            label1: 'TERM ENDING',
            label2: formatDate2(termEnd),
            label3: 'الفترة',
          },
          {
            label1: 'NEXT TERM BEGIN',
            label2: formatDate2(nextTerm),
            label3: 'الفصل الدراسي',
          },
        ]}
      >
        <TableHeader></TableHeader>
        {/* <TableHeader>
          <TableCell weighting={0.25}>
            <View style={styles.tableNamesubject}>
              <Text>STUDENT NAME</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.5}>
            <View style={styles.studentDetailsCenterUbuntu}>
              <Text>{nameUpper}</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.25}>
            <View style={styles.tableNamesubjectArabic}>
              <Text>اسم الطالب</Text>
            </View>
          </TableCell>
        
        </TableHeader> */}
        <TableBody>
          <DataTableCell
            weighting={0.33}
            style={styles.tableNamesubject8}
            getContent={(r) => r.label1}
          />
          <DataTableCell
            weighting={0.34}
            style={styles.studentDetailsCenter8}
            getContent={(r) => r.label2}
          />
          <DataTableCell
            weighting={0.33}
            style={styles.tableNamesubjectArabic8}
            getContent={(r) => r.label3}
          />

          {/* <DataTableCell getContent={(r) => r.score} />
          <DataTableCell getContent={(r) => r.average} /> */}
        </TableBody>
      </Table>
    )
  }

  function floatToArabic(num) {
    // Define the Arabic numerals
    const arabicNumerals = ['٠', '١', '٢', '٣', '٤', '٥', '٦', '٧', '٨', '٩']

    // Convert the number to a string
    const numStr = num.toString()

    // Initialize an empty string to store the Arabic equivalent
    let arabicEquivalent = ''

    // Loop through each character in the number string
    for (let i = 0; i < numStr.length; i++) {
      // If the character is a digit, append its Arabic equivalent
      if (!isNaN(parseInt(numStr[i]))) {
        arabicEquivalent += arabicNumerals[parseInt(numStr[i])]
      } else {
        // If the character is not a digit, append it as is
        arabicEquivalent += numStr[i]
      }
    }

    return arabicEquivalent
  }

  const TotalTableAlbayan = ({ student, index, size }) => {
    const nameUpper = student.name.toUpperCase()
    var timesPresent = checkPresent(student.doc_id)
    const hideAttendance = reactLocalStorage.get('hide-attendance')
    const { numberToArabic } = require('number-to-arabic')
    return (
      <Table
        data={[
          {
            label1: 'TOTAL SCORE',
            label2: getTotalScore(student),
            // label3: getTotalScore(student),
            label4: 'المجموع',
          },
          {
            label1: 'AVERAGE SCORE',
            label2: getAverage(student),
            // label3: getAverage(student),
            label4: 'النسبة المئوية ',
          },

          {
            label1: 'FINAL GRADE',
            label2: getGradeAlbayanSecondAssessment(getAverage(student)),
            // label3: getGradeDetails(student),
            label4: 'التقدير العام',
          },
        ]}
      >
        <TableHeader>
          <TableCell>
            <View></View>
          </TableCell>
        </TableHeader>

        <TableBody>
          <DataTableCell
            weighting={0.25}
            style={styles.tableNamesubject8}
            getContent={(r) => r.label1}
          />
          <DataTableCell
            weighting={0.5}
            style={styles.studentDetailsCenter8}
            getContent={(r) => r.label2}
          />
          {/* <DataTableCell
            weighting={0.25}
            style={styles.tableNamesubjectArabic8}
            getContent={(r) => (
              <Text style={{ fontFamily: 'Ubuntu', direction: 'rtl' }}>
                {floatToArabic(r.label3)}
              </Text>
            )}
          /> */}
          <DataTableCell
            weighting={0.25}
            style={styles.tableNamesubjectArabic8}
            getContent={(r) => r.label4}
          />

          {/* <DataTableCell getContent={(r) => r.score} />
          <DataTableCell getContent={(r) => r.average} /> */}
        </TableBody>
      </Table>
    )
  }
  const RemarkTable = ({ student, index, size }) => {
    const currentSchoolName = reactLocalStorage.get('school-name')
    const blissGombe = 'BLISS SUCCESS MODEL SCHOOL'
    const modibbo = 'MODIBBO IDRIS ACADEMY, GOMBE'
    return (
      <Table
        data={[
          {
            label1: ' ',
            label2: ' ',
          },
          {
            label1: "FORM TEACHER'S SIGNATURE",
            label2:
              classValue.includes('J.S.S') ||
              classValue.includes('S.S.S') ||
              classValue.includes('J.I.S') ||
              classValue.includes('S.I.S')
                ? "PRINCIPAL'S SIGNATURE"
                : currentSchoolName === blissGombe
                ? 'HEADMISTRESS SIGNATURE'
                : currentSchoolName === modibbo
                ? "HEAD TEACHER'S SIGNATURE"
                : "HEADMASTER'S SIGNATURE",
          },
        ]}
      >
        <TableHeader>
          <TableCell weighting={0.25}>
            <View style={styles.student}>
              <Text>COMMENT</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.75}>
            <View style={styles.student}>
              <Text>{getRemark(student)}</Text>
            </View>
          </TableCell>
          {/* <TableCell>DOB</TableCell>
          <TableCell>Country</TableCell> */}
        </TableHeader>
        <TableBody>
          <DataTableCell
            weighting={0.5}
            style={styles.student}
            getContent={(r) => r.label1}
          />
          <DataTableCell
            weighting={0.5}
            style={styles.studentDetails}
            getContent={(r) => r.label2}
          />
        </TableBody>
      </Table>
    )
  }

  const RemarkTableAlbayan = ({ student, index, size }) => {
    return (
      <Table
        data={[
          {
            label1: ' ',
            label2: ' ',
          },
          {
            label1: "EXAMINATION OFFICER'S SIGNATURE / مسؤول الإمتحانات",
            label2: "PRINCIPAL'S SIGNATURE / توقيع المدير",
          },
        ]}
      >
        <TableHeader>
          <TableCell weighting={0.25}>
            <View style={styles.student8}>
              <Text>COMMENT</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.75}>
            <View style={styles.student8}>
              <Text>{getRemark(student)}</Text>
            </View>
          </TableCell>
          {/* <TableCell>DOB</TableCell>
          <TableCell>Country</TableCell> */}
        </TableHeader>
        <TableBody>
          <DataTableCell
            weighting={0.5}
            style={styles.studentUbuntu}
            getContent={(r) => r.label1}
          />
          <DataTableCell
            weighting={0.5}
            style={styles.studentDetailsUbuntu8}
            getContent={(r) => r.label2}
          />
        </TableBody>
      </Table>
    )
  }
  const BehaviouralTable = ({ student }) => {
    const flyingColorsGombe = 'knNn0QpqbUaVBcOBUBFU'
    const currentSchool = reactLocalStorage.get('current-school')
    var dt = student.behaviour
    var punctuality = 'A'
    var attendance = 'A'
    var attentiveness = 'B'
    var assigment = 'B'
    var participation = 'B'
    var neatness = 'A'
    var honesty = 'A'
    var selfControl = 'C'
    var relationship = 'A'
    var leadership = 'B'
    var concentration = 'A'
    var games = 'A'
    if (dt !== undefined && dt !== null) {
      punctuality = dt.punctuality
      assigment = dt.assigment
      attendance = dt.attendance
      attentiveness = dt.attentiveness
      honesty = dt.honesty
      leadership = dt.leadership
      neatness = dt.neatness
      participation = dt.participation
      selfControl = dt.self_control
      concentration = dt.concentration
      relationship = dt.relationship
      if (dt.games !== undefined && dt.games !== null) {
        games = dt.games
      }
    }
    return (
      <Table
        data={[
          {
            label1: 'Punctuality',
            label2: punctuality,
          },
          {
            label1: 'Class Attendance',
            label2: attendance,
          },
          {
            label1: 'Attentiveness in class',
            label2: attentiveness,
          },
          {
            label1: 'Carrying out assigments',
            label2: assigment,
          },
          {
            label1: 'Participation in activities',
            label2: participation,
          },
          {
            label1: 'Neatness',
            label2: neatness,
          },
          {
            label1: 'Honesty',
            label2: honesty,
          },
          {
            label1: 'Seft-control',
            label2: selfControl,
          },
          {
            label1: 'Relationship with others',
            label2: 'A',
          },
          {
            label1: 'Leadership',
            label2: leadership,
          },
          // {
          //   label1: 'Games and Sports',
          //   label2: games,
          // },
          // {
          //   label1: 'Handling of lab tools',
          //   label2: 'C',
          // },
          // {
          //   label1: 'Cooperation',
          //   label2: 'B',
          // },
          // {
          //   label1: 'Concentration',
          //   label2: concentration,
          // },
        ]}
      >
        <TableHeader textAlign={'center'}>
          <TableCell style={styles.tablesubject}>RATINGS</TableCell>
        </TableHeader>
        <TableBody>
          <DataTableCell
            style={styles.tableRating}
            weighting={0.8}
            getContent={(r) => r.label1}
          />
          <DataTableCell
            style={styles.table}
            weighting={0.2}
            getContent={(r) => r.label2}
          />
        </TableBody>
      </Table>
    )
  }

  const BehaviouralTableAlImanCollege = ({ student }) => {
    var dt = student.behaviour
    var punctuality = 'A'
    var attendance = 'A'
    var attentiveness = 'B'
    var assigment = 'B'
    var participation = 'B'
    var neatness = 'A'
    var honesty = 'A'
    var selfControl = 'C'
    var relationship = 'A'
    var leadership = 'B'
    var concentration = 'A'
    var games = 'A'
    if (dt !== undefined && dt !== null) {
      punctuality = dt.punctuality
      assigment = dt.assigment
      attendance = dt.attendance
      attentiveness = dt.attentiveness
      honesty = dt.honesty
      leadership = dt.leadership
      neatness = dt.neatness
      participation = dt.participation
      selfControl = dt.self_control
      concentration = dt.concentration
      relationship = dt.relationship
      if (dt.games !== undefined && dt.games !== null) {
        games = dt.games
      }
    }
    return (
      <Table
        data={[
          {
            label1: 'Punctuality',
            label2: punctuality,
          },
          {
            label1: 'Class Attendance',
            label2: attendance,
          },
          {
            label1: 'Attentiveness in class',
            label2: attentiveness,
          },
          {
            label1: 'Carrying out assigments',
            label2: assigment,
          },
          {
            label1: 'Participation in activities',
            label2: participation,
          },
          {
            label1: 'Neatness',
            label2: neatness,
          },
          {
            label1: 'Honesty',
            label2: honesty,
          },
          // {
          //   label1: 'Seft-control',
          //   label2: selfControl,
          // },
          // {
          //   label1: 'Relationship with others',
          //   label2: 'A',
          // },
          // {
          //   label1: 'Leadership',
          //   label2: leadership,
          // },
          // {
          //   label1: 'Games and Sports',
          //   label2: games,
          // },
          // {
          //   label1: 'Handling of lab tools',
          //   label2: 'C',
          // },
          // {
          //   label1: 'Cooperation',
          //   label2: 'B',
          // },
          // {
          //   label1: 'Concentration',
          //   label2: concentration,
          // },
        ]}
      >
        <TableHeader textAlign={'center'}>
          <TableCell style={styles.tablesubject}>RATINGS</TableCell>
        </TableHeader>
        <TableBody>
          <DataTableCell
            style={styles.tableRating}
            weighting={0.8}
            getContent={(r) => r.label1}
          />
          <DataTableCell
            style={styles.table}
            weighting={0.2}
            getContent={(r) => r.label2}
          />
        </TableBody>
      </Table>
    )
  }

  const BehaviouralTableBliss = ({ student }) => {
    var dt = student.behaviour
    var punctuality = 'A'
    var attendance = 'A'
    var attentiveness = 'B'
    var assigment = 'B'
    var participation = 'B'
    var neatness = 'A'
    var honesty = 'A'
    var selfControl = 'C'
    var relationship = 'A'
    var leadership = 'B'
    var concentration = 'A'
    var games = 'A'
    if (dt !== undefined && dt !== null) {
      punctuality = dt.punctuality
      assigment = dt.assigment
      attendance = dt.attendance
      attentiveness = dt.attentiveness
      honesty = dt.honesty
      leadership = dt.leadership
      neatness = dt.neatness
      participation = dt.participation
      selfControl = dt.self_control
      concentration = dt.concentration
      relationship = dt.relationship
      if (dt.games !== undefined && dt.games !== null) {
        games = dt.games
      }
    }
    return (
      <Table
        data={[
          {
            label1: 'Punctuality',
            label2: punctuality,
          },
          {
            label1: 'Class Attendance',
            label2: attendance,
          },
          {
            label1: 'Attentiveness in class',
            label2: attentiveness,
          },
          {
            label1: 'Carrying out assigments',
            label2: assigment,
          },
          {
            label1: 'Participation in activities',
            label2: participation,
          },
          {
            label1: 'Neatness',
            label2: neatness,
          },
          {
            label1: 'Honesty',
            label2: honesty,
          },
          {
            label1: 'Seft-control',
            label2: selfControl,
          },
          {
            label1: 'Relationship with others',
            label2: 'A',
          },
          {
            label1: 'Leadership',
            label2: leadership,
          },
          // {
          //   label1: 'Games and Sports',
          //   label2: games,
          // },
          // {
          //   label1: 'Handling of lab tools',
          //   label2: 'C',
          // },
          // {
          //   label1: 'Cooperation',
          //   label2: 'B',
          // },
          // {
          //   label1: 'Concentration',
          //   label2: concentration,
          // },
        ]}
      >
        <TableHeader textAlign={'center'}>
          <TableCell style={styles.tablesubject}>RATINGS</TableCell>
        </TableHeader>
        <TableBody>
          <DataTableCell
            style={styles.tableRating}
            weighting={0.8}
            getContent={(r) => r.label1}
          />
          <DataTableCell
            style={styles.table}
            weighting={0.2}
            getContent={(r) => r.label2}
          />
        </TableBody>
      </Table>
    )
  }
  const PsychomotorTableBliss = ({ student }) => {
    var dt = student.behaviour
    var verbal = 'A'
    var tools = 'A'
    var musics = 'B'
    var writting = 'A'
    var games = 'A'
    if (dt !== undefined && dt !== null) {
      verbal = dt.verbal ? dt.verbal : verbal
      tools = dt.tools ? dt.tools : tools
      musics = dt.musics ? dt.musics : musics
      writting = dt.writting ? dt.writting : writting
      if (dt.games !== undefined && dt.games !== null) {
        games = dt.games
      }
    }
    return (
      <Table
        data={[
          {
            label1: 'Games & Sport',
            label2: games,
          },
          {
            label1: 'Handwriting',
            label2: writting,
          },
          {
            label1: 'Musical Skills',
            label2: musics,
          },
          {
            label1: 'Handling tools',
            label2: tools,
          },
          {
            label1: 'Verbal Fluency',
            label2: verbal,
          },
        ]}
      >
        <TableHeader textAlign={'center'}>
          <TableCell style={styles.tablesubject}>RATINGS</TableCell>
        </TableHeader>
        <TableBody>
          <DataTableCell
            style={styles.tableRating}
            weighting={0.8}
            getContent={(r) => r.label1}
          />
          <DataTableCell
            style={styles.table}
            weighting={0.2}
            getContent={(r) => r.label2}
          />
        </TableBody>
      </Table>
    )
  }

  const GradingTable = () => {
    return (
      <Table
        data={[
          {
            label1: 'Range',
            label2: 'Grade',
            label3: 'Remark',
          },
          {
            label1: '70 - 100',
            label2: 'A',
            label3: 'Excellent',
          },
          {
            label1: '60 - 69',
            label2: 'B',
            label3: 'Very Good',
          },
          {
            label1: '50 - 59',
            label2: 'C',
            label3: 'Good',
          },
          {
            label1: '45 - 49',
            label2: 'D',
            label3: 'Pass',
          },
          {
            label1: '40 - 44',
            label2: 'E',
            label3: 'Pass',
          },

          {
            label1: '39 - below',
            label2: 'F',
            label3: 'Fail',
          },
        ]}
      >
        <TableHeader textAlign={'center'}>
          <TableCell style={styles.tablesubject}>GRADING SYSTEM</TableCell>
        </TableHeader>
        <TableBody>
          <DataTableCell
            style={styles.tableRating}
            weighting={0.3}
            getContent={(r) => r.label1}
          />
          <DataTableCell
            style={styles.table}
            weighting={0.3}
            getContent={(r) => r.label2}
          />
          <DataTableCell
            style={styles.tableRating}
            weighting={0.4}
            getContent={(r) => r.label3}
          />
        </TableBody>
      </Table>
    )
  }

  const GradingTableAlbayan = () => {
    return (
      <Table
        data={[
          {
            label1: 'Range',
            label2: 'Grade',
            label3: 'Remark',
            label4: 'ترجمة التقدير',
          },
          {
            label1: '75 - 100',
            label2: 'A1',
            label3: 'Distinction',
            label4: 'ممتاز',
          },
          {
            label1: '70 - 74',
            label2: 'B2',
            label3: 'Very Good',
            label4: 'جيد جدا',
          },
          {
            label1: '65 - 69',
            label2: 'B3',
            label3: 'Good',
            label4: 'جيد جدا',
          },
          {
            label1: '60 - 64',
            label2: 'C4',
            label3: 'Credit',
            label4: 'جيد',
          },
        ]}
      >
        <TableHeader textAlign={'center'}>
          <TableCell style={styles.tablesubject}>GRADING SYSTEM</TableCell>
        </TableHeader>
        <TableBody>
          <DataTableCell
            style={styles.tableRating}
            weighting={0.23}
            getContent={(r) => r.label1}
          />
          <DataTableCell
            style={styles.table}
            weighting={0.23}
            getContent={(r) => r.label2}
          />
          <DataTableCell
            style={styles.tableRating}
            weighting={0.23}
            getContent={(r) => r.label3}
          />
          <DataTableCell
            style={styles.tableRatingUbuntu7}
            weighting={0.31}
            getContent={(r) => r.label4}
          />
        </TableBody>
      </Table>
    )
  }
  const GradingTableAlbayanContinue = () => {
    return (
      <Table
        data={[
          {
            label1: '55 - 59',
            label2: 'C5',
            label3: 'Credit',
            label4: 'جيد',
          },

          {
            label1: '50 - 54',
            label2: 'C6',
            label3: 'Credit',
            label4: 'جيد',
          },
          {
            label1: '45 - 49',
            label2: 'D7',
            label3: 'Pass',
            label4: 'ناجح',
          },
          {
            label1: '40 - 44',
            label2: 'E8',
            label3: 'Pass',
            label4: 'ناجح',
          },

          {
            label1: '0 - 39',
            label2: 'F9',
            label3: 'Fail',
            label4: 'راسب',
          },
        ]}
      >
        <TableHeader textAlign={'center'}>
          <TableCell style={styles.tablesubject}>GRADING SYSTEM</TableCell>
        </TableHeader>
        <TableBody>
          <DataTableCell
            style={styles.tableRating}
            weighting={0.23}
            getContent={(r) => r.label1}
          />
          <DataTableCell
            style={styles.table}
            weighting={0.23}
            getContent={(r) => r.label2}
          />
          <DataTableCell
            style={styles.tableRating}
            weighting={0.23}
            getContent={(r) => r.label3}
          />
          <DataTableCell
            style={styles.tableRatingUbuntu7}
            weighting={0.31}
            getContent={(r) => r.label4}
          />
        </TableBody>
      </Table>
    )
  }

  const GradingTableBliss = () => {
    return (
      <Table
        data={[
          {
            label1: 'Range',
            label2: 'Grade',
          },
          {
            label1: '80 - 100',
            label2: 'A',
          },
          {
            label1: '70 - 79',
            label2: 'B',
          },
          {
            label1: '60 - 69',
            label2: 'C',
          },
          {
            label1: '50 - 59',
            label2: 'D',
          },
          {
            label1: '40 - 49',
            label2: 'E',
          },

          {
            label1: '39 - below',
            label2: 'F',
          },
        ]}
      >
        <TableHeader textAlign={'center'}>
          <TableCell style={styles.tablesubject}>GRADING SYSTEM</TableCell>
        </TableHeader>
        <TableBody>
          <DataTableCell
            style={styles.tableRating}
            weighting={0.5}
            getContent={(r) => r.label1}
          />
          <DataTableCell
            style={styles.table}
            weighting={0.5}
            getContent={(r) => r.label2}
          />
        </TableBody>
      </Table>
    )
  }

  const GradingTableModibbo = () => {
    return (
      <Table
        data={[
          {
            label1: 'Range',
            label2: 'Grade',
            label3: 'Remark',
          },
          {
            label1: '80 - 100',
            label2: 'A',
            label3: 'Excellent',
          },
          {
            label1: '70 - 79',
            label2: 'B',
            label3: 'Very Good',
          },
          {
            label1: '50 - 69',
            label2: 'C',
            label3: 'Good',
          },

          {
            label1: '0 - 49',
            label2: 'F',
            label3: 'Fail',
          },
        ]}
      >
        <TableHeader textAlign={'center'}>
          <TableCell style={styles.tablesubject}>GRADING SYSTEM</TableCell>
        </TableHeader>
        <TableBody>
          <DataTableCell
            style={styles.tableRating}
            weighting={0.3}
            getContent={(r) => r.label1}
          />
          <DataTableCell
            style={styles.table}
            weighting={0.3}
            getContent={(r) => r.label2}
          />
          <DataTableCell
            style={styles.tableRating}
            weighting={0.4}
            getContent={(r) => r.label3}
          />
        </TableBody>
      </Table>
    )
  }
  const GradingTableSmartAcademy = () => {
    return (
      <Table
        data={[
          {
            label1: 'Range',
            label2: 'Grade',
            label3: 'Remark',
          },
          {
            label1: '70 - 100',
            label2: 'A',
            label3: 'Excellent',
          },
          {
            label1: '60 - 69',
            label2: 'B',
            label3: 'Very Good',
          },
          {
            label1: '50 - 65',
            label2: 'C',
            label3: 'Good',
          },
          {
            label1: '45 - 49',
            label2: 'D',
            label3: 'Pass',
          },
          {
            label1: '40 - 44',
            label2: 'E',
            label3: 'Fair',
          },

          {
            label1: '0 - 49',
            label2: 'F',
            label3: 'Fail',
          },
        ]}
      >
        <TableHeader textAlign={'center'}>
          <TableCell style={styles.tablesubject}>GRADING SYSTEM</TableCell>
        </TableHeader>
        <TableBody>
          <DataTableCell
            style={styles.tableRating}
            weighting={0.3}
            getContent={(r) => r.label1}
          />
          <DataTableCell
            style={styles.table}
            weighting={0.3}
            getContent={(r) => r.label2}
          />
          <DataTableCell
            style={styles.tableRating}
            weighting={0.4}
            getContent={(r) => r.label3}
          />
        </TableBody>
      </Table>
    )
  }
  const GradingTableFlyingCols = () => {
    return (
      <Table
        data={[
          {
            label1: 'Range',
            label2: 'Grade',
            label3: 'Remark',
          },
          {
            label1: '80 - 100',
            label2: 'A1',
            label3: 'Excellent',
          },
          {
            label1: '70 - 79.9',
            label2: 'B2',
            label3: 'Very Good',
          },
          {
            label1: '65 - 69.9',
            label2: 'B3',
            label3: 'Good',
          },
          {
            label1: '60 - 64.9',
            label2: 'C4',
            label3: 'Credit',
          },
          {
            label1: '55 - 59.9',
            label2: 'C5',
            label3: 'Credit',
          },
          {
            label1: '50 - 54.9',
            label2: 'C6',
            label3: 'Credit',
          },
          {
            label1: '45 - 49.9',
            label2: 'D7',
            label3: 'Pass',
          },
          {
            label1: '40 - 44.9',
            label2: 'E8',
            label3: 'Pass',
          },

          {
            label1: '0 - 39.9',
            label2: 'F',
            label3: 'Fail',
          },
        ]}
      >
        <TableHeader textAlign={'center'}>
          <TableCell style={styles.tablesubject}>GRADING SYSTEM</TableCell>
        </TableHeader>
        <TableBody>
          <DataTableCell
            style={styles.tableRating}
            weighting={0.3}
            getContent={(r) => r.label1}
          />
          <DataTableCell
            style={styles.table}
            weighting={0.3}
            getContent={(r) => r.label2}
          />
          <DataTableCell
            style={styles.tableRating}
            weighting={0.4}
            getContent={(r) => r.label3}
          />
        </TableBody>
      </Table>
    )
  }

  const RatingTableModibbo = () => {
    return (
      <Table
        data={[
          {
            label2: 'Grade',
            label3: 'Remark',
          },
          {
            label2: 'A',
            label3: 'Excellent',
          },
          {
            label2: 'B',
            label3: 'Very Good',
          },
          {
            label2: 'C',
            label3: 'Good',
          },
          {
            label2: 'D',
            label3: 'Fair',
          },

          {
            label2: 'F',
            label3: 'Poor',
          },
        ]}
      >
        <TableHeader textAlign={'center'}>
          <TableCell style={styles.tablesubject}>RATING SYSTEM</TableCell>
        </TableHeader>
        <TableBody>
          <DataTableCell
            style={styles.table}
            weighting={0.3}
            getContent={(r) => r.label2}
          />
          <DataTableCell
            style={styles.tableRating}
            weighting={0.4}
            getContent={(r) => r.label3}
          />
        </TableBody>
      </Table>
    )
  }
  const RatingTableSmartAcademy = () => {
    return (
      <Table
        data={[
          {
            label2: 'Grade',
            label3: 'Remark',
          },
          {
            label2: 'A',
            label3: 'Excellent',
          },
          {
            label2: 'B',
            label3: 'Very Good',
          },
          {
            label2: 'C',
            label3: 'Good',
          },
          {
            label2: 'D',
            label3: 'Pass',
          },
          {
            label2: 'E',
            label3: 'Fair',
          },

          {
            label2: 'F',
            label3: 'Poor',
          },
        ]}
      >
        <TableHeader textAlign={'center'}>
          <TableCell style={styles.tablesubject}>RATING SYSTEM</TableCell>
        </TableHeader>
        <TableBody>
          <DataTableCell
            style={styles.table}
            weighting={0.3}
            getContent={(r) => r.label2}
          />
          <DataTableCell
            style={styles.tableRating}
            weighting={0.4}
            getContent={(r) => r.label3}
          />
        </TableBody>
      </Table>
    )
  }
  const MyTable3 = () => {
    return (
      <Table
        data={[
          {
            firstName: 'John',
            lastName: 'Smith',
            dob: new Date(2000, 1, 1),
            country: 'Australia',
            phoneNumber: 'xxx-0000-0000',
          },
        ]}
      >
        <TableHeader textAlign={'center'}>
          <TableCell weighting={0.08}>Student's Name</TableCell>
          <TableCell weighting={0.08}>Last Name</TableCell>
          <TableCell>DOB</TableCell>
          <TableCell>Country</TableCell>
          <TableCell>Phone Number</TableCell>
        </TableHeader>
        <TableBody>
          <DataTableCell weighting={0.08} getContent={(r) => r.firstName} />
          <DataTableCell weighting={0.08} getContent={(r) => r.lastName} />
          <DataTableCell getContent={(r) => r.dob.toLocaleString()} />
          <DataTableCell getContent={(r) => r.country} />
          <DataTableCell getContent={(r) => r.phoneNumber} />
        </TableBody>
      </Table>
    )
  }
  function getUnique(arr, index) {
    const unique = arr
      .map((e) => e[index])

      // store the keys of the unique objects
      .map((e, i, final) => final.indexOf(e) === i && i)

      // eliminate the dead keys & store unique objects
      .filter((e) => arr[e])
      .map((e) => arr[e])

    return unique
  }

  function getPosition(index) {
    var scores = []

    student.map((stu) => {
      scores.push(getTotalScore(stu))
    })

    var positions = findRanks(scores)
    return positions[index]
  }
  function getTotalScore(record) {
    var totalScore = 0
    subject.map((subj) => {
      const uniqueStatesArray = removeDuplicates(
        subj.students,
        'doc_id',
        'subject'
      )
      uniqueStatesArray.map((stud) => {
        if (record.doc_id == stud.doc_id) {
          totalScore = totalScore + Number(stud.total)
        }
      })
    })

    return totalScore
  }

  function getAverage(record) {
    var totalScore = 0
    var count = 0
    var avg = 0
    subject.map((subj) => {
      const uniqueStatesArray = removeDuplicates(
        subj.students,
        'doc_id',
        'subject'
      )
      uniqueStatesArray.map((stud) => {
        if (record.doc_id == stud.doc_id) {
          totalScore = totalScore + Number(stud.total)
          count = count + 1
        }
      })
    })
    avg = totalScore / count
    var avgFixed = avg.toFixed(1)
    return avgFixed
  }
  function getGrade(totalValue) {
    const currentSchoolName = reactLocalStorage.get('school-name')
    const modibbo = 'MODIBBO IDRIS ACADEMY, GOMBE'

    const currentSchool = reactLocalStorage.get('current-school')
    const albayan = 'ATi6GodoPjPJkLfzU5OQ'

    if (currentSchoolName === modibbo) {
      return getGradeModibbo(totalValue)
    }
    if (currentSchool === albayan) {
      return getGradeAlbayan(totalValue)
    }

    var grade = ''
    if (totalValue >= 70) {
      grade = 'A'
    } else if (totalValue >= 60) {
      grade = 'B'
    } else if (totalValue >= 50) {
      grade = 'C'
    } else if (totalValue >= 45) {
      grade = 'D'
    } else if (totalValue >= 40) {
      grade = 'E'
    } else {
      grade = 'F'
    }
    return grade
  }

  function getGradeDetails(record) {
    const currentSchoolName = reactLocalStorage.get('school-name')
    const modibbo = 'MODIBBO IDRIS ACADEMY, GOMBE'

    var totalScore = 0
    var totalValue = 0
    var size = 0
    subject.map((subj) => {
      const uniqueStatesArray = removeDuplicates(
        subj.students,
        'doc_id',
        'subject'
      )
      uniqueStatesArray.map((stud) => {
        if (record.doc_id == stud.doc_id) {
          totalScore = totalScore + Number(stud.total)
        }
      })
      size = subject.length
    })

    totalValue = totalScore / size

    if (currentSchoolName === modibbo) {
      return getGradeModibbo(totalValue)
    }

    var grade = ''
    if (totalValue >= 70) {
      grade = 'A'
    } else if (totalValue >= 60) {
      grade = 'B'
    } else if (totalValue >= 50) {
      grade = 'C'
    } else if (totalValue >= 45) {
      grade = 'D'
    } else if (totalValue >= 40) {
      grade = 'E'
    } else {
      grade = 'F'
    }
    return grade
  }
  function getGradeModibbo(totalValue) {
    var grade = ''
    if (totalValue >= 80) {
      grade = 'A'
    } else if (totalValue >= 70) {
      grade = 'B'
    } else if (totalValue >= 50) {
      grade = 'C'
    } else {
      grade = 'F'
    }
    return grade
  }

  function getGradeArabic(gradeValue) {
    var grade = ''
    if (gradeValue >= 'A') {
      grade = 'A'
    } else if (gradeValue >= 'B') {
      grade = 'B'
    } else if (gradeValue >= 'C') {
      grade = 'C'
    } else if (gradeValue >= 'D') {
      grade = 'D'
    } else if (gradeValue >= 'E') {
      grade = 'E'
    } else {
      grade = 'F'
    }
    return grade
  }
  function getGradeAlbayan(totalValue) {
    var grade = ''
    if (totalValue >= 75) {
      grade = 'A1'
    } else if (totalValue >= 74) {
      grade = 'B2'
    } else if (totalValue >= 69) {
      grade = 'B3'
    } else if (totalValue >= 64) {
      grade = 'C4'
    } else if (totalValue >= 59) {
      grade = 'C5'
    } else if (totalValue >= 54) {
      grade = 'C6'
    } else if (totalValue >= 49) {
      grade = 'D7'
    } else if (totalValue >= 44) {
      grade = 'E8'
    } else {
      grade = 'F9'
    }
    return grade
  }
  function getGradeAlbayanFirstAssessment(totalValue) {
    var grade = ''
    if (totalValue >= 12) {
      grade = 'A1'
    } else if (totalValue >= 11.5) {
      grade = 'B2'
    } else if (totalValue >= 10.5) {
      grade = 'B3'
    } else if (totalValue >= 9.5) {
      grade = 'C4'
    } else if (totalValue >= 8.75) {
      grade = 'C5'
    } else if (totalValue >= 7.75) {
      grade = 'C6'
    } else if (totalValue >= 6.75) {
      grade = 'D7'
    } else if (totalValue >= 6) {
      grade = 'E8'
    } else {
      grade = 'F9'
    }
    return grade
  }
  function getGradeAlbayanSecondAssessment(totalValue) {
    var grade = ''
    if (totalValue >= 22.5) {
      grade = 'A1'
    } else if (totalValue >= 22) {
      grade = 'B2'
    } else if (totalValue >= 20.5) {
      grade = 'B3'
    } else if (totalValue >= 19) {
      grade = 'C4'
    } else if (totalValue >= 17.75) {
      grade = 'C5'
    } else if (totalValue >= 15.75) {
      grade = 'C6'
    } else if (totalValue >= 13.75) {
      grade = 'D7'
    } else if (totalValue >= 12) {
      grade = 'E8'
    } else {
      grade = 'F9'
    }
    return grade
  }

  function getSubjectRemark(totalValue) {
    const currentSchoolName = reactLocalStorage.get('school-name')
    const modibbo = 'MODIBBO IDRIS ACADEMY, GOMBE'
    const currentSchool = reactLocalStorage.get('current-school')
    const albayan = 'ATi6GodoPjPJkLfzU5OQ'
    if (currentSchoolName === modibbo) {
      return getSubjectRemarkModibbo(totalValue)
    }
    if (currentSchool === albayan) {
      return getSubjectRemarkAlbayan(totalValue)
    }

    var grade = ''
    if (totalValue >= 70) {
      grade = 'Excellent'
    } else if (totalValue >= 60) {
      grade = 'Very Good'
    } else if (totalValue >= 50) {
      grade = 'Good'
    } else if (totalValue >= 45) {
      grade = 'Pass'
    } else if (totalValue >= 40) {
      grade = 'Pass'
    } else {
      grade = 'Fail'
    }
    return grade
  }
  function getSubjectRemarkModibbo(totalValue) {
    var grade = ''
    if (totalValue >= 80) {
      grade = 'Excellent'
    } else if (totalValue >= 70) {
      grade = 'Very Good'
    } else if (totalValue >= 50) {
      grade = 'Good'
    } else {
      grade = 'Fail'
    }
    return grade
  }
  function getSubjectRemarkAlbayan(totalValue) {
    var grade = ''
    if (totalValue >= 75) {
      grade = 'Distinction'
    } else if (totalValue >= 74) {
      grade = 'Very Good'
    } else if (totalValue >= 69) {
      grade = 'Good'
    } else if (totalValue >= 64) {
      grade = 'Credit'
    } else if (totalValue >= 59) {
      grade = 'Credit'
    } else if (totalValue >= 54) {
      grade = 'Credit'
    } else if (totalValue >= 49) {
      grade = 'Pass'
    } else if (totalValue >= 44) {
      grade = 'Pass'
    } else {
      grade = 'Fail'
    }
    return grade
  }
  function getSubjectRemarkAlbayanFirstAssessment(totalValue) {
    var remark = ''
    if (totalValue >= 11.25) {
      remark = 'Distinction'
    } else if (totalValue >= 11) {
      remark = 'Very Good'
    } else if (totalValue >= 10.25) {
      remark = 'Good'
    } else if (totalValue >= 9.5) {
      remark = 'Credit'
    } else if (totalValue >= 8.85) {
      remark = 'Credit'
    } else if (totalValue >= 7.85) {
      remark = 'Credit'
    } else if (totalValue >= 6.85) {
      remark = 'Pass'
    } else if (totalValue >= 6) {
      remark = 'Pass'
    } else {
      remark = 'Fail'
    }
    return remark
  }

  function getSubjectRemarkAlbayanSecondAssessment(totalValue) {
    var remark = ''
    if (totalValue >= 22.5) {
      remark = 'Distinction'
    } else if (totalValue >= 22) {
      remark = 'Very Good'
    } else if (totalValue >= 20.5) {
      remark = 'Good'
    } else if (totalValue >= 19) {
      remark = 'Credit'
    } else if (totalValue >= 17.75) {
      remark = 'Credit'
    } else if (totalValue >= 15.75) {
      remark = 'Credit'
    } else if (totalValue >= 13.75) {
      remark = 'Pass'
    } else if (totalValue >= 12) {
      remark = 'Pass'
    } else {
      remark = 'Fail'
    }
    return remark
  }

  function getRemark(record) {
    const currentSchool = reactLocalStorage.get('current-school')
    const currentSchoolName = reactLocalStorage.get('school-name')
    const blissGombe = 'BLISS SUCCESS MODEL SCHOOL'
    var totalScore = 0
    var count = 0
    var avg = 0
    var name = ''
    var gender = ''
    if (currentSchoolName === blissGombe) {
      return BlissRemark(record)
    }

    subject.map((subj) => {
      const uniqueStatesArray = removeDuplicates(
        subj.students,
        'doc_id',
        'subject'
      )
      uniqueStatesArray.map((stud) => {
        if (record.doc_id == stud.doc_id) {
          var studentName = findStudentNameById(student, stud.doc_id)
          totalScore = totalScore + Number(stud.total)
          name = stud.name
          count = count + 1
          gender =
            studentName !== 'Student not found'
              ? studentName.gender
              : stud.gender
        }
      })
    })
    avg = totalScore / count
    var avgFixed = avg.toFixed(1)

    var remark = ''
    if (avgFixed >= 70) {
      remark = success[randomNumberInRange()]
    } else if (avgFixed >= 60) {
      remark = success[randomNumberInRange()]
    } else if (avgFixed >= 50) {
      remark = improvement[randomNumberInRange()]
    } else if (avgFixed >= 45) {
      remark = assistance[randomNumberInRange()]
    } else if (avgFixed >= 40) {
      remark = assistance[randomNumberInRange()]
    } else {
      remark = assistance[randomNumberInRange()]
    }

    //remark = remark.replaceAll('has', name)
    //remark = remark.replaceAll('Has', name)
    if (gender.toUpperCase() === 'MALE') {
      remark = remark.replaceAll('himself/herself', 'himself')
      remark = remark.replaceAll('his/her', 'his')
      remark = remark.replaceAll('he/she', 'he')
      remark = remark.replaceAll('him/her', 'him')
      remark = remark.replaceAll('He/she', 'He')
    } else {
      remark = remark.replaceAll('himself/herself', 'herself')
      remark = remark.replaceAll('his/her', 'her')
      remark = remark.replaceAll('he/she', 'she')
      remark = remark.replaceAll('him/her', 'her')
      remark = remark.replaceAll('He/she', 'She')
    }
    return remark
  }
  function BlissRemark(record) {
    var totalScore = 0
    var count = 0
    var avg = 0
    var name = ''
    var gender = ''

    subject.map((subj) => {
      const uniqueStatesArray = removeDuplicates(
        subj.students,
        'doc_id',
        'subject'
      )
      uniqueStatesArray.map((stud) => {
        if (record.doc_id == stud.doc_id) {
          totalScore = totalScore + Number(stud.total)
          name = stud.name
          count = count + 1
          gender = stud.gender
        }
      })
    })
    avg = totalScore / count
    var avgFixed = avg.toFixed(1)

    var remark = ''
    if (avgFixed >= 80) {
      remark = 'An excellent Result Keep it up'
    } else if (avgFixed >= 70) {
      remark = 'A very Good Result, Keep it up'
    } else if (avgFixed >= 60) {
      remark = 'A good result, Put in more effort next term'
    } else if (avgFixed >= 50) {
      remark = 'An Average Result, Put in more effort next term'
    } else if (avgFixed >= 40) {
      remark = 'A passed result, work harder on all Subjects'
    } else {
      remark = 'A Failed Result, work harder on all subjects'
    }

    return remark
  }
  function getSubjectsAverage(subjValue) {
    var totals = []
    subject.map((sub) => {
      const uniqueStatesArray = removeDuplicates(
        sub.students,
        'doc_id',
        'subject'
      )
      if (subjValue === sub.subject) {
        //console.log('::', sub.students)
        uniqueStatesArray.map((stu) => {
          totals.push(stu.total)
        })
      }
    })
    return totals
  }

  // Function to remove duplicates based on 'name' and 'code' properties
  function removeDuplicates(array, key1, key2) {
    const seen = new Set()
    return array.filter((item) => {
      const identifier = item[key1] + item[key2]
      if (!seen.has(identifier)) {
        seen.add(identifier)
        return true
      }
      return false
    })
  }

  function getCAData(record) {
    //setLoading(false)
    var dat = []
    var count = 1

    subject.map((subj) => {
      const uniqueStatesArray = removeDuplicates(
        subj.students,
        'doc_id',
        'subject'
      )
      uniqueStatesArray.map((stud, index) => {
        if (record.doc_id == stud.doc_id) {
          if (!stud.test2) {
            stud.test2 = 0
          }

          var sumCaMo =
            parseInt(stud.ass1 !== '-' ? stud.ass1 : 0) +
            parseInt(stud.ass2 !== '-' ? stud.ass2 : 0) +
            parseInt(stud.test !== '-' ? stud.test : 0) +
            parseInt(stud.test2 !== '-' ? stud.test2 : 0)

          dat.push({
            index: (
              <View style={styles.tableLeft2}>
                <Text>{count}</Text>
              </View>
            ),
            name: (
              <View style={styles.tableLeft2}>
                <Text>{stud.name.toUpperCase()}</Text>
              </View>
            ),
            subject: (
              <View style={styles.tablesubject}>
                <Text>{subj.subject}</Text>
              </View>
            ),
            ass1: (
              <View style={styles.table}>
                <Text>{stud.ass1}</Text>
              </View>
            ),
            ass2: (
              <View style={styles.table}>
                <Text>{stud.ass2}</Text>
              </View>
            ),
            test: (
              <View style={styles.table}>
                <Text>{stud.test}</Text>
              </View>
            ),

            test2: (
              <View style={styles.table}>
                <Text>{stud.test2}</Text>
              </View>
            ),
            camodibbo: (
              <View style={styles.table}>
                <Text>{sumCaMo !== 0 ? sumCaMo : '-'}</Text>
              </View>
            ),
            exam: (
              <View style={styles.table}>
                <Text>{stud.exam}</Text>
              </View>
            ),
            total: (
              <View style={styles.total}>
                <Text>{stud.total}</Text>
              </View>
            ),
            total2: (
              <View style={styles.table}>
                <Text>{stud.total}</Text>
              </View>
            ),
            grade: (
              <View style={styles.table}>
                <Text>{getGrade(stud.total)}</Text>
              </View>
            ),
            pos: (
              <View style={styles.table}>
                <Text>{stud.position}</Text>
              </View>
            ),
            out: (
              <View style={styles.table}>
                <Text>{subj.students.length}</Text>
              </View>
            ),

            avg: (
              <View style={styles.table}>
                <Text>{stud.class_average}</Text>
              </View>
            ),
            remark: (
              <View style={styles.tableLeft}>
                <Text>{getSubjectRemark(stud.total)}</Text>
              </View>
            ),
          })
          count++
        }
        // console.log(subj.subject, '::', averages.length)
      })
    })
    //setData(dat)
    //setLoading(false)
    // setShowDownload(false)

    return dat
  }
  function getCADataArabicSubjectName(record) {
    //setLoading(false)
    var dat = []
    var count = 1

    subject.map((subj) => {
      const uniqueStatesArray = removeDuplicates(
        subj.students,
        'doc_id',
        'subject'
      )
      uniqueStatesArray.map((stud, index) => {
        if (record.doc_id == stud.doc_id) {
          if (!stud.test2) {
            stud.test2 = 0
          }

          var sumCaMo =
            parseInt(stud.ass1 !== '-' ? stud.ass1 : 0) +
            parseInt(stud.ass2 !== '-' ? stud.ass2 : 0) +
            parseInt(stud.test !== '-' ? stud.test : 0) +
            parseInt(stud.test2 !== '-' ? stud.test2 : 0) 
            dat.push({
              index: (
                <View style={styles.tableLeft2}>
                  <Text>{count}</Text>
                </View>
              ),
              name: (
                <View style={styles.tableLeft2}>
                  <Text>{stud.name.toUpperCase()}</Text>
                </View>
              ),
              subject: (
                <View style={styles.tablesubjectUbuntu}>
                  <Text>{subj.subject}</Text>
                </View>
              ),
              ass1: (
                <View style={styles.table}>
                  <Text>{stud.ass1}</Text>
                </View>
              ),
              ass2: (
                <View style={styles.table}>
                  <Text>{stud.ass2}</Text>
                </View>
              ),
              test: (
                <View style={styles.table}>
                  <Text>{stud.test}</Text>
                </View>
              ),

              test2: (
                <View style={styles.table}>
                  <Text>{stud.test2}</Text>
                </View>
              ),
              camodibbo: (
                <View style={styles.table}>
                  <Text>{sumCaMo !== 0 ? sumCaMo : '-'}</Text>
                </View>
              ),
              exam: (
                <View style={styles.table}>
                  <Text>{stud.exam}</Text>
                </View>
              ),
              total: (
                <View style={styles.total}>
                  <Text>{stud.total}</Text>
                </View>
              ),
              total2: (
                <View style={styles.table}>
                  <Text>{stud.total}</Text>
                </View>
              ),
              grade: (
                <View style={styles.table}>
                  <Text>{getGrade(stud.total)}</Text>
                </View>
              ),
              pos: (
                <View style={styles.table}>
                  <Text>{stud.position}</Text>
                </View>
              ),
              out: (
                <View style={styles.table}>
                  <Text>{subj.students.length}</Text>
                </View>
              ),

              avg: (
                <View style={styles.table}>
                  <Text>{stud.class_average}</Text>
                </View>
              ),
              remark: (
                <View style={styles.tableLeft}>
                  <Text>{getSubjectRemark(stud.total)}</Text>
                </View>
              ),
            })
          count++
        }
        // console.log(subj.subject, '::', averages.length)
      })
    })

    return dat
  }
  function getCADataArabicSubjectNameSunnah(record) {
    //setLoading(false)
    var dat = []
    var count = 1

    subject.map((subj) => {
      const uniqueStatesArray = removeDuplicates(
        subj.students,
        'doc_id',
        'subject'
      )
      uniqueStatesArray.map((stud, index) => {
        if (record.doc_id == stud.doc_id) {
          if (!stud.test2) {
            stud.test2 = 0
          }

          var sumCaMo =
            parseInt(stud.ass1 && stud.ass1 !== '-' ? stud.ass1 : 0) +
            parseInt(stud.ass2 && stud.ass2 !== '-' ? stud.ass2 : 0) +
            parseInt(stud.test && stud.test !== '-' ? stud.test : 0) +
            parseInt(stud.test2 && stud.test2 !== '-' ? stud.test2 : 0) 

       

            dat.push({
              index: (
                <View style={styles.tableLeft2}>
                  <Text>{count}</Text>
                </View>
              ),
              name: (
                <View style={styles.tableLeft2}>
                  <Text>{stud.name.toUpperCase()}</Text>
                </View>
              ),
              subject: (
                <View style={styles.tablesubjectUbuntu}>
                  <Text>{subj.subject}</Text>
                </View>
              ),
              ass1: (
                <View style={styles.table}>
                  <Text>{sumCaMo !== 0 ? sumCaMo : '-'}</Text>
                </View>
              ),
              ass2: (
                <View style={styles.table}>
                  <Text>{stud.ass2}</Text>
                </View>
              ),
              test: (
                <View style={styles.table}>
                  <Text>{stud.test}</Text>
                </View>
              ),

              test2: (
                <View style={styles.table}>
                  <Text>{stud.test2}</Text>
                </View>
              ),
              camodibbo: (
                <View style={styles.table}>
                  <Text>{sumCaMo !== 0 ? sumCaMo : '-'}</Text>
                </View>
              ),
              exam: (
                <View style={styles.table}>
                  <Text>{stud.exam}</Text>
                </View>
              ),
              total: (
                <View style={styles.total}>
                  <Text>{stud.total}</Text>
                </View>
              ),
              total2: (
                <View style={styles.table}>
                  <Text>{stud.total}</Text>
                </View>
              ),
              grade: (
                <View style={styles.table}>
                  <Text>{getGrade(stud.total)}</Text>
                </View>
              ),
              pos: (
                <View style={styles.table}>
                  <Text>{stud.position}</Text>
                </View>
              ),
              out: (
                <View style={styles.table}>
                  <Text>{subj.students.length}</Text>
                </View>
              ),

              avg: (
                <View style={styles.table}>
                  <Text>{stud.class_average}</Text>
                </View>
              ),
              remark: (
                <View style={styles.tableLeft}>
                  <Text>{getSubjectRemark(stud.total)}</Text>
                </View>
              ),
            })
          count++
        }
        // console.log(subj.subject, '::', averages.length)
      })
    })

    return dat
  }
  function getCADataArabicSubjectNameAlbayan(record) {
    //setLoading(false)
    var dat = []
    var count = 1
    var english = /^[A-Za-z0-9]*$/

    subject.map((subj) => {
      const uniqueStatesArray = removeDuplicates(
        subj.students,
        'doc_id',
        'subject'
      )

      uniqueStatesArray.map((stud, index) => {
        if (record.doc_id == stud.doc_id) {
          if (!stud.test2) {
            stud.test2 = 0
          }

          var sumCaMo =
            parseInt(stud.ass1 !== '-' ? stud.ass1 : 0) +
            parseInt(stud.ass2 !== '-' ? stud.ass2 : 0) +
            parseInt(stud.test !== '-' ? stud.test : 0) +
            parseInt(stud.test2 !== '-' ? stud.test2 : 0) 
            dat.push({
              index: (
                <View style={styles.tableLeft8}>
                  <Text>{count}</Text>
                </View>
              ),
              name: (
                <View style={styles.tableLeft}>
                  <Text>{stud.name.toUpperCase()}</Text>
                </View>
              ),
              subject: (
                <View style={styles.tablesubjectUbuntu8}>
                  <Text>
                    {isEnglishString(subj.subject)
                      ? subj.subject
                      : getSubjectTranslationById(schoolSubject, subj.subject)
                          .translation}
                  </Text>
                </View>
              ),
              subjectArabic: (
                <View style={styles.tablesubjectUbuntuRight8}>
                  <Text>
                    {isEnglishString(subj.subject)
                      ? getSubjectTranslationById(schoolSubject, subj.subject)
                          .translation
                      : subj.subject}
                  </Text>
                </View>
              ),
              ass1: (
                <View style={styles.table}>
                  <Text>{stud.ass1}</Text>
                </View>
              ),
              ass2: (
                <View style={styles.table}>
                  <Text>{stud.ass2}</Text>
                </View>
              ),
              test: (
                <View style={styles.table}>
                  <Text>{stud.test}</Text>
                </View>
              ),

              test2: (
                <View style={styles.table}>
                  <Text>{stud.test2}</Text>
                </View>
              ),
              camodibbo: (
                <View style={styles.table}>
                  <Text>{sumCaMo !== 0 ? sumCaMo : '-'}</Text>
                </View>
              ),
              exam: (
                <View style={styles.table}>
                  <Text>{stud.exam}</Text>
                </View>
              ),
              total: (
                <View style={styles.totalUbuntu8}>
                  <Text>{stud.total}</Text>
                </View>
              ),
              total2: (
                <View style={styles.totalUbuntu8}>
                  <Text>{stud.total}</Text>
                </View>
              ),
              grade: (
                <View style={styles.table}>
                  <Text>{getGrade(stud.total)}</Text>
                </View>
              ),
              pos: (
                <View style={styles.table}>
                  <Text>{stud.position}</Text>
                </View>
              ),
              out: (
                <View style={styles.table}>
                  <Text>{subj.students.length}</Text>
                </View>
              ),

              avg: (
                <View style={styles.table}>
                  <Text>{stud.class_average}</Text>
                </View>
              ),
              remark: (
                <View style={styles.tableLeft8}>
                  <Text>{getSubjectRemark(stud.total)}</Text>
                </View>
              ),
            })
          count++
        }
        // console.log(subj.subject, '::', averages.length)
      })
    })

    return dat
  }
  function getCADataArabicSubjectNameAlbayanFirstAssessment(record) {
    //setLoading(false)
    var dat = []
    var count = 1
    var english = /^[A-Za-z0-9]*$/

    subject.map((subj) => {
      const uniqueStatesArray = removeDuplicates(
        subj.students,
        'doc_id',
        'subject'
      )

      uniqueStatesArray.map((stud, index) => {
        if (record.doc_id == stud.doc_id) {
          if (!stud.test2) {
            stud.test2 = 0
          }

          var sumCaMo =
            parseInt(stud.ass1 !== '-' ? stud.ass1 : 0) +
            parseInt(stud.ass2 !== '-' ? stud.ass2 : 0) +
            parseInt(stud.test !== '-' ? stud.test : 0) +
            parseInt(stud.test2 !== '-' ? stud.test2 : 0) 
            dat.push({
              index: (
                <View style={styles.tableLeft8}>
                  <Text>{count}</Text>
                </View>
              ),
              name: (
                <View style={styles.tableLeft}>
                  <Text>{stud.name.toUpperCase()}</Text>
                </View>
              ),
              subject: (
                <View style={styles.tablesubjectUbuntu8}>
                  <Text>
                    {isEnglishString(subj.subject)
                      ? subj.subject
                      : getSubjectTranslationById(schoolSubject, subj.subject)
                          .translation}
                  </Text>
                </View>
              ),
              subjectArabic: (
                <View style={styles.tablesubjectUbuntuRight8}>
                  <Text>
                    {isEnglishString(subj.subject)
                      ? getSubjectTranslationById(schoolSubject, subj.subject)
                          .translation
                      : subj.subject}
                  </Text>
                </View>
              ),
              ass1: (
                <View style={styles.table}>
                  <Text>{stud.ass1}</Text>
                </View>
              ),
              ass2: (
                <View style={styles.table}>
                  <Text>{stud.ass2}</Text>
                </View>
              ),
              test: (
                <View style={styles.table}>
                  <Text>{stud.test}</Text>
                </View>
              ),

              test2: (
                <View style={styles.table}>
                  <Text>{stud.test2}</Text>
                </View>
              ),

              exam: (
                <View style={styles.table}>
                  <Text>{stud.exam}</Text>
                </View>
              ),
              total: (
                <View style={styles.totalUbuntu8}>
                  <Text>{stud.total}</Text>
                </View>
              ),
              total2: (
                <View style={styles.totalUbuntu8}>
                  <Text>{stud.total}</Text>
                </View>
              ),
              grade: (
                <View style={styles.table}>
                  <Text>{getGradeAlbayanSecondAssessment(stud.total)}</Text>
                </View>
              ),
              pos: (
                <View style={styles.table}>
                  <Text>{stud.position}</Text>
                </View>
              ),
              out: (
                <View style={styles.table}>
                  <Text>{subj.students.length}</Text>
                </View>
              ),

              avg: (
                <View style={styles.table}>
                  <Text>{stud.class_average}</Text>
                </View>
              ),
              remark: (
                <View style={styles.tableLeft8}>
                  <Text>
                    {getSubjectRemarkAlbayanSecondAssessment(stud.total)}
                  </Text>
                </View>
              ),
            })
          count++
        }
        // console.log(subj.subject, '::', averages.length)
      })
    })

    return dat
  }

  function isEnglishString(str) {
    // Define regular expressions for English and Arabic characters
    const englishRegex = /^[A-Za-z0-9\s!"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]+$/
    const arabicRegex = /^[\u0600-\u06FF\s]+$/

    // Test the string against the regular expressions
    if (englishRegex.test(str)) {
      return true // English characters detected
    } else if (arabicRegex.test(str)) {
      return false // Arabic characters detected
    } else {
      return true // Assume English if neither English nor Arabic characters detected
    }
  }

  function getSubjectTranslationById(array, doc_id) {
    // Iterate through the array of objects
    for (let i = 0; i < array.length; i++) {
      // Check if the current object's doc_id matches the provided doc_id
      if (array[i].id === doc_id) {
        // Return the object if a match is found
        return array[i]
      }
    }
    // Return null if no matching object is found
    return null
  }

  const getSchoolSubjects = () => {
    var db = firebase.firestore()
    var query = db.collection('school-subjects')
    query
      .doc(reactLocalStorage.get('current-school'))
      .get()
      .then((doc) => {
        if (doc.exists) {
          setSchoolSubject(doc.data().subject)
          setLoading(false)
        }
      })
      .catch((error) => {
        console.log('Error getting document:', error)
      })
  }
  function getCADataSmartAcademy(record) {
    //setLoading(false)
    var dat = []
    var count = 1

    subject.map((subj) => {
      const uniqueStatesArray = removeDuplicates(
        subj.students,
        'doc_id',
        'subject'
      )
      uniqueStatesArray.map((stud, index) => {
        if (record.doc_id == stud.doc_id) {
          var arr = getSubjectsAverage(subj.subject)
          var max = arr.reduce(function (a, b) {
            return Math.max(a, b)
          })
          var min = arr.reduce(function (a, b) {
            return Math.min(a, b)
          })

          dat.push({
            index: (
              <View style={styles.tableLeft2}>
                <Text>{count}</Text>
              </View>
            ),
            name: (
              <View style={styles.tableLeft2}>
                <Text>{stud.name.toUpperCase()}</Text>
              </View>
            ),
            subject: (
              <View style={styles.tablesubject}>
                <Text>{subj.subject}</Text>
              </View>
            ),
            ass1: (
              <View style={styles.table}>
                <Text>{stud.ass1}</Text>
              </View>
            ),
            ass2: (
              <View style={styles.table}>
                <Text>{stud.ass2}</Text>
              </View>
            ),
            test: (
              <View style={styles.table}>
                <Text>{stud.test}</Text>
              </View>
            ),
            exam: (
              <View style={styles.table}>
                <Text>{stud.exam}</Text>
              </View>
            ),
            total: (
              <View style={styles.total}>
                <Text>{stud.total}</Text>
              </View>
            ),
            total2: (
              <View style={styles.table}>
                <Text>{stud.total}</Text>
              </View>
            ),
            grade: (
              <View style={styles.table}>
                <Text>{stud.grade}</Text>
              </View>
            ),
            pos: (
              <View style={styles.table}>
                <Text>{stud.position}</Text>
              </View>
            ),
            out: (
              <View style={styles.table}>
                <Text>{subj.students.length}</Text>
              </View>
            ),
            highest: (
              <View style={styles.table}>
                <Text>{max}</Text>
              </View>
            ),
            lowest: (
              <View style={styles.table}>
                <Text>{min}</Text>
              </View>
            ),
            avg: (
              <View style={styles.table}>
                <Text>{stud.class_average}</Text>
              </View>
            ),
            remark: (
              <View style={styles.tableLeft}>
                <Text>{stud.remark}</Text>
              </View>
            ),
          })
          count++
        }
        // console.log(subj.subject, '::', averages.length)
      })
    })
    //setData(dat)
    //setLoading(false)
    // setShowDownload(true)
    return dat
  }

  const findRanks = (arr = []) => {
    const { length } = arr
    let sortArray = arr.slice()
    sortArray.sort((a, b) => b - a)
    const result = []
    for (let i = 0; i < length; i++) {
      const j = sortArray.indexOf(arr[i])
      result.push(j + 1)
    }

    return result
  }
  const getTerm = () => {
    setTermEnd(reactLocalStorage.get('end'))
    setNextTerm(reactLocalStorage.get('next'))
    setShowPosition(reactLocalStorage.get('position'))
    //var date1 = new Date(reactLocalStorage.get('start'))
    //var date2 = new Date(reactLocalStorage.get('end'))
    //var difference_In_Time = date2.getTime() - date1.getTime()
    // To calculate the no. of days between two dates
    //var difference_In_Days = difference_In_Time / (1000 * 3600 * 24)

    const publicHolidays = [
      '2023-01-02',
      '2023-04-07',
      '2023-04-10',
      '2023-04-21',
      '2023-05-01',
      '2023-05-29',
      '2023-06-12',
      '2023-06-28',
      '2023-06-29',
      '2023-09-27',
      '2023-10-02',
      '2023-12-25',
      '2023-12-26',
      '2024-01-01',
      '2024-03-29',
      '2024-04-09',
      '2024-05-01',
      '2024-06-12',
      '2024-06-17',
      '2024-09-15',
      '2024-10-01',
      '2024-12-25',
      '2024-12-26',
    ] // Example public holidays

    var difference_In_Days = getBusinessDaysDiff(
      reactLocalStorage.get('start'),
      reactLocalStorage.get('end'),
      publicHolidays
    )
    setTimesOpen(difference_In_Days)
  }

  function getBusinessDaysDiff(startDate, endDate, publicHolidays) {
    const currentDate = new Date(startDate)
    const finalDate = new Date(endDate)

    let businessDays = 0

    while (currentDate <= finalDate) {
      const dayOfWeek = currentDate.getDay() // 0 = Sunday, 1 = Monday, ..., 6 = Saturday

      // Check if it's not a Saturday (6) or Sunday (0) and not a public holiday
      if (
        dayOfWeek !== 0 &&
        dayOfWeek !== 6 &&
        !isPublicHoliday(currentDate, publicHolidays)
      ) {
        businessDays++
      }

      currentDate.setDate(currentDate.getDate() + 1)
    }

    return businessDays
  }

  function isPublicHoliday(date, publicHolidays) {
    // Convert the date to a string in 'yyyy-mm-dd' format
    const dateString = date.toISOString().split('T')[0]

    // Check if the date string matches any public holiday date
    return publicHolidays.includes(dateString)
  }

  function randomNumberInRange() {
    // 👇️ get number between min (inclusive) and max (inclusive)
    return Math.floor(Math.random() * (9 - 0 + 1)) + 0
  }

  function PdfWithQrCode({ value }) {
    var school = reactLocalStorage.get('current-school')
    var newValue = `https://edusaf-629d9.web.app/v/${value}`
    // var newValue = `http://172.20.10.4:3000/v/${value}`

    const qrCodeComponent = <QRCode value={newValue} renderAs="svg" size={30} />

    const qrCodeComponentStaticMarkup = renderToStaticMarkup(qrCodeComponent)

    const parsedQrCodeSvg = parseQrCodeMarkup(qrCodeComponentStaticMarkup)
    if (!parsedQrCodeSvg) {
      return null
    }

    return (
      <View>
        <Svg style={styles.qrcode} viewBox="0 0 29 29">
          {parsedQrCodeSvg.props.children
            .filter((c) => c.type === 'path')
            .map((child, index) => (
              <Path key={index} d={child.props.d} fill={child.props.fill} />
            ))}
        </Svg>
      </View>
    )
  }

  const parseQrCodeMarkup = (markup) => {
    let parsedQrCodeSvg = null

    ReactHtmlParser(markup).forEach((el) => {
      const { type } = el
      if (type === 'svg') {
        parsedQrCodeSvg = el
      }
    })

    return parsedQrCodeSvg
  }

  const BroadSheet = () => {
    var logo = reactLocalStorage.get('heading')
    const sch = reactLocalStorage.get('school-name')
    if (sch === 'IMAMUL BUKHARI ACADEMY, GOMBE') {
      switch (classValue) {
        case 'IBN MAJAH':
        case "NASA'I":
        case 'TIRMIZI':
        case 'ABU DAWOOD':
        case 'AHMAD BN HAMBAL':
        case "IMAMUSH SHAFI'A":
        case 'MALIK BN ANAS':
        case 'ANAS BN MALIK':
        case 'MUSLIM BN HAJJAJ':
        case 'ABDULLAHI BN ABBAS':
        case 'ABDULLAHI BN AMRU BN ASS':
        case 'ABDULLAHI BN MAS’UD':
        case 'SA’AD BN ABI WAQQAS':
        case 'ABDULLAHI BN UMAR':
        case 'ABDULLAHI BN QAISIN':
          {
            console.log('hhh:: ', classValue)
            logo =
              'https://firebasestorage.googleapis.com/v0/b/edusaf-629d9.appspot.com/o/heading%2FJsTijJ2meHpVfoAGeWC7_islamic.jpg?alt=media&token=d3b07cc4-a89c-4a26-888b-bf2e789ac8a5'
          }
          break
      }
    }
    const currentSchool = reactLocalStorage.get('current-school')
    const currentSchoolName = reactLocalStorage.get('school-name')
    const modibbo = 'MODIBBO IDRIS ACADEMY, GOMBE'
    const albayan = 'ATi6GodoPjPJkLfzU5OQ'
    //const modibbo = 'Northride Secondary School'
    return (
      <Document>
        <Page size="A4" orientation="landscape" style={styles.page}>
          <Image style={currentSchool !== albayan ? styles.logo : styles.logo2} src={logo} />
          <View style={styles.school2}>
            <Text>BROAD SHEET</Text>
          </View>
          <BroadSheetHeading />

          <View>
            <Text style={styles.section}>''</Text>
          </View>
          <MyTableBroadSheet />
          <View>
            <Text style={styles.section}>''</Text>
          </View>

          {/* <View>
            <View style={styles.tableBehaviourParent}>
              <View style={styles.pupils}>
                <Text>Subject Analysis</Text>
              </View>
              <View>
                <Text style={styles.section2}>''</Text>
              </View>
              <View style={styles.pupils}>
                <Text></Text>
              </View>
            </View>

            <View style={styles.tableBehaviourParent}>
              <View style={styles.tableBehaviour}>
                 <SubjectAnalysisTable />
              </View>
             
              <View style={styles.tableBehaviour}>
                <Text style={styles.section2}>''</Text>
              </View>
              <View style={styles.pupils}></View>
            </View>
          </View> */}

          <View style={styles.edusaf}>
            <Text>Powered by edutech</Text>
          </View>
        </Page>
      </Document>
    )
  }

  const BroadSheetHeading = () => {
    return (
      <Table
        data={[
          {
            label1: 'CLASS: '.concat(classValue.toUpperCase()),
            label2: 'TERM: '.concat(termValue.toUpperCase()),
            label3: 'SESSION: '.concat(sessionValue),
          },
        ]}
      >
        <TableBody>
          <DataTableCell
            weighting={0.33}
            style={styles.studentDetails}
            getContent={(r) => r.label1}
          />
          <DataTableCell
            weighting={0.33}
            style={styles.studentDetails}
            getContent={(r) => r.label2}
          />
          <DataTableCell
            weighting={0.34}
            style={styles.studentDetails}
            getContent={(r) => r.label3}
          />
        </TableBody>
      </Table>
    )
  }
  const MyTableBroadSheet = () => {
    const alImanCollegeJos = 'Al-iman College Jos'
    const currentSchoolName = reactLocalStorage.get('school-name')
    const currentSchool = reactLocalStorage.get('current-school')
    const albayan = 'ATi6GodoPjPJkLfzU5OQ'
    const sunnahJosDogonDutse = 'dF1Ve6tDZfykDTtl8Xs7'
      const sunnahJos = '8B2gLb0TzZBOzkLc5byX'


    const arabicFont =
      currentSchoolName === alImanCollegeJos || currentSchool === albayan || currentSchool === sunnahJosDogonDutse || currentSchool === sunnahJos
    var dt = getCADataBroadSheet()

    var columnWidth = parseFloat(0.71) / parseFloat(subject.length)
    return (
      <Table data={[...dt]}>
        <TableHeader>
          <TableCell weighting={0.04} style={styles.tableRating2}>
            <View>
              <Text>SN</Text>
            </View>
          </TableCell>
          <TableCell weighting={0.25} style={styles.tableRating2}>
            <View>
              <Text>NAME</Text>
            </View>
          </TableCell>

          {subject.map((subj) => {
            return (
              <TableCell
                weighting={columnWidth}
                style={arabicFont ? styles.tableUbuntu : styles.table}
              >
                <View>
                  <Text>{subj.subject.slice(0, 4).toUpperCase()}</Text>
                </View>
              </TableCell>
            )
          })}

          <TableCell weighting={columnWidth} style={styles.table}>
            <View>
              <Text>TOTAL</Text>
            </View>
          </TableCell>

          <TableCell weighting={columnWidth} style={styles.table}>
            <View>
              <Text>AVG</Text>
            </View>
          </TableCell>
          <TableCell weighting={columnWidth} style={styles.table}>
            <View>
              <Text>GRADE</Text>
            </View>
          </TableCell>
          <TableCell weighting={columnWidth} style={styles.table}>
            <View>
              <Text>POS</Text>
            </View>
          </TableCell>
        </TableHeader>
        <TableBody>
          <DataTableCell
            style={styles.tableRating2}
            weighting={0.04}
            getContent={(r) => r.index}
          />
          <DataTableCell
            style={styles.tableRating2}
            weighting={0.25}
            getContent={(r) => r.name}
          />
          {subject.map((subj, index) => {
            // console.log('sub::: ', subj.students[index].subject)
            return (
              <DataTableCell
                style={styles.table2}
                weighting={columnWidth}
                // getContent={(r) =>
                //   r.subjectNames[index] === subj.students[index].subject &&
                //   r.subjectNames[index] === subj.subject
                //     ? r.subject[index]
                //     : '-'
                // }
                getContent={(r) => r.subject[index]}
              />
            )
          })}
          <DataTableCell
            style={styles.table2}
            weighting={columnWidth}
            getContent={(r) => r.total}
          />

          <DataTableCell
            style={styles.table2}
            weighting={columnWidth}
            getContent={(r) => r.avg}
          />
          <DataTableCell
            style={styles.table2}
            weighting={columnWidth}
            getContent={(r) => r.grade}
          />
          <DataTableCell
            style={styles.table2}
            weighting={columnWidth}
            getContent={(r) => r.pos}
          />
        </TableBody>
      </Table>
    )
  }

  function getCADataBroadSheet() {
    var dat = []
    student.map((stu, index) => {
      var score = getSubjectScores(stu.doc_id)
      var subjectN = getSubjectName(stu.doc_id)
      var totalScore = calTotalScore(score)

      //AVG
      var avg = totalScore / subjectN.length
      var avgFixed = avg.toFixed(1)

      //GRADE
      var gradeValue = totalScore / score.length

      const currentSchool = reactLocalStorage.get('current-school')
      const albayan = 'ATi6GodoPjPJkLfzU5OQ'

      dat.push({
        index: index + 1,
        name: stu.name.toUpperCase(),
        subject: score,
        subjectNames: subjectN,
        total: totalScore,
        doc_id: stu.doc_id,
        pos: getPosition(index),
        avg: avgFixed,
        grade: currentSchool === albayan ? getGradeAlbayanSecondAssessment(gradeValue):  getGrade(gradeValue),
      })
    })

    // Sorting the array by the 'total' property in ascending order
    const sortedArray = dat.sort((a, b) => b.total - a.total)

    // Updating the 'index' property based on the new position
    sortedArray.forEach((item, index) => {
      item.index = index + 1
    })
    return sortedArray
  }

  function getSubjectScores(doc_id) {
    var score = []
    var subs = []
    subject.forEach((subj) => {
      const uniqueStatesArray = removeDuplicates(
        subj.students,
        'doc_id',
        'subject'
      )
      let subjectScore = '-'
      uniqueStatesArray.forEach((stud) => {
        if (doc_id === stud.doc_id && stud.subject === subj.subject) {
          subjectScore = stud.total
        }
      })
      subs.push(subj.subject)
      score.push(subjectScore)
    })
    return score
  }

  function getSubjectName(doc_id) {
    var subjectNames = []
    subject.map((subj, index) => {
      const uniqueStatesArray = removeDuplicates(
        subj.students,
        'doc_id',
        'subject'
      )
      uniqueStatesArray.map((stud, ind) => {
        if (doc_id === stud.doc_id && stud.subject === subj.subject) {
          if (subjectNames.indexOf(subj.subject) === -1) {
            subjectNames.push(subj.subject)
          }
        }
      })
    })
    return subjectNames
  }

  function calTotalScore(scores) {
    var score = 0
    scores.forEach((s) => {
      if (s !== '-') {
        score += parseFloat(s)
      }
    })
    return score
  }

  const SubjectAnalysisTable = () => {
    return (
      <Table
        data={[
          {
            label1: 'CODE',
            label2: 'NAME',
            label3: 'LOWEST SCORE',
            label4: 'HIGHEST SCORE',
          },
          {
            label1: 'AGRI',
            label2: 'AGRICULTURAL SCIENCE',
            label3: '35',
            label4: '100',
          },
          {
            label1: 'BASI',
            label2: 'BASIC SCIENCE',
            label3: '40',
            label4: '89',
          },
          {
            label1: 'BIOL',
            label2: 'BIOLOGY SCIENCE',
            label3: '20',
            label4: '90',
          },
          {
            label1: 'CHEM',
            label2: 'CHEMISTRY',
            label3: '15',
            label4: '96',
          },
        ]}
      >
        {/* <TableHeader textAlign={'center'}>
          <TableCell style={styles.tablesubject}>SUBJECT ANALYSIS</TableCell>
        </TableHeader> */}
        <TableBody>
          <DataTableCell
            style={styles.tableRating}
            weighting={0.2}
            getContent={(r) => r.label1}
          />
          <DataTableCell
            style={styles.tableRating}
            weighting={0.4}
            getContent={(r) => r.label2}
          />
          <DataTableCell
            style={styles.tableRating}
            weighting={0.2}
            getContent={(r) => r.label3}
          />
          <DataTableCell
            style={styles.tableRating}
            weighting={0.2}
            getContent={(r) => r.label4}
          />
        </TableBody>
      </Table>
    )
  }

//   const generatePdfDocument = async (documentData,fileName) => {
//     const blob = await pdf((
//         <MyDocument
//             title='My PDF'
//             pdfDocumentData={documentData}
//         />
//     )).toBlob();
//     saveAs(blob, fileName);
// };

  return (
    <div className="">
      <div className="mx-0 md:mx-12 md:my-3 bg-primary md:bg-white  ">
        <p className="md:hidden py-3 md:py-0 ml-4 md:ml-0 text-white md:text-gray-800 font-medium text-lg">
          People
        </p>
      </div>

      <div className="">
        <div className="m:0 md:mx-12 md:my-5  ">
          <div className="grid grid-flow-col items-center mb-5">
            <div className="grid grid-cols-2 gap-2 md:flex text-center items-center text-primary rounded-sm px-3 md:px-0  md:space-x-4"> 
              {/* <select
                className="rounded-sm p-2 w-full md:w-56 focus:outline-none focus:ring-1 bg-gray-50"
                placeholder="session"
                required
                name="sessionValue"
                value={sessionValue}
                onChange={(e) => setSessionValue(e.target.value)}
              >
              
                <option value="2022/2023">2022/2023</option>
                <option value="2023/2024">2023/2024</option>
                <option value="2024/2025">2024/2025</option>
              </select>

              <select
                className="rounded-sm p-2 w-full md:w-56 focus:outline-none focus:ring-1 bg-gray-50"
                placeholder="term"
                required
                name="termValue"
                value={termValue}
                onChange={(e) => setTermValue(e.target.value)}
              >
            
                <option value="First Term">First Term</option>
                <option value="Second Term">Second Term</option>
                <option value="Third Term">Third Term</option>
              </select> */}

              <select
                className="rounded-sm p-2 w-full md:w-56 focus:outline-none focus:ring-1 bg-gray-50"
                placeholder="class"
                required
                name="classValue"
                value={classValue}
                onChange={(e) => getSubjects(e)}
              >
                <option value="select" disabled selected>
                  select
                </option>
                {classDocument.map((classes, index) =>
                  classes.click ? (
                    <option
                      className="uppercase"
                      key={classes.id}
                      value={classes.name}
                    >
                      {classes.name}
                    </option>
                  ) : (
                    ''
                  )
                )}
              </select>
            </div>
          </div>

          {loading ? (
            <div className="grid  justify-center items-center ">
              <i
                className={' text-primary fas fa-circle-notch fa-spin text-5xl'}
              ></i>
            </div>
          ) : (
            <div className="my-5 ">
              <div className="grid px-4 md:px-0 gap-2 md:gap-4  grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4  xl_1:grid-cols-5 ">
                {subject.length !== 0 ? (
                  subject.map((subj, index) => (
                    <div
                      key={index}
                      className={subj.click ? clickStyle : defaultStyle}
                      // onClick={() =>
                      //   handleClick(subj.subject, subj.id, subj.click, index)
                      // }
                    >
                      <div className="flex justify-between">
                        <div className="">
                          {/* <input
                            checked={subj.click}
                            type="checkbox"
                            id={subj.id}
                            value={subj.subject}
                          />{' '} */}
                          <label className="capitalize">{subj.subject}</label>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <p className="">No assessment record found</p>
                )}
              </div>

              {activated  && subject.length !== 0 ? (
                <div className="grid grid-flow-col gap-10 py-5 px-4 md:px-0  justify-end">
                 <button
                    onClick={() => {
                      // emptyRecord ? handleSave() : handleUpdate()
                      setShowDownload(true)
                      //SetCAData()
                    }}
                    className="px-3 py-1.5  rounded-sm outline-none focus:outline-none text-white  bg-primary"
                  >
                    {loading ? (
                      <i
                        className={'fas fa-circle-notch fa-spin text-base'}
                      ></i>
                    ) : !showDownload ? (
                      <p>RESULT SHEET</p>
                    ) : (
                      <div>
                        <div className="download-link">
                          <PDFDownloadLink
                            document={<MyDocument />}
                            fileName={fileName}
                          >
                            {({ blob, url, loading, error }) =>
                            loading ? ( <i
                                className={'fas fa-circle-notch fa-spin text-base'}
                              ></i>) : 'DOWNLOAD RESULT SHEET'
                            }
                          </PDFDownloadLink>
                        </div>
                      </div>
                    )}
                  </button>
                  <button
                    onClick={() => {
                      // emptyRecord ? handleSave() : handleUpdate()
                      setShowDownloadBroad(true)
                      //SetCAData()
                    }}
                    className="px-3 py-1.5  rounded-sm outline-none focus:outline-none text-white  bg-primary"
                  >
                    {loadingBroad ? (
                      <i
                        className={'fas fa-circle-notch fa-spin text-base'}
                      ></i>
                    ) : !showDownloadBroad ? (
                      <p>BROAD SHEET</p>
                    ) : (
                      <div>
                        <div className="download-link">
                          <PDFDownloadLink
                            document={<BroadSheet />}
                            fileName={'Broadsheet'.concat(fileName)}
                          >
                            {({ blob, url, loadingBroad, error }) =>
                              loadingBroad
                                ? 'Loading...'
                                : 'DOWNLOAD BROAD SHEET'
                            }
                          </PDFDownloadLink>
                        </div>
                      </div>
                    )}
                  </button>
                </div>
              ) : (
                ''
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default AssessmentReport
