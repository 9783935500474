import { useState } from 'react'
import firebase from 'firebase/app'
import { reactLocalStorage } from 'reactjs-localstorage'
import { useEffect } from 'react'
import User from './User'

const ManageUser = ({ user, setSubTitle, setClickPeople }) => {
  const [userClick, setUserClick] = useState(user)
  const [userCount, setUserCount] = useState()
  const tabDefaultStyle =
    'text-white text-xs md:text-sm  md:text-gray-600 uppercase py-4 px-4 md:px-6 block hover:text-blue-500 focus:outline-none'
  const tabClickStyle =
    'uppercase text-sm md:text-sm  py-4 px-4 md:px-6 block md:hover:text-blue-500 focus:outline-none text-white md:text-blue-500 border-b-2 font-medium md:border-blue-500'

  useEffect(() => {
  }, [])

  

  //TEMPORAY
  return (
    <div className="">
      <div className="mx-0 md:mx-12 md:my-3 bg-primary md:bg-white  ">
        <p className="md:hidden py-3 md:py-0 ml-4 md:ml-0 text-white md:text-gray-800 font-medium text-lg">
          People
        </p>
      </div>
      <div className="">
        <div className="m:0 md:mx-12 md:my-5  ">
          <div class="bg-primary md:bg-gray-50">
            <nav class="flex flex-row">
              {/* <button
                onClick={() => {
                  handleTabClick('teacher')
                  // setClickPeople(true);
                  // setSubTitle("Teachers");
                }}
                className={roleClick ? tabClickStyle : tabDefaultStyle}
              >
                Role ({roleCount})
              </button> */}

              <button
                onClick={() => {
                 // handleTabClick('student')
                  // setClickPeople(true);
                  // setSubTitle("Students");
                }}
                className={tabClickStyle}
              >
                User ({userCount})
              </button>
            </nav>
          </div>

          <div className="my-5 ">
            <User
              key="user"
              setUserCount={setUserCount}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ManageUser
