import { useState } from 'react'
import AddSubject from './AddSubject'
import firebase from 'firebase/app'
import { reactLocalStorage } from 'reactjs-localstorage'
import { NotificationManager } from 'react-notifications'
import 'react-notifications/lib/notifications.css'
import {
  PDFDownloadLink,
  PDFViewer,
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
} from '@react-pdf/renderer'

const WebManual = ({ loading }) => {
  const defaultStyle = 'px-3 py-1.5 bg-gray-50 select-none cursor-default'
  const clickStyle = 'px-3 py-1.5 bg-account_click select-none cursor-default'

  const [load, setLoad] = useState()
  const [error, setError] = useState()

  if (loading) {
    return (
      <div className="grid  justify-center items-center ">
        <i className={' text-primary fas fa-circle-notch fa-spin text-5xl'}></i>
      </div>
    )
  }

  return (
    <div className="">
      <div className="px-7   mb-10 text-sm md:text-base">
        <p className="text-justify md:text-left">
          Welcome to Edusaf, the innovative school management system designed to
          streamline your school's administrative processes and enhance
          collaboration among staff, teachers, and parents. Edusaf is divided
          into two components: the web platform for school administration and
          the mobile app for teachers and school staff. This division ensures
          efficient management and allows teachers and staff to access important
          features even without an internet connection. Let's get started!
        </p>
        <p className="my-2 font-semibold">
          The Web Platform for School Administration
        </p>
        <p className="font-medium">Account Creation:</p>
        <ol className="list-decimal">
          <li className="text-justify md:text-left">
            Visit the Edusaf website at www.edusaf.com
          </li>
          <li className="text-justify md:text-left">
            Click on the "Sign Up" button to create your account.
          </li>
          <li className="text-justify md:text-left">
            Select school account and click on next to continue
          </li>
          <li className="text-justify md:text-left">
            Enter email address and desired password.
          </li>
          <li className="text-justify md:text-left">
            Click on the "Sign up with your email" button to complete the
            registration process.
          </li>
        </ol>
        <p className="mt-2 font-medium">Account Verification:</p>
        <ol className="list-decimal">
          <li className="text-justify md:text-left">
            After creating your account, you will receive a verification email
            at the provided email address.
          </li>
          <li className="text-justify md:text-left">
            Open the email and click on the verification link to verify your
            account.
          </li>
          <li className="text-justify md:text-left">
            Once verified, you can proceed to log in to your Edusaf account.
          </li>
        </ol>
        <p className="mt-2 font-medium">Logging In</p>
        <ol className="list-decimal">
          <li className="text-justify md:text-left">
            Click on the "Continue" button to login
          </li>
          <li className="text-justify md:text-left">
            Enter your registered email address and password.
          </li>
          <li className="text-justify md:text-left">
            Click on the "Sign in with your email" button to access your Edusaf
            account.
          </li>
        </ol>
        <p className="mt-2 font-medium">Getting Started:</p>
        <ol className="list-decimal">
          <li className="text-justify md:text-left">
            Upon logging in, you will be guided through a setup process to
            configure your school's details.
          </li>
          <li className="text-justify md:text-left">
            Provide the necessary information, such as school name, state,
            address and contact details
          </li>
          <li className="text-justify md:text-left">
            Follow the on-screen prompts to complete the setup process.
          </li>
        </ol>
        <p className="mt-2 font-medium">Manage School:</p>
        <ol className="list-decimal">
          <li className="text-justify md:text-left">
            To manage school settings, click on "Manage School" from the left
            navigation menu.
          </li>
          <li className="text-justify md:text-left">
            The "Manage School" section contains Term, Subject, Class, and Fee
            Settings.
          </li>
          <li className="text-justify md:text-left">
            In the "Term" section, set the current session and term, and select
            the term start date, end date, and next term begin date from the
            date selection.
          </li>
          <li className="text-justify md:text-left">
            . In the "Subject" section, you can select subjects from the list of
            displayed subjects or add new ones if needed.
          </li>
          <li className="text-justify md:text-left">
            In the "Class" section, you can follow a similar process as subjects
            to manage and add new classes.
          </li>
          <li className="text-justify md:text-left">
            In the "Fee" section, enter class fee amount for each term.
          </li>
        </ol>
        <p className="mt-2 font-medium">Manage People:</p>
        <ol className="list-decimal">
          <li className="text-justify md:text-left">
            To manage teachers and students, click on "Manage People" from the
            left navigation menu.
          </li>
          <li className="text-justify md:text-left">
            The "Manage People" menu allows you to add and manage teachers and
            students.
          </li>
          <li className="text-justify md:text-left">
            In the "Teachers" section, you can add new teachers by providing
            their details such as name, contact information, and assigned
            subjects.
          </li>
          <li className="text-justify md:text-left">
            In the "Students" section, you can add new students by entering
            their details such as name, contact information, and assigned
            classes.
          </li>
        </ol>
        <p className="mt2 font-medium">User Roles and Permissions:</p>
        <ol className="list-decimal">
          <li className="text-justify md:text-left">
            Edusaf offers different user roles to teachers, such as teaching,
            form master, and accountant.
          </li>
          <li className="text-justify md:text-left">
            Each user role has specific permissions and access levels within the
            system.
          </li>
          <li className="text-justify md:text-left">
            Ensure that user accounts are assigned the appropriate roles and
            permissions based on their responsibilities.
          </li>
        </ol>
        <p className="mt-2 font-medium">Feature Exploration:</p>
        <ol className="list-decimal ">
          <li className="text-justify md:text-left">
            Navigate through the menu options to explore various features of
            Edusaf.
          </li>
          <li className="text-justify md:text-left">
            Familiarize yourself with features such as attendance tracking, fee
            management, assessment management and reports.
          </li>
          <li className="text-justify md:text-left">
            Take advantage of the intuitive interface and user-friendly design
            to efficiently manage school-related tasks.
          </li>
        </ol>
      </div>
    </div>
  )
}

export default WebManual
