import React, { useContext, useEffect, useState } from 'react'
import { auth } from '../firebase'

const AuthContext = React.createContext()

export function useAuth() {
  return useContext(AuthContext)
}
export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState()
  const [loading, setLoading] = useState(true)
  function signUp(email, password) {
    return auth.createUserWithEmailAndPassword(email, password)
  }
  function signIn(email, password) {
    return auth.signInWithEmailAndPassword(email, password)
  }
  function disableUser(uid, status) {
    return auth.updateuser(uid, { disabled: status })
  }
  function resentVerification(email) {
    return auth.resentVerification(email)
  }

  function resetPassword(email) {
    return auth.sendPasswordResetEmail(email)
  }

  function signOut() {
    return auth.signOut()
  }

  //ONLY RUN COMPONENT IS MOUNT
  useEffect(() => {
    const unSubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setCurrentUser(user)
      } else {
        setCurrentUser(null)
      }
      setLoading(false)
    })
    return unSubscribe
  }, [])

  const value = {
    currentUser,
    signUp,
    signIn,
    resetPassword,
    resentVerification,
    signOut,
    disableUser,
  }

  if (loading) {
    return (
      <div className="grid min-h-screen justify-center items-center">
        <i className={' text-primary fas fa-circle-notch fa-spin text-5xl'}></i>
      </div>
    )
  }
  return (
    <AuthContext.Provider value={value}>
      {children}
      {/* {!loading && children} */}
    </AuthContext.Provider>
  )
}
