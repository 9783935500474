import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useRef, useState } from 'react'
import firebase from 'firebase/app'
import { reactLocalStorage } from 'reactjs-localstorage'
import { NotificationManager } from 'react-notifications'
import 'react-notifications/lib/notifications.css'

export default function AddSubject({ document, setDocument }) {
  const [open, setOpen] = useState(false)
  const cancelButtonRef = useRef()

  const [name, setName] = useState('')

  const tabClickStyle =
    'uppercase text-sm md:text-sm  py-4 px-4 md:px-6 block  focus:outline-none text-primary md:text-white  border-b-2 font-medium border-primary md:border-white'
  const saveButtonDefault =
    'px-3 py-1.5  rounded-sm outline-none focus:outline-none text-gray-300  bg-gray-100'
  const saveButtonClick =
    'px-3 py-1.5  rounded-sm outline-none focus:outline-none text-white  bg-primary'
  const [errorMessage, setError] = useState('')
  const [loading, setLoading] = useState(false)

  function closeModal() {
    setOpen(false)
    setName('')
  }

  function openModal() {
    setOpen(true)
  }

  const handleSave = () => {
    if (name !== '') {
      const values = [
        ...document,
        {
          name: name.trim(),
          id: name.trim(),
          click: true,
          deletable: false,
        },
      ]
      setDocument(values)
      save(values)
    } else {
      NotificationManager.info('Enter the require fields')
    }
  }

  const save = (values) => {
    setLoading(true)
    setError('')
    var db = firebase.firestore()
    db.collection('school-subjects')
      .doc(reactLocalStorage.get('current-school'))
      .update({
        subject: values,
        date: firebase.firestore.Timestamp.now(),
      })
      .then((docRef) => {
        NotificationManager.info('Added Successful')
        setLoading(false)
      })
      .catch((error) => {
        setError(error.message)
        setLoading(false)
      })
    closeModal()
  }

  return (
    <>
      <div className="">
        <button
          type="button"
          onClick={openModal}
          className="space-x-1 flex justify-center outline-none focus:outline-none border py-2 px-4 rounded-sm"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-5 w-5"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
              clipRule="evenodd"
            />
          </svg>
          Add New
        </button>
      </div>
      <Transition show={open} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-y-auto"
          initialFocus={cancelButtonRef}
          static
          open={open}
          onClose={closeModal}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-full max-w-md border border-gray-100  md:border-none text-sm  overflow-hidden text-left align-middle transition-all transform bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 py-3  md:text-white bg-white md:bg-primary"
                >
                  <nav class="flex flex-row justify-center">
                    <button onClick={() => {}} className={tabClickStyle}>
                      Add Subject
                    </button>
                  </nav>
                </Dialog.Title>
                {
                  <form className="flex flex-col px-5">
                    <label htmlFor="name" className="mt-5 py-1.5">
                      Name
                    </label>
                    <input
                      className="rounded-sm p-2 focus:outline-none focus:ring-1 bg-account_background "
                      type="text"
                      placeholder="subject name"
                      required
                      name="name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </form>
                }
                <div className="px-5 py-5 flex items-end justify-end space-x-5">
                  <button
                    onClick={closeModal}
                    className="px-3 py-1.5 rounded-sm outline-none focus:outline-none text-gray-500 hover:bg-gray-100"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={() => {
                      handleSave()
                    }}
                    className={name ? saveButtonClick : saveButtonDefault}
                  >
                    {loading ? (
                      <i
                        className={'fas fa-circle-notch fa-spin text-base'}
                      ></i>
                    ) : (
                      <p className="2xl:text-lg">Save</p>
                    )}
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}
