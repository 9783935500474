import { Link } from 'react-router-dom';
import school from '../assets/img/school_image.svg';
import edutech from '../assets/img/edutech2.png'

const AuthenticationSidenav = ({type}) => {
    return (
      <div className="bg-primary  ">
        <div className="grid min-h-screen ">
          <a className="p-3" href="https://edutech.com.ng/">
            <img src={edutech} />
          </a>
          {type === "authentication" ? (
            <div className=" select-none text-left px-3 ">
              <p className="text-white font-medium text-lg  md:text-2xl 2xl:text-3xl">
                Manage activities from your school at your convenience.{" "}
              </p>
              <p className="text-white mt-5  text-sm md:text-base 2xl:text-xl">
                Kindly follow the steps to your right to onboard yourself onto
                the school management platform
              </p>
            </div>
          ) : (
            <div className="">
              <div className="flex items-center uppercase">
                <svg
                  width="20"
                  height="20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10 0C4.48 0 0 4.48 0 10s4.48 10 10 10 10-4.48 10-10S15.52 0 10 0zM8 15l-5-5 1.41-1.41L8 12.17l7.59-7.59L17 6l-9 9z"
                    fill="#41E7C5"
                  />
                </svg>
                <p className="ml-2 text-light_green ">school details</p>
              </div>
              <div className="flex items-center uppercase py-4">
                <svg
                  width="20"
                  height="20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="10" cy="10" r="10" fill="#B7B9BE" />
                  <path
                    d="M13.398 16H5.945v-1.04l3.938-4.374c.583-.662.984-1.198 1.203-1.61a2.69 2.69 0 00.336-1.289c0-.593-.18-1.08-.54-1.46-.359-.38-.838-.57-1.437-.57-.718 0-1.278.205-1.68.616-.395.407-.593.974-.593 1.704H5.727c0-1.047.335-1.894 1.007-2.54.677-.645 1.581-.968 2.711-.968 1.058 0 1.893.278 2.508.836.615.552.922 1.289.922 2.21 0 1.12-.713 2.454-2.14 4L7.687 14.82h5.71V16z"
                    fill="#000"
                  />
                </svg>
                <p className="ml-2 text-gray-400 ">personal details</p>
              </div>
              <div className="flex items-center uppercase">
                <svg
                  width="20"
                  height="20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="10" cy="10" r="10" fill="#B7B9BE" />
                  <path
                    d="M8.047 9.61h1.086c.682-.011 1.219-.19 1.61-.54.39-.349.585-.82.585-1.414 0-1.333-.664-2-1.992-2-.625 0-1.125.18-1.5.54-.37.353-.555.825-.555 1.413H5.836c0-.9.328-1.648.984-2.242.662-.599 1.5-.898 2.516-.898 1.073 0 1.914.284 2.523.851.61.568.914 1.357.914 2.367 0 .495-.161.974-.484 1.438-.318.464-.752.81-1.305 1.04.625.197 1.107.525 1.446.983.343.459.515 1.019.515 1.68 0 1.021-.333 1.83-1 2.43-.666.599-1.534.898-2.601.898-1.068 0-1.938-.289-2.61-.867-.666-.578-1-1.341-1-2.289h1.454c0 .599.195 1.078.585 1.438.391.359.915.539 1.57.539.699 0 1.232-.183 1.602-.547.37-.365.555-.888.555-1.57 0-.662-.203-1.17-.61-1.524-.406-.354-.992-.537-1.757-.547H8.047V9.61z"
                    fill="#000"
                  />
                </svg>
                <p className="ml-2 text-gray-400 ">service</p>
              </div>
            </div>
          )}

          <div className=" grid justify-center items-center">
            <img src={school} alt="" />
            <p className="text-gray-400 absolute bottom-0 px-3">
              Copyright © 2024 edutech All right reserved
            </p>
          </div>
        </div>
      </div>
    );
}
 
export default AuthenticationSidenav;