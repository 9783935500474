import { useState, useEffect } from 'react'
import AddClasses from './AddClasses'
import firebase from 'firebase/app'
import { reactLocalStorage } from 'reactjs-localstorage'
import { NotificationManager } from 'react-notifications'
import 'react-notifications/lib/notifications.css'
import Calendar from 'react-calendar'
import School from './School'

const Term = ({
  classDocument,
  classEmptyRecord,
  loading,
  setClassDocument,
}) => {
  const defaultStyle = 'px-3 py-1.5 bg-gray-50 select-none cursor-default'
  const clickStyle = 'px-3 py-1.5 select-none cursor-default'

  const [load, setLoad] = useState()
  const [error, setError] = useState()
  const [classValue, setClassValue] = useState('select')
  const [sessionValue, setSessionValue] = useState('2023/2024')
  const [termValue, setTermValue] = useState('First Term')
  const [selectedClass, setSelectedClass] = useState([])
  const [isNewRecord, setIsNewRecord] = useState(false)
  const [loading2, setLoading2] = useState(false)
  const [termStart, setTermStart] = useState()
  const [termEnd, setTermEnd] = useState()
  const [nextTerm, setNextTerm] = useState()
  const [showPosition, setShowPosition] = useState(true)
  //const [paid, setPaid]= useState([])

  useEffect(() => {
    getTerm()
  }, [])

  const getTerm = () => {
    setLoading2(true)
    var db = firebase.firestore()
    var query = db.collection('term')
    query
      .doc(reactLocalStorage.get('current-school'))
      .get()
      .then((doc) => {
        if (doc.exists) {
          // if (doc.data().session === sessionValue && doc.data().term === termValue
          // ) {
          setTermEnd(doc.data().end)
          setNextTerm(doc.data().next)
          setTermStart(doc.data().start)
          setShowPosition(doc.data().position)
          setTermValue(doc.data().term)
          setSessionValue(doc.data().session)
          setLoading2(false)
          setIsNewRecord(false)
          reactLocalStorage.remove('activated')

          // }
          // else {
          //   setTermStart('')
          //   setTermEnd('')
          //   setNextTerm('')
          //   setShowPosition(false)
          //   NotificationManager.info('No record found')
          //   setIsNewRecord(true)
          //   setLoading2(false)
          // }
        } else {
          setTermStart('')
          setTermEnd('')
          setNextTerm('')
          setShowPosition(false)
          NotificationManager.info('No record found')
          setIsNewRecord(true)
          // doc.data() will be undefined in this case
          //  getClasses()
          //  setClassEmptyRecord(true)
          setLoading2(false)
        }
      })
      .catch((error) => {
        console.log('Error getting document:', error)
        setLoading2(false)
      })
  }

  const save = () => {
    if (termEnd !== '' && nextTerm !== '') {
      setLoad(true)
      setError('')
      var db = firebase.firestore()
      db.collection('term')
        .doc(reactLocalStorage.get('current-school'))
        .set({
          school: reactLocalStorage.get('current-school'),
          session: sessionValue,
          term: termValue,
          start: termStart,
          end: termEnd,
          next: nextTerm,
          position: showPosition,
          date: firebase.firestore.Timestamp.now(),
        })
        .then((docRef) => {
          reactLocalStorage.set('session', sessionValue)
          reactLocalStorage.set('term', termValue)
          reactLocalStorage.set('start', termStart)
          reactLocalStorage.set('end', termEnd)
          reactLocalStorage.set('next', nextTerm)
          reactLocalStorage.set('position', showPosition)

          NotificationManager.info('Added Successful')
          setLoad(false)
        })
        .catch((error) => {
          setError(error.message)
          setLoad(false)
        })
    } else {
      NotificationManager.info('Select term end and next term')
    }
  }

  // const update = () => {
  //   setLoad(true)
  //   setError('')
  //   var db = firebase.firestore()
  //   db.collection('term')
  //     .doc(
  //       reactLocalStorage
  //         .get('current-school')
  //         .concat('_')
  //         .concat(sessionValue.replace('/', '_'))
  //         .concat('_')
  //         .concat(termValue.replace(' ', '_'))
  //     )
  //     .update({
  //       //school: reactLocalStorage.get('current-school'),
  //       // session: sessionValue,
  //       // term: termValue,
  //       start: termStart,
  //       end: termEnd,
  //       next: nextTerm,
  //       position: showPosition,
  //       date: firebase.firestore.Timestamp.now(),
  //     })
  //     .then((docRef) => {
  //       NotificationManager.info('Saved Successful')
  //       setLoad(false)
  //     })
  //     .catch((error) => {
  //       setError(error.message)
  //       setLoad(false)
  //     })
  // }

  if (loading) {
    return (
      <div className="grid  justify-center items-center ">
        <i className={' text-primary fas fa-circle-notch fa-spin text-5xl'}></i>
      </div>
    )
  }

  return (
    <div className="mx-4 md:mx-0">
      <div className=" grid grid-flow-col  justify-start items-center mb-5">
        <div className="text-primary  flex text-center items-center rounded-sm space-x-4">
          <select
            className="rounded-sm p-2 w-40 md:w-56 focus:outline-none focus:ring-1 bg-gray-50 w-full"
            placeholder="session"
            required
            name="sessionValue"
            value={sessionValue}
            onChange={(e) => {
              setSessionValue(e.target.value)
            }}
          >
            {/* <option value="select" selected>
                  Session
                </option> */}
            {/* <option value="2022/2023">2022/2023</option> */}
            <option value="2023/2024">2023/2024</option>
            <option value="2024/2025">2024/2025</option>
          </select>
          <select
            className="rounded-sm p-2 w-40 md:w-56 focus:outline-none focus:ring-1 bg-gray-50 w-full"
            placeholder="term"
            required
            name="termValue"
            value={termValue}
            onChange={(e) => setTermValue(e.target.value)}
          >
            {/* <option value="select" selected>
                  term
                </option> */}
            <option value="First Term">First Term</option>
            <option value="Second Term">Second Term</option>
            <option value="Third Term">Third Term</option>
            {/* <option value="Fourth Term">Fourth Term</option> */}
          </select>

          <button
            type="button"
            onClick={() => getTerm()}
            className="space-x-1 flex justify-center outline-none focus:outline-none border py-2 px-4 rounded-sm"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              class="h-5 w-5"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
              />
            </svg>
            Search
          </button>
        </div>
      </div>
      {!loading2 ? (
        <div className="grid grid-flow-col  justify-start items-center mb-5  ">
          <div className="text-primary  grid grid-cols-1  md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4  xl_1:grid-cols-5 items-center rounded-sm  md:space-x-4">
            <div className="py-4 ">
              <div className="grid grid-flow-col justify-between  md:flex flex-col">
                <label htmlFor="start" className="p-2">
                  Term Starts
                </label>
                <input
                  className="rounded-sm p-2 w-full md:w-56 focus:outline-none focus:ring-1 bg-gray-50"
                  type="date"
                  placeholder="term starts"
                  required
                  name="start"
                  value={termStart}
                  onChange={(e) => setTermStart(e.target.value)}
                />
              </div>
            </div>

            <div className="py-4">
              <div className="grid grid-flow-col justify-between md:flex flex-col">
                <label htmlFor="end" className="p-2">
                  Term Ends
                </label>
                <input
                  className="rounded-sm p-2 w-full md:w-56 focus:outline-none focus:ring-1 bg-gray-50"
                  type="date"
                  placeholder="term ends"
                  required
                  name="end"
                  value={termEnd}
                  onChange={(e) => setTermEnd(e.target.value)}
                />
              </div>
            </div>

            <div className="py-4">
              <div className="grid grid-flow-col justify-between md:flex flex-col ">
                <label htmlFor="next" className="p-2">
                  Next Begins
                </label>
                <input
                  className="rounded-sm p-2 w-full md:w-56 focus:outline-none focus:ring-1 bg-gray-50 "
                  type="date"
                  placeholder="next begins"
                  required
                  name="next"
                  value={nextTerm}
                  onChange={(e) => setNextTerm(e.target.value)}
                />
              </div>
            </div>

            {/* <div className="">
              <label htmlFor="position">Show student position on report </label>
              <div className="  ">
                <input
                  className="rounded-sm p-2 w-5 h-5 focus:outline-none focus:ring-1 bg-gray-50 "
                  type="checkbox"
                  required
                  name="position"
                  checked={showPosition}
                  onChange={(e) => setShowPosition(!showPosition)}
                />
              </div>
            </div> */}
          </div>
        </div>
      ) : (
        <div className="grid  justify-center items-center ">
          <i
            className={' text-primary fas fa-circle-notch fa-spin text-5xl'}
          ></i>
        </div>
      )}

      <div className="grid  px-4 md:px-0  justify-end">
        <button
          onClick={() => {
            save()
          }}
          className="px-5 py-2  rounded-sm outline-none focus:outline-none text-white  bg-primary"
        >
          {load ? (
            <i className={'fas fa-circle-notch fa-spin text-base'}></i>
          ) : (
            <p className="2xl:text-lg">Save</p>
          )}
        </button>
      </div>
    </div>
  )
}

export default Term
