import { useState } from 'react'
import AddSubject from './AddSubject'
import firebase from 'firebase/app'
import { reactLocalStorage } from 'reactjs-localstorage'
import { NotificationManager } from 'react-notifications'
import 'react-notifications/lib/notifications.css'
import {
  PDFDownloadLink,
  PDFViewer,
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
} from '@react-pdf/renderer'

const MobileManual = ({ loading }) => {
  const defaultStyle = 'px-3 py-1.5 bg-gray-50 select-none cursor-default'
  const clickStyle = 'px-3 py-1.5 bg-account_click select-none cursor-default'

  const [load, setLoad] = useState()
  const [error, setError] = useState()

  if (loading) {
    return (
      <div className="grid  justify-center items-center ">
        <i className={' text-primary fas fa-circle-notch fa-spin text-5xl'}></i>
      </div>
    )
  }

  return (
    <div className="">
      <div className="px-7   mb-10 text-sm md:text-base">
        <p className="text-justify md:text-left">
          Welcome to the Edusaf Staff Mobile App! This user manual will guide
          you through the features and functionality of the app, designed
          specifically for teachers and school staff. Let's get started!
        </p>

        <p className="font-medium">Sign Up:</p>
        <ol className="list-decimal">
          <li className="text-justify md:text-left">
            The school administrator will add you as a staff member in Edusaf
            using the email address provided by you
          </li>
          <li className="text-justify md:text-left">
            Once added, open the Staff Mobile App and click on Sign up.
          </li>
          <li className="text-justify md:text-left">
            Enter the email you provided and desired password.
          </li>
          <li className="text-justify md:text-left">
            The app automatically log in after sign up
          </li>
        </ol>
        <p className="mt-2 font-medium">Dashboard:</p>
        <ol className="list-decimal">
          <li className="text-justify md:text-left">
            Upon logging in, you will be taken to the app dashboard.
          </li>
          <li className="text-justify md:text-left">
            The dashboard provides an overview of important information and
            quick access to key features.
          </li>
          <li className="text-justify md:text-left">
            Familiarize yourself with the different sections and navigation
            options available on the dashboard.
          </li>
        </ol>
        <p className="mt-2 font-medium">Manage Students:</p>
        <ol className="list-decimal">
          <li className="text-justify md:text-left">
            Access the "Manage Students" section to view and manage student
            information.
          </li>
          <li className="text-justify md:text-left">
            Add new students, update existing student details, and view student
            profiles.
          </li>
        </ol>
        <p className="mt-2 font-medium">Attendance:</p>
        <ol className="list-decimal">
          <li className="text-justify md:text-left">
            Click on the "Attendance" section to record and manage student
            attendance.
          </li>
          <li className="text-justify md:text-left">
            Use the intuitive interface to mark students as present, absent, or
            tardy.
          </li>
          <li className="text-justify md:text-left">
            Take advantage of features such as bulk attendance entry and
            attendance reports.
          </li>
        </ol>
        <p className="mt-2 font-medium">Assessment:</p>
        <ol className="list-decimal">
          <li className="text-justify md:text-left">
            Access the "Assessment" section to manage and enter student score.
          </li>
          <li className="text-justify md:text-left">
            Enter score for various assignments, test, or exams.
          </li>
        </ol>

        <p className="mt2 font-medium">Payment:</p>
        <ol className="list-decimal">
          <li className="text-justify md:text-left">
            The "Payment" section allows you to manage student fees and
            payments.
          </li>
          <li className="text-justify md:text-left">
            View payment records, record new payments and print payment receipt
          </li>
          <li className="text-justify md:text-left">
            Keep track of student fee balances and easily communicate with
            parents regarding outstanding payments.
          </li>
        </ol>
        <p className="mt-2 font-medium">Feature Exploration:</p>
        <ol className="list-decimal ">
          <li className="text-justify md:text-left">
            Navigate through the menu options to explore various features of
            Edusaf.
          </li>
          <li className="text-justify md:text-left">
            Familiarize yourself with features such as attendance tracking, fee
            management, assessment management and reports.
          </li>
          <li className="text-justify md:text-left">
            Take advantage of the intuitive interface and user-friendly design
            to efficiently manage school-related tasks.
          </li>
        </ol>
      </div>
    </div>
  )
}

export default MobileManual
